<div class="position-relative">
  <nz-upload class="p-image-uploader"
             [ngStyle]="{'padding-bottom': (displayRatioHeight / displayRatioWidth * 100) + '%'}"
             nzAccept = "image/*"
             [nzBeforeUpload]="customUpload">
    <div class="p-box d-flex align-items-center" [ngClass]="{error: !!errors}">
      <div class="p-upload-icon" *ngIf="!picUrl && !uploading">


        <!--<i class="upload-icon" nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>-->

        <i style="font-size: 32px;" nz-icon [nzType]="displayIcon" nzTheme="outline"></i>
        <ng-container *ngIf="displayText">
          <div class="m-3"></div>
          <span>{{displayText}}</span>
        </ng-container>
        <!--<div class="mt-5 p-progress-bar" *ngIf="uploading">-->
        <!--<div class="p-progress-bg" [style.width]="progress*0.2+'rem'"></div>-->
        <!--<div class="p-progress-value">{{progress}}%</div>-->
        <!--</div>-->
      </div>
      <div class="p-upload-progress-bg" *ngIf="uploading">
        <div class="i-bg" [style.width]="progress+'%'"></div>
        <div class="i-text">
          Uploading...
        </div>
      </div>
      <div class="p-preview" *ngIf="picPreview && (!uploading && picUrl) "
           [style.background-image]="picUrl | backgroundImage">
      </div>
    </div>
  </nz-upload>
  <div class="p-btn-delete" *ngIf="canDelete"
       nz-popconfirm nzPopconfirmTitle="Are you sure ?"
       (nzOnConfirm)="onDelete()"
  >
    <i nz-icon nzType="close-circle" nzTheme="outline"></i>
  </div>
</div>
