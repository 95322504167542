import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
// import { ViewthatyouwantGuard } from './path to your view';
import { QupeiyinFormComponent } from '../components/admin/pages/admin/qupeiyin/form/qupeiyin-form.component';

@Injectable()
export class ConfirmDeactivateGuard implements CanDeactivate<QupeiyinFormComponent> {

  canDeactivate(target: QupeiyinFormComponent) {
    if (target.hasChanges) {
      return window.confirm('Do you really want to cancel?');
    }
    return true;
  }

}
