import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
// import {NzNotificationService} from 'ng-zorro-antd/notification';
import { Location } from '@angular/common';
import { UploadImageWithPreviewComponent } from '../../../../common/upload-image-with-preview/upload-image-with-preview.component';
import { StorybookService } from '../../../../../services/storybook.service';
import { LevelsService } from '../../../../../services/levels.service';


@Component({
  providers: [UploadImageWithPreviewComponent ],
  selector: 'app-storybook-add',
  templateUrl: './storybook-add.component.html',
  styleUrls: ['./storybook-add.component.scss'],
})
export class StorybookAddComponent implements OnInit {

  picturesList = [];
  title = '';
  picturesHash = [];
  _piclst = [];
  audioHash = '';
  levelValue = '';
  levelTree = [];
  _tmpFiles = [];
  constructor(
    private fb: UntypedFormBuilder,
    private storybook: StorybookService,
    private level: LevelsService,
    private msg: NzMessageService,
    private uploader: UploadImageWithPreviewComponent,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
  }
  ngOnInit() {
    this.level.fetchLevelTree().then((data) => {
      console.log(data);
      this.levelTree = data;
    });
  }
  titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }
  capitalizeTitle(evt: any) {
    const cap = this.titleCase(evt);
    setTimeout(() => {
      this.title = cap;
    }, 1);
  }
  // onOpenLevelTree(evt: NzFormatEmitEvent) {
  //   if (evt.node.getChildren().length === 0 && evt.node.isExpanded) {
  //     this.loadNode(evt.node.key).then(data => {
  //       const _data = data.map((row) => {
  //         return {
  //           title: row.name,
  //           value: row.id,
  //           key: `${row.id}`
  //         };
  //       });
  //       evt.node.addChildren(_data);
  //     });
  //   }
  // }
  onChangeTreeValue(evt: any) {
    // console.log(evt, this.levelValue);
  }
  onImageUploadSuccess(pic: any) {
    // console.log('onImageUploadSuccess', pic);
    // @ts-ignore
    this._piclst.push(pic);

  }
  onAudioUploadSuccess(audio: any) {
    // console.log('onAudioUploadSuccess', audio);
    this.audioHash = audio.hash;
  }
  sortName = (a: any , b: any) => {
    const _1  = parseInt(a.name.match(/\d+/g)[0], 10);
    const _2  = parseInt(b.name.match(/\d+/g)[0], 10);
    // console.log(_1, _2);
    if (_1 < _2) {
      return -1;
    }
    if (_1 > _2) {
      return 1;
    }
    return 0;
  }
  handleBeforeUpload = (item: any, fileList: any[]) => {
    // this.uploader.fireUpload(item);
    // @ts-ignore
    this._tmpFiles.push(item);
    if (fileList.length === this._tmpFiles.length) {
      this.picturesList = this._tmpFiles;
      this.picturesList.sort(this.sortName);
    }
    return false;
  }
  removeImage(idx: number) {
    let rf: any = this.picturesList.splice(idx, 1);
    if (rf[0]) {
      rf = rf[0];
      // console.log(this._piclst)
      this._piclst = this._piclst.filter((p) => {
        // @ts-ignore
        return p.name !== rf.name;
      });
      // console.log(this._piclst)
      // console.log(this.picturesList);
    }
  }
  doMakeStory(evt?: any) {
    this._piclst.sort(this.sortName);
    // @ts-ignore
    this.picturesHash = this._piclst.map((p) => {
      // @ts-ignore
      return p.hash;
    });
    this.storybook.addResource({
      title: this.title,
      pics: this.picturesHash,
      audio: this.audioHash,
      level_id: this.levelValue
    }).then(data => {
      if (data) {
        this.router.navigate([`/admin/storybook/make/${data}`]);
      }
    }).catch(e => {
      console.log(e);
      this.msg.error('save data error');
    });
  }
}
