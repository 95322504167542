import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class GiftsService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
    // console.log(22222222222222)
    // debugger
    // this.getInfoPromise = this.get('/user/getInfo');
  }

  getGift(id: string|number) {
    return super.get(`/gifts/item/${id}`);
  }

  getGifts(page: string|number) {
    return super.get(`/gifts/list/page/${page}`);
  }

  deleteGift(id: string|number) {
    return super.remove(`/gifts/item/${id}`);
  }
  saveGift(id: string|number, data: any) {
    return super.post(`/gifts/item/${id}`, data);
  }

  addGift(data: any) {
    return super.post(`/gifts/add`, data);
  }

  getGiftsDeliveryStatus(page: string|number, query: any) {
    let q = '';
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      q += `?${searchParams.toString()}`;
    }
    return super.get(`/gifts/delivery/page/${page}${q}`);
  }

  changeGiftsDeliveryStatus(id: string|number, status: string|number) {
    return super.post(`/gifts/item/${id}/delivery/status`, {status});
  }






  changeSubsidyStatus(data: any) {
    return super.post(`/orders/subsidy/status`, data);
  }

  getPrice(quantity: any) {
    return super.post(`/orders/calc-price`, {quantity});

  }

  reSubmitSybsidy(data: any) {
    return super.post(`/orders/subsidy/proof`, data);
  }

  getStatistic(query: any) {
    return super.get(`/orders/statistics?dt=${query.dt}&page=${query.page}`);
  }

  manualAddOrder(data: any) {
    return super.post(`/orders/manual/add`, data);
  }

}
