
<nz-table #classTable [nzData]="displayData"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [nzLoading]="this.loadingData"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalNumber">
    <thead>
    <tr>
        <th>ID</th>
        <th>姓名</th>
        <th>电话</th>
        <th>金币</th>
        <th>奖卷</th>
        <th style="text-align: center">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <!--<td>{{data.id}}</td>-->
        <td>{{data.uid}}</td>
        <td>{{data.name}}</td>
        <td>{{data.phone}}</td>
        <td>{{data.coins}}</td>
        <td>{{data.lottery}}</td>
        <td style="width:20rem">
            <div class="operations">
                <a (click)="restoreStudent(data.del_id)">恢复</a>
            </div>
        </td>
    </tr>
    </tbody>
</nz-table>

<app-teacher-class-selector title="恢复到班级" [schoolId]="schoolId"
                            (ok)="restoreStudentOk($event)"
                            (loaded)="dataLoaded()"
                            (cancel)="restoreStudentCancel()"
                            [visibility]="showClassTeacher"></app-teacher-class-selector>
