// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements  OnInit, AfterViewInit {
  title = 'app';
  notice = null;
  constructor(private router: Router,
              private userService: UserService,
              private http: HttpClient,
              private nzMessageService: NzMessageService,
              private modalService: NzModalService) {
    router.events.subscribe((val) => {
      // see also
      // console.log(val);

      if (val instanceof NavigationEnd  ) {
        this.noticeBrowser();
        const user = this.userService.getUserInfo();
        if (user
          && user.isPL3 && user.isLeakPass
          && val.url !== '/admin/setting/index'
          && val.url !== '/login'
          && val.url !== '/logout') {
          this.nzMessageService.error('密码过弱，请修改密码!');
          this.router.navigate(
            ['/admin/setting/index'],
            { queryParams: { pass: 'change' }});
        }

        // console.log(456);
      }

      if (val instanceof NavigationStart) {
        if (this.notice) {
          this.notice.close();
        }
        // console.log(123);
      }
    });
  }

  noticeBrowser() {
    const isTouchDevice = function() {  return 'ontouchstart' in window || 'onmsgesturechange' in window; };
    const isDesktop = /*window.screenX !== 0 && */!isTouchDevice();
    const isChrome = navigator.userAgent.includes('Chrome') && navigator.vendor.includes('Google Inc');
    const shouldNotice = !isDesktop || !isChrome;
    const style = isDesktop ? {minWidth: '600px'} : {};
    // const style =  {minWidth: '600px'};
    if (!shouldNotice) {
      return;
    }

    setTimeout(() => {
      console.log(this.modalService.confirm);
      // if (this.notice) {
      //   this.notice.open();
      //   return;
      // }
      // window['ccc'] = this;
        this.notice = this.modalService.confirm({
          nzStyle: {minWidth: '600px'},
          nzTitle: '<b>提示</b>',
          nzContent: '<i>为保证您的使用体验，请使用“电脑版谷歌浏览器”打开全阅读管理后台</i>',
          nzOnOk: () => {
            window.open('https://www.google.cn/chrome/', '_blank');
            return false;
          },
          nzOkText: '点击下载谷歌浏览器',
          nzCancelText: '我不怕有问题，我要继续使用当前浏览器'
        });
      }, 0);
  }

  ngOnInit(): void {
    // console.log('app1')
    // http://ip.taobao.com/service/getIpInfo.php?ip=67.218.153.160
    /*this.http.jsonp('https://mips.kugou.com/check/iscn?&format=jsonp', 'callback')
      .subscribe((res: any) => {
        console.log(res)
        if (res.status && 0 == res.flag) {
          const modal = this.modalService.warning({
            nzTitle: '提示',
            nzContent: '抱歉，您所在的国家或地区暂无法提供此服务',
            nzOkText: null
          });
        }
      });*/
  }
  ngAfterViewInit() {

  }
}
