import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LevelsService } from '../../../../services/levels.service';
import { GroupsService } from '../../../../services/groups.service';
import { StorybookService } from '../../../../services/storybook.service';
import { TimerTotal } from './timer-total';


@Component({
  selector: 'app-user-books-resource',
  templateUrl: './user-books-resource.component.html',
  styleUrls: ['./user-books-resource.component.scss'],
})
export class UserBooksResourceComponent implements OnInit, OnDestroy {

  isVisible = false;
  isShowAnswer = false;

  modalData: any = null;
  mediaFlag = '';
  timerTotal: TimerTotal | null | undefined;

  bookId = 0;
  book: {
    name: string
    audios: any[]
    videos: any[]
    quiz: any[]
  } = {
    name: '',
    audios: [],
    videos: [],
    quiz: []
  };
  constructor(
    private bookService: StorybookService,
    private userService: UserService,
    private levelService: LevelsService,
    private groupService: GroupsService,
    private activatedRoute: ActivatedRoute ,
    private router: Router ,
    private nzMessageService: NzMessageService,
  ) {
    this.activatedRoute.params.subscribe( params => {
      if (params['bookId'] && parseInt(params['bookId'], 10) === params['bookId'] * 1) {
        this.bookId = parseInt(params['bookId'], 10);
      }
    });
  }

  showAnswer(item: any) {
    console.log(item);
    item.isShowAnswer = !item.isShowAnswer;
  }
  openPlayer(item: any, flag: any) {
    this.modalData = item;
    this.mediaFlag = flag;
    this.isVisible = true;
    this.timerTotal = new TimerTotal();
  }

  handleModalCancel() {
    // @ts-ignore
    this.timerTotal.stop();
    // 上报数据
    // @ts-ignore
    const minuts = this.timerTotal.getTotalMinute();
    this.reportTotalTime(minuts, this.mediaFlag);

    this.timerTotal = null;
    this.modalData = null;
    this.mediaFlag = '';
    this.isVisible = false;
  }

  reportTotalTime(minuts: number, flag: string) {
    const obj = {
      'listen': 0,
      'animation': 0
    };
    // @ts-ignore
    obj[flag] = minuts;
    this.userService.reportTotalTime(obj);
  }

  mediaPlay() {
    console.log('=========mediaPlay=========');
    // @ts-ignore
    this.timerTotal.begin();
  }

  mediaPause() {
    console.log('=========mediaPause=========');
    // @ts-ignore
    this.timerTotal.pause();
  }

  mediaStop() {
    // @ts-ignore
    this.timerTotal.stop();
    // 上报数据
    // @ts-ignore
    const minuts = this.timerTotal.getTotalMinute();
    this.reportTotalTime(minuts, this.mediaFlag);
    // @ts-ignore
    this.timerTotal.reset();
  }



  ngOnInit(): void {

    console.log('this.bookId', this.bookId);
    this.bookService.fetchBookResource(this.bookId).then(res => {
      console.log(res);
      this.book = res;

    });
    // 浏览器关闭监听
    window.addEventListener('beforeunload', (event) => {
      if (this.timerTotal) {
        this.timerTotal.stop();
        // 上报数据
        const minuts = this.timerTotal.getTotalMinute();
        this.reportTotalTime(minuts, this.mediaFlag);
      }
    });
  }
  onBack() {
    this.router.navigate(['/user/books']);
  }
  indexToLetter(i: number) {
    return String.fromCharCode(65 + i);
  }
  ngOnDestroy(): void {
    if (this.timerTotal) {
      this.timerTotal.stop();
      // 上报数据
      const minuts = this.timerTotal.getTotalMinute();
      this.reportTotalTime(minuts, this.mediaFlag);
    }
  }
}
