<div>
    <nz-input-group nzSearch nzSize="large" [nzSuffix]="suffixButton">
        <input type="text" nz-input [(ngModel)]="inputPhone" placeholder="input search phone">
    </nz-input-group>
    <ng-template #suffixButton>
        <button nz-button nzType="primary" nzSize="large" nzSearch (click)="doSearch('phone')">搜手机号</button>
        <button nz-button nzType="primary" nzSize="large" nzSearch (click)="doSearch('school')">搜学校名</button>
    </ng-template>
</div>
<div>

    <nz-table nzSize="small" #smallTable [nzData]="rows">
        <thead>
        <tr>
            <th>学校id</th>
            <th>学校名</th>

            <th>电话</th>
            <th>id</th>
            <th>姓名</th>
            <th>班级id</th>
            <th>班级名</th>
            <th>班级教师</th>
            <th>教师电话</th>
            <th>类型</th>
<!--            <th>小音箱</th>-->
<!--            <th>操作</th>-->
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let r of smallTable.data">
            <td style="background:#87d068">{{r.school_id}}</td>
            <td style="background:#87d068">{{r.school_name}}</td>
            <td style="background:#2db7f5">{{r.sphone}}</td>
            <td style="background:#2db7f5">{{r.uid}}</td>
            <td style="background:#2db7f5">{{r.name}}</td>

            <td style="background:#f50">{{r.class_id}}</td>
            <td style="background:#f50">{{r.class_name}}</td>
            <td style="background:#f50">{{r.teacher}}</td>
            <td style="background:#f50">{{r.tphone}}</td>
            <td style="background:#f50">{{r.type}}</td>
<!--            <td>{{r.speaker}}</td>-->
<!--            <td>-->
<!--                <ng-container *ngIf="opUserMapList[r.uid]">-->
<!--                <a nz-dropdown [nzDropdownMenu]="opMenu">操作</a>-->
<!--                <nz-dropdown-menu #opMenu="nzDropdownMenu">-->
<!--                    <ul nz-menu nzSelectable>-->
<!--                        <li *ngFor="let item of opUserMapList[r.uid]; let i = index"-->
<!--                            nz-menu-item (click)="opUser(item)" >-->
<!--                            <a >{{item.title}}</a>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </nz-dropdown-menu>-->
<!--                </ng-container>-->
<!--            </td>-->
        </tr>
        </tbody>
    </nz-table>

</div>
