import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'backgroundImage'
})
export class BackgroundImagePipe implements PipeTransform {

  transform(value: any) {
    // let ret = value > 0 ? `url(/api/media-resource/${value}/file)` : '';
    const ret = value ? `url(${value})` : '';
    return ret;
  }

}
