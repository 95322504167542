import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class TrashService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
    // console.log(22222222222222)
    // debugger
    // this.getInfoPromise = this.get('/user/getInfo');
  }
  getItemsList(page: string|number) {

    return super.get(`/trash/${page}`);
  }
  restore(delId: any, classId: any) {
    return super.post(`/trash/user/restore`, {
      del_id: delId,
      class_id: classId
    });
  }

}
