import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SystemService } from '../../../../../services/system.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { UserService } from '../../../../../services/user.service';
import { SchoolService } from '../../../../../services/school.service';

@Component({
  selector: 'app-admin-batch-add-user',
  templateUrl: './batch-add-user.component.html',
  styleUrls: ['./batch-add-user.component.scss']
})
export class AdminBatchAddUserComponent implements OnInit {
  loadingSchool = false;
  selectedSchool: any = null;
  schoolList: any[] = [];
  searchSchoolChange$ = new BehaviorSubject('');
  selectedSchoolAddress = '';
  // @ts-ignore
  @ViewChild('studentTextarea', {static: true}) studentTextarea: ElementRef;

  selectedTeacher: any;
  selectedClass: any;
  teacherList: any[] = [];
  classList: any[] = [];
  teacherCache = {};
  classCache = {};

  students = '';
  isSaving = false;

  newTeacherName = '';
  newTeacherPhone = '';
  newTeacherClassName = '';

  errMsg = false;
  errlist: any[] = [];
  compareFn = (o1: any, o2: any) => o1 && o2 ? o1.id === o2.id : o1 === o2;
  constructor(private sysSrv: SystemService,
              private userSrv: UserService,
              private schoolSrv: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {

  }

  ngOnInit() {
    const getSchoolNameList = (name: string) => {
      this.selectedSchoolAddress = '';
      return this.sysSrv.searchSchool(name)
      .pipe(map((res: any) => {
        return res;
      }));
    };
    const schoolList$: Observable<string[]> = this.searchSchoolChange$.asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getSchoolNameList));
    schoolList$.subscribe(data => {
      this.schoolList = data;
      this.loadingSchool = false;
    });
  }
  doSearchSchool(value: string): void {
    this.loadingSchool = true;
    this.searchSchoolChange$.next(value);

  }

  doSelectSchool(school: any) {
    this.selectedSchool = school;
    this.classList = [] as any[];
    this.teacherList = [] as any[];
    this.selectedClass = null;
    this.selectedTeacher = null;
    // @ts-ignore
    if (this.teacherCache[this.selectedSchool.id]) {
      // @ts-ignore
      this.teacherList = this.teacherCache[this.selectedSchool.id].teachers;
      return;
    }
    if ( Object.keys(this.classCache).length) {
      const _tmp = [];
      for (const tid of Object.keys(this.classCache)) {
        // @ts-ignore
        for (const c of this.classCache[tid]) {
          _tmp.push(c);
        }
      }
      this.classList = _tmp;
    }
    this.sysSrv.loadTeacherAndClasses(this.selectedSchool.id).then(result => {
      // @ts-ignore
      this.teacherCache[this.selectedSchool.id] = result.teachers;
      for (const k of Object.keys(result.teachers)) {
        const t = result.teachers[k];
        // @ts-ignore
        if (this.classCache[t.teacher_id]) {
          // @ts-ignore
          this.classCache[t.teacher_id] = [...this.classCache[t.teacher_id], ...result.classes[t.teacher_id]];
        } else {
          // @ts-ignore
          this.classCache[t.teacher_id] = result.classes[t.teacher_id] ? result.classes[t.teacher_id] : [] ;
        }

      }
      this.teacherList = Object.values(result.teachers);

      if ( Object.keys(this.classCache).length) {
        const _tmp = [];
        for (const tid of Object.keys(this.classCache)) {
          // @ts-ignore
          for (const c of this.classCache[tid]) {
            _tmp.push(c);
          }
        }
        this.classList = _tmp;
      }
    });
  }
  doSelectTeacher(teacher: any) {

    this.selectedTeacher = teacher;
    this.classList = [];
    this.selectedClass = null;
    // @ts-ignore
    if (this.classCache[teacher.teacher_id]) {
      // @ts-ignore
      this.classList = this.classCache[teacher.teacher_id] as any;
    } else {
      alert('no classes for this teacher');
    }
  }
  highlightError(s: any) {
    const s_pos = this.students.indexOf(s);
    const e_pos = this.students.indexOf('\n', s_pos);
    this.studentTextarea.nativeElement.focus();
    this.studentTextarea.nativeElement.selectionStart = s_pos;
    this.studentTextarea.nativeElement.selectionEnd = e_pos;
  }

  save() {
    this.isSaving = true;
    // console.log(this.students);
    // console.log(this.selectedClass.id);
    // console.log(this.selectedTeacher.teacher_id);
    // console.log(this.selectedSchool.id);
    const students = this.students.split('\n');
    const new_s = [];
    const reg = /1[3456789]\d{9}/g;
    let line = 0;
    for (let s of students) {
      line ++;
      s = s.trim();
      if (!s) {
        continue;
      }
      // const rs = reg.exec(s);
      let phone: any = s.match(reg);

      if (!phone || !phone[0].length) {
        this.highlightError(s);
        this.nzMessageService.error(`格式错误，${line}行，不能正确提取手机号`);
        this.isSaving = false;
        return;
      }
      phone = phone[0].trim();


      if (!/^1[3456789]\d{9}$/.test(phone)) {
        this.nzMessageService.error(`格式错误，${line}行，手机号格式错误`);
        this.isSaving = false;
        this.highlightError(s);
        return;
      }
      let remain_str = s.replace(phone, '');
      remain_str = remain_str.trim();
      // s = s.replace(/[\t ,#，]/g, '|');
      remain_str = remain_str.replace(/(\s+)/g, '|');
      let ps: any = remain_str.split('|');

      if (ps.length > 1) {
        this.nzMessageService.error(`格式错误，${line}行，名字内好像有空格`);
        this.isSaving = false;
        this.highlightError(s);
        return;
      }
      ps = ps[0].trim();
      if (!ps) {
        this.nzMessageService.error(`格式错误，${line}行，缺少姓名`);
        this.isSaving = false;
        this.highlightError(s);
        return;
      }
      // if (ps.length != 2) {
      //   this.nzMessageService.error(`格式错误，${line}行，不能正确分割出手机号和名字`);
      //   this.isSaving = false;
      //   return;
      // }
      // let p = ps[0];
      // p = p.trim()
      // if (!/^1[3456789]\d{9}$/.test(p)) {
      //   this.nzMessageService.error(`格式错误，${line}行，手机号错误`);
      //   this.isSaving = false;
      //   return;
      // }
      // let n = ps[1];
      // n = n.trim();
      // if (!n) {
      //   this.nzMessageService.error(`格式错误，${line}行，拆分名字错误`);
      //   this.isSaving = false;
      //   return;
      // }
      /*
      const pe = new_s.find(el => {return el.phone === phone; });
      if (pe) {
        this.nzMessageService.error(`格式错误，${line}行，${phone}已经存在！`);
        this.isSaving = false;
        this.highlightError(s);
        return;
      }*/
      new_s.push({
        name: ps,
        phone: phone
      });
    }

    this.sysSrv.batchAddStudent(this.selectedSchool.id, this.selectedTeacher.teacher_id, this.selectedClass.id, new_s ).then(r => {
      this.isSaving = false;
      this.nzMessageService.success('保存成功');
      this.selectedTeacher = '';
      this.selectedClass = '';
    }).catch(e => {
      console.log(e);
      this.errlist = e.data;
      this.isSaving = false;
      this.errMsg = true;
      if (e.message ) {
        this.nzMessageService.error(e.message + (e.data ? e.data.message : ''), { nzDuration: 10000 });
      }

    });

  }
  closeError() {
    this.errMsg = false;
    this.errlist = [];
  }
  addTeacher() {
    this.newTeacherName = this.newTeacherName.trim();
    this.newTeacherPhone = this.newTeacherPhone.trim();
    if (!/^1[3456789]\d{9}$/.test(this.newTeacherPhone)) {
      this.nzMessageService.error('教师手机格式错误');
      return;
    }
    if (this.newTeacherName && this.newTeacherPhone && this.selectedSchool) {
      this.userSrv.addTeacher({
        name: this.newTeacherName,
        phone: this.newTeacherPhone,
        school_id: this.selectedSchool.id
      }).then(res => {
        console.log(res);
        // @ts-ignore
        delete this.teacherCache[this.selectedSchool.id];
        this.doSelectSchool(this.selectedSchool);
        this.nzMessageService.success('教师添加成功');
      }).catch(e => {
        console.log(e);
        this.nzMessageService.error(e.message);
      });
    }
  }
  addTeacherClass() {
    if (!this.selectedSchool) {
      this.nzMessageService.error('请先正确选择学校');
      return;
    }
    if (!this.selectedTeacher) {
      this.nzMessageService.error('请先正确选择教师');
      return;
    }
    if (!this.newTeacherClassName) {
      this.nzMessageService.error('请先正确填写班级名称');
      return;
    }
    if (this.newTeacherClassName && this.selectedTeacher && this.selectedSchool) {
      this.schoolSrv.addClass({
        name: this.newTeacherClassName.trim(),
        school_id: this.selectedSchool.id,
        teacher_id: this.selectedTeacher.teacher_id
      }).then(res => {
        console.log(res);

        // this.classCache[this.selectedTeacher.teacher_id]
        const cd = [];
        // @ts-ignore
        res.forEach(r => {
        });
        // @ts-ignore
        this.classCache[this.selectedTeacher.teacher_id] = res;
        this.classCache = {...this.classCache};
        // @ts-ignore
        this.classList = this.classCache[this.selectedTeacher.teacher_id];
        // delete this.teacherCache[this.selectedSchool.id]
        // this.doSelectSchool(this.selectedSchool);
        this.nzMessageService.success('班级添加成功');
      }).catch(e => {
        console.log(e);
        this.nzMessageService.error(e.message);
      });
    }
  }
}
