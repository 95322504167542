<div class="login-view">
  <div class="content">
    <div class="p-title"></div>
    <div>
      <input class="username p-input" placeholder="账户"
             (keydown.enter)="onBtnLogin()"
             [(ngModel)]="user.phone"/>
    </div>
    <div>
      <input class="password p-input" type="password" placeholder="密码"
             (keydown.enter)="onBtnLogin()"
             [(ngModel)]="user.password"/>
    </div>
    <label nz-checkbox (ngModelChange)="toggleAgreement($event)" [(ngModel)]="agree">
      <span>我已阅读并同意
        <a style="color: #1890ff" (click)="openAgreement(1, $event)">个人隐私协议</a>和
        <a style="color: #1890ff" (click)="openAgreement(2, $event)">用户协议</a>
      </span>
    </label>
    <button class="p-submit" (click)="onBtnLogin()" [disabled]="btnDisable">登录</button>
    <div class="info">Copyright@2018 by iPlayabc Ltd. Company</div>
    <div style="position: absolute;
    top: calc(100% + 3rem);
    font-size: 12px;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;">
      <img src="/assets/gongan.png">
<!--      <a href="http://beian.miit.gov.cn/"  target=_blank>辽ICP备15010626号-3</a>-->
      <a href="http://beian.miit.gov.cn/"  target=_blank>{{ICPString}}</a>
    </div>
  </div>
</div>

<nz-modal
        [(nzVisible)]="isAgreementVisible1"
        nzTitle="隐私保护政策"
        (nzOnCancel)="isAgreementVisible1 = false"
        (nzOnOk)="isAgreementVisible1 = false;"
>
  <ng-container *nzModalContent>
  绘玩教育非常重视对个人信息的保护，在使用绘玩教育的英语课程APP服务前，请您务必仔细阅读并透彻理解本声明。一旦您选择使用，即表示您认可并接受本条款现有内容及其可能随时更新的内容。
  <br>一、适用范围
  <br>  本隐私权保护声明适用于由绘玩教育提供的服务，解释了用户（自然人）个人信息收集和使用的有关情况。
  <br>  本声明不适用于由其他社会组织或者个人提供的服务，例如课程平台网页中出现的第三方链接，以及可能包含绘玩教育英语课程APP的其他相关网站和/或服务的网站。绘玩教育建议您访问相关网站和/或服务了解其隐私政策。
  <br>二、个人信息的收集<br>
  <br>  1. 您向绘玩教育提供的信息<br>
  <br>  为了向您提供更好的用户服务,绘玩教育会在您自愿选择服务或提供信息的情况下系统自动存储您的个人信息，并将这些信息进行整合。请您在注册时及时、详尽及准确的提供个人资料，并不断更新注册资料。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，由您自行承担相应的后果。请您不要将您的帐号、密码转让或出借予他人使用。如您发现您的帐号遭他人非法使用，应立即通知绘玩教育。互联网上不排除因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，此类情况与绘玩教育无关。<br>
  <br>  2. 您同意我们自动存储您在使用绘玩教育产品和/或服务的过程中产生的信息。例如：<br>
  <br>  a) 日志信息<br>
  <br>  当您使用绘玩教育英语课程APP服务时，服务器会自动记录一些信息，例如您对我们服务的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言以及访问日期和时间等。<br>
  <br>  b) 设备信息<br>
  <br>  某些产品和/或服务包含唯一应用程序编号。当您安装、激活、更新、卸载相关服务或当这些服务定期与绘玩教育英语课程APP通信（例如软件的自动更新）时，系统会将此编号以及与安装相关的信息（例如操作系统类型和应用程序版本号）发送给绘玩教育。 为了改善用户体验，系统可能会自动存储和设备有关的信息，用以进行服务适配。例如您的硬件型号、操作系统版本等。<br>
  <br>  c) Cookie和匿名标示符等工具<br>
  <br>  cookie主要的功能是便于您使用网站产品和/或服务，以及帮助网站统计独立访客数量等。运用cookie技术，绘玩教育能够为您提供更加周到的个性化服务，并允许您设定您特定的服务选项。<br>
  <br>  当您使用服务时，会向您的设备发送cookie。您可以选择接受或拒绝 cookie。您可以通过修改浏览器设置的方式拒绝cookie。如果您选择拒绝cookie，则您可能无法登录或使用依赖于cookie 的绘玩教育服务或功能。<br>
  <br>  3. 以上数据信息都将以匿名的方式使用。同时，我们也会对信息采取加密处理，保证信息的安全性。<br>
  <br>三、个人信息的管理<br>
  <br>  1. 绘玩教育有权利用您在使用绘玩教育相关产品和/或服务过程产生的信息，用以改进绘玩教育的产品和/或服务，并用于开发新的产品和/或服务。绘玩教育还会向您提供个性化的服务，例如向您展现相关程度更高的搜索结果或者推广结果。<br>
  <br>  2. 绘玩教育会以高度的勤勉义务对待这些信息，除非事先获得您的授权或本声明另有规定外，不会将这些信息对外公开或向第三方提供（绘玩教育关联公司除外）。<br>
  <br>  3. 绘玩教育不会向无关第三方提供、分享用户个人信息。除非绘玩教育与合作伙伴共同向您提供您所要求的服务或者共同向您展示您可能感兴趣的内容，且信息为该项产品/服务所必须。<br>
  <br>  4. 绘玩教育可能会对产品使用情况进行统计。同时，绘玩教育可能会与公众分享这些统计信息，以展示我们服务的整体使用趋势。这些统计信息不包含您的任何身份识别信息。<br>
  <br>  5. 在如下情况下，绘玩教育可能会披露您的个人信息：（1）事先获得您的授权；（2）您使用共享功能；（3）根据法律、法规、法律程序的要求或政府主管部门的强制性要求；（4）以学术研究或公共利益为目的，且不包含任何个人身份识别信息；（5）为维护绘玩教育的合法权益，例如查找、预防、处理欺诈或安全方面的问题；（6）遵照政府机关、证券监管机构或法院、仲裁机构命令披露信息；（7）符合相关服务条款或使用协议的规定；（8）其他有利于绘玩教育和您共同利益目的。<br>
  <br>四、未成年人的个人信息保护<br>
  <br>  绘玩教育非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用绘玩教育的产品和/或服务前，应事先取得您家长或法定监护人的同意。<br>
  <br>五、修订<br>
  <br>  绘玩教育随时会对本隐私权保护声明进行修订。绘玩教育会在网页中显著的位置发布相关规定以便及时通知您。<br>
  <br>附：名词解释<br>
  <br>1. 匿名标示符<br>
  <br>  匿名标示符通常是一类随机的字符串，通常用于识别包括特定移动设备在内的一些用户设备。例如网络设备MAC地址、国际移动设备身份码等。<br>
  <br>2. Cookie<br>
  <br>  支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于Web的客户端/服务器应用。服务器在向客户端返回HTTP对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的URL范围。此后，客户端发起的该范围内的HTTP请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为cookie。<br>
  <br>  如果您还有其他问题和建议，请联系绘玩教育。绘玩教育会始终致力于在充分保护您的个人信息，并为您提供更优质的体验和服务';
  </ng-container>
</nz-modal>


<nz-modal
        [(nzVisible)]="isAgreementVisible2"
        nzTitle="服务条款"
        (nzOnCancel)="isAgreementVisible2 = false"
        (nzOnOk)="isAgreementVisible2 = false"
>

  欢迎申请使用绘玩教育科技（大连）有限公司及其他合作运营主体（下列简称为“绘玩教育”）提供的英语课程APP服务。请您（下列简称为“用户”）仔细阅读以下全部内容。 如用户不同意本服务条款任意内容，请勿注册或使用绘玩教育课程英语APP服务。如用户通过进入注册程序并勾选“我同意绘玩教育英语课程APP服务条款”，即表示用户与绘玩教育已达成协议，自愿接受本服务条款的所有内容。此后，用户不得以未阅读本服务条款内容作任何形式的抗辩。
  <br>1、服务条款的确认和接纳
  <br>  绘玩教育服务涉及到的绘玩教育产品的所有权以及相关软件的知识产权归绘玩教育所有。用户在享受绘玩教育课程APP服务时，应当受本服务条款的约束。
  <br>  当用户使用绘玩教育英语课程APP服务时，即表示认可本服务条款以及绘玩教育在该服务中发出的各类公告。
  <br>2、绘玩教育课程英语APP服务简介
  <br>  本服务条款所称的“绘玩教育英语课程APP账户”是指用户以绘玩教育认可的邮箱注册的合法、有效的帐号。 绘玩教育运用自己的操作系统通过国际互联网络为用户提供各项服务。用户必须：
  <br>  （1）自行准备相关设备，如个人电脑、手机或其他上网设备。
  <br>  （2）个人上网和支付与此服务有关的费用。
  <br>  考虑到绘玩教育课程平台产品服务的重要性，用户同意：
  <br>  （1）提供真实、准确、完整的个人资料。
  <br>  （2）及时更新注册资料，符合及时、详尽准确的要求。绘玩教育课程APP注册资料包括：用户的绘玩教育英语课程APP帐号、密码及注册英语课程APP或更新英语课程APP注册资料时输入的所有信息。用户注册英语课程APP账号时填写的身份证件信息不能更新。
  <br>  如果用户提供给绘玩教育的资料存在虚假记载、误导性陈述或遗漏，绘玩教育有权随时终止用户使用英语课程APP服务。用户同意，其提供的真实、准确、完整的绘玩教育英语课程APP注册资料作为认定用户与其绘玩教育英语课程APP帐号的关联性以及用户身份的唯一证据。用户在享用绘玩教育英语课程APP服务的同时，同意接受绘玩教育提供的各类信息服务。绘玩教育提醒用户，用户注册英语课程APP账号或更新注册信息时填写的证件号码，在注册英语课程APP账号成功或补充填写后将无法进行修改，请用户慎重填写各类注册信息。
  <br>  用户同意与注册、使用绘玩教育英语课程APP帐号相关的一切数据和记录，包括但不限于帐号名称、注册信息、所有登录、消费记录和相关的使用统计数字等归绘玩教育所有。发生争议时，用户同意以绘玩教育的系统数据为准。
  <br>3、服务条款的修改
  <br>  绘玩教育有权在必要时通过在网页上发出公告等合理方式修改本服务条款以及各单项服务的相关条款。用户在享受各项服务时，应当及时查阅了解修改的内容，并自觉遵守本服务条款的相关条款。用户如继续使用使用绘玩教育英语课程APP服务，则视为对修改内容的同意，当发生有关争议时，以最新的服务条款为准；用户在不同意修改内容的情况下，有权停止使用本服务条款涉及的服务。
  <br>4、服务的变更或中止
  <br>  绘玩教育始终在不断变更和改进服务。绘玩教育可能会增加或删除功能，也可能暂停或彻底停止某项服务。用户同意绘玩教育有权行使上述权利且不需对用户或第三方承担任何责任。
  <br>5、用户隐私制度
  <br>  用户知悉并同意，为便于向用户提供更好的服务，绘玩教育在用户自愿选择服务或者提供信息的情况下有权收集用户的个人信息，并将这些信息进行整合。在用户使用绘玩教育服务时，服务器会自动记录一些信息，包括但不限于URL、IP地址、浏览器类型、使用语言、访问日期和时间等。为方便用户登录或使用绘玩教育的服务，绘玩教育在有需要时将使用cookies等技术，并将收集到的信息发送到对应的服务器。用户可以选择接受或者拒绝cookies。如用户选择拒绝 cookies，则用户有可能无法登陆或使用依赖于cookies的服务或者功能。绘玩教育收集的信息将成为绘玩教育常规商业档案的一部分，且有可能因为转让、合并、收购、重组等原因而被转移到绘玩教育的继任公司或者指定的一方。绘玩教育同意善意使用收集的信息，且采取各项措施保证信息安全。
  <br>  尊重用户个人隐私是绘玩教育的一项基本政策。所以，绘玩教育不会公开或透露用户的注册资料及保存在绘玩教育课程平台服务中的非公开内容，除非绘玩教育在诚信的基础上认为透露这些信息在以下几种情况是必要的：
  <br>  （1）事先获得用户的明确授权；
  <br>  （2）遵守有关法律规定，包括在国家有关机关查询时，提供用户的注册信息、用户在绘玩教育课程平台的网页上发布的信息内容及其发布时间、互联网地址或者域名；
  <br>  （3）保持维护绘玩教育的知识产权和其他重要权利；
  <br>  （4）在紧急情况下竭力维护用户个人和社会大众的隐私安全；
  <br>  （5）遵照政府机关、证券监管机构或法院、仲裁机构命令披露信息，但是必须事先以书面形式通知披露方；
  <br>  （6）根据本条款相关规定或者绘玩教育认为必要的其他情况下。
  <br>  绘玩教育可能会与第三方合作向用户提供相关的网络服务，在此情况下，绘玩教育可将用户信息提供给该第三方。
  <br>6、用户的帐号、密码和安全性
  <br>  用户一旦注册成功成为用户，用户将得到一个密码和帐号。用户可随时改变用户的密码和图标，也可以结束旧的帐户重开一个新帐户。用户应维持密码及帐号的机密安全，如果用户未保管好自己的帐号和密码而对用户、绘玩教育或第三方造成损害，用户将负全部责任。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，有义务立即通告绘玩教育。
  <br>7、不可抗力条款
  <br>  绘玩教育对不可抗力导致的损失不承担责任。本服务条款所指不可抗力包括：天灾、疫情、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如境内外基础电信运营商的故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免并不能克服的客观情况。
  <br>8、用户管理
  <br>  用户独立承担其发布内容的责任。用户对服务的使用必须遵守所有适用于服务的国家和地方法律、法规、规章及其他规范性文件和国际法律。用户承诺：
  <br>  （1）用户在绘玩教育的网页上发布信息或者利用绘玩教育英语课程APP服务时必须符合中国有关法律、法规，不得在绘玩教育的网页上或者利用绘玩教育英语课程APP服务制作、复制、发布、传播以下信息：
  <br>  (a) 违反宪法确定的基本原则的；
  <br>  (b) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
  <br>  (c) 损害国家荣誉和利益的；
  <br>  (d) 煽动民族仇恨、民族歧视，破坏民族团结的；
  <br>  (e) 破坏国家宗教政策，宣扬邪教和封建迷信的；
  <br>  (f) 散布谣言，扰乱社会秩序，破坏社会稳定的；
  <br>  (g) 散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
  <br>  (h) 侮辱或者诽谤他人，侵害他人合法权益的；
  <br>  (i)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；
  <br>  (j) 以非法民间组织名义活动的；
  <br>  (k) 含有法律、行政法规禁止的其他内容的。
  <br>  （2）用户在绘玩教育的网页上发布信息或者利用绘玩教育课程平台服务时还必须符合其他有关国家和地区的法律、法规、规章及其他规范性文件规定以及国际法的有关规定。
  <br>  （3）用户不得利用绘玩教育的服务从事以下活动：
  <br>  (a) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
  <br>  (b) 未经允许，对计算机信息网络功能进行删除、修改或者增加的；
  <br>  (c) 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
  <br>  (d) 故意制作、传播计算机病毒等破坏性程序的；
  <br>  (e) 其他危害计算机信息网络安全的行为。
  <br>  （4）用户不得以任何方式干扰绘玩教育的服务。
  <br>  （5）用户不得滥用绘玩教育服务，包括但不限于：利用绘玩教育服务进行侵害他人知识产权或者合法利益的其他行为。
  <br>  （6）用户应遵守绘玩教育的所有其他规定和程序。
  <br>  用户须对自己在使用绘玩教育服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在绘玩教育首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予绘玩教育等额的赔偿。若用户违反以上规定，绘玩教育有权作出独立判断立即暂停或终止对用户提供部分或全部服务，包括冻结、取消用户服务帐号等措施。用户理解，如果绘玩教育发现其网站传输的信息明显属于上段第(1)条所列内容之一，依据中国法律，绘玩教育有权立即停止传输、保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。
  <br>9、保障
  <br>  用户同意保障和维护绘玩教育全体成员的利益，承担由用户违反服务条款的损害补偿费用，其它人使用用户的电脑、帐号而产生的费用。用户或者使用用户帐号的其他人侵犯第三方知识产权及其他权利而导致被侵权人索赔的，由用户承担责任。如用户或其它网络服务提供者利用绘玩教育的服务侵害他人民事权益的，应当承担侵权等法律责任。
  <br>  如用户利用绘玩教育的服务实施侵权行为的，被侵权人有权通知绘玩教育采取删除、屏蔽、断开链接等必要措施。绘玩教育接到通知后，绘玩教育有权立即采取必要措施，在此情形下，绘玩教育并不因此对用户、被侵权人承担任何责任。
  <br>10、通知
  <br>  所有发给用户的通知都可通过电子邮件、常规的信件或在网站显著位置公告的方式进行传送。绘玩教育将通过上述方法之一将消息传递给用户，告知他们服务条款的修改、服务变更、或其它重要事情。
  <br>11、内容、标识的所有权
  <br>  绘玩教育提供的内容包括但不限于：非用户上传/提供的文字、软件、声音、相片、视频、图表等。所有这些内容均属于绘玩教育，并受版权、商标、专利和其它财产所有权法律的保护。所以，用户只能在绘玩教育授权下才能使用这些内容，而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。
  <br>  绘玩教育英语课程APP产品、服务的名称，均为绘玩教育所有（以下简称“绘玩教育标识”）。未经绘玩教育事先书面同意，用户不得将绘玩教育标识以任何方式展示或使用或作其他处理，也不得向他人表明您有权展示、使用、或其他有权处理绘玩教育标识的行为。
  <br>12、法律
  <br>  本条款适用中华人民共和国的法律，并且排除一切冲突法规定的适用。
  <br>  如出现纠纷，用户和绘玩教育一致同意将纠纷交由玩教育科技（大连）有限公司所在地法院管辖。
  <br>13、信息储存及相关知识产权
  <br>  绘玩教育对英语课程APP服务将尽力维护其安全性及方便性，但对服务中出现的信息（包括但不限于用户发布的信息）删除或储存失败不承担任何责任。另外绘玩教育有权判定用户的行为是否符合本服务条款的要求，如果用户违背了本服务条款的规定，绘玩教育有权中止或者终止对其绘玩教育英语课程APP帐号的服务。
  <br>  绘玩教育尊重知识产权并注重保护用户享有的各项权利。在绘玩教育英语课程APP所含服务中，用户可能需要通过上传、发布等各种方式向绘玩教育提供内容。在此情况下，用户仍然享有此等内容的所有权。用户在提供内容时将授予绘玩教育一项全球性的免费许可，允许绘玩教育使用、传播、复制、修改、再许可、翻译、创建衍生作品、出版、表演及展示此等内容。
  <br>14、青少年用户特别提示
  <br>  青少年用户必须遵守全国青少年网络文明公约： 要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。
  <br>15、绘玩教育英语课程APP帐号的有效期
  <br>  用户清楚知悉绘玩教育英语课程APP帐号存在有效期，并同意不定时登录使用绘玩教育英语课程APP帐号以延续其有效期。
  <br>  如果用户的绘玩教育英语课程APP帐号连续180天没有登录，则绘玩教育有权删除该绘玩教育英语课程APP帐号，当帐号被删除后，该绘玩教育英语课程APP帐号的所有资料以及与该绘玩教育英语课程APP帐号相关的全部服务资料和数据（包括但不限于邮箱信息、绘玩教育充值一卡通点数信息、游戏帐号信息等）将同时删除，且不可恢复。该帐号名有可能会被新的用户注册。
  <br>  登录绘玩教育英语课程APP是指通过绘玩教育英语课程APP帐号密码认证，包括但不限于如下方式：
  <br>  (1) 通过Web方式登录；
  <br>  (2) 通过邮件，手机应用等客户端软件登录。
  <br>16、其他
  <br>  （1）绘玩教育不行使、未能及时行使或者未充分行使本条款或者按照法律规定所享有的权利，不应被视为放弃该权利，也不影响绘玩教育在将来行使该权利。
  <br>  （2）如本条款中的任何条款无论因何种原因完全或部分无效或不具有执行力，本服务条款的其余条款仍应有效且具有约束力，绘玩教育及用户均应尽力使该条款设定的本意得到实现。
  <br>  （3）本条款中的标题仅为方便而设，不作为解释本条款的依据。;
</nz-modal>
