// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { UserType } from '../../../model/Const';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss']
})
export class AdminHeaderComponent implements OnInit {
  isCollapsed = false;
  @Output()
  sideEvent =  new EventEmitter();
  user: any = null;
  canGoFront = false;
  schoolName = '';

  @ViewChild('appTeacherQrcodeContent', {static: true})
  appTeacherQrcodeContent: TemplateRef<{}>;

  @ViewChild('appStudentQrcodeContent', {static: true})
  appStudentQrcodeContent: TemplateRef<{}>;


  constructor(
    private router: Router,
    private modalService: NzModalService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.user = this.userService.getUserInfo();
    this.canGoFront = this.user.type >= UserType.TEACHER;
    this.schoolName = this.user.school_name || '超级管理员';
  }
  triggerSide($evt) {
    this.isCollapsed = !this.isCollapsed;
    this.sideEvent.emit(this.isCollapsed);
  }
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  showAppDownload(type) {
    const content = type === 0 ? this.appStudentQrcodeContent : this.appTeacherQrcodeContent;
    const modal = this.modalService.create({
      nzTitle: 'APP下载',
      nzContent: content,
      // nzComponentParams: data,
      // nzMaskClosable: false,
      // nzClosable: false,
      nzClassName: 'app-download-modal',
      nzWidth: 400,
      nzFooter: null
      // nzFooter: [
      // nzFooter: [
      //   {
      //     label: '关闭',
      //     type: 'default',
      //     loading: false,
      //     onClick(): void {
      //
      //       modal.destroy();
      //     }
      //   }
      // ]
    });
  }
}
