import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { SchoolService } from '../../../../../services/school.service';
import { UserType } from '../../../../../model/Const';

@Component({
  selector: 'app-admin-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class AdminUserSettingComponent implements OnInit {
  validateForm: UntypedFormGroup;
  pwValidateForm: UntypedFormGroup;
  isLoadingTeachers = false;
  teacherList = [];
  selectedTeacher = null;
  editUserId = null;
  classId = 0;
  returnPage = 1;

  curUserName = '';
  curPhone = '';
  user = null;
  editFlag = false;
  loading = false;
  isOkLoading = false;
  tipVisible = false;
  curControlValue = null;
  changePwFlag = false;
  nameTitle = '姓名';
  phoneTitle = '电话';

  pwDataArr = [
    {visible: false, value: '', title: '旧密码'},
    {visible: false, value: '', title: '新密码'},
    {visible: false, value: '', title: '密码确认'}
  ];
  pwVisibleArr = [false, false, false];
  pwValueArr = ['', '', ''];

  pwValidator = Validators.pattern(/^.{4,25}$/);
  pwErrorTip = '密码长度4-25位';

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {

    this.user = this.userService.getUserInfo();
    // @ts-ignore
    if (this.user.isPL3) {
      this.pwValidator = Validators.pattern(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20}$/);
      this.pwErrorTip = '密码中必须包含大小写字母、数字，至少6个字符，最多20个字符！';
    }
    // @ts-ignore
    this.curUserName = this.user.name;
    // @ts-ignore
    this.curPhone = this.user.phone;
    // console.log('user:', this.user);
    // @ts-ignore
    if (this.user.type === UserType.SCHOOL_ADMIN) {
      this.nameTitle = '管理员姓名';
      this.phoneTitle = '管理员电话';
    }


    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query['page'] && parseInt(query['page'], 10) === query['page'] * 1) {
        this.returnPage = parseInt(query['page'], 10);

      }
      if (query['pass'] && query['pass'] === 'change') {
        this.changePwFlag = true;
      }
    });
    this.validateForm = this.fb.group({
      name: [ '', [ Validators.required ] ],
      // birthday: [ ''],
      // sex: [null],
      phone: ['', [ Validators.required, Validators.pattern(/^1[3456789]\d{9}$/) ], [ this.phoneAsyncValidator ] ],
    });
    this.pwValidateForm = this.fb.group({
      pwOld: ['', [Validators.required ]],
      pwNew: ['', [Validators.required, this.pwValidator ],  ],
      pwConfirm: ['', [this.confirmValidator]]
    });
    this.activatedRoute.params.subscribe( (params: any) => {


      if (params.id && parseInt(params.id, 10) === params.id * 1) {
        // @ts-ignore
        this.editUserId = parseInt(params.id, 10);
      }
    });
  }
  phoneAsyncValidator = (control: UntypedFormControl) => new Observable((observer: Observer<ValidationErrors>) => {

    // @ts-ignore
    if (this.user.phone === control.value) {
      // @ts-ignore
      observer.next(null);
      observer.complete();
      return;
    }

    const data = {phone: control.value};
    // if (this.editUserId) {
    //     data['id'] = this.editUserId;
    // }
    this.userService.checkUserExist(data).then(resp => {
      // console.log(99, resp);
      if (resp && resp.length) {
        observer.next({ error: true, duplicated: true });
      } else {
        // @ts-ignore
        observer.next(null);
      }
      observer.complete();
    });
    // setTimeout(() => {
    //   if (control.value === 'JasonWood') {
    //     observer.next({ error: true, duplicated: true });
    //   } else {
    //     observer.next(null);
    //   }
    //   observer.complete();
    // }, 1000);
  })
  ngOnInit() {

    // @ts-ignore
    this.validateForm.controls['name'].setValue(this.user.name);
    // @ts-ignore
    this.validateForm.controls['phone'].setValue(this.user.phone);


    if (this.editUserId) {
      this.userService.fetchStudent(this.editUserId).then(td => {
        this.validateForm.controls['name'].setValue(td.name);
        this.validateForm.controls['birthday'].setValue(td.birthday);
        this.validateForm.controls['sex'].setValue(td.sex + '');
        // this.teacherSexValue = td.sex + '';
        this.validateForm.controls['phone'].setValue(td.phone);
      });
    }

  }

  formatDate (d: any) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }
  checkPhone(phone: string) {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      return false;
    }
    return true;
  }
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }

    this.curControlValue = value;
    this.tipVisible = true;

  }


  pwSubmitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.pwValidateForm.controls) {
      this.pwValidateForm.controls[ key ].markAsDirty();
      this.pwValidateForm.controls[ key ].updateValueAndValidity();
    }


    const {pwOld, pwNew} = value;

    this.userService.changePassword({pw_old: pwOld, pw_new: pwNew}).then(data => {

        console.log('data:', data);
        let msg = '密码修改成功!';

// @ts-ignore
          if (this.user.isPL3) {
            msg += '请重新登陆！';
            setTimeout(() => {
              // this.router.navigate(['/logout']);
              localStorage.removeItem('token');
              this.router.navigate(['/login']);
            }, 2000);
          }
      this.nzMessageService.success(msg);
      this.changePwFlag = false;

      // console.log('data:', data);
      // const msg = '密码修改成功!';
      // this.nzMessageService.success(msg);
      // this.changePwFlag = false;

    }).catch( error => {

      const msg = '您输入的旧密码有误，请重新输入';
      this.nzMessageService.error(msg);

    });

  }



  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
  }
  teacherChange(evt?: any) {

  }

  editClick() {
    this.editFlag = true;
  }






  handleOk(): void {
// @ts-ignore
    if (this?.curControlValue.phone === this?.user.phone
      // @ts-ignore
        && this?.curControlValue.name === this?.user.name) {
      this.tipVisible = false;
      return;
    }


    this.isOkLoading = true;
// @ts-ignore
    this.userService.saveTeacher(this.user.id, this.curControlValue).then( res => {

      this.router.navigate([`/login`], {});

    });

  }

  handleCancel(): void {
    this.tipVisible = false;
  }



  changePw() {
    this.changePwFlag = true;
  }


  confirmValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
      // @ts-ignore
    } else if (control.value !== this.pwValidateForm.controls.pwNew.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  validateConfirmPassword(): void {
    // @ts-ignore
    setTimeout(() => this.pwValidateForm.controls.pwConfirm.updateValueAndValidity());
  }

  // nameSave() {
  //   this.saveStart();
  //   // this.editFlag = false;
  // }
  //
  // phoneSave() {
  //   this.saveStart();
  //   // this.editFlag = false;
  // }
  //
  // onKeyPress(e) {
  //   // console.log(e);
  //   const code = e.which || e.keyCode;
  //   if (code == 13) {
  //     this.saveStart();
  //     // this.editFlag = false;
  //   }
  // }


  // saveStart() {
  //
  //   this.editFlag = false;
  //
  //
  //   if (this.curUserName.length < 2) {
  //     this.curUserName = this.user.name;
  //     this.nzMessageService.error(`姓名至少两个字符`);
  //     return;
  //   }
  //
  //   let p = this.curPhone;
  //   p = p.trim();
  //   if (!/^1[3456789]\d{9}$/.test(p)) {
  //     this.curPhone = this.user.phone;
  //     this.nzMessageService.error(`手机格式错误`);
  //     return;
  //   }
  //
  //   this.loading = true;
  //
  //   const data = {};
  //   if (this.curUserName) {
  //     data['name'] = this.curUserName;
  //   }
  //   if (this.curPhone) {
  //     data['phone'] = this.curPhone;
  //   }
  //
  //   data['sex'] = '1';
  //
  //   this.userService.saveTeacher(this.user.id, data).then( res => {
  //     console.log('res:', res);
  //
  //     return res;
  //     // this.router.navigate([redirect_url]);
  //   }).then( res => {
  //
  //     console.log('res2:', res);
  //
  //     const userData = {
  //       phone: this.curPhone,
  //       password: (this.curPhone).substr(this.curPhone.length - 4)
  //     }
  //
  //     this.userService.userLogin(userData).then(login_res => {
  //
  //       console.log('login_res', login_res);
  //       this.user = this.userService.getUserInfo();
  //       this.loading = false;
  //     });
  //
  //   });
  //
  //
  // }

}
