import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Const } from '../../../../config/const';
import { UserType } from '../../../../model/Const';

const argeementContent = '';
@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit {
  // @ts-ignore
  loginLabel: string;
  user: any = {
    phone: '',
    password: ''
  };
  // @ts-ignore
  btnDisable: boolean;
  agree = false;
  isAgreementVisible1 = false;
  isAgreementVisible2 = false;

  ICPString = '辽ICP备15010626号-3';
  constructor(
    private modalService: NzModalService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute ,
    private router: Router ,
    private nzMessageService: NzMessageService,
  ) {
    // console.log(333);
    // @ts-ignore
    const agree = isNaN(+localStorage.getItem('agree')) ? false : !!+localStorage.getItem('agree');
    this.agree = agree;

  }
  toggleAgreement(val: any) {
    localStorage.setItem('agree', val ? '1' : '0');
    // @ts-ignore
    const agree = isNaN(+localStorage.getItem('agree')) ? false : !!+localStorage.getItem('agree');
    this.agree = agree;
  }

  openAgreement(type: any, evt: any) {
    evt.stopPropagation();
    evt.preventDefault();
    // @ts-ignore
    this[`isAgreementVisible${type}`] = true;

  }



  ngOnInit() {
    // console.log(44444);
    // this.user = {
    //   phone: '',
    //   password: ''
    // };
    // this.loginLabel = 'Login';
    if (window.location.host === 'ireadabc.tech') {
      this.ICPString = '辽ICP备2023006924号-1';
    }
  }

  onBtnLogin() {
    // this.btnDisable = true;
    // this.loginLabel = 'Loading...';
    if (!this.agree) {
      this.modalService.confirm({
        nzStyle: {minWidth: '600px'},
        nzTitle: '<b>提示</b>',
        nzContent: '<i>是和否同意隐私保护政策和服务条款</i>',
        nzOnOk: () => {
         this.toggleAgreement(true);
        },
        nzOkText: '同意',
      });
      return;
    }
    const checkKey = `errorCheck${this.user.phone}`;
    const errorCheckJson = localStorage.getItem(checkKey);
    let errorCheck = {
      count: 0,
      lastLogin: 0
    };
    try {
      if (errorCheckJson) {
        errorCheck = JSON.parse(errorCheckJson);
      }
    } catch (e) {

    }
    const currErrCount = errorCheck.count || 0;
    // parseInt(localStorage.getItem('errCount'), 10);
    let lastLogin = errorCheck.lastLogin || 0;
    // parseInt(localStorage.getItem('lastLogin'), 10);
    if (isNaN(lastLogin)) {
      lastLogin  = 0;
    }

    // if (currErrCount >= 5 && (lastLogin && (lastLogin - Date.now()) < 30 * 60 * 1000) ) {
    //   const mins = ((lastLogin + 30 * 60 * 1000) - Date.now())  / 60000;
    //   this.nzMessageService.error(`登陆失败次数过多，请等待${mins.toFixed(0)}分钟后重试`);
    //   return;
    // }
    errorCheck.lastLogin = Date.now();
    localStorage.setItem(checkKey, JSON.stringify(errorCheck));
    this.userService.userLogin(this.user)
      .then(data => {
        const user = this.userService.getUserInfo();
        // if (user.isPL3) {
        //   this.router.navigate([return_url]);
        // }
        // const return_url = this.activatedRoute.snapshot.queryParams.return;
        let return_url = '/user/books';


        if (user.type === UserType.SUPER_ADMIN) {
          return_url = '/admin/schools';
        } else if (user.type === UserType.PAD_SYSTEM_ADMIN) {
          return_url = '/admin/tablet-manage-single/index';
        } else if (user.type === UserType.SCHOOL_ADMIN) {
          return_url = '/admin/classes';
        } else if (user.type === UserType.TEACHER) {
          return_url = '/admin/classes';
        } else if (user.type === UserType.STUDENT) {
          return_url = '/user/books';
        }
        // if (user.type === UserType.TEACHER) {
        //   return_url = '/user/classes';
        // }
        // if (user.type === UserType.SCHOOL_ADMIN) {
        //   return_url = '/admin/classes';
        // }
        if (return_url) {
          this.router.navigate([return_url]);
        } else {
          this.router.navigate(['/classes/books']);
        }
        localStorage.removeItem(checkKey);
      }).catch(error => {

      errorCheck.count += 1;

      localStorage.setItem(checkKey, JSON.stringify(errorCheck));
      let msg = error.message || 'Something wrong';
      switch (error.errorCode) {
        case Const.ERROR_CODE.NO_USER:
          msg = '用户不存在或密码错误';
          break;
        case Const.ERROR_CODE.INVALID_PARAMS:
          msg = '请输入正确的用户名密码';
          break;
      }
      this.nzMessageService.error(msg);
    });
  }
}
