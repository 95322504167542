import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { StudentService } from '../../../../../services/student.service';
import { SystemService } from '../../../../../services/system.service';
import { SCHOOL_TYPE, UserType } from '../../../../../model/Const';
import { PreviousRouteService } from '../../../../../services/previous-route.service';


@Component({
  selector: 'app-class-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class StudentsIndexComponent implements OnInit {
  displayData: any[] = [];
  totalClasses = 0;
  currentPageIndex = 1;
  classId = 0;
  className = '';
  classComment = '';
  errStudentDataVisible = false;
  errStus = [];
  showBatchAddBtn = false;

  disabledButton = true;
  allChecked = false;
  indeterminate = false;
  checkedNumber = 0;

  classChangeVisible = false;
  isLoadingTeacherCLass = false;

  teacherList: any[] = [];
  classList: any[] = [];
  teacherCache = {};
  classCache = {};

  selectedTeacher = null;
  selectedClass = null;
  confirmChangeVisible = false;
  schoolMaster = false;
  schoolType = 0;
  schoolId = 0;

  PAYG_TYPE = SCHOOL_TYPE.PAYG;
  returnPage = 1;
  isAccountManager = false;

  deleteStudentConfirm: NzModalRef|null = null;

  delStudent: any = null;

  changeClassText = '';
  teacher = '';
  openclassing = false;
  loadingData = false;



  accountInfoSuccess = false;
  accountInfoError = false;
  accountInfo: any[]|null = null;
  accountDaysType = null;
  accountDaysTypeRemainCount = 0;
  openUserId = null;
  changeClassButtonLoading = false;



  // @ViewChild('accountInfoSuccessTpl', {static: true})
  // accountInfoSuccessTpl: TemplateRef<{}>;
  private isSA = false;


  constructor(private schoolService: SchoolService,
              private modalService: NzModalService,
              private userService: UserService,
              private prService: PreviousRouteService,
              private stuService: StudentService,
              private clsService: ClassService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private sysSrv: SystemService,
              private nzMessageService: NzMessageService) {
    const user = this.userService.getUserInfo();
    console.log(user);
    this.schoolMaster = user.type === UserType.SCHOOL_ADMIN;
    this.schoolType = user.school_type;
    this.schoolId = user.school_id;
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query['page'] && parseInt(query['page'], 10) === query['page'] * 1) {
        this.returnPage = parseInt(query['page'], 10);
      }
    });

    this.isAccountManager = user.isAccountManager
      || user.type === UserType.SCHOOL_ADMIN ;
    this.isSA = user.type === UserType.SUPER_ADMIN;
  }

  ngOnInit() {
    this.loadData(this.currentPageIndex);
    this.loadClassData();
  }
  loadData(page: string|number) {
    this.loadingData = true;
    this.stuService.fetchStudentsBySchoolId(page, null, this.schoolId, this.classId).then(classes => {
    // this.stuService.fetchStudentsByClassId(this.classId, page).then(classes => {
      console.log(classes.rows);
      this.displayData = classes.rows;
      this.totalClasses = classes.total;
      // this.className = classes.class_name;
      this.classComment = classes.class_comment;
      // @ts-ignore
      this.currentPageIndex = page;
      this.refreshStatus();
      this.loadingData = false;
      // this.setTmpNumId();
      console.log('classes:', classes);
    }).catch(err => {
      this.loadingData = false;
      this.nzMessageService.error(`错误：${err.message}`);
    });
  }
  setTmpNumId() {
    for (let i = 0; i < this.displayData.length; i++) {
      // @ts-ignore
      this.displayData[i].numId = i + 1 + (this.currentPageIndex - 1) * 10;
    }
  }
  loadClassData() {
    const srv = this.clsService;
    srv.fetchClassAndTeacher(this.classId).then(data => {
      this.className = data[0].class_name;
      this.teacher = data[0].teacher;
    });
  }
  closeDeleteStudentConfirm() {
    this.delStudent = null;
    // this.deleteStudentConfirm = false;
  }
  sureDeleteStudentConfirm() {
    if (!this.delStudent) {
      return;
    }
    this.loadingData = true;
    this.userService.unbindClassStudent(this.classId, this.delStudent.user_id).then(classes => {
      this.loadData(this.currentPageIndex);
      // this.currentPageIndex = 1;
      // this.deleteStudentConfirm = false;
    }).catch(e => {
      this.loadingData = false;
      // this.deleteStudentConfirm = false;
      this.nzMessageService.error(e.message);
    });
    this.delStudent = null;
  }
  deleteStudentReConfirm(row: any) {
    this.delStudent = row;
    this.deleteStudentConfirm = this.modalService.confirm({
      nzTitle: '确定将学生移除该班级吗？',
      nzContent: '<b style="color: red;">确认移除吗？？？</b>',
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.sureDeleteStudentConfirm(),
      nzCancelText: 'No',
      // nzOnCancel: () => console.log('Cancel')
    });
  }
  deleteStudents() {
    // @ts-ignore
    const ids = this.displayData.filter(value => value.checked).map( u => u.id);
    this.userService.removeStudents(this.classId, ids).then(classes => {
      this.loadData(this.currentPageIndex);
      // this.currentPageIndex = 1;
    });
  }
  handleCancelOpenAccountDialog () {
    this.openUserId = null;
    this.accountInfoSuccess = false;
  }
  handleOpenAccount () {
    if (this.loadingData) {
      return;
    }
    this.loadingData = true;
    this.accountInfoSuccess = false;
    debugger
    // @ts-ignore
    this.userService.grantUser(this.openUserId, this.accountDaysType).then(data => {
      this.loadData(this.currentPageIndex );
    }).catch(err => {
      this.loadingData = false;
      this.nzMessageService.error(err.message);
    });

  }
  grantUser(id: number|string) {
    // @ts-ignore
    this.openUserId = id;
    this.accountInfoSuccess = true;
    this.schoolService.getRemainAccountInfo().then( info => {
      console.log(info);
      this.accountInfo = info;
      if (info.length) {
        this.accountDaysType = info[0].days;
        this.accountDaysTypeRemainCount = info[0].count;
      }
      this.loadingData = false;
    }).catch(err => {
      this.loadingData = false;
      this.nzMessageService.error(err.message);
    });


  }
  grantAllStudentsInClass () {
    this.openclassing = true;
    this.userService.grantClass(this.classId).then(data => {
      this.loadData(this.currentPageIndex );
      this.openclassing = false;
    }).catch(err => {
      this.nzMessageService.error(err.message);
      this.openclassing = false;
    });
  }
  editStudent(id: string|number) {
    this.router.navigate([`/admin/classes/${this.classId}/students/edit/${id}`]);
  }

  batchAddStudents = (file: File) => {
    const data = new FormData();
    data.append('file', file);
    this.userService.batchAddUsers(this.classId , data).then(r => {
      console.log(r);
      this.errStus = r;
      this.errStudentDataVisible = true;
    });
    return false;
  }
  handleErrStuCancel() {
    this.loadData(1);
    this.errStudentDataVisible = false;
  }
  handleErrStuOk()  {
    this.loadData(1);
    this.errStudentDataVisible = false;
  }

  checkAll(value: boolean): void {
    // @ts-ignore
    this.displayData.forEach(data => data.checked = value);
    this.refreshStatus();
  }
  refreshStatus(): void {
    // @ts-ignore
    const allChecked = this.displayData.every((value: any) => value.checked === true);
    // @ts-ignore
    const allUnChecked = this.displayData.every((value: any) => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    // @ts-ignore
    this.disabledButton = !this.displayData.some((value: any) => value.checked);
    // @ts-ignore
    this.checkedNumber = this.displayData.filter((value: any) => value.checked).length;
  }
  changeStudentClass() {
    if (this.checkedNumber > 0) {
      if (Object.keys(this.teacherCache).length && Object.keys(this.classCache).length) {
        this.classChangeVisible = true;
        return;
      }
      this.isLoadingTeacherCLass = true;
      const info = this.userService.getUserInfo();
      this.sysSrv.loadTeacherAndClasses(info.school_id).then(result => {
        this.classChangeVisible = true;

        console.log('result:', result);
        // @ts-ignore
        this.teacherCache[info.school_id] = result.teachers;
        for (const k of Object.keys(result.teachers)) {
          const t = result.teachers[k];
          // @ts-ignore
          if (this.classCache[t.teacher_id]) {
            // @ts-ignore
            this.classCache[t.teacher_id] = [...this.classCache[t.teacher_id], ...result.classes[t.teacher_id]];
          } else {
            // @ts-ignore
            this.classCache[t.teacher_id] = result.classes[t.teacher_id] ? result.classes[t.teacher_id] : [] ;
          }
        }
        this.teacherList = Object.values(result.teachers) as any[];

        this.isLoadingTeacherCLass = false;
      });
    } else {
      this.nzMessageService.error('请先勾选需要调整的学生');
    }
  }
  closeChangeStudentClass() {
    this.classChangeVisible = false;
    this.isLoadingTeacherCLass = false;
  }
  doSelectTeacher(teacher: any) {
    this.selectedTeacher = teacher;
    this.classList = [];
    this.selectedClass = null;
    // @ts-ignore
    if (this.classCache[teacher.teacher_id]) {
      // @ts-ignore
      this.classList = this.classCache[teacher.teacher_id].filter(c => {
        return +c.id !== +this.classId;
      });
    } else {
      alert('no classes for this teacher');
    }
  }
  setChangeClassText() {
      let str = '将 ';
      let len = 0;
      for (let i = 0; i <  this.displayData.length; i++) {
        // @ts-ignore
          if (this.displayData[i].checked) {
              len ++ ;
            // @ts-ignore
              str += (this.displayData[i].name || this.displayData[i].user_name) + ' 、';
          }
      }
      str = str.substr(0, str.length - 1);
    // @ts-ignore
      str += `移动到 ${this.selectedTeacher.name} 老师的 ${this.selectedClass.name} 吗？`;
      this.changeClassText = str;
  }
  handleChangeOk() {
    this.confirmChangeVisible = true;
    this.changeClassButtonLoading = false;
    this.setChangeClassText();
    this.modalService.confirm({
      nzTitle: '调整班级',
      nzContent: `<b style="color: red;">${this.changeClassText}</b>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.sureConfirm(),
      nzCancelText: 'No',
    });
  }
  closeConfirm() {
    this.confirmChangeVisible = false;
  }
  sureConfirm() {
    console.log('in sureConfirm');
    // this.changeClassButtonLoading = true;
    this.loadingData = true;

    this.confirmChangeVisible = false;
    this.classChangeVisible = false;
    this.changeClassButtonLoading = false;
// @ts-ignore
    const uids = this.displayData.filter(value => value.checked).map(r => {
      return {
        user_id: r.user_id,
        class_id: r.class_id,
        school_id: r.school_id,
        row_id: r.class_user_row_id
      };
    });
    console.log(this.selectedClass);
    console.log(uids);
    // @ts-ignore
    this.stuService.changeClass(this.selectedClass.id, uids).then(r => {
      this.loadData(this.currentPageIndex);
      this.confirmChangeVisible = false;
      this.classChangeVisible = false;
      this.changeClassButtonLoading = false;
    }).catch(e => {
      this.nzMessageService.error(e.message);
    });
  }
  goBack() {
    const prevUrl = this.prService.getPreviousUrl();
    if (prevUrl) {
      this.router.navigate([prevUrl]);
    } else {
      history.go(-1);
    }
  }
  createConfirmCheckModal(title = '提示', rejectContent: string, data?: {}, okCb?: Function, errCb?: Function): NzModalRef {
    const self = this;
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: rejectContent,
      nzComponentParams: data,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: '关闭',
          type: 'default',
          loading: false,
          onClick(): void {
            if (errCb) {
              errCb();
            }
            modal.destroy();
          }
        },
        {
          label: '确定',
          type: 'primary',
          loading: false,
          onClick(): void {
            // console.log(modal);
            // const m = modal.getInstance();
            // m.nzFooter[0].disabled = true;
            // this.loading = true;
            /*
            self.orderService.changeSubsidyStatus(data).then(resp => {
              this.loading = false;
              self.modalService.closeAll();
              self.loadData(self.currentPageIndex);
              modal.destroy();
            }).catch(err => {
              this.nzMessageService.error(err.message);
              this.loading = false;
            });*/
            if (okCb) {
              okCb(modal);
            }

          }
        },
      ]
    });
    return modal;
  }
  changeOpenStudentAccount(evt: any) {
    // @ts-ignore
    for (const at of this.accountInfo) {
      if (at.days === evt) {
        this.accountDaysTypeRemainCount = at.count;
      }
    }
  }
}
