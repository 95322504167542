
export enum UserType {
  STUDENT = 0,
  TEACHER = 1,
  SCHOOL_ADMIN = 9,
  TEACH_RESEARCHER = 10, // 教研
  PAD_SYSTEM_ADMIN = 100,
  SUPER_ADMIN = 65535,

}
export const SchoolStatus = {
  0: '正常',
  1: '停用'

};
export const UserTypeDesc = {
  0: '学生',
  1: '教师',
  9: '学校管理员',
  999: '超级管理员'
};
export enum UserStatus {
  NORM = 0, LEAVED
}
export enum ResType  {
  PIC = 0,
  AUDIO = 1,
  VIDEO = 2,
}
export const SCHOOL_TYPE = {
  NONE: 0,
  OWNED: 1,
  FRANCHISED: 2,
  PAYG: 3,
  DESC: {
    0: '未设置',
    1: '直营校',
    2: '加盟校',
    3: '按量付费校',
  },
};
export const PAD_VIDEO_GROUP = {
  ADMIN: 0,
  USER : 1,
  DESC: {
    0: '管理员上传',
    1: '用户上传',
  }
};
export const PAY_STATUS = {
  NONE: 0,
  NO_PAY: 1,
  PAID: 2,
  EXPIRED: 3,
  CLOSED: 5,
  PAYING: 7,
  FAILED: 8,
  DESC: {
    0: 'None',
    1: '未支付',
    2: '已支付',
    3: '过期',
    4: '转入退款',
    5: '已关闭',
    6: '已撤销(刷卡支付)',
    7: '用户支付中',
    8: '支付失败(其他原因, 如银行返回失败)',
  }
};
