import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserType, UserTypeDesc} from '../../../model/Const';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  userName = '';
  userType = null;
  CUserRoleType = UserType;
  viewType = 'classes';
  userRole = 0;

  @Output()
  onChangeType = new EventEmitter();
  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute ,
  ) {
  }

  ngOnInit() {
    const user = this.userService.getUserInfo();
    this.userName = user.name;
    // @ts-ignore
    this.userType = UserTypeDesc[user.type]; //  user.type === UserType.TEACHER ? '教师' : '学生';
    this.userRole = user.type ;
    // @ts-ignore
    this.viewType = this.activatedRoute.snapshot.data.view;
  }
  selectType(evt: any, type: any) {
    this.viewType = type;
    // this.onChangeType.emit(type);
    this.router.navigate([`/user/${type}`]);
  }
  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

}
