import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { HWResourceService as ResourceService } from '../../../services/huawei-resource.service';
import { UserService } from '../../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-upload-image-with-preview',
  templateUrl: './upload-image-with-preview.component.html',
  styleUrls: ['./upload-image-with-preview.component.scss']
})
export class UploadImageWithPreviewComponent implements OnDestroy, OnChanges {
  uploading = false;
  progress = 0;
  @Input()
  displayRatioWidth = 16;
  @Input()
  displayRatioHeight = 9;
  @Input()
  displayIcon = 'cloud-upload';
  @Input()
  displayText = 'Click here to upload image';

  @Input()
  picPreview = true;

  @Input()
  errors = false;


  @Input()
  picUrl = '';
  @Input()
  canDelete = false;
  @Output()
  imageUploaded = new EventEmitter();
  @Output()
  imageUploadFailure = new EventEmitter();
  @Output()
  delete = new EventEmitter();
  @Input()
  picItem = null;

  constructor(private nzMessageService: NzMessageService,
              private http: HttpClient,
              private userService: UserService,
              private resService: ResourceService) {
  }
  ngOnChanges(vars: any) {
    if (!this.picItem) {
      return;
    }
    this.customUpload(this.picItem);
  }

  customUpload = (item: any) => {
    if (!item) {
      return false;
    }
    // @ts-ignore
    this.picUrl = null;
    // this.beforeUpload(item.file as any);
    if (!this.checkSelectFile(item)) {
      return false;
    }
    this.uploading = true;
    this.progress = 0;
    // debugger
    let path = '/images/';
    if (!environment.production) {
      path = '/dev' + path;
    }
    // this.resService.doUpload(item as any,  /*ResType.PIC ,*/ path,
    //   this.doProgress , this.uploadSuccess , this.uploadFailure );
    this.resService.doUpload({file: item, /* type: ResType,*/
      osspath: path,
      progress_cb: this.doProgress,
      success_cb: this.uploadSuccess,
      error_cb: this.uploadFailure});
    return false;
  }
  onDelete() {
    this.delete.emit();
  }
  checkSelectFile(file: any) {
    const isImg = ['image/jpeg', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp'].includes(file.type);
    if (!isImg) {
      this.nzMessageService.error('You can only upload Image file (jpg|gif|png|bmp)');
      return false;
    }
    const isGif = !['image/jpeg', 'image/png', 'image/jpeg', 'image/bmp'].includes(file.type);
    const delta = isGif ? 20 : 5;
    const isOverSize = file.size / 1024 / 1024 < delta;
    if (!isOverSize) {
      this.nzMessageService.error(`${isGif ? 'Gif' : 'Image'} must smaller than ${delta}MB!`);
      return false;
    }
    return true;
  }
  getImageInfo({name, hash, url, file}: {name: string, hash: string, url: string, file: any}, img?: any) {
    if (!img) {
      img = new Image();
    }
    img.onerror = () => {
      this.getImageInfo({name, hash, url, file}, img);
    };
    img.onload = () => {

      const height = img.naturalHeight;
      const width = img.naturalWidth;
      file['height'] = height;
      file['width'] = width;
      img.remove();
      this.imageUploaded.emit({name, hash, url, file});
    };
    img.src = url;
  }
  uploadSuccess = (name: string, hash: string, url: string, file?: File, inOSS = false) => {

    console.log('uploadSuccessuploadSuccessuploadSuccessuploadSuccess');
    this.nzMessageService.info('Upload Success');
    // this.updateStatus(false);
    this.uploading = false;
    // this.updateSource(url);
    if (this.picPreview) {
      // @ts-ignore
      this.picUrl = url;
    }
    this.getImageInfo({name, hash, url, file});
    /*
    const img = new Image();
    img.addEventListener('load', () => {

      const height = img.naturalHeight;
      const width = img.naturalWidth;
      file['height'] = height;
      file['width'] = width;
      img.remove();
      this.imageUploaded.emit({name, hash, url, file});

    });
    img.src = url;
    */

    /*
    // this.uploadFinished(url);
    if (!inOSS) {
      const img = new Image();
      img.addEventListener('load', () => {

        const height = img.naturalHeight;
        const width = img.naturalWidth;
        file['height'] = height;
        file['width'] = width;
        img.remove();
        this.resService.updateImage(id, {width, height}).then( () => {
          this.imageUploaded.emit({id, name, hash, url});
          // console.log(1, this.picUrl, this.uploading)
        });

      });
      img.src = url;
    } else {
      this.imageUploaded.emit({name, hash, url});
      // console.log(2, this.picUrl, this.uploading)
    }*/
    // this.imageUploaded.emit({name, hash, url});
  }
  uploadFailure = (err: any, file: any) => {
    this.uploading = false;
    if (err.name && err.name === 'cancel') {
      return;
    }
    console.log(err);
    this.nzMessageService.error('Upload Error ' + err.message);
    this.imageUploadFailure.emit(file);
  }

  doProgress = function (p: number) {
    if (p > 1) {
      p = 1;
    }
    if (p < 0) {
      p = 0;
    }
    // console.log(Math.floor(p * 100));
    // @ts-ignore
    this.progress =  Math.floor(p * 100);
  };
  ngOnDestroy () {
  }
  /*
    beforeUpload = (file: File) => {
      // console.log('beforeUpload');
      const isImg = ['image/jpeg', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp'].includes(file.type);
      if (!isImg) {
        this.nzMessageService.error('You can only upload Image file (jpg|gif|png|bmp)');
        return;
      }
      const isGif = !['image/jpeg', 'image/png', 'image/jpeg', 'image/bmp'].includes(file.type);
      const delta = isGif ? 20 : 5;
      const isOverSize = file.size / 1024 / 1024 < delta;
      if (!isOverSize) {
        this.nzMessageService.error(`${isGif ? 'Gif' : 'Image'} must smaller than ${delta}MB!`);
        return;
      }
      this.uploading = true;
      this.resService.checkFileHash(file).then((hash) => {
        const ext = file.name.substr(file.name.lastIndexOf('.') );
        this.resService.checkOSSObject(hash + ext).then((res) => {
          const {info, client} = res;
          if (!client && !info) {
            this.nzMessageService.error('system error');
            return ;
          }
          if (info) {
            let url = info.res.requestUrls[0];
            if ( url.indexOf('?') > -1 ) {
              url = url.substring(0, url.indexOf('?'));
            }
            this.picUrl = url;
          } else if (client) {
            this.uploadFile(client, file, hash);
          }
        });
      });

      return isImg && isOverSize;
    }
    async uploadFile (client, file, hash) {
      console.log('uploadFile', hash);
      const ext = file.name.substr(file.name.lastIndexOf('.') );

      const key = hash + ext; // `${this.item.u_id}_${hash}_${file.name}`;
      // console.log(file.name + ' => ' + key);
      // const self = this;
      const user_info = this.userService.getUserInfo();
      return await client.multipartUpload(key, file, {
        meta: {
          userId: user_info.id,
          fileName: encodeURI(file.name),
          fileMd5: key
        },
        progress: this.doProgress.bind(this)
      }).then((resp) => {
        let url = resp.res.requestUrls[0];
        if ( url.indexOf('?') > -1 ) {
          url = url.substring(0, url.indexOf('?'));
        }

        this.resService.add({
          user_id: user_info.id,
          name: file.name,
          hash: hash,
          url:  url,
          size: file.size,
          type: 0
        }).then((res) => {
          this.nzMessageService.info('Upload Success');
          this.uploading = false;
          this.picUrl = url;
          this.imageUploaded.emit(res);
        });

      }).catch((e) => {
        this.uploading = false;
        if (e.name && e.name === 'cancel') {
          return;
        }
        console.log(e);
        this.nzMessageService.error('Upload Error ' + e.message);
      });
    }
    doProgress (p) {
      if (p > 1) {
        p = 1;
      }
      if (p < 0) {
        p = 0;
      }
      this.progress =  Math.floor(p * 100);
    } */
  handlePreview() {

  }
}
