import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'brLineBreaker'
})
export class BrLineBreakerPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value ? value.replace(/\n/g, '<br />') : '';
  }

}
