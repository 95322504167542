import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  CourseType = {
    FREE: 0, // 免费课标识
    SERIAL: 1,
    SPECIAL: 2,
  };


  constructor() {
  }

  static launchFullscreenByElement(element: any) {
    const func = element.requestFullscreen
      || element.mozRequestFullScreen
      || element.msRequestFullscreen
      || element.webkitRequestFullscreen;
    func.apply(element);
  }

  static launchFullscreen() {
    this.launchFullscreenByElement(document.documentElement);
  }

  static exitFullscreen() {

    const func = document.exitFullscreen
      // @ts-ignore
      || document['msExitFullscreen']
      // @ts-ignore
      || document['mozCancelFullScreen']
      // @ts-ignore
      || document['webkitExitFullscreen'];
    func.apply(document);
  }

  static isFullScreen() {
    // @ts-ignore
    return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
  }
}
