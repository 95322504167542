import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StorybookService } from '../../../../services/storybook.service';

@Component({
  selector: 'app-user-book-player',
  templateUrl: './user-book-player.component.html',
  styleUrls: ['./user-book-player.component.scss'],
})



export class UserBookPlayerComponent implements OnInit, OnDestroy {

  storybookId = 0;
  pages: any[] = [];
  autoPlay = true;
  pauseCurrentAudio = false;
  playingCurrentAudio = false;
  // @ts-ignore
  @ViewChild('player', {static: true}) player: NzCarouselComponent;
  // @ts-ignore
  @ViewChild('pageContainer', {static: true}) pageContainer: ElementRef;
  // @ts-ignore
  @ViewChild('wordHighlight', {static: true}) wordHighlight: ElementRef;
  // @ts-ignore
  @ViewChild('audioPlayer', {static: true}) audioPlayer: ElementRef;
  audio: any = null;

  currentPlayIndex = 0;
  currentFromIndex = 0;
  emptyAudioTimer = null;
  maxIndex = 0;
  playedBook = false;
  resourceLoading = true;
  resourceLoadingPercent = '0';
  audioPlayed = true;
  highlightTimer = null;

  usePreload = true;

  allResource = {
    images: {},
    audios: {}
  };
  pageAudiosCache = new Map();
  _pageImgSize = {};
  _allResourceCount = 0;
  _allResourceLoadedCount = 0;
  highlightPosition = {
    width: 0,
    height: 0,
    left: 0,
    top: 0
  };
  playEndTimer = null;
  constructor(
    private userService: UserService,
    private activateRouter: ActivatedRoute ,
    private bookService: StorybookService,
    private router: Router ,
    private nzMessageService: NzMessageService,
  ) {
    console.log('book player');
    this.activateRouter.params.subscribe( params => {
      if (params['book_id'] && parseInt(params['book_id'], 10) === params['book_id'] * 1) {
        this.storybookId = parseInt(params['book_id'], 10);
      }


    });

  }
  isPlaying() {
    if (!this.audio) {
      return;
    }
    return !!(this.audio?.currentTime > 0 && !this.audio.paused && !this.audio.ended && this.audio.readyState > 2);
  }
  audioPlaying = (e?: any) => {
    console.log(this.audio.currentTime);
  }
  audioLoadError = (e?: any) => {
    if (!this.audio.src) {
      return;
    }
    console.log('audio load error', e.target === this.audio);
    if (this.autoPlay) {
      // @ts-ignore
      this.playEndTimer = setTimeout(() => {
        console.log('videoPlayEnd form error');
        this.audioPlayEnd();
      }, 2 * 1000);
    }

  }
  audioLoadedMetaData = (e?: any) => {
    console.log('current audio loaded', e);
    // @ts-ignore
    this.emptyAudioTimer = setTimeout(() => {
      if (this.audio.src) {

      }

      // this.audio.play();
      this.playAudio();
    }, 1000);
  }
  audioPlayEnd = (e ?: any) => {
    // @ts-ignore
    clearInterval(this.highlightTimer);
    console.log('playedned ', e);
    this.audio.currentTime = 0;
    this.pauseCurrentAudio = true;
    const toplay = this.pages[this.currentPlayIndex] && this.pages[this.currentPlayIndex].toPlayAudios;
    if (toplay && toplay.length > 0) {
      this.beforeChange({from: this.currentFromIndex , to: this.currentPlayIndex});
      return;
    }

    // this.audio.pause();
    // this.audio.removeAttribute('src');
    this.stopAudio();
    if (this.autoPlay && this.playedBook) {
      // @ts-ignore
      this.emptyAudioTimer = setTimeout(() => {
        this.nextPage();
      }, 1000);
    }
  }
  orderTextConfig = (a: any, b: any) => {
    return a.cue_start_ms > b.cue_start_ms;
  }
  highlightLine() {

  }
  highlightWord() {

  }
  interval(func: any, wait: number, times: number) {
    const interv = function(w, t) {
      return function () {
        if ( typeof t === 'undefined' || t-- > 0) {
          setTimeout(interv, w);
          try {
            func.call(null);
          } catch (e) {
            t = 0;
            // @ts-ignore
            throw new Error(e.toString());
          }
        }
      };
    }(wait, times);
    setTimeout(interv, wait);
  }
  transCoord(id: any, conf: any) {
    // @ts-ignore
    const {w, h} = this._pageImgSize[id];
    const size = this.pageContainer.nativeElement.getBoundingClientRect();
    const w_ratio = w / size.width;
    const h_ratio = h / size.height;
    conf.top = conf.top * h_ratio;
    conf.height = conf.height * h_ratio;
    conf.left = conf.left * w_ratio;
    conf.width = conf.width * w_ratio;
    conf.cue_start_ms = conf.cue_start_ms * 1;
    return conf;
  }
  AdjustingInterval() {
    function func(workFunc: any, interval: number, errorFunc: any) {
      // @ts-ignore
      const that = this;
      let expected: number, timeout: string | number | NodeJS.Timeout | undefined;
      // @ts-ignore
      this.interval = interval;
// @ts-ignore
      this.start = function() {
        expected = Date.now() + this.interval;
        timeout = setTimeout(step, this.interval);
      };
// @ts-ignore
      this.stop = function() {
        clearTimeout(timeout);
      };

      function step() {
        const drift = Date.now() - expected;
        if (drift > that.interval) {
          // You could have some default stuff here too...
          if (errorFunc) {
            errorFunc();
          }
        }
        workFunc();
        expected += that.interval;
        timeout = setTimeout(step, Math.max(0, that.interval - drift));
      }
    }
    return func;
  }
  audioPlayStart = (idx: number) => {
    // @ts-ignore
    clearTimeout(this.emptyAudioTimer);
    // console.log('has audio source', this.audio.src && this.audio.buffered.length);
    if (this.audio.src) {
// @ts-ignore
      this.emptyAudioTimer = setTimeout(() => {
        /*this.pages[idx].text_config.sort(this.orderTextConfig);
        this.pages[idx].text_config.forEach(cfg => {
          this.transCoord(this.pages[idx].page_id, cfg);
        });
        const lines = _.groupBy(this.pages[idx].text_config, 'line')

        this.pageContainer

        // let hlWordIdx = 0;
        // let cfg = this.pages[idx].text_config[hlWordIdx];
        // let conf = this.transCoord(this.pages[idx].page_id, cfg);
        // let hlLineIdx = cfg.line * 1;

        // this.videoPlayEnd
        let ms = 0;
        let i = 1;
        let j = 0;
        let cfg = lines[i][j];
        cfg = this.transCoord(this.pages[idx].page_id, cfg);
        */
        // this.audio.play();
        this.playAudio();
        /*
        this.highlightTimer = setInterval(() => {
          if (!lines[i]) {
            console.log('trigger end 1');
            clearInterval(this.highlightTimer);
          }
          if (lines[i][j]) {
            // console.log(cfg.cue_start_ms , ms)
            // (cfg.cue_start_ms - 5 ) < ms && (cfg.cue_start_ms + 5 ) > ms
            if ( cfg.cue_start_ms === ms ) {
              console.log('go next word');
              this.highlightPosition = cfg;
              // this.wordHighlight.nativeElement.style =
              // `top:${cfg.top}px;left:${cfg.left}px;width:${cfg.width}px;height:${cfg.height}px;`;
              j++;
              cfg = lines[i][j];
              if (!cfg) {
                i++;
                j = 0;
                cfg = lines[i][j];
                console.log('trigger change line 1');
              }
              if (!cfg) {
                console.log('trigger end 2');
                clearInterval(this.highlightTimer);
                return;
              }
              cfg = this.transCoord(this.pages[idx].page_id, cfg);
            }
          } else {
            ms--;
            i++;
            j = 0;
            console.log('trigger change line 2');
          }
          ms++;
        }, 1); */
      }, 1000);
    } else {
      // @ts-ignore
      this.emptyAudioTimer = setTimeout(() => {
        console.log('videoPlayEnd, from without audio source ');
        this.audioPlayEnd();
      }, 3 * 1000);
    }
  }
  prevPage(event?: any) {
    console.log('prevPage');
    // @ts-ignore
    clearTimeout(this.emptyAudioTimer);
    this.emptyAudioTimer = null;
    // this.audio.pause();
    this.pauseAudio();
    // this.currentPlayIndex -= 1;
    // if (this.currentPlayIndex < 0 ) {
    //   this.currentPlayIndex = 0;
    // }
    if (this.currentPlayIndex === 0) {
      return;
    }
    // this.audio.src = '';
    // this.audio.removeAttribute('src');
    this.stopAudio();
    setTimeout(() => {
      this.player.pre();
    }, 10);
    // this.book-player.goTo(this.currentPlayIndex);
  }

  nextPage(event?: any) {
    // @ts-ignore
    clearTimeout(this.emptyAudioTimer);
    this.emptyAudioTimer = null;
    // this.audio.pause();
    this.pauseAudio();
    this.pauseCurrentAudio = false;
    // this.currentPlayIndex += 1;
    // if (this.currentPlayIndex > this.maxIndex ) {
    //   this.currentPlayIndex = this.maxIndex;
    // }
    if (!this.audioPlayed && this.currentPlayIndex === this.maxIndex) {
      return;
    }
    // this.audio.src = '';
    // this.audio.removeAttribute('src');
    this.stopAudio();
    setTimeout(() => {
      this.player.next();
    }, 10);
    // this.book-player.goTo(this.currentPlayIndex);
  }
  ngOnDestroy() {
    console.log('destroy component');
    this.audio.pause();
    this.audio.src = '';
    // @ts-ignore
    clearTimeout(this.playEndTimer);
    // @ts-ignore
    clearTimeout(this.emptyAudioTimer);
    this.autoPlay = false;
  }

  processLoadResource () {

    this.resourceLoadingPercent =  '' + (this._allResourceLoadedCount / this._allResourceCount * 100).toFixed(1);
    console.log(this._allResourceLoadedCount, this._allResourceCount, this.resourceLoadingPercent);
    if (this._allResourceLoadedCount === this._allResourceCount) {
      this.resourceLoading = false;
    }
  }
  preLoadImage(url: string, page_id: string) {
    const i = new Image();
    i.addEventListener('load', this._resourceImageLoaded, false);
    i.addEventListener('error', () => {
      this._allResourceLoadedCount += 1;
    }, false);
    i.setAttribute('media_id', page_id);
    // url = url.replace('http:');
    // url = url.replace('https:');
    i.src = url;
  }
  preLoadAudio(url: string) {
    const a = new Audio();
    a.addEventListener('canplaythrough', this._resourceAudioLoaded, false);
    a.addEventListener('error', () => {
      this._allResourceLoadedCount += 1;
    }, false);
    a.src = url;
    this.pageAudiosCache.set(url, a);
  }
  _resourceImageLoaded = (e: any) => {
    this._allResourceLoadedCount += 1;
    const img = e.target;
    const mid = img.getAttribute('media_id');
    // @ts-ignore
    this._pageImgSize[mid] = {
      w: img.naturalWidth,
      h: img.naturalHeight
    };
    this.processLoadResource();
  }
  _resourceAudioLoaded = (e?: any) => {
    this._allResourceLoadedCount += 1;
    this.processLoadResource();
  }

  ngOnInit() {
    this.audio = this.audioPlayer.nativeElement;
    this.audio.addEventListener('ended', this.audioPlayEnd);
    this.audio.addEventListener('loadedmetadata', this.audioLoadedMetaData);
    this.audio.addEventListener('error', this.audioLoadError);
    // this.audio.addEventListener('timeupdate', this.audioPlaying);
    const previewToken = this.bookService.getParamValueQueryString('preview-token');
    if (previewToken) {
      this.usePreload = false;
    }
    setTimeout(() => {
      this.bookService.fetch(this.storybookId).then(data => {
        this.pages = data;
        // data.forEach(r => {
        //   r.img = r.img.replace('http:');
        //   r.img = r.img.replace('https:');
        //
        //   for (let i = 0; i < r.audios.length; i++) {
        //     r.audios[i] = r.audios[i].replace('http:');
        //     r.audios[i] = r.audios[i].replace('https:');
        //     i++;
        //   }
        // });
        console.log(11111, data, this.usePreload);
        if (this.usePreload) {
          data.map((p: any) => {
            if (p.audios.length) {
              this._allResourceCount += p.audios.length;
            }
            if (p.img) {
              this._allResourceCount += 1;
            }
          });
          for (const page of data) {
            if (page.audios.length) {
              let i = 0;
              for (const url of page.audios) {
                this.preLoadAudio(url);
                i++;
              }
            }
            if (page.img) {
              this.preLoadImage(page.img, page.page_id);
            }
          }
        }

        // this._allResourceCount = this.allAudioResourcesList.length + this.allImageResourcesList.length;
        // let rec = localStorage.getItem('listen-books-rec');
        // if (rec) {
        //   rec = JSON.parse(rec);
        //   if (rec[this.storybookId]) {
        //     this.currentPlayIndex = parseInt(rec[this.storybookId], 10) ;
        //   }
        // }

        if (data[this.currentPlayIndex] && data[this.currentPlayIndex].audio) {
          this.audio.src = data[this.currentPlayIndex].audio;
        }
        this.maxIndex = this.pages.length - 1;
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 100);
      });
    }, 300);

  }
  goBack() {
    window.history.go(-1);
  }

  afterChange(idx?: any) {
    this.audioPlayStart(idx);
  }
  beforeChange (evt: any) {
    const {from, to} = evt;
    this.currentFromIndex = from ;
    console.log('beforeChange beforeChange beforeChange');
    // this.audio.pause();
    // this.audio.src = '';
    // this.audio.removeAttribute('src');
    // this.stopAudio();
    if (this.currentPlayIndex !== to) {
      this.pages[to].toPlayAudios = [];
    }
    if (from === this.pages.length - 1 && to === 0) {
      this.autoPlay = true;
      this.playedBook = false;
      return;
    }
    this.playAudioSource(to);
    this.currentPlayIndex = to;

  }
  playAudioSource(to: any) {
    if (!this.pages[to].toPlayAudios || !this.pages[to].toPlayAudios.length) {
      this.pages[to].toPlayAudios = [].concat(this.pages[to].audios);
    }
    if (this.pages[to].toPlayAudios.length) {
      // this.pages[to].toPlayAudios = this.pages[to].audios;
      const audio_src = this.pages[to].toPlayAudios.shift();
      // audio_src = audio_src.replace('http:', '');
      // audio_src = audio_src.replace('https:', '');
      this.audio.currentTime = 0;
      if (audio_src === this.audio.src) {
        // this.audio.play();
        this.playAudio();
      } else {
        // this.audio = this.pageAudiosCache[audio_src];
        this.audio.src = audio_src;
      }


    }
  }
  listenBook (evt?: any) {
    console.log('start here');
    if (this.playedBook) {
      this.playedBook = false;
      this.audio.pause();
    } else {
      this.beforeChange({from: 0, to: 0});
      this.afterChange(0);
      this.playedBook = true;

    }

  }
  toggleAutoPlay() {
    this.autoPlay = !this.autoPlay;
    if (this.autoPlay && this.playedBook) {
      this.continueAutoPlay();
    }
  }
  continueAutoPlay() {
    this.audio.play();
  }
  replayCurrentAudio () {
    if (!this.autoPlay && this.playedBook) {
      // if (this.isPlaying()) {
      //   this.audio.pause();
      // } else {
      //   this.audio.play();
      // }
      if (this.audio.currentTime === 0) {
        this.playAudioSource(this.currentPlayIndex);
      } else {
        if (this.audio.paused) {
          this.playAudio();
        } else {
          // this.audio.pause();
          this.pauseAudio();
        }
      }
      // if (this.pages[this.currentPlayIndex].audio) {
      //   this.audio.src = this.pages[this.currentPlayIndex].audio;
      //   this.audio.play();
      // }
    }
  }
  togglePauseCurrentAudio(evt: any) {
    this.pauseCurrentAudio = !this.pauseCurrentAudio;
    if (this.pauseCurrentAudio) {
      this.audio.pause();
    } else {
      // this.audio.play();
      this.playAudio();
    }

  }
  pauseAudio() {
    this.audio.pause();
  }
  playAudio() {
    this.audio.play();
    this.playingCurrentAudio = true;
    this.pauseCurrentAudio = false;
  }
  stopAudio() {
    this.playingCurrentAudio = false;
    this.audio.pause();
    this.audio.currentTime = 0;
    // this.audio.src = '';
    this.audio.removeAttribute('src');
  }
}
