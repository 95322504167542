// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GiftsService } from '../../../../../../services/gifts.service';

@Component({
  selector: 'app-admin-gifts-add',
  templateUrl: './gifts-add.component.html',
  styleUrls: ['./gifts-add.component.scss']
})
export class GiftsAddComponent implements OnInit {
  giftForm: UntypedFormGroup;



  previewImgUrl = '';
  previewVisible = false;
  coverError = false;
  saving = false;
  editGiftId = null;
  returnPage = 1;

  constructor(private modalService: NzModalService,
              private giftsService: GiftsService,
              private fb: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {

    this.giftForm = this.fb.group({
      cover: [null, [Validators.required]],
      name: [null, [Validators.required]],
      price: [null, [Validators.required, Validators.min(1)]],
      amount: [null, [Validators.required, Validators.min(-1)]],
    });
    this.activatedRoute.params.subscribe( (params: any) => {
      if (params.id && parseInt(params.id, 10) === params.id * 1) {
        // @ts-ignore
        this.editGiftId = parseInt(params.id, 10);
      }
    });
  }

  ngOnInit() {

    if (this.editGiftId) {
      this.giftsService.getGift(this.editGiftId).then(td => {
        this.giftForm.controls['cover'].setValue(td.cover);
        this.previewImgUrl = td.cover;
        this.giftForm.controls['name'].setValue(td.name);
        this.giftForm.controls['price'].setValue(td.price);
        this.giftForm.controls['amount'].setValue(td.amount);
      });
    }
  }

  submitForm() {

    for (const i in this.giftForm.controls) {
      this.giftForm.controls[i].markAsDirty();
      this.giftForm.controls[i].updateValueAndValidity();
    }
    console.log(this.giftForm.value);
    if (this.giftForm.status !== 'VALID') {
      return;
    }
    this.saving = true;

    if (this.editGiftId) {
      this.giftsService.saveGift(this.editGiftId, this.giftForm.value).then(data => {
        this.saving = false;
        this.nzMessageService.success('编辑成功');
        this.giftForm.reset();
        this.router.navigate(['/admin/gifts/index']);
      }).catch(() => {
        this.saving = false;
        this.nzMessageService.error('编辑失败');
      });
    } else {
      this.giftsService.addGift(this.giftForm.value).then(data => {
        this.saving = false;
        this.nzMessageService.success('添加成功');
        this.giftForm.reset();
        this.router.navigate(['/admin/gifts/index']);
      }).catch(() => {
        this.saving = false;
        this.nzMessageService.error('添加失败');
      });
    }


  }

  onImageUploaded(evt: any) {
    this.giftForm.controls['cover'].setValue(evt.url);
    console.log(evt.url);

    this.previewImgUrl = evt.url;
  }
  previewImage(file: any) {
    this.previewImgUrl = file.url;
    this.previewVisible = true;
  }

}
