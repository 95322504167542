<nz-header class="admin-top-bar" style="padding: 0">
  <ul nz-menu  [nzMode]="'horizontal'" style="">
    <!--<li style="position: relative;top: 1px;float: left;  font-weight: 400;">-->
      <!--<i class="trigger" nz-icon-->
         <!--[type]="isCollapsed?'menu-unfold':'menu-fold'"-->
         <!--(click)="triggerSide($event)"></i>-->
      <!--<ng-template #trigger>-->
        <!--<i nz-icon type="up"></i>-->
      <!--</ng-template>-->
    <!--</li>-->
    <li class="school-name" style="color: #0b1829">全阅读 · 汇报助手平台 <span style="color: #f50;font-size: 1rem;">V2</span>| </li>
    <li class="school-name"> {{schoolName}} </li>
    <li style="float: right;margin-top: 10px;margin-right: 1rem;">
      <div>
        <a style="color: #f50"  (click)="showAppDownload(1)">教师端下载</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a style="color: #f50"  (click)="showAppDownload(0)">学生端下载</a>
        <nz-divider nzType="vertical"></nz-divider>
        <span style="margin-left: 1rem">{{user.name}}</span>
        <a style="color: #0e90d2; margin-left: 20px" (click)="logout()">退出</a>
      </div>
      <!--<nz-dropdown [nzPlacement]="'bottomRight'">-->
        <!--<a nz-dropdown style="line-height: 64px;display: block;">-->
          <!--<nz-avatar [nzShape]="'square'" [nzSize]="'large'" [nzIcon]="'anticon anticon-user'"></nz-avatar>-->
        <!--</a>-->
        <!--<ul nz-menu>-->
          <!--<li nz-menu-item *ngIf="user.name">-->
            <!--<span>{{user.name}}</span>-->
          <!--</li>-->
          <!--<ng-container  *ngIf="canGoFront">-->
          <!--<li nz-menu-item>-->
            <!--<span [routerLink]="'/user/classes'">访问前端</span>-->
          <!--</li>-->
          <!--<li nz-menu-divider></li>-->
          <!--</ng-container>-->
          <!--<li nz-menu-item (click)="logout()">Logout</li>-->
        <!--</ul>-->
      <!--</nz-dropdown>-->
    </li>
  </ul>
</nz-header>
<ng-template #appTeacherQrcodeContent>
  <div style="text-align: center">
    <img style="width: 100%;height: auto;" src="//hw-cdn.ireadabc.com/app/ireadabc_teacher.png" alt="">
  </div>
</ng-template>
<ng-template #appStudentQrcodeContent>
  <div style="text-align: center">
    <img style="width: 100%;height: auto;" src="//hw-cdn.ireadabc.com/app/ireadabc_student.png" alt="">
  </div>
</ng-template>
