<div class="table-operations">

    <!--<button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/classes/'+classId+'/students/add'">-->
        <!--<i nz-icon nzType="team" nzTheme="outline"></i>添加新学生</button>-->


    <!--<button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/classes/'+classId+'/students/batch_add'">-->
        <!--<i nz-icon type="team" theme="outline"></i>批量添加学生-->
    <!--</button>-->



    <button nz-button nzType="dashed"  [nzSize]="'large'" [nzLoading]="isLoadingTeacherCLass" (click)="changeStudentClass()">
        <i nz-icon nzType="team" nzTheme="outline"></i>调整班级</button>

    <nz-modal [(nzVisible)]="classChangeVisible" nzTitle="调整班级" (nzOnCancel)="closeChangeStudentClass()" (nzOnOk)="handleChangeOk()">
        <ng-container *nzModalContent>
        <div class="gutter-example">
            <div nz-row nzGutter="16">
                <div nz-col class="gutter-row" nzSpan="14">
                    <div class="gutter-box">
                        <nz-select style="width: 100%;"
                                   [(ngModel)]="selectedTeacher"
                                   (ngModelChange)="doSelectTeacher($event)"
                                   nzPlaceHolder="Select Teacher" nzAllowClear>
                            <nz-option *ngFor="let t of teacherList" [nzValue]="t" [nzLabel]="t.name+'|'+t.phone"></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col class="gutter-row" nzSpan="10">
                    <div class="gutter-box">
                        <nz-select style="width: 100%;"
                                   [(ngModel)]="selectedClass"
                                   nzPlaceHolder="Select Class" nzAllowClear>
                            <nz-option *ngFor="let c of classList" [nzValue]="c" [nzLabel]="c.name"></nz-option>
                        </nz-select>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
    </nz-modal>


    <nz-modal [(nzVisible)]="confirmChangeVisible" *ngIf="this.confirmChangeVisible"
              nzTitle="调整班级"
              (nzOnCancel)="closeConfirm()"
              (nzOnOk)="sureConfirm()">
        <h1 style="color: red" page="1">是否确认？</h1>
        <label >
            {{changeClassText}}
        </label>
        <!--<h3>{{selectedTeacher.name}} to {{selectedClass.name}}</h3>-->
    </nz-modal>


    <a nz-popconfirm nzPopconfirmTitle="确定批量删除学生？"
       (nzOnConfirm)="deleteStudents()" >
        <button nz-button  [nzSize]="'large'" [disabled]="disabledButton">
            <i nz-icon nzType="team" nzTheme="outline"></i>
            批量删除学生</button>
    </a>

    <!--<ng-container *ngIf="schoolMaster">-->


     <!---->



    <!--</ng-container>-->
    <!--<ng-container *ngIf="showBatchAddBtn">-->
        <!--<nz-upload-->
                <!--[nzAccept]="'*.csv'"-->
                <!--[nzBeforeUpload]="batchAddStudents"-->
                <!--nzAction="/users/batch-add-users">-->
            <!--<button nz-button [nzSize]="'large'">-->
                <!--<i nz-icon type="upload"></i><span>批量添加学生</span>-->
            <!--</button>-->
        <!--</nz-upload>-->
        <!--<nz-modal [(nzVisible)]="errStudentDataVisible" nzTitle="错误学生数据" (nzOnCancel)="handleErrStuCancel()" (nzOnOk)="handleErrStuOk()">-->
            <!--<p style="color: red">请记录后再点击退出或确认，点击后自动加载新数据</p>-->
            <!--<p *ngFor="let d of errStus">{{d}}</p>-->
        <!--</nz-modal>-->
    <!--</ng-container>-->
    <!--&lt;!&ndash;<button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/class/'+classId+'/students/add'">&ndash;&gt;-->
        <!--&lt;!&ndash;<i nz-icon type="team" theme="outline"></i>添加已有学生</button>&ndash;&gt;-->





</div>
<nz-modal [(nzVisible)]="deleteStudentConfirm" nzTitle="确认学生数据" (nzOnCancel)="closeDeleteStudentConfirm()" (nzOnOk)="sureDeleteStudentConfirm()">
    <ng-container *nzModalContent>
    <p style="color: red" page="2">如删除学生，则该学生的汇报数据，作业数据将全部清零</p>
    <p style="color: red">确认删除吗？？？</p>
    </ng-container>
</nz-modal>
<div class="table-operations">
    <h3>{{className}} | {{teacher}}</h3>
    <span style="color: #999;" *ngIf="classComment">备注： {{classComment}}</span>
</div>
<nz-table #studentsTable [nzData]="displayData"
          [nzLoading]="loadingData"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalNum">
    <thead>
    <tr>
        <th nzShowCheckbox [(nzChecked)]="allChecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="checkAll($event)"></th>
        <th>序号</th>
        <!--<th>学校</th>-->
        <th>姓名</th>
        <th>电话</th>
        <th>教师</th>
        <th>班级</th>
        <th style="text-align: center">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of studentsTable.data">
        <td nzShowCheckbox [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td>{{data.numId}}</td>
        <!--<td>{{data.school_name}}</td>-->
        <!--<td>{{data.id}}</td>-->
        <td>{{data.user_name}}</td>
        <td>{{data.phone}}</td>
        <td>{{data.teacher_name}}</td>
        <td>
            <div class="operations">
                <a (click)="goClassStudentsList(data.class_id)">{{data.class_name}}</a>
            </div>
        </td>
        <td style="width: 10rem;">
            <div class="operations">
                <a nz-popconfirm nzPopconfirmTitle="确定删除学生？"
                   (nzOnConfirm)="deleteStudentReConfirm(data.user_id)" >
                    删除
                </a>
                <a (click)="editStudent(data.user_id)">编辑</a>
            </div>

        </td>
    </tr>
    </tbody>
</nz-table>
