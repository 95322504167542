import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-log',
  templateUrl: './app-log.component.html',
  styleUrls: ['./app-log.component.scss']
})
export class AppLogComponent implements OnInit {
  loading = false;
  // ltsUrl: SafeResourceUrl = '';
  ltsUrl = '';
  // @ts-ignore
  iframeLtsUrl: SafeResourceUrl = null;
  groupId = '57d4a937-7d73-42d6-aa68-1e3cd8af4954';
  topicId = '7fd4689e-11d0-4d80-9f54-2fc3b4419235';
  topicName = 'HTTP日志';
  groupName = '全阅读';
  epsId = '9a2c1b38-fcce-4728-9a95-cde9dcdad89a';
  regionId = 'cn-north-4';
  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer,
              private msg: NzMessageService) {
  }

  ngOnInit() {

  }
  openLTS() {
    window.open(this.ltsUrl, '_blank');
  }
  fetchLTS() {
    this.loading = true;
    this.http.post('/hw-iam/lts/get-access-token', {}).subscribe(r => {
      console.log(r);
      this.loading = false;
      // const turl = `https://console.huaweicloud.com/lts/?region=${this.regionId}&cfModuleHide=hidden#/cts/logEventsLeftMenu/events?groupId=${this.groupId}&topicId=${this.topicId}&topicName=${this.topicName}&groupName=${this.groupName}&epsId=${this.epsId}`;
      const turl = `https://console.huaweicloud.com/lts/?region=${this.regionId}&cfModuleHide=hidden#/cts/logEventsLeftMenu/events?groupId=${this.groupId}&topicId=${this.topicId}&epsId=${this.epsId}`;
      console.log(turl);
      const target_console_url = encodeURIComponent(turl);
      console.log(target_console_url);
      // @ts-ignore
      const logintoken = encodeURIComponent(r['login_token']);
      const auth_lts = ['https://auth.huaweicloud.com/authui/federation/login?service=',
        target_console_url, '&logintoken=', logintoken, '&idp_login_url='];
      const url = auth_lts.join('');
      console.log(url);
      this.ltsUrl = url;
      this.iframeLtsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }, err => {
      this.loading = false;
      this.msg.error(err.message);
    });
  }
}
