import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TabletService } from '../../../../../../services/tablet.service';


@Component({
  selector: 'app-pad-active-manage-import',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class TabletManageImportComponent implements OnInit {
  data: any[] = [];
  brandInfo = [{brand: 'incar_710', model: 'R20'},
    {brand: 'unisoc', model: 'T10'},
    {brand: 'unisoc', model: 'X20'},
    {brand: 'unisoc', model: 'X20_NEW'},
    {brand: 'unisoc', model: 'X20_v202303'},
    {brand: 'unisoc', model: 'HW_A6'},
    {brand: 'unisoc', model: '606'},
    {brand: 'incar', model: 'R20'}];
  brands: string[] = [];
  models: string[] = [];
  validateForm!: UntypedFormGroup;
  isSubmitting = false;

  constructor(private activatedRoute: ActivatedRoute,
              private fb: UntypedFormBuilder,
              private tabletService: TabletService,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      brand: [null, [Validators.required]],
      model: [null, [Validators.required]],
      sn: [null, [Validators.required]],
      // type: [0, [Validators.required]],
      // agoAndAfter: [[null, null], [Validators.required]],
    });
    // @ts-ignore
    window.validateForm = this.validateForm;
    this.brands = Array.from(new Set(this.brandInfo.map((b: any) => {
      return b.brand;
    })));

  }
  brandChange(value: string): void {
    const models = this.brandInfo.filter((b: any) => {
      return b.brand === value;
    }).map((m: any) => {
      return m.model;
    });
    this.models = Array.from(new Set(models));
  }
  submit() {
  }
  loadData(page: any) {

  }

  submitForm(): void {
    if (this.validateForm.valid) {
      this.isSubmitting = true;
      console.log('submit', this.validateForm.value);
      this.tabletService.import(this.validateForm.value).then((res: any) => {
        this.router.navigate(['/admin/tablet-manage/index']);
      }).catch((err: any) => {
        this.nzMessageService.error(err.message);
        this.isSubmitting = false;
      });
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
