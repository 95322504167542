<div class="teacher-class-view">
  <div class="view-body">
    <app-user-header (onChangeType)="changeView($event)"></app-user-header>
    <div class="content classes" >
      <div class="side-menu">
        <div class="menu-top"></div>
        <div class="menu-body">
          <div class="school t"><span><span class="mark">•</span> 学校</span>
            <span class="c">{{schoolName}}</span></div>
          <div class="teacher t"><span><span class="mark">•</span> 老师</span>
            <span class="c">{{userName}}</span></div>
          <div class="classes t">
              <span><span class="mark">•</span> 班级</span>
            <div class="class-list">
              <ul>
                <li *ngFor="let cls of classes; let i = index"
                    [ngClass]="{'active': cls.class_id === currentClassId || currentClassId === null}">
                  <span   (click)="changeClass($event, cls.class_id)" style="cursor: pointer">{{cls.class_name}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>


      </div>
      <div class="main">
        <div class="table-wrapper">
          <nz-table
                  nzFrontPagination = "false"
                  [(nzPageIndex)] = "pageIndex"
                  [nzTotal]="totalRow"
                  nzSize="small"
                  [nzPageSize]="15"
                  #rowSelectionTable
                  [nzData]="dataSet"
                  (nzPageIndexChange)="showClassNumber($event)" >
            <thead>
            <tr>
              <th>学生姓名</th>
              <th>账号</th>
              <th style="width:100px">状态</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of rowSelectionTable.data">
              <td>{{data.name}}</td>
              <td>{{data. phone}}</td>
              <td>{{data.status}}</td>
            </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </div>
  </div>
</div>

