import { ElpService } from '../../../../../../services/elp.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component } from '@angular/core';


@Component({
  selector: 'app-elp-log-index',
  template: `
    <nz-table #nzTable 
              [nzLoading]="loadingData" 
              [nzData]="listOfDisplayData"
              nzSize="small"
              [nzTotal]="total"
              [nzPageSize]="pageSize"
              [nzPageIndex]="pageIndex"
              [nzFrontPagination]="false"
              [nzLoading]="loadingData"
              (nzQueryParams)="onQueryParamsChange($event)"
              nzTableLayout="fixed">
      <thead>
        <tr>
          <th nzCustomFilter>
            设备
            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
              <span nz-icon nzType="search"></span>
            </nz-filter-trigger>
          </th>
          <th>记录时间</th>
          <th>上传时间</th>
          <th>日志连接</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of nzTable.data">
          <td>{{ data.p_device_id }}</td>
          <td>{{ data.start }}</td>
          <td>{{ data.upload }}</td>
          <td>
              <a target="_blank" href="{{ data.log }}">{{ data.log }}</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
          <button nz-button nzSize="small" nzType="primary" (click)="search()" class="search-button">Search</button>
          <button nz-button nzSize="small" (click)="reset()">Reset</button>
        </div>
      </div>
    </nz-dropdown-menu>
  `,
  styles: [
    `
      .search-box {
        padding: 8px;
      }

      .search-box input {
        width: 188px;
        margin-bottom: 8px;
        display: block;
      }

      .search-box button {
        width: 90px;
      }

      .search-button {
        margin-right: 8px;
      }
    `
  ]
})

export class ElpLogIndexComponent {
  searchValue = '';
  visible = false;

  listOfDisplayData = [];
  total = 0;
  loadingData = false;
  pageSize = 50;
  pageIndex = 1;
  private currentParams: any = {};
  constructor(private elpService: ElpService,
              private nzMessageService: NzMessageService) {

  }
  reset(): void {
    this.searchValue = '';
    this.listOfDisplayData = [] ;
  }
  onQueryParamsChange(params: any): void {
    console.log(params);
    if (!params['identifier']) {
      params['identifier'] = this.searchValue;
    }
    if (!params['identifier']) {
      return;
    }
    this.currentParams = params;
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort && sort.find((item: any) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadData(this.currentParams);
  }
  search() {
    this.currentParams['identifier'] = this.searchValue;
    this.onQueryParamsChange({
      page: 1,
      identifier: this.searchValue
    });
    this.loadingData = true;

  }
  loadData(query: {pageIndex: number, pageSize?: number, mac?: string}) {
    if (!query.pageSize) {
      query.pageSize = this.pageSize;
    }
    this.loadingData = true;
    this.visible = false;
    this.elpService.fetchLogList(query).then((res: any) => {
      console.log(res);
      this.listOfDisplayData = res.rows;
      this.total = res.total;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      this.loadingData = false;
    });
  }

}
