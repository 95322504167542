<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/schools/index'" [queryParams]="{page: returnListPage}">
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    </div>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm($event,validateForm.value)">
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>学校名称</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入学校名称！">
                <input nz-input formControlName="school_name" placeholder="学校名称">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>管理员姓名</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入教师姓名！">
                <input nz-input formControlName="name" placeholder="管理员姓名">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" >管理员性别</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback  class="sex">
                <nz-radio-group nzName="sex" formControlName="sex">
                    <label nz-radio nzValue="1" >男</label>
                    <label nz-radio nzValue="0" >女</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>
        <!--<nz-form-item>-->
            <!--<nz-form-label [nzSpan]="7" >管理员生日</nz-form-label>-->
            <!--<nz-form-control [nzSpan]="12" >-->
                <!--<nz-date-picker formControlName="birthday"></nz-date-picker>-->
            <!--</nz-form-control>-->

        <!--</nz-form-item>-->
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>管理员电话</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="phoneErrorTpl">
                <input nz-input
                        style="width: 100%"
                        nz-input
                        formControlName="phone"
                        placeholder="管理员电话">
                <ng-template #phoneErrorTpl let-control>
                    <ng-container *ngIf="validateForm.get('phone')?.hasError('duplicated')">
                        管理员电话已存在！
                    </ng-container>
                    <ng-container *ngIf="validateForm.get('phone')?.hasError('required')">
                        请正确输入管理员电话！
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7">功能</nz-form-label>
            <nz-form-control>

                <nz-checkbox-group
                        formControlName="config"
                        [(ngModel)]="checkConfigOptions" ></nz-checkbox-group>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="7">App定制</nz-form-label>
            <nz-form-control>
                <nz-switch nzName="customized" formControlName="customized" nzCheckedChildren="开" nzUnCheckedChildren="关"></nz-switch>
            </nz-form-control>
        </nz-form-item>
        <ng-container *ngIf="validateForm.value.customized">
            <nz-form-item>
                <nz-form-label [nzSpan]="7">学校简介</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <textarea
                            nz-input
                            nzName="about" formControlName="about"
                            [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                    ></textarea>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7">学校地址</nz-form-label>
                <nz-form-control [nzSpan]="12">
                        <textarea
                                nz-input
                                nzName="address" formControlName="address"
                                [nzAutosize]="{ minRows: 3, maxRows: 6 }"
                        ></textarea>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7">App定制规则</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <div nz-row style="margin-bottom: 2rem">
                        <div nz-col [nzSpan]="7">splash图片</div>
                        <div nz-col [nzSpan]="17">
                            <app-upload-image-with-preview
                                    [picUrl]="appConfig.splash_url"
                                    [picItem]="null"
                                    (imageUploaded)="onSplashUploadSuccess($event)"
                            ></app-upload-image-with-preview>
                        </div>
                    </div>
                    <div nz-row style="margin-bottom: 2rem">
                        <div nz-col [nzSpan]="7">学校logo</div>
                        <div nz-col [nzSpan]="17">
                            <app-upload-image-with-preview
                                    [picUrl]="appConfig.logo_url"
                                    [picItem]="null"
                                    (imageUploaded)="onLogoUploadSuccess($event)"
                            ></app-upload-image-with-preview>
                        </div>
                    </div>
                    <div nz-row style="margin-bottom: 2rem">
                        <div nz-col [nzSpan]="7">分享页面二维码 </div>
                        <div nz-col [nzSpan]="17">
                            <app-upload-image-with-preview
                                    [picUrl]="appConfig.qrcode_url"
                                    [picItem]="null"
                                    (imageUploaded)="onQrcodeUploadSuccess($event)"
                            ></app-upload-image-with-preview>
                        </div>
                    </div>
                    <div nz-row style="margin-bottom: 2rem">
                        <div nz-col [nzSpan]="7">app背景</div>
                        <div nz-col [nzSpan]="17">
                            <app-upload-image-with-preview
                                    [picUrl]="appConfig.bg_url"
                                    [picItem]="null"
                                    (imageUploaded)="onBgUploadSuccess($event)"
                            ></app-upload-image-with-preview>
                        </div>
                    </div>

                </nz-form-control>
            </nz-form-item>
        </ng-container>



        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <button style="margin-right:1rem;" nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
                <button nz-button (click)="resetForm($event)">Reset</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
