import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { BaseService } from './base.service';
import jwt_decode from 'jwt-decode';
import { UserStatus, UserType } from '../model/Const';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class UserService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
    // console.log(22222222222222)
    // debugger
    // this.getInfoPromise = this.get('/user/getInfo');
  }
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }
  getUserInfo () {
    let token = localStorage.getItem('token');
    const previewToken = this.getParamValueQueryString('preview-token');
    if (previewToken) {
      localStorage.setItem('token', previewToken);
      token = previewToken;
    }
    if (!token) {
      return false;
    }

    return this.getDecodedAccessToken(token);
  }
  isValidAdmin(router: any): boolean {
    // TODO 过期时间,角色检测
    const info = this.getUserInfo();
    if (!info) {
      return false;
    }
    const expire = new Date(info.exp * 1000);
    const curr = new Date();
    if (expire < curr) {
      localStorage.removeItem('token');
      this.router.navigate([`/login`]);
      return false;
    }
    if (!router.data.role) {
      return false;
    }
    if (info.type === UserType.SUPER_ADMIN) {
      return true;
    }
    // const token  = localStorage.getItem('token');
    // const sorted_roles  = router.data.role.sort();
    // const max_premission = sorted_roles[sorted_roles.length - 1]
    // const role_condition = info.type >= max_premission;

    const role_condition = router.data.role.includes(info.type) ;

    // const token  = localStorage.getItem('token');
    return info.status === UserStatus.NORM && role_condition;
  }
  isValidUser(router: any): boolean {
    return this.isValidAdmin(router);
  }

  userLogin(user: any) {
    return super.post('/login', user).then((data) => {
      localStorage.setItem('token', _.get(data, 'token'));
      const info = this.getUserInfo();
      console.log('userinfo', info);
      return info;
    });
    //   .pipe(
    //   tap((data => {
    //     localStorage.setItem('token', _.get(data, 'token'));
    //     // this.getInfoPromise = this.get('/user/getInfo');
    //     const info = this.getUserInfo();
    //     return info;
    //   }))
    // );
  }

  fetchClassMember(class_id: string|number, page: string|number) {
    return super.get(`/classes/${class_id}/users/page/${page}`).then((resp) => {
      return resp;
    });
  }


  checkStudentExist(data: any) {
    return super.post(`/student/check`, data).then(resp => {
      return resp;
    });
  }
  checkUserExist(data: any) {
    return super.post(`/user/check`, data).then(resp => {
      return resp;
    });
  }
  addTeacher(data: any) {
    return super.post(`/user/teacher`, data);
  }
  removeTeacher(id: string|number) {
    return super.remove(`/user/teacher/${id}`);
  }
  saveTeacher(id: string|number, class_data: any) {
    console.log('class_data: ', class_data);
    return super.post(`/user/teacher/${id}`, class_data);
  }
  fetchTeacher(id: string|number) {
    return super.get(`/user/teacher/${id}`);
  }
  fetchStudent(id: string|number) {
    return super.get(`/user/student/${id}`);
  }

  addStudent(class_id: string|number, data: any) {
    return super.post(`/class/${class_id}/student`, data);
  }
  unbindClassStudent(class_id: string|number, id: string|number) {
    return super.remove(`/class/${class_id}/student/${id}`);
  }
  // removeStudent(id: string|number) {
  //   return super.remove(`/student/${id}`);
  // }
  removeStudents(class_id: string|number, user_ids: any) {
    return super.post(`/class/${class_id}/students`, {user_ids});
  }
  saveStudent(class_id: string|number, user_id: string|number , user_data: any) {
    return super.post(`/class/${class_id}/student/${user_id}`, user_data);
  }

  fetchSchoolAndAdmin(id: string|number) {
    return super.get(`/user/school/${id}/admin`);
  }
  addSchool(data: any) {
    return super.post(`/schools/add`, data);
  }
  saveSchool(id: string|number, data: any) {
    return super.post(`/schools/${id}/save`, data);
  }






  fetch(id: string|number) {
    return super.get(`/user/${id}`).then((resp) => {
      return resp;
    });
  }
  fetchPageData(page: string|number) {
    if (!page) {
      page = 1;
    }
    return super.get(`/user/page/${page}`).then((resp) => {
      return resp;
    });
  }
  delete (class_id: string|number, user_id: string|number) {
    return super.remove(`/remove/class/${class_id}/student/${user_id}`);
  }
  removeUserFromClass(class_id: string|number, user_id: string|number) {
    return super.remove(`/class/${class_id}/student/${user_id}`);
  }

  batchAddUsers(class_id: string|number, data: any) {
    return super.post(`/users/${class_id}/batch-add-users`, data);
  }

  changePassword(data: any) {
    return super.post(`/user/change-password`, data);
  }

  resetPassword(data: any) {
    return super.post(`/user/reset-password`, data);
  }
  changeTeacherManage(data: any) {
    return super.post(`/user/change-permission`, data);
  }

  grantUser(id: string|number, days: string|number) {
    return super.post(`/user/grant-user`, {id, days});
  }
  grantClass(class_id: string|number) {
    return super.post(`/user/grant-class`, {class_id});
  }

  formatDate (d?: any, sep = '-') {
    if (typeof d === 'string') {
      d = new Date(d);
    } else if (typeof d === 'object') {
    } else {
      d = new Date();
    }
    let month = d.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = d.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${d.getFullYear()}${sep}${month}${sep}${day}`;
  }

  reportTotalTime(minites: any) {
    const info = this.getUserInfo();
    return super.post(`/reports/user/${info.id}/report/${this.formatDate()}`, minites);
  }

  checkPassPL3(pass: string) {
    const regex = new RegExp('(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20}');
    return regex.test(pass);
  }

}
