import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadComponent } from 'ng-zorro-antd/upload';
import { DomSanitizer } from '@angular/platform-browser';
// import {_coursewareService} from '../../../services/courseware.service';
// import {ResourceService} from '../../../services/resource.service';
import { HWResourceService as ResourceService } from '../../../services/huawei-resource.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements OnChanges, OnInit {
  uploading = false;
  checking = false;
  // checkVideoExists = false;
  // uploadClicked = false;
  // disableSelectFile = false;
  @Input()
  limit = 500;
  @Input()
  errors = 'error';
  @Input()
  message = 'Click here to upload video';

  @Input()
  videoFile: File & {url: string}|null = null;

  @Input()
  bucket = null;
  @Input()
  domain = null;

  // uploaderInst = null;

  progress = 0;
  // @Input()
  // setCovering = false;
  @Input()
  allowUpload = true;
  @Input()
  videoUrl = '';
  @Input()
  savePath = '';
  @ViewChild('videoNode', {static: false}) videoNode: ElementRef | undefined;
  @ViewChild('NzUploadBtn', {static: true}) NzUploadBtn: NzUploadComponent | undefined;
  // @Input()
  // extractCoverFunc = null;

  @Output()
  videoUploaded = new EventEmitter();
  @Output()
  videoUploadFailure = new EventEmitter();
  @Output()
  extractVideoCover = new EventEmitter();
  @Output()
  videoSizeOverLimited = new EventEmitter();







  @Input()
  autoUpload = true;

  // @Input()
  // extraCoverEvent: EventEmitter<any>;

  // @Input()
  // uploadEvent: EventEmitter<any>;

  // @Input()
  // selectFileEvent: EventEmitter<any>;

  // videoItem = null;
  constructor(private nzMessageService: NzMessageService,
              private sanitization: DomSanitizer,
              // private cwService: _coursewareService,
              private resService: ResourceService) {
    this.uploading = false;
    this.videoFile = null;
    // @ts-ignore
    window.u = this;
  }
  ngOnInit() {
  }
  // onExtraCoverEvent = () => {
  //   this.extraCover();
  // }
  // onUploadEvent = () => {
  //   this.handleUpload();
  // }
  ngOnChanges(val: any) {

    // if (val.extraCoverEvent) {
    //   // this.showExtraCoverBtn = false;
    //   if (val.extraCoverEvent.currentValue !== val.extraCoverEvent.previousValue) {
    //     if (val.extraCoverEvent.previousValue) {
    //       val.extraCoverEvent.previousValue.unsubscribe();
    //     }
    //     if (val.extraCoverEvent.currentValue) {
    //       val.extraCoverEvent.currentValue.subscribe(this.onExtraCoverEvent);
    //     }
    //   }
    // }


    if (val.selectFileEvent) {
      if (val.selectFileEvent.currentValue !== val.selectFileEvent.previousValue) {
        if (val.selectFileEvent.previousValue) {
          val.selectFileEvent.previousValue.unsubscribe();
        }
        if (val.selectFileEvent.currentValue) {
          val.selectFileEvent.currentValue.subscribe(() => {
            this.NzUploadBtn?.uploadComp.file.nativeElement.click();
          });
        }
      }
    }

    // if (val.uploadEvent) {
    //   // this.showUploadBtn = false;
    //   if (val.uploadEvent.currentValue !== val.uploadEvent.previousValue) {
    //     if (val.uploadEvent.previousValue) {
    //       val.uploadEvent.previousValue.unsubscribe();
    //     }
    //     if (val.uploadEvent.currentValue) {
    //       val.uploadEvent.currentValue.subscribe(this.onUploadEvent);
    //     }
    //   }
    // }
    if (!this.videoFile) {
      return;
    }
    console.log('video ngOnChanges', val);
    this.beforeUpload(this.videoFile);
    // this.handleUpload();
  }
  beforeUpload = (file: any, fileList ?: any[]): boolean => {
    console.log(111);
    if (!file ) {
      this.nzMessageService.info('请添加上传文件');
      return false;
    }
    // this.beforeUpload(item.file as any);
    if (!this.checkSelectFile(file)) {
      this.videoSizeOverLimited.emit();
      return false;
    }
    let url = null;
    if (file.url && typeof file.url === 'string') {
      url = file.url;
    } else {
      url = this.sanitization.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    }
    // const url = this.sanitization.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    if (typeof url === 'string') {

    }
    // console.log(4444444444444)
    // @ts-ignore
    this.videoUrl = url;
    // @ts-ignore
    this.videoFile = file;
    // this.uploaderInst = null;
    this.progress = 0;
    // this.uploadClicked = true;
    if (this.autoUpload) {
      this.handleUpload();
    }
    return false;
  }
  extraCover( ) {
    // this.setCovering = true;
    if (!this.videoNode) {
      return;
    }
    const video  = this.videoNode.nativeElement;
    if (!video.src) { return; }
    if (video.src.constructor.name !== 'String') { return; }
    // console.log(222, video.currentTime)
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth ;
    canvas.height = video.videoHeight ;
    // @ts-ignore
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    // item.video_preview_thumb = canvas.toDataURL();
    const video_preview_thumb = canvas.toDataURL();

    canvas.remove();


    const img = this.convertImageToBlob(video_preview_thumb);
    this.extractVideoCover.emit(img);

  }
  convertImageToBlob(data: any) {
    const imgType = data.substring(data.indexOf(':') + 1, data.indexOf(';'));
    const ext = data.substring(data.indexOf('/') + 1, data.indexOf(';'));
    const binary = atob(data.split(',')[1]);
    const array = [];
    let i = 0;
    while (i < binary.length) {
      array.push (binary.charCodeAt(i));
      i++;
    }
    const fileObj: any = new Blob([ new Uint8Array(array) ], {type: imgType});
    fileObj.name = ' .' + ext;
    return fileObj;
  }
  handleUpload = () => {

    this.checking = true;
    this.uploading = false;
    this.progress = 0;
    let path = this.savePath || '/video/';
    if (!environment.production) {
      path = '/dev' + path;
    }
    // this.resService.doUpload(this.videoFile as any,  /*ResType.VIDEO,*/ path,
    //   this.doProgress,
    //   this.uploadSuccess,
    //   this.uploadFailure,
    //   this.checkHashFinish);
    console.log(123);
    this.resService.doUpload({file: this.videoFile, /* type: ResType,*/
      // bucket: this.bucket,
      // domain: this.domain,
      osspath: path,
      progress_cb: this.doProgress,
      success_cb: this.uploadSuccess,
      error_cb: this.uploadFailure,
      check_cb: this.checkHashFinish});

    return false;
  }
  checkSelectFile(file: any) {
    if (!file.lastModified) {
      return false;
    }
    const isOverSize = (file.size / 1024 / 1024) < this.limit;
    if (!isOverSize) {
      this.nzMessageService.error(`video must smaller than ${this.limit}MB!`);
      return false;
    }
    return true;
  }
  checkHashFinish = (hash: string) => {
    this.checking = false;
    this.uploading = true;
  }
  uploadSuccess = ( name: string, hash: string, url: string, file?: File, inOSS = false) => {
    this.nzMessageService.info('Upload Success');
    // this.updateStatus(false);
    this.uploading = false;
    this.videoFile = null;
    // this.updateSource(url);
    this.videoUrl = url;
    // console.log(this.picUrl)
    // this.uploadFinished(url);

    const vid = document.createElement('video');
    vid.addEventListener('loadedmetadata', () => {
      const height = vid.videoHeight;
      const width = vid.videoWidth;
      let duration = vid.duration;
      if (duration) {
        duration = duration * 1000;
      }
      // @ts-ignore
      file['height'] = height;
      // @ts-ignore
      file['width'] = width;
      // @ts-ignore
      file['duration'] = duration;
      vid.preload = 'none';
      vid.src = '';
      vid.remove();
      this.videoUploaded.emit({ name, hash, url, file, inOSS});
    });
    vid.src = url;




    /*
    if (!inOSS) {
      const vid = document.createElement('video');
      vid.addEventListener('loadedmetadata', () => {
        const height = vid.videoHeight;
        const width = vid.videoWidth;
        let duration = vid.duration;
        if (duration) {
          duration = duration * 1000;
        }
        file['height'] = height;
        file['width'] = width;
        file['duration'] = duration;
        vid.preload = 'none';
        vid.src = '';
        vid.remove();
        this.resService.updateVideo(id, {width, height, duration}).then( () => {
          this.videoUploaded.emit({id, name, hash, url});
        });

      });
      vid.src = url;
    } else {
      this.videoUploaded.emit({ name, hash, url});
    }
    */




    // this.videoUploaded.emit({id, url});
  }
  uploadFailure = (err: any, file: any) => {
    this.uploading = false;
    if (err.name && err.name === 'cancel') {
      return;
    }
    console.log(err);
    this.nzMessageService.error('Upload Error ' + err.message);
    this.videoUploadFailure.emit(file);
  }

  doProgress =  (p: number) => {
    if (p > 1) {
      p = 1;
    }
    if (p < 0) {
      p = 0;
    }
    // console.log(Math.floor(p * 100));
    // @ts-ignore
    this.progress =  Math.floor(p * 100);
  }

}

