// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from '../../../../../../services/user.service';
import { PAD_VIDEO_GROUP, UserType } from '../../../../../../model/Const';
import { PadService } from '../../../../../../services/pad.service';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HWResourceService as ResourceService } from '../../../../../../services/huawei-resource.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzFormatEmitEvent, NzTreeComponent, NzTreeNode } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-admin-pad-video-categories',
  templateUrl: './video-categories.component.html',
  styleUrls: ['./video-categories.component.scss']
})
export class VideoCategoriesComponent implements OnInit {
  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent;
  defaultExpandedKeys = [];
  dataSet: any[] = [];
  tableLoading = false;
  isAddVideoCategoriesModalVisible = false;
  // @ts-ignore
  addVideoForm: UntypedFormGroup;
  isSavingForm = false;
  editTreeNode = null;

  // saveCateData = {
  //   name: '',
  //   cover: '',
  // };


  @ViewChild('addVideoFormElement', {static: false})
  addVideoFormElement: FormGroupDirective;
  private isSA = false;
  PAD_VIDEO_GROUP = PAD_VIDEO_GROUP;
  videoGroup = PAD_VIDEO_GROUP.ADMIN;
  routePrefix = '';
  sessionStoreKey = 'cate_expandedNodeList';


  constructor(private modalService: NzModalService,
              private fb: UntypedFormBuilder,
              private padService: PadService,
              private userService: UserService,
              private sanitization: DomSanitizer,
              private activatedRoute: ActivatedRoute ,
              private resourceSvc: ResourceService,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    const info = this.userService.getUserInfo();
    if (info) {
      this.isSA = info.type === UserType.SUPER_ADMIN;
      if (this.isSA) {
        this.videoGroup = PAD_VIDEO_GROUP.ADMIN;
      } else {
        this.videoGroup = PAD_VIDEO_GROUP.USER;
      }
    }
    if (window.location.hash.includes('admin')) {
      this.videoGroup = PAD_VIDEO_GROUP.ADMIN;
      this.routePrefix = '/admin';
    } else {
      this.videoGroup = PAD_VIDEO_GROUP.USER;
      this.routePrefix = '';
    }
    // const expandedNodeListStr = window.sessionStorage.getItem('expandedNodeList');
    // if (expandedNodeListStr) {
    //   try {
    //     const expandedNodeList = JSON.parse(expandedNodeListStr);
    //     this.defaultExpandedKeys = expandedNodeList;
    //   } catch (e) {
    //   }
    // }
    window.aaaaa = this;
  }




  ngOnInit() {
    console.log('this.defaultExpandedKeys', this.defaultExpandedKeys);
    this.addVideoForm = this.fb.group({
      id: [0, [Validators.required]],
      pid: [0, [Validators.required]],
      name: ['', [Validators.required]],
      cover: ['', [Validators.required]],
    });
    const expandedNodeListStr = window.sessionStorage.getItem(this.sessionStoreKey);
    // window.sessionStorage.removeItem('expandedNodeList');
    let expandedNodeList = [];
    try {
      expandedNodeList = JSON.parse(expandedNodeListStr);
    } catch (e) {
    }
    console.log(this.sessionStoreKey, expandedNodeList);

    this.loadData(expandedNodeList);
    // @ts-ignore
    window.p = this;
  }
  loadData(expendedKeys=[]) {
    this.tableLoading = true;
      this.padService.fetchAllVideoCategories(this.videoGroup).then(data => {
      console.log(data);
      this.dataSet = data;
      this.tableLoading = false;
      this.defaultExpandedKeys = expendedKeys;
    }).catch(e => {
      this.nzMessageService.error(`加载失败 ${e.message}`);
      this.tableLoading = false;
      });
  }


  showModifyModal(event) {
    let data = event;
    if (event.eventName) {
      data = event.node;
    }
    console.log('showModifyModal', data);
    // this.editTreeNode = data;
    this.editCategory(data.origin);
  }


  openFolder(event: NzTreeNode | NzFormatEmitEvent): void {
    let data = event;
    if (event.eventName) {
      data = event.node;
    }
    console.log('openFolder', data);
    if (data.origin.children.length == 0 ) {
      return;
    }
    // do something if u want
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
  }

  resourceManage(node) {
    const expandedNodeList = this.nzTreeComponent.getExpandedNodeList().map(n => n.key);
    // console.log(expandedNodeList);
    window.sessionStorage.setItem(this.sessionStoreKey, JSON.stringify(expandedNodeList));
    this.router.navigate(
      [this.routePrefix + '/pad/video/category/' + node.origin.id],
      { queryParams: {name: node.origin.name}});
  }

  openDialog(pid) {
    this.addVideoForm.get('name').setValue('');
    this.addVideoForm.get('cover').setValue('');
    this.addVideoForm.get('id').setValue(0);
    this.addVideoForm.get('pid').setValue(pid);
    this.isAddVideoCategoriesModalVisible = true;
  }
  closeDialog() {
    this.isSavingForm = false;
    this.isAddVideoCategoriesModalVisible = false;
    this.addVideoForm.get('name').setValue('');
    this.addVideoForm.get('cover').setValue('');
    this.addVideoForm.get('id').setValue(0);
    this.addVideoForm.get('pid').setValue(0);
  }
  saveDialog() {

    this.addVideoFormElement.ngSubmit.emit();
  }
  submitForm() {
    console.log('submitForm');
    for (const i in this.addVideoForm.controls) {
      this.addVideoForm.controls[i].markAsDirty();
      this.addVideoForm.controls[i].updateValueAndValidity();
    }

    const cover = this.addVideoForm.get('cover')?.value;
    if (!cover) {
      this.addVideoForm.get('cover')?.setErrors({required: true});
    } else {
      this.addVideoForm.get('cover')?.clearValidators();
    }
    const name = this.addVideoForm.get('name').value;
    // this.editTreeNode.origin.cover= cover;




    // if (checkedTheme.length === 0 ) {
    //   this.validateForm.get('theme').setErrors({required: true});
    // } else {
    //   this.validateForm.get('theme').clearValidators();
    // }

    if (this.addVideoForm.status === 'INVALID') {
      return;
    }
    this.isSavingForm = true;
    const expandedNodeList = this.nzTreeComponent.getExpandedNodeList().map(n => n.key);
    expandedNodeList.push(this.addVideoForm.value.pid);
    this.padService.addVideoCategory({...this.addVideoForm.value, group: this.videoGroup}).then(r => {
      this.isSavingForm = false;
      this.loadData(expandedNodeList);
      this.closeDialog();
    }).catch(e => {
      this.isSavingForm = false;
      this.nzMessageService.error(`发生错误，保存失败 ${e.message}`);
    });
  }


  safeUrl(url) {
    const currentUrl = this.addVideoForm.controls.url.value;
    if (currentUrl && currentUrl === url) {
      return;
    }

    if (!url) {
      return;
    }
    console.log('safeUrl', url);
    return this.sanitization.bypassSecurityTrustUrl(url);
  }


  onImageUploadSuccess(evt) {
    console.log('onImageUploadSuccess', evt);
    // this.saveCateData.cover = evt.url;
    this.addVideoForm.get('cover').setValue('');
    setTimeout(() => {
      this.addVideoForm.get('cover').setValue(evt.url);
    }, 1000);

  }
  deleteCategory(id) {
    this.tableLoading = true;
    const expandedNodeList = this.nzTreeComponent.getExpandedNodeList().map(n => n.key);
    this.padService.removeVideoCategory(id).then(r => {
      this.loadData(expandedNodeList);
      this.tableLoading = false;
    }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
  }
  editCategory(row) {
    // this.saveCateData.cover = evt.url;
    // const row = this.dataSet.find(r => r.id === id);
    this.addVideoForm.get('cover').setValue('');
    this.addVideoForm.get('cover').setValue(row.cover);
    this.addVideoForm.get('name').setValue('');
    this.addVideoForm.get('name').setValue(row.name);
    this.addVideoForm.get('id').setValue('');
    this.addVideoForm.get('id').setValue(row.id);
    this.addVideoForm.get('pid').setValue('');
    this.addVideoForm.get('pid').setValue(row.pid);
    this.isAddVideoCategoriesModalVisible = true;
  }
  noCache(s) {
    if (s && typeof s === 'string') {
      return s + '?t=' + Math.random();
    }
    return s;
  }

  onTreeDrop(event) {
    console.log('onDrop', event);
    let data = event;
    if (event.eventName) {
      data = event.dragNode;
    }
    // const pid = event.node.origin.pid;
    if (event.node.children.length == 0) {
      event.node.isExpanded = false;
    }
    let pid = 0;
    let list = this.nzTreeComponent.getTreeNodes();
    if (data.parentNode) {
      pid = data.parentNode.origin.id;
      list = data.parentNode.children;
    }

    const reorder = list.map((n, i) => {
      // if (n.origin.sort === i) {
      //   return false;
      // }
      return {id: n.origin.id, name: n.origin.name, pid, sort: i};
      // return n.origin ? n.origin.id : n.id;
    }).filter(item => !!item);
    console.log('reorder', reorder);
    const expandedNodeList = this.nzTreeComponent.getExpandedNodeList().map(n => n.key);
    this.tableLoading = true;
    this.padService.sortCategoriesTree(reorder)
      .then(r => {
        this.loadData(expandedNodeList);
        this.tableLoading = false;
      }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
  }
  drop(event: CdkDragDrop<string[]>): void {
    console.log('drop', event);
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    let _form = event.previousIndex;
    let _to = event.currentIndex;
    if (event.currentIndex < event.previousIndex) { // down-up
      console.log('down-up');
      _form = event.previousIndex;
      _to = event.currentIndex;
    } else { // up-down
      console.log('up-down');
    }
    console.log({_form, _to});

    const currentItem = this.dataSet.find((r, i) => {
      return i === _form;
    });
    const currentId = currentItem.id;
    moveItemInArray(this.dataSet, event.previousIndex, event.currentIndex);
    const idx = this.dataSet.findIndex((r, i) => {
      return r.id === currentId;
    });
    const data = {
      beforeId: this.dataSet[idx - 1] ? this.dataSet[idx - 1].id : 0,
      afterId: this.dataSet[idx + 1] ? this.dataSet[idx + 1].id : 0,
      currentId
    };
    this.tableLoading = true;
    this.padService.sortCategories(data)
      .then(r => {
        this.loadData();
        this.tableLoading = false;
      }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
  }
}
