<nz-spin [nzSpinning]="isSpinning">
<div class="table-operations">
    <button nz-button nzType="primary" [nzSize]="'large'" (click)="addVer()">
        <i nz-icon nzType="file-add" nzTheme="twotone"></i>添加新版本</button>
    <div  style="float: right;margin-top: 1rem;">
        <div *ngIf="this.AppPackageInfo">
            <span *ngFor="let qr of this.AppPackageInfo['qr']">
                <nz-divider nzType="vertical"></nz-divider>
                <a (click)="showAppDownload(qr.qr_pic, qr.download_link)">
                    {{qr.name}}
                </a>
            </span>


        </div>
    </div>
</div>
<nz-table #dataTable [nzData]="displayData"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalCount">
    <thead>
    <tr>
        <th style="text-align: center">平台</th>
        <th style="text-align: center">类型</th>
        <th style="text-align: center">版本</th>
        <th style="text-align: center">发布时间</th>
        <th style="text-align: center">操作</th>
    </tr>
    </thead>
    <tbody  style="text-align: center">
    <tr *ngFor="let data of dataTable.data">
        <td>
            <i *ngIf="data.platform === 'android'" nz-icon nzType="android" nzTheme="fill"></i>
            <i *ngIf="data.platform === 'ios'" nz-icon nzType="apple" nzTheme="fill"></i>
        </td>
        <td>
            <span *ngIf="data.type === 'student'">学生端</span>
            <span *ngIf="data.type === 'teacher'">教师端</span>
        </td>
        <td>{{data.version}}</td>
        <td>{{data.pub_date}}</td>
        <td style="width: 10rem;">
            <div class="operations">
                <a (click)="edit(data.id)">
                    查看详情
                </a>
                <a  nz-popconfirm nzPopconfirmTitle="确定删除该版本？"
                    (nzOnConfirm)="deleteVer(data.id)">删除</a>
            </div>
        </td>
    </tr>
    </tbody>
</nz-table>
<nz-modal [(nzVisible)]="appDetail"
          nzTitle="App"
          [nzClosable]="false"
          [nzMaskClosable]="false"
          (nzOnCancel)="handleCancel()"
          (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
    <nz-spin [nzSpinning]="isUploadingApk">
    <div nz-row style="margin-bottom: .5rem;">
        <label  nz-col class="gutter-row" [nzSpan]="6">平台:</label>
        <div nz-col class="gutter-row" [nzSpan]="18">
            <nz-radio-group [nzDisabled]="isEditOp" [(ngModel)]="info.platform"  (ngModelChange)="onChangeApp('platform', $event)">
                <label nz-radio [nzDisabled]="this.AppPackageInfo['android'] == undefined" nzValue="android">安卓</label>
                <label nz-radio [nzDisabled]="this.AppPackageInfo['ios'] == undefined" nzValue="ios">IOS</label>
            </nz-radio-group>
        </div>
    </div>
    <div nz-row style="margin-bottom: .5rem;">
        <label  nz-col class="gutter-row" [nzSpan]="6">类型:</label>
        <div nz-col class="gutter-row" [nzSpan]="18">
            <nz-radio-group [nzDisabled]="isEditOp"  [(ngModel)]="info.type"  (ngModelChange)="onChangeApp('type', $event)">
                <label nz-radio [nzDisabled]="(this.AppPackageInfo[info.platform]?.teacher == undefined)" nzValue="teacher">教师端</label>
                <label nz-radio [nzDisabled]="(this.AppPackageInfo[info.platform]?.student == undefined)" nzValue="student">学生端</label>
            </nz-radio-group>
        </div>
    </div>

        <div nz-row style="margin-bottom: .5rem;">
            <label  nz-col class="gutter-row" [nzSpan]="6">是否强升:</label>
            <div nz-col class="gutter-row" [nzSpan]="18">
                <nz-radio-group [(ngModel)]="info.force" [nzDisabled]="info.platform == 'ios'">
                    <label nz-radio [nzValue]="1">是</label>
                    <label nz-radio [nzValue]="0">否</label>
                </nz-radio-group>
            </div>
        </div>


    <div nz-row style="margin-bottom: .5rem;">
        <label  nz-col class="gutter-row" [nzSpan]="6">版本:</label>
        <div nz-col class="gutter-row" [nzSpan]="18">
            <input nz-input placeholder="版本号"
                   [disabled]="isEditOp"
                   #versionElement
                   (ngModelChange)="onChangeVersion($event)"
                   [(ngModel)]="info.version">
        </div>
    </div>

    <div nz-row style="margin-bottom: .5rem;">
        <label  nz-col class="gutter-row" [nzSpan]="6">发布日期:</label>
        <div nz-col class="gutter-row" [nzSpan]="18">
            <nz-date-picker [disabled]="isEditOp" nzPlaceHolder="选择发布日期" [(ngModel)]="info.pub_date"></nz-date-picker>
        </div>
    </div>


    <div nz-row style="margin-bottom: .5rem;">
        <label  nz-col class="gutter-row" [nzSpan]="6">下载地址:</label>
        <div nz-col class="gutter-row" [nzSpan]="18">
            <div *ngIf="info.platform == 'android' && info.type && info.version && info.version !== '0.0.0' && verReg.test(this.info.version)">
                <nz-upload [nzBeforeUpload]="beforeUpload"
                           [nzShowUploadList]="false"
                           *ngIf="!isEditOp"
                           [nzMultiple]="false" [nzAccept]="'*.apk'">
                    <button nz-button><i nz-icon nzType="upload"></i><span>Select File</span></button>
                </nz-upload>
                <div class="progress" style="width: 100%;height: 4px;margin-top: 4px;">
                    <div class="bar" [ngStyle]="{width: this.progress + '%'}"></div>
                </div>
                {{info.store_list}}
            </div>
            <input [disabled]="isEditOp" nz-input *ngIf="info.platform !== 'android'"
                   [(ngModel)]="info.store_list">

<!--            <textarea rows="4" nz-input [(ngModel)]="info.store_list"></textarea>-->

        </div>
    </div>
    <div nz-row style="margin-bottom: .5rem;">
        <label  nz-col class="gutter-row" [nzSpan]="6">备注:</label>
        <div nz-col class="gutter-row" [nzSpan]="18">
            <textarea rows="4" nz-input [(ngModel)]="info.change_log"></textarea>
        </div>
    </div>
    </nz-spin>
    </ng-container>
</nz-modal>
</nz-spin>
