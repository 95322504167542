<div class="page">

    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/qupeiyin/form'">
            <i nz-icon nzType="plus" nzTheme="outline"></i>添加趣配音
        </button>
<!--        <nz-divider></nz-divider>-->
    </div>
    <div>
        <nz-spin  style="width: 100%;height: 100%;"  [nzSpinning]="dataLoading">
    <div class="condition-box">
        <div class="condition-row">
            <div class="condition-label">
                等级
            </div>
            <div class="condition-value">
                <nz-radio-group  (ngModelChange)="filterLevel($event)" [(ngModel)]="query.level">
                    <label nz-radio [nzValue]="0">全部</label>
                    <label nz-radio [nzValue]="o.id" *ngFor="let o of formLevelOptions">{{ o.name }}</label>
                </nz-radio-group>
            </div>
        </div>
        <nz-divider></nz-divider>
        <div class="condition-row">
            <div class="condition-label">
                类型
            </div>
            <div class="condition-value">
                <nz-checkbox-group [(ngModel)]="formKindOptions" (ngModelChange)="filterKind()"></nz-checkbox-group>
            </div>
        </div>
        <nz-divider></nz-divider>
        <div class="condition-row">
            <div class="condition-label">
                主题
            </div>
            <div class="condition-value">
                <nz-checkbox-group [(ngModel)]="formThemeOptions" (ngModelChange)="filterTheme()"></nz-checkbox-group>
            </div>
        </div>
        <nz-divider></nz-divider>
    </div>
    <div class="qpy-item-list">

        <ng-container *ngFor="let row of tableData.rows">
            <nz-card class="qpy-item"  nzHoverable style="" [nzCover]="coverTemplate">

                <button class="edit-it" nz-button nzType="primary"
                        [routerLink]="'/admin/qupeiyin/form'"
                        [queryParams]="{id: row.id}">
                    <i nz-icon nzType="form" nzTheme="outline"></i></button>

                <button class="delete-it" nz-button nzType="danger"
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure delete this task?"
                        nzPopconfirmPlacement="bottom"
                        (nzOnConfirm)="delete(row.id)">
                    <i nz-icon nzType="delete" nzTheme="outline"></i></button>

                <nz-card-meta [nzTitle]="row.title"
                              [nzDescription]="descTemplate"></nz-card-meta>
            </nz-card>
            <ng-template #coverTemplate>

                <div class="item-cover"  [ngStyle]="{'background-image': 'url('+row.cover_url+')'}"></div>
            </ng-template>
            <ng-template #descTemplate>
                <p ><i nz-icon *ngIf="row.status!=7" class="item-status" nzType="loading" nzTheme="outline"></i>
                   <span *ngIf="row.status == 0">任务已添加到队列中</span>
                   <span *ngIf="row.status == 1 || row.status == 2">正在同步到AI服务器</span>
                   <span *ngIf="row.status == 3 || row.status == 4">AI处理中</span>
                   <span *ngIf="row.status == 5 || row.status == 6">数据同步中</span>
                   <span *ngIf="row.status == 7">处理完成</span>
                </p>
                <div class="lbl-row">
                    <div class="lbl-l">句子：</div>
                    <div class="lbl-v">共{{row.sents_count}}句</div>
                </div>
                <div class="lbl-row" *ngFor="let tag of row.tags">
                    <div class="lbl-l" *ngIf="tag.tag_type === 1">等级：</div>
                    <div class="lbl-l" *ngIf="tag.tag_type === 2">类型：</div>
                    <div class="lbl-l" *ngIf="tag.tag_type === 3">主题：</div>
                    <div class="lbl-v">{{tag.tag}}</div>
                </div>
            </ng-template>
        </ng-container>
    </div>
    <div style="width: 100%;">
        <nz-pagination
                [nzPageSize]="10"
                [nzPageIndex]="currentPageNumber"
                       (nzPageIndexChange)="changePage($event)"
                       [nzTotal]="tableData.total"></nz-pagination>
    </div>
        </nz-spin>
    </div>

</div>
