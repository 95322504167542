<div class="table-operations">
    <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/promotions/add'">
        <i nz-icon nzType="pay-circle" nzTheme="outline"></i>创建活动
    </button>
    <code>活动说明：仅有效时间内，最新活动有效</code>
    <nz-divider></nz-divider>
</div>

<nz-table #dataTable
          [nzData]="dataSet"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [nzLoading]="loadingData"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalNum">
    <thead>
    <tr>
        <th>ID</th>
        <th>活动名称</th>
        <th>学校类型</th>
        <th>活动价格</th>
        <th>帐号时长</th>
        <th>活动描述</th>
        <th>开始时间</th>
        <th>结束时间</th>
        <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of dataTable.data">
        <td>{{data.id}}</td>
        <td>{{data.title}}</td>
        <td>
            <nz-tag *ngIf="data.school_type == 0" [nzColor]="'magenta'">全部</nz-tag>
            <nz-tag *ngIf="data.school_type == 1"  [nzColor]="'#2db7f5'">直营校</nz-tag>
            <nz-tag *ngIf="data.school_type == 2" [nzColor]="'#87d068'">加盟校</nz-tag>
            <nz-tag *ngIf="data.school_type == 3" [nzColor]="'#68d0bb'">按量付费校</nz-tag>
        </td>

        <td>{{!!data.promo_price ? (data.promo_price / 100) : 0}}元</td>
        <td>{{data.days}}</td>
        <td>{{data.desc}}</td>
        <td>{{data.start_date}}</td>
        <td>{{data.end_date}}</td>
        <td>
            <a (click)="onEdit(data.id)">编辑</a>
            <nz-divider nzType="vertical"></nz-divider>
            <a nz-popconfirm nzPopconfirmTitle="确定删除活动？"
               (nzOnConfirm)="deletePromotion(data.id)" >
                删除
            </a>
        </td>
    </tr>
    </tbody>
</nz-table>


