import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-ocr-index',
  templateUrl: './ocr-index.component.html',
  styleUrls: ['./ocr-index.component.scss']
})
export class OcrIndexComponent implements OnInit {

  constructor(private http: HttpClient,
              private msg: NzMessageService) {
  }

  ngOnInit() {
  }
  beforeUpload = (file: any) => {
    this.b64File(file, (data: any) => {
      console.log(data);
      this.postToAli(data);
    }, (err: any) => {
      this.msg.error(err.message);
    });
    return  false;
  }
  b64File(file: any, success_cb: any, failure_cb: any) {
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        // console.log(reader.result);
        success_cb(reader.result);
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error);
        failure_cb(error);
      };
      reader.readAsDataURL( file );
    }
  }
  postToAli(imgData: any) {
    const appcode = 'c73592bd2a43414da589749631a565f0';
    const headers = new HttpHeaders();
    headers.set('Authorization', 'APPCODE ' + appcode);
    this.http.post('https://ocrapi-ugc.taobao.com/ocrservice/ugc', {
      img: imgData,
      prob: true
    }, {headers: headers}).subscribe((resp) => {
      console.log(resp);
    });
  }

}
