import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
// import {NG_VALUE_ACCESSOR} from '@angular/forms';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-wx-payment',
  templateUrl: './wx-payment.component.html',
  styleUrls: ['./wx-payment.component.scss']
  // providers: [NzRangePickerComponent],
})
export class WxPaymentComponent /*extends  NzRangePickerComponent*/ implements OnInit, OnDestroy, AfterViewInit {
  checkoutConfirm = false;

  @Input()
  checkoutConfirmMsg = '';
  @Input() orderUrl = '';
  @Input() beforeOrder = null;
  @Input() checkOrderUrl = '';
  @Input() beforeCheckOrder = null;
  // @ts-ignore
  @Input() paySuccessCallback: Function = null;
  @Input() currentPrice = '';
  @Input() orderData = {};
  isIniting = true;
  orderProcessing = false;

  wxpayInfo: any = null;
  userInfo = null;
  orderCheckTimer = null;
  // @ts-ignore
  private times: number;


  @Output()
  weekRangeChanged = new EventEmitter();
  constructor(private modalService: NzModalService,
              private http: HttpClient) {

  }
  ajax(method: string, url: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      console.log(this.http[method.toLowerCase()], url, data);
      // @ts-ignore
      this.http[method.toLowerCase()](url, data).subscribe((res) => {
        resolve(res);
      }, reject);
    });
  }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
  }

  checkout() {
    // console.log(12312);
    // if (!Object.keys(this.purchaseItems).length) {
    //   this.modalService.error({
    //     nzTitle: '请选择希望购买的课件',
    //     nzContent: '请您正确选择您希望购买的课件数量'
    //   });
    //   return;
    // }
    // this.checkoutConfirmMsg = Object.values(this.confirmMsg);
    this.checkoutConfirm = true;
  }
  handleConfirmOk(): void {
    // this.checkoutConfirm = false;
    // console.log(this.confirmMsg);
    if (!this.orderUrl) {
      this.modalService.error({
        nzTitle: 'error',
        nzContent: 'error 1001'
      });
      return;
    }
    this.orderProcessing = true;
    this.ajax('POST', this.orderUrl, this.orderData ).then(res => {
      this.orderProcessing = false;
      this.wxpayInfo = res;
      this.checkPayStatus(res.prepay_id);
    }).catch(err => {
      this.orderProcessing = false;
      this.modalService.error({
        nzTitle: '订单错误',
        nzContent: err.message
      });
      return;
    });
  }

  handleConfirmCancel(): void {
    this.checkoutConfirm = false;
  }
  closePayModal() {
    this.modalService.confirm({
      nzTitle: '<i>确定关闭支付窗口吗?</i>',
      nzContent: '<b>如果您已经支付，请等待窗口自动关闭，否则可能出现支付错误，影响您的正常使用!!!~</b>',
      nzOnOk: () => {
        // @ts-ignore
        clearTimeout(this.orderCheckTimer);
        this.orderCheckTimer = null;
        this.wxpayInfo = null;
      }
    });
  }
  // @ts-ignore
  checkPayStatus(prepay_id: string) {
    this.times = 0;
    const check = () => {
      this.times++;
      if (this.times >= 120) {
        // @ts-ignore
        clearTimeout(this.orderCheckTimer);
        this.orderCheckTimer = null;
        this.modalService.error({
          nzTitle: '订单超时',
          nzContent: `您的订单已经超时，请重新购买！`,
          nzOnOk: () => {
            this.wxpayInfo = null;
          }
        });
        return;
      }
      // @ts-ignore
      this.orderCheckTimer = setTimeout(() => {
        this.ajax('POST', this.checkOrderUrl, {prepay_id} ).then(async res => {
          if (res.status === 2) {
            // @ts-ignore
            clearTimeout(this.orderCheckTimer);
            this.orderCheckTimer = null;

            this.modalService.success({
              nzTitle: '购买成功',
              nzContent: `您的APP学生帐号已经购买成功！`,
              nzOnOk: () => {
                // @ts-ignore
                if (this.paySuccessCallback && this.paySuccessCallback.call) {
                  this.paySuccessCallback();
                }
              }
            });
            return;
          }
          check();
        }).catch(err => {
          // @ts-ignore
          clearTimeout(this.orderCheckTimer);
          this.orderCheckTimer = null;
          this.modalService.error({
            nzTitle: '订单错误',
            nzContent: err.message,
            nzOnOk: () => {
              this.wxpayInfo = null;
            }
          });
        });
      }, 2000);
    };
    check();
    // this.orderCheckTimer = setInterval(() => {
    //   check();
    // }, 1000);
  }
}
