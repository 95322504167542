<div style="margin: 0;padding: 0" [ngStyle]="{padding: videoGroup == PAD_VIDEO_GROUP.USER  ? '20px' : '0'}">

<div class="table-operations">

    <div style="flex: 1 auto">
        <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="routePrefix + '/pad/video/categories'">
            <i nz-icon nzType="left"></i>返回分类
        </button>
        {{categoryName}}
    </div>
    <div  style="flex: 0 auto">
        <button nz-button nzType="primary" [nzSize]="'large'" (click)="openVideoDialog()">
            添加视频
        </button>
        <button nz-button nzType="primary" [nzSize]="'large'" (click)="openAudioDialog()">
            添加音频
        </button>
        <button nz-button nzType="primary" [nzSize]="'large'" (click)="openMoveDialog()">
            转移文件
        </button>
    </div>

</div>


<nz-table #dataTable
          [nzLoading]="tableLoading"
          [nzData]="dataSet"

          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          >
<!--    [nzPageSize]="50"-->
    <!--            nzSimple-->
    <!--            -->
    <thead>
    <tr>
        <th nzShowCheckbox [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
        <th>ID</th>
        <th>封面</th>
        <th>类型</th>
        <th>名称</th>
        <th>时长</th>
        <th>操作</th>
    </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
    <tr *ngFor="let data of dataSet" cdkDrag>
        <td nzShowCheckbox [nzChecked]="setOfCheckedId.has(data.id)"
            (nzCheckedChange)="onItemChecked(data.id, $event)"></td>
        <td>{{data.id}}</td>
        <td>
            <img class='cate-cover' [src]="data.cover" alt="">
        </td>
        <td><span style="font-weight: 600">{{data.type ? '音频资源' : '视频资源'}}</span></td>
        <td>{{data.name}}</td>
        <td>{{convertTimeTo(data.duration / 1000)}}</td>
        <td>
            <div class="operations">
                <a (click)="edit(data.id)">编辑</a>
                <a nz-popconfirm nzPopconfirmTitle="确定删除吗？"
                   (nzOnConfirm)="delete(data.id)" >
                    删除
                </a>

            </div>
        </td>
    </tr>
    </tbody>
</nz-table>


<nz-modal
        [(nzVisible)]="isAddVideoModalVisible"
        nzTitle="添加"
        nzOkText="Ok"
        nzCancelText="Cancel"
        [nzClosable]="false"
        [nzMaskClosable]="false"
        [nzKeyboard]="false"
        [nzOkLoading]="isSavingForm"
        [nzCancelLoading]="isSavingForm"
        (nzOnOk)="saveDialog()"
        (nzOnCancel)="closeDialog()"
>
    <ng-container *nzModalContent>
    <form nz-form #addVideoFormElement="ngForm" [formGroup]="addVideoForm" (ngSubmit)="submitForm()">

        <nz-form-item class="term-item" >
            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="category">标题</nz-form-label>
            <nz-form-control
                    [nzSm]="14"
                    [nzSm]="24"
                    nzErrorTip="请设置标题"
            >

                <input type="text" nz-input
                       [formControlName]="'name'"/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item class="term-item" >
            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="category">封面</nz-form-label>
            <nz-form-control
                    [nzSm]="14"
                    [nzSm]="24"
                    nzErrorTip="请设置封面"
            >
                <app-upload-image-with-preview
                        [picUrl]="addVideoForm.get('cover')?.value"
                        [picItem]="null"
                        (imageUploaded)="onImageUploadSuccess($event)"
                        [errors]="addVideoForm.get('cover')?.dirty && addVideoForm.get('cover')?.status=='INVALID'"></app-upload-image-with-preview>
                <code *ngIf="!isAudioType">可选择手动上传或从视频截取</code>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item class="term-item" >
            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="url">上传{{isAudioType? '音频' : '视频'}}</nz-form-label>
            <nz-form-control
                    [nzSm]="14"
                    [nzSm]="24"
                    nzErrorTip="请上传媒体文件"
            >
                <ng-container *ngIf="!isAudioType">
                    <!--                [videoUrl]="safeUrl(DUBData.video_url)"-->
                    <app-upload-video
                            [videoUrl]="addVideoForm.get('url').value"
                            (extractVideoCover)="onVideoCoverExtract($event)"
                            [limit]="100"
                            [savePath]="'/pad/video/'"
                            [errors]="addVideoForm.get('url').dirty && addVideoForm.get('url').status=='INVALID'"
                            (videoUploaded)="onVideoUploaded($event)"
                            (videoUploadFailure)="onVideoUploadFailure()"></app-upload-video>
                </ng-container>
                <ng-container *ngIf="isAudioType">
<!--                    <app-audio-recorder></app-audio-recorder>-->
                    <app-upload-audio
                            [audioUrl]="addVideoForm.get('url').value"
                            (audioUploadFailure)="onAudioUploadFailure($event)"
                            (audioUploaded)="onAudioUploadSuccess($event)"></app-upload-audio>
                    <code *ngIf="addVideoForm.get('url').dirty && addVideoForm.get('url').status=='INVALID'">请正确上传音频文件</code>
                </ng-container>

            </nz-form-control>
        </nz-form-item>
        <nz-form-item class="term-item" >
            <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="desc">简介</nz-form-label>
            <nz-form-control
                    [nzSm]="14"
                    [nzSm]="24"
            >
                <textarea nz-input
                          [formControlName]="'desc'"></textarea>
            </nz-form-control>
        </nz-form-item>


    </form>
    </ng-container>
</nz-modal>


    <nz-modal
            [(nzVisible)]="moveResourceModalVisible"
            nzTitle="移动"
            nzOkText="Ok"
            nzCancelText="Cancel"
            [nzClosable]="false"
            [nzMaskClosable]="false"
            [nzKeyboard]="false"
            [nzOkLoading]="isMoving"
            [nzCancelLoading]="isMoving"
            (nzOnOk)="moveResource()"
            (nzOnCancel)="closeMoveDialog()"
    >
        <ng-container *nzModalContent>
            <nz-spin [nzSpinning]="cateLoading">
                <nz-tree #nzTreeComponent [nzData]="allCategories" [nzSelectedKeys]="[]" nzBlockNode ></nz-tree>
            </nz-spin>
        </ng-container>
    </nz-modal>
</div>



