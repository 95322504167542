// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from '../../../../../../services/user.service';
import { PAD_VIDEO_GROUP } from '../../../../../../model/Const';
import { PadService } from '../../../../../../services/pad.service';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { HWResourceService as ResourceService } from '../../../../../../services/huawei-resource.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NzTreeComponent } from 'ng-zorro-antd/tree';

@Component({
  selector: 'app-admin-pad-video-lsit',
  templateUrl: './pad-video-list.component.html',
  styleUrls: ['./pad-video-list.component.scss']
})
export class PadVideoListComponent implements OnInit {
  @ViewChild('nzTreeComponent', { static: false }) nzTreeComponent!: NzTreeComponent;
  dataSet: any[] = [];
  filteredDataSet: any[] = [];
  categoriesList: any[] = [];
  tableLoading = false;
  cateLoading = false;
  isAddVideoModalVisible = false;
  moveResourceModalVisible = false;
  isAudioType = 0;
  selectedCategory = '';
  lastCategoryValue = '';
// @ts-ignore
  addVideoForm: UntypedFormGroup;
  isSavingForm = false;
  isMoving = false;

  formatter = new Intl.NumberFormat('en', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: false,
  });
  __saveVideoData = {
    category: '',
    name: '',
    url: '',
    size: '',
    duration: '',
    width: '',
    height: '',
    cover: '',
    desc: '',
  };

  categoryId = 0;
  categoryName = '';
// @ts-ignore
  @ViewChild('addVideoFormElement', {static: false}) addVideoFormElement: FormGroupDirective;

  isSA = false;
  PAD_VIDEO_GROUP = PAD_VIDEO_GROUP;
  videoGroup = PAD_VIDEO_GROUP.ADMIN;
  routePrefix = '';
  indeterminate = false;
  setOfCheckedId = new Set<number>();
  checked = false;
  allCategories = null;
  defaultExpandedKeys = [];

  constructor(private modalService: NzModalService,
              private fb: UntypedFormBuilder,
              private padService: PadService,
              private userService: UserService,
              private sanitization: DomSanitizer,
              private activatedRoute: ActivatedRoute ,
              private resourceSvc: ResourceService,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    // const info = this.userService.getUserInfo();
    // if (info) {
    //   this.isSA = info.type === UserType.SUPER_ADMIN;
    // }

    // console.log('isSA', this.isSA);
    if (window.location.hash.includes('admin')) {
      this.videoGroup = PAD_VIDEO_GROUP.ADMIN;
      this.routePrefix = '/admin';
    } else {
      this.videoGroup = PAD_VIDEO_GROUP.USER;
      this.routePrefix = '';
    }
    this.activatedRoute.params.subscribe( (params: any) => {
      if (params.id && parseInt(params.id, 10) === params.id * 1) {
        this.categoryId = parseInt(params.id, 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      this.categoryName = query.name;
    });
  }


  onAllChecked(checked: boolean): void {
    this.dataSet
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    this.checked = this.dataSet.every(({ id }) => this.setOfCheckedId.has(id));
    this.indeterminate = this.dataSet.some(({ id }) => this.setOfCheckedId.has(id)) && !this.checked;
  }
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }


  ngOnInit() {
    this.addVideoForm = this.fb.group({
      id: ['0'],
      category_id: [ this.categoryId],
      type: [0],
      name: ['', [Validators.required]],
      cover: ['', [Validators.required]],
      url: ['', [Validators.required]],
      width: [0],
      height: [0],
      size: [0],

      duration: [],
      desc: ['', []],
    });
    this.loadData();
  }
  loadData() {
    this.setOfCheckedId.clear();
    this.tableLoading = true;
      this.padService.fetchAllVideo(this.categoryId).then(data => {
      console.log(data);
      this.dataSet = data.map((v: any, i: number) => {
        v.sort = i;
        return v;
      });

      this.categoriesList = Array.from(new Set([]));
      this.tableLoading = false;
    }).catch(e => {
      this.nzMessageService.error(`加载失败 ${e.message}`);
      this.tableLoading = false;
      });
  }
  loadCategoriesData(expendedKeys= []) {
    this.padService.fetchAllVideoCategories(this.videoGroup).then(data => {
      console.log(data);
      this.allCategories = data;
      this.cateLoading = false;
      this.defaultExpandedKeys =  expendedKeys;
    }).catch(e => {
      this.nzMessageService.error(`加载失败 ${e.message}`);
      this.cateLoading = false;
    });
  }
  openVideoDialog() {
    this.isAddVideoModalVisible = true;
    this.isAudioType = 0;
  }
  openAudioDialog() {
    this.isAddVideoModalVisible = true;
    this.isAudioType = 1;
  }
  openMoveDialog() {
    if (this.setOfCheckedId.size==0) {
      this.nzMessageService.error('请正确选择迁移文件');
      return;
    }
    this.moveResourceModalVisible = true;
    if (!this.allCategories) {
      this.cateLoading = true;
      this.padService.fetchAllVideoCategories(this.videoGroup).then(data => {
        console.log(data);
        this.allCategories = data;
        this.cateLoading = false;
        // this.defaultExpandedKeys = expendedKeys;
      }).catch(e => {
        this.nzMessageService.error(`加载失败 ${e.message}`);
        this.cateLoading = false;
      });
    }
  }

  moveResource() {
    const selected = this.nzTreeComponent.getSelectedNodeList();
    if (selected.length == 0) {
      this.nzMessageService.error('请正确选择迁移目录');
      return;
    }
    const category_id = selected[0].origin.id;
    if (!category_id) {
      this.nzMessageService.error('所选迁移目录id错误');
      return;
    }
    const result = Array.from(this.setOfCheckedId).map(rid => [rid, category_id ]);
    console.log('result', result);
    this.isMoving = true;
    this.padService.updateVideoCategories(result).then(res => {
      this.moveResourceModalVisible = false;
      this.isMoving = false;
      this.loadData();
    }).catch(e => {
      this.isMoving = false;
    });
  }

  closeMoveDialog() {
    this.moveResourceModalVisible = false;
  }
  closeDialog() {
    this.isSavingForm = false;
    this.isAddVideoModalVisible = false;
    // @ts-ignore
    this.addVideoForm.get('id').setValue('0');
    // @ts-ignore
    this.addVideoForm.get('name').setValue('');
    // @ts-ignore
    this.addVideoForm.get('url').setValue('');
    // @ts-ignore
    this.addVideoForm.get('cover').setValue('');
    // @ts-ignore
    this.addVideoForm.get('desc').setValue('');
    // @ts-ignore
    this.addVideoForm.get('width').setValue('');
    // @ts-ignore
    this.addVideoForm.get('height').setValue('');
    // @ts-ignore
    this.addVideoForm.get('size').setValue('');
    // @ts-ignore
    this.addVideoForm.get('duration').setValue('');
  }
  convertTimeTo(time: number): string {
    if (!time) {
      return '';
    }
    const hh = Math.floor(time / 60 / 60)
      .toString()
      .padStart(2, '0');
    const mm = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const ss = this.formatter.format(time % 60);

    return  `${hh}:${mm}:${ss}`;
  }

  saveDialog() {
    this.addVideoFormElement.ngSubmit.emit();
  }
  submitForm() {
    console.log('submitForm');
    for (const i in this.addVideoForm.controls) {
      this.addVideoForm.controls[i].markAsDirty();
      this.addVideoForm.controls[i].updateValueAndValidity();
    }
// @ts-ignore
    const cover_url = this.addVideoForm.get('cover')?.value;
    if (!cover_url) {
      // @ts-ignore
      this.addVideoForm.get('cover')?.setErrors({required: true});
    } else {
      // @ts-ignore
      this.addVideoForm.get('cover')?.clearValidators();
    }
// @ts-ignore
    const video_url = this.addVideoForm.get('url')?.value;
    if (!video_url) {
      // @ts-ignore
      this.addVideoForm.get('url')?.setErrors({required: true});
    } else {
      // @ts-ignore
      this.addVideoForm.get('url')?.clearValidators();
    }
// @ts-ignore
    this.addVideoForm.get('type')?.setValue(this.isAudioType);

    // if (checkedTheme.length === 0 ) {
    //   this.validateForm.get('theme').setErrors({required: true});
    // } else {
    //   this.validateForm.get('theme').clearValidators();
    // }

    if (this.addVideoForm.status === 'INVALID') {
      return;
    }
    this.isSavingForm = true;
    console.log(this.addVideoForm.value);
    this.padService.addVideo(this.addVideoForm.value).then(r => {
      this.closeDialog();
      this.loadData();
      this.isSavingForm = false;
    }).catch(e => {
      this.isSavingForm = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
  }
  setCategory(evt: any) {
    console.log('setCategory', evt);
    this.categoriesList.push(evt);
  }


  safeUrl(url: any) {
    // @ts-ignore
    const currentUrl = this.addVideoForm.controls.url.value;
    if (currentUrl && currentUrl === url) {
      return;
    }

    if (!url) {
      return;
    }
    console.log('safeUrl', url);
    return this.sanitization.bypassSecurityTrustUrl(url);
  }
  onVideoUploaded(evt: any) {
    console.log('onVideoUploaded', evt);
    // @ts-ignore
    this.addVideoForm.get('url').setValue('');
// @ts-ignore
    this.addVideoForm.get('width').setValue('');
    // @ts-ignore
    this.addVideoForm.get('height').setValue('');
    // @ts-ignore
    this.addVideoForm.get('size').setValue('');
    // @ts-ignore
    this.addVideoForm.get('duration').setValue('');
    // @ts-ignore
    setTimeout(() => {
      // @ts-ignore
      this.addVideoForm.get('width').setValue(evt.file.width);
      // @ts-ignore
      this.addVideoForm.get('height').setValue(evt.file.height);
      // @ts-ignore
      this.addVideoForm.get('size').setValue(evt.file.size);
      // @ts-ignore
      this.addVideoForm.get('url').setValue(evt.url);
      // @ts-ignore
      this.addVideoForm.get('duration').setValue(evt.file.duration);
    });

  }
  onVideoUploadFailure() {}
  onVideoCoverExtract(evt: any) {
    console.log('onVideoCoverExtract', evt);
    const osspath = '/pad/cover/';
    this.isSavingForm = true;
    // const ha = this.DUBData.video_url.lastIndexOf('/') + 1;
    // const hb = this.DUBData.video_url.lastIndexOf('.');
    // const hashVal = this.DUBData.video_url.substring(ha, hb);
    const params = {file: evt, /* type: ResType,*/
      osspath,
      success_cb: (name: string, hash: string, url: string, file: any, inOSS: boolean) => {
        // console.log(2, name, hash, url, file, inOSS);
        // this.saveVideoData.cover = '';
        // this.saveVideoData.cover = url;
        // @ts-ignore
        this.addVideoForm.get('cover').setValue('');
        setTimeout(() => {
          // @ts-ignore
          this.addVideoForm.get('cover').setValue(url);
        }, 1000);

        this.nzMessageService.success('封面提取成功');
        this.isSavingForm = false;
      },
      error_cb: (s: any, file: any) => {
        // console.log(3, s, file);
        this.nzMessageService.error('封面提取失败');
        this.isSavingForm = false;
      } };
    // @ts-ignore
    this.resourceSvc.doUpload(params);
  }
  onImageUploadSuccess(evt: any) {
    console.log('onImageUploadSuccess', evt);
    // @ts-ignore
    this.addVideoForm.get('cover').setValue('');
    setTimeout(() => {
      // @ts-ignore
      this.addVideoForm.get('cover').setValue(evt.url);
    }, 1000);

  }
  noCache(s: any) {
    if (s && typeof s === 'string') {
      return s + '?t=' + Math.random();
    }
    return s;
  }
  edit (id: any) {
    const row = this.dataSet.find(r => r.id === id);
    if (row) {
      // @ts-ignore
      this.addVideoForm.get('id').setValue(row.id);
      // @ts-ignore
      this.addVideoForm.get('name').setValue(row.name);
      // @ts-ignore
      this.addVideoForm.get('cover').setValue(row.cover);
      // @ts-ignore
      this.addVideoForm.get('url').setValue(row.url);
      // @ts-ignore
      this.addVideoForm.get('desc').setValue(row.desc);
      if (+row.type) {
        this.openAudioDialog();
      } else {
        this.openVideoDialog();
      }

    } else {
      this.nzMessageService.error(`发生错误，请刷新页面重试`);
    }

  }
  delete (id: any) {
    this.tableLoading = true;
    this.padService.removeVideo(id).then(r => {
      this.loadData();
      this.tableLoading = false;
    }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
  }
  drop(event: CdkDragDrop<string[]>): void {
    console.log('drop1', event);
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    console.log('drop2', JSON.parse(JSON.stringify(this.dataSet)));
    moveItemInArray(this.dataSet, event.previousIndex, event.currentIndex);
    console.log('drop3', this.dataSet);
    const ordered =  this.dataSet.map((item, i) => {
      if (item.sort === i) {
        return false;
      }
      return {id: item.id, sort: i};
    }).filter(item => {
      return !!item;
    });
    console.log(ordered);
    this.tableLoading = true;
    this.padService.sortVideoV2(ordered)
      .then(r => {
        this.loadData();
        this.tableLoading = false;
      }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
    return;
    let _form = event.previousIndex;
    let _to = event.currentIndex;
    if (event.currentIndex < event.previousIndex) { // down-up
      console.log('down-up');
      _form = event.previousIndex;
      _to = event.currentIndex;
    } else { // up-down
      console.log('up-down');
    }
    console.log({_form, _to});

    const currentItem = this.dataSet.find((r, i) => {
      return i === _form;
    });
    const currentId = currentItem.id;
    moveItemInArray(this.dataSet, event.previousIndex, event.currentIndex);
    const idx = this.dataSet.findIndex((r, i) => {
      return r.id === currentId;
    });
    const data = {
      beforeId: this.dataSet[idx - 1] ? this.dataSet[idx - 1].id : 0,
      afterId: this.dataSet[idx + 1] ? this.dataSet[idx + 1].id : 0,
      currentId
    };
    this.tableLoading = true;
    this.padService.sortVideo(data)
      .then(r => {
        this.loadData();
        this.tableLoading = false;
      }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(`发生错误 ${e.message}`);
    });
  }
  onAudioUploadFailure(evt: any) {
    console.log('onAudioUploadFailure', evt);
  }
  onAudioUploadSuccess(evt: any) {
    console.log('onAudioUploadSuccess', evt);
    // @ts-ignore
    this.addVideoForm.get('url').setValue('');
    // @ts-ignore
    this.addVideoForm.get('size').setValue('');
    // @ts-ignore
    this.addVideoForm.get('duration').setValue('');
    // @ts-ignore
    setTimeout(() => {
      // @ts-ignore
      this.addVideoForm.get('size').setValue(evt.file.size);
      // @ts-ignore
      this.addVideoForm.get('url').setValue(evt.url);
      // @ts-ignore
      this.addVideoForm.get('duration').setValue(evt.file.duration);
    });
  }




}
