import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { PreviousRouteService } from '../services/previous-route.service';
import { Router } from '@angular/router';

@Directive({
  selector: '[appGoBack]'
})
export class GoBackDirective {

  constructor(private location: Location,
              private router: Router ,
              private prService: PreviousRouteService) {
  }
  // @ts-ignore
  @HostListener('click', ['$event']) onClick($event) {
    const prevUrl = this.prService.getPreviousUrl();

    if (prevUrl) {
      this.router.navigate([prevUrl]);
    } else {
      this.location.back();
    }
  }
}
