import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import {Observable} from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    let headers = req.headers.set('Client-Type', 'BROWSER');
    let newReq = req;
    if (token) {
      headers = headers.set('token', token);
      newReq = req.clone({
        headers: headers
      });
    }
    return next.handle(newReq);
  }
}
