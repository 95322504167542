<div>
    <div class="toolbar">
        <button nz-button nzType="primary" [routerLink]="['/admin/tablet-manage/import']">批量导入</button>
    </div>
    <nz-modal
            [(nzVisible)]="addContractModalVisible"
            nzTitle="平板续费"
            (nzOnCancel)="handleCancelAddContract()"
            (nzOnOk)="handleAddContract()"
            [nzOkLoading]="isAddContractLoading"
    >
        <p *nzModalContent>
            <nz-radio-group [(ngModel)]="selectContract">
            <label nz-radio [nzValue]="c.type" *ngFor="let c of contractGroup">{{ c.type }}({{c.days}}天)</label>
            </nz-radio-group>
        </p>
    </nz-modal>
<!--    height: calc(100vh - 188px);-->
<!--    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"-->
    <nz-table #tabletInfoTable nzSize="small"
              [nzTotal]="total"
              [nzPageSize]="pageSize"
              [nzPageIndex]="pageIndex"
              [nzFrontPagination]="false"
              [nzLoading]="loadingData"
              [nzPageSize]="50"
              [nzScroll]="{ y: tableHeight + 'px'}"
              (nzQueryParams)="onQueryParamsChange($event)"
              class="sm-mac-table"
              [nzData]="tableData">
        <thead>
        <tr>
<!--            <th>brand</th>-->
<!--            <th>model</th>-->
            <th nzWidth="100px">project</th>
            <th nzWidth="150px" nzCustomFilter>SN
                <nz-filter-trigger [(nzVisible)]="snSearchVisible" [nzActive]="snSearchValue.length > 0" [nzDropdownMenu]="menuSN">
                    <span nz-icon nzType="search"></span>
                </nz-filter-trigger></th>
            <th nzWidth="150px" nzCustomFilter>mac
                <nz-filter-trigger [(nzVisible)]="macSearchVisible" [nzActive]="macSearchValue.length > 0" [nzDropdownMenu]="menuMac">
                    <span nz-icon nzType="search"></span>
                </nz-filter-trigger></th>
            <th nzWidth="150px" nzCustomFilter>phone
                <nz-filter-trigger [(nzVisible)]="phoneSearchVisible" [nzActive]="phoneSearchValue.length > 0" [nzDropdownMenu]="menuPhone">
                    <span nz-icon nzType="search"></span>
                </nz-filter-trigger></th>
            <th nzWidth="150px">ago</th>
            <th nzWidth="150px">after</th>
            <th nzWidth="90px">contract</th>
            <th nzWidth="200px">active at</th>
            <th nzWidth="200px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of tabletInfoTable.data">
<!--            <td>{{ data.brand }}</td>-->
            <td>{{ data.model }}</td>
<!--            <td>{{ data.project }}</td>-->
            <td>{{ data.sn }}</td>
            <td>{{ data.mac }}</td>
            <td>{{ data.phone }}</td>
            <td>{{ data.ago }}</td>
            <td>{{ data.after }}</td>
            <td>{{ data.contract }}</td>
            <td>{{ data.active_at}}</td>
            <td>
                <a
                        [style]="{color: data.type==1? 'gray':'#f50'}"
                        nz-popconfirm
                        nzPopconfirmTitle="重置后使用时长清零，用户需要重新激活使用"
                        (nzOnConfirm)="resetByMac(data)"
                >重置</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a style="color: #1890ff" (click)="showAddContractModal(data)">续费</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a style="color: #1890ff" (click)="showOtherInfo(data)">信息</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a [style]="{color: data.type==1? 'gray':'#1890ff'}" (click)="syncInfo(data)">同步</a>

            </td>
        </tr>
        </tbody>
    </nz-table>
    <nz-dropdown-menu #menuMac="nzDropdownMenu">
        <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="Search mac" [(ngModel)]="macSearchValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchMacAction()" class="search-button">Search</button>
                <button nz-button nzSize="small" (click)="resetSearchMacAction()">Reset</button>
            </div>
        </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuPhone="nzDropdownMenu">
        <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="Search phone" [(ngModel)]="phoneSearchValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchPhoneAction()" class="search-button">Search</button>
                <button nz-button nzSize="small" (click)="resetSearchPhoneAction()">Reset</button>
            </div>
        </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuSN="nzDropdownMenu">
        <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="Search sn" [(ngModel)]="snSearchValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchSNAction()" class="search-button">Search</button>
                <button nz-button nzSize="small" (click)="resetSearchSNAction()">Reset</button>
            </div>
        </div>
    </nz-dropdown-menu>
</div>

