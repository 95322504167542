import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from '../../../../environments/environment';
import { HWResourceService as ResourceService } from '../../../services/huawei-resource.service';
// import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss']
})
export class AudioRecorderComponent implements OnInit, OnChanges, OnDestroy {
  _audioUrl = '';
  audio = new Audio();
  playIcon = 'caret-right';
  isPlaying = false;
  isRecording = false;
  isUploading = false;
  type = Type.UPLOAD; // record | upload
  Type = Type;
  @Input()
  withRmBtn = false;


  @Input()
  needRemove = false;

  @Input()
  audioItem: any = null;
  private uploading = false;

  @Input()
  set audioUrl(url) {
    this._audioUrl = url;
    if (url) {
      this.audio.src = this._audioUrl;
      this.audio.load();
    }
    this.init();
  }

  get audioUrl() {
    return this._audioUrl;
  }

  @Output() audioUploaded = new EventEmitter();
  @Output() audioUploadFailure = new EventEmitter();
  @Output() audioRemoved = new EventEmitter();
  percent = 0;
  progress = 0;
  recorder: any;
  audioBlob: any;


  constructor( private nzMessageService: NzMessageService,
               private resService: ResourceService) {
  }

  init() {
    this.playIcon = 'caret-right';
    this.isPlaying = false;
    this.isRecording = false;
    this.isUploading = false;
    this.percent = 0;
    this.progress = 0;
    this.audioBlob = null;
  }
  ngOnChanges() {
    // if (!this.audioItem || !this.audioItem.type) {
    //   return;
    // }
    // this.beforeUpload(this.audioItem);
  }
  ngOnInit() {

    this.audio.onplay = () => {
      this.onPlay();
    };
    this.audio.onpause = () => {
      this.onPause();
    };
    this.audio.ontimeupdate = (event) => {
      this.onTimeUpdate(event);
    };
    this.audio.onended = (event) => {
      this.onEnded();
    };
  }

  ngOnDestroy() {
    this.audio.pause();
    this.isPlaying = false;
    this.audio.remove();
    // if (this.recorder.worker) {
    //   this.recorder.worker.terminate();
    // }
  }

  progressText(percent: any) {
    return ``;
  }

  onPlay() {
    console.log('caret-right');
    this.playIcon = 'pause';
    this.isPlaying = true;
  }

  onPause() {
    console.log('pause');
    this.playIcon = 'caret-right';
    this.isPlaying = false;
  }

  onEnded() {
    console.log('on end');
    this.playIcon = 'caret-right';
    this.percent = 0;
    this.isPlaying = false;
  }

  onTimeUpdate(event: any) {
    this.percent = Math.floor((this.audio.currentTime / this.audio.duration) * 100);
  }

  onBtnPlay() {
    if (this.isRecording) {
      this.nzMessageService.warning('In Recording');
      return;
    }
    if (this.isPlaying) {
      this.audio.pause();
    } else {
      this.audio.play();
    }
  }

  // 开始录音
  onBtnRecord = () => {
  }

  // 切换模式
  onBtnSwitchType() {
  }
  onBtnClearAudio() {
    this.audioUrl = '';
    this.audioRemoved.emit();
  }

  onBtnDeleteAudio() {
    this.audioUrl = '';
    this.audioRemoved.emit();
  }




  handleChange() {
    if (!this.audioItem.type) {
      return;
    }
    this.customUpload(this.audioItem);
  }
  customUpload = (item?: any) => {
    if (!item) {
      return false;
    }
    this.audioUrl = '';
    if (!this.checkSelectFile(item)) {
      return false;
    }
    this.uploading = true;
    this.progress = 0;
    let path = '/audios/';
    if (!environment.production) {
      path = '/dev' + path;
    }
    // this.resService.doUpload(item as any, /* ResType.AUDIO,*/ path,
    //   this.doProgress , this.uploadSuccess , this.uploadFailure );
    this.resService.doUpload({file: item,
      osspath: path,
      progress_cb: this.doProgress,
      success_cb: this.uploadSuccess,
      error_cb: this.uploadFailure});
    return false;
  }
  uploadSuccess = (name: string, hash: string, url: string, file?: File, inOss = false) => {
    this.nzMessageService.info('Upload Success');
    // this.updateStatus(false);
    this.uploading = false;
    // this.updateSource(url);
    this.audioUrl = url;
    // this.uploadFinished(url);
    this.audioUploaded.emit({name, hash, url});
    /*
    if (!inOss) {
      const a = new Audio();
      a.preload = 'metadata';
      a.src = url;
      a.addEventListener('loadedmetadata', () => {

        let duration = a.duration; // the duration variable now holds the duration (in seconds) of the audio clip
        a.preload = 'none';
        a.src = '';
        a.remove();
        if (duration) {
          duration = duration * 1000;
          file['duration'] = duration;
          this.resService.updateAudio(id, {duration}).then(() => {
            this.audioUploaded.emit({id, name, hash, url});
          });
        }

      });
    } else {
      this.audioUploaded.emit({id, name, hash, url});
    }*/
  }
  uploadFailure = (err: any, file: any) => {
    this.uploading = false;
    if (err.name && err.name === 'cancel') {
      return;
    }
    console.log(err);
    this.nzMessageService.error('Upload Error ' + err.message);
    this.audioUploadFailure.emit(file);
  }

  doProgress = (p: number) => {
    if (p > 1) {
      p = 1;
    }
    if (p < 0) {
      p = 0;
    }
    // console.log(Math.floor(p * 100));
    // @ts-ignore
    this.progress =  Math.floor(p * 100);
  }
  checkSelectFile(file: any) {
    if (!file) {
      return;
    }
    console.log(file.type);
    const isAudio = ['audio/mp3', 'audio/wav', 'audio/ogg', , 'audio/mpeg'].includes(file.type);
    if (!isAudio) {
      this.nzMessageService.error('You can only upload Audio file ( mp3 | wav |ogg)');
      return;
    }
    const delta =  25;
    const isOverSize = (file.size / 1024 / 1024) < delta;
    if (!isOverSize) {
      this.nzMessageService.error(`audio file  must smaller than ${delta}MB!`);
      return false;
    }
    return true;
  }

}

enum Type {
  RECORD = 1, UPLOAD
}
