<div class="lesson-resource">
    <nz-page-header *ngIf="theme_id" (nzBack)="onBack()"
                    nzBackIcon
                    nzTitle="返回列表"
                    nzSubtitle=""> </nz-page-header>
    <div class="content" >
        <nz-spin  [nzSpinning]="isSpinning" >
            <nz-input-group  nzSize="large" nzSearch [nzAddOnAfter]="suffixIconButton">
                <input type="text"  [(ngModel)]="kwds"  nz-input placeholder="input search text" />
            </nz-input-group>
            <ng-template #suffixIconButton>
                <button nz-button nzType="primary"
                        (click)="searchResource()"
                        nzSize="large" nzSearch><i nz-icon nzType="search"></i></button>
            </ng-template>
            <ng-template #suffixTemplate><i
                    nz-icon
                    nz-tooltip
                    class="ant-input-clear-icon"
                    nzTheme="fill"
                    nzType="close-circle"
                    *ngIf="kwds"
                    (click)="kwds = null"></i></ng-template>
            <br><br>


            <nz-empty *ngIf="!currentRows.length"></nz-empty>
        <div nz-row [nzGutter]="16">
            <ng-container  *ngFor="let item of currentRows">
            <div style="margin-bottom: 20px;position: relative;" nz-col class="gutter-row" [nzSpan]="6"
                 *ngIf="item.name.includes(kwds)"
                >
                <nz-tag *ngIf="item.school_id > 0" style="position: absolute;z-index: 1;margin: 4px 0 0 4px;" [nzColor]="'#2db7f5'">自制</nz-tag>
                <nz-card (click)="onOpenTheme(item)"
                         [title]="item.name"
                            nzHoverable style="width: 100%" [nzCover]="coverTemplate">
                    <nz-card-meta
                            *ngIf="isTheme(item)"
                            [nzTitle]="item.name"
                            [nzDescription]="themeInfo(item)"></nz-card-meta>
                    <nz-card-meta
                            *ngIf="!isTheme(item)"
                            [nzTitle]="item.name"
                            [nzDescription]="'时长：'+formatDuration(item.duration)"></nz-card-meta>
                </nz-card>
                <ng-template #coverTemplate>
                    <div class="cover-box">
                        <svg viewBox="0 0 16 9"></svg>
<!--                        <img class="lazy" [alt]="item.name" [attr.data-src]="item.cover" />-->
                        <div class="cover lazy" [title]="item.name" [ngStyle]="{'background-image': 'url(' + item.cover + ')'}"></div>
                    </div>
                </ng-template>

            </div>
            </ng-container>
        </div>
            <nz-drawer
                    [nzClosable]="!isLoadingSearchResult"
                    [nzVisible]="showSearchResultPanel"
                    [nzPlacement]="'bottom'"
                    [nzHeight]="'80%'"
                    nzTitle="搜索结果"
                    (nzOnClose)="closeSearchPanel()">
                <ng-container *nzDrawerContent>
                    <nz-spin  [nzSpinning]="isLoadingSearchResult" >
                    <div nz-row [nzGutter]="16">
                       <div style="margin-bottom: 20px;position: relative;" nz-col class="gutter-row" [nzSpan]="6" *ngFor="let item of searchResult">
                       <nz-tag *ngIf="item.school_id > 0" style="position: absolute;z-index: 1;margin: 4px 0 0 4px;" [nzColor]="'#2db7f5'">自制</nz-tag>
                       <nz-card (click)="onOpenTheme(item)"
                                nzHoverable style="width: 100%" [nzCover]="coverTemplate">
                            <nz-card-meta
                                 *ngIf="isTheme(item)"
                                 [nzTitle]="item.name"
                                 [nzDescription]="themeInfo(item)"></nz-card-meta>
                             <nz-card-meta
                                 *ngIf="!isTheme(item)"
                                 [nzTitle]="item.name"
                                 [nzDescription]="'时长：'+formatDuration(item.duration)+'秒'"></nz-card-meta>
                            </nz-card>
                            <ng-template #coverTemplate>
                             <div class="cover-box">
                             <svg viewBox="0 0 16 9"></svg>
                                <img class="cover" [title]="item.name" [alt]="item.name" [src]="item.cover" />
                             </div>
                            </ng-template>
                            </div>
                       </div>
                    </nz-spin>
                </ng-container>
            </nz-drawer>
        </nz-spin>
    </div>
</div>
<nz-modal
        [(nzVisible)]="isVisible"
        nzTitle=""
        nzWidth="70%"
        [nzClosable]="false"
        (nzOnCancel)="handleModalCancel()"
        [nzMaskClosable]="true" >
    <ng-container *nzModalContent>
    <div *ngIf="modalData&&type=='Audio'" class="res-cover" [ngStyle]="{'background-image': 'url(' + modalData.cover + ')' }">
        <video

                [src]="modalData?.url" controls autoplay style="width: 100%;"></video>
    </div>
    <video
            *ngIf="modalData&&type=='Video'"

            [src]="modalData?.url" controls autoplay style="width: 100%;"></video>
    </ng-container>
</nz-modal>

