import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let ret = '00:00';
    if (_.isNumber(value) && value > 0) {
      const minutes = Math.floor(value / 60);
      const seconds = Math.floor(value % 60);
      // @ts-ignore
      ret = _.padStart(minutes, 2, '0') + ':' + _.padStart(seconds, 2, '0');
    }
    return ret;
  }

}
