import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class LessonResourceService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  searchMedia(type: string|number, kwds: string|number) {
    return super.get(`/media/resource/search?q=${kwds}&page=all&type=${type}`);
  }
  getAudioTheme(theme_id: string|number, query: any) {
    const searchParams = new URLSearchParams();
    let q = '';
    if (query) {
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      q = `?${searchParams.toString()}`;
    }

    // let url = `/mutil-media/v2/audio/themes/page/all`;
    // if (theme_id) {
    //   url = `/mutil-media/v2/theme/${theme_id}/audio/page/all`;
    // }
    const url = '/mutil-media/audio/themes/tree';
    return super.get(`${url}${q}`);
  }
  getVideoTheme(theme_id: string|number, query: any) {
    const searchParams = new URLSearchParams();
    let q = '';
    if (query) {
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      q = `?${searchParams.toString()}`;
    }
    // let url = `/mutil-media/v2/video/themes/page/all`;
    // if (theme_id) {
    //   url = `/mutil-media/v2/theme/${theme_id}/video/page/all`;
    // }
    const url = '/mutil-media/video/themes/tree';
    return super.get(`${url}${q}`);
  }

  getAudioList(theme_id: string|number, query: any) {
    const searchParams = new URLSearchParams();
    let q = '';
    if (query) {
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      q = `?${searchParams.toString()}`;
    }

    let url = `/mutil-media/audio/themes/page/all`;
    if (theme_id) {
      url = `/mutil-media/theme/${theme_id}/audio/page/all`;
    }
    return super.get(`${url}${q}`);
  }
  getVideoList(theme_id: string|number, query: any) {
    const searchParams = new URLSearchParams();
    let q = '';
    if (query) {
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      q = `?${searchParams.toString()}`;
    }
    let url = `/mutil-media/video/themes/page/all`;
    if (theme_id) {
      url = `/mutil-media/theme/${theme_id}/video/page/all`;
    }
    return super.get(`${url}${q}`);
  }

  addAudioSeries(data: any) {
    return super.post(`/media-series/audio`, data);
  }
  updateAudioSeries(id: string|number, data: any) {
    return super.post(`/media-series/audio/${id}`, data);
  }

  updateVideoSeries(id: string|number, data: any) {
    return super.post(`/media-series/video/${id}`, data);
  }
  addVideoSeries (data: any) {
    return super.post(`/media-series/video`, data);
  }
  fetchAudioById(id: string|number) {
    return super.get(`/media-series/audio/${id}`);
  }
  fetchVideoById(id: string|number) {
    return super.get(`/media-series/video/${id}`);
  }
  removeVideoById(id: string|number) {
    return super.remove(`/media-series/video/${id}`);
  }
  removeAudioById(id: string|number) {
    return super.remove(`/media-series/audio/${id}`);
  }
}
