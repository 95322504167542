<div class="teacher-class-view">
  <div class="view-body">
    <div class="content book">
      <nz-page-header [nzTitle]="book.name" nzSubtitle="绘本相关资源"> </nz-page-header>
      <nz-tabset nzSize="large">
        <nz-tab nzTitle="音视频资源">
          <ng-container *ngIf="(book.audios.length + book.videos.length) == 0">
            <h5>暂无音视频资源</h5>
          </ng-container>
          <ng-container *ngIf="(book.audios.length + book.videos.length) != 0">
            <div nz-row nzGutter="16">
              <div nz-col class="gutter-row" nzSpan="6" *ngFor="let item of book.audios">
                <div class="gutter-box">
                  <div class="item-res">
                    <div class="res-cover" (click)="openPlayer(item, 'listen')" [ngStyle]="{'background-image': 'url(' + item.cover + ')' }">
                      <div class="res-icon" >
                        <i nz-icon nzType="sound" nzTheme="twotone"></i>
                      </div>
                    </div>

                    <div class="res-name">{{item.name}}</div>
                    <div class="res-type">歌曲歌谣</div>
                  </div>
                </div>
              </div>
              <div nz-col class="gutter-row" nzSpan="6" *ngFor="let item of book.videos">
                <div class="gutter-box">
                  <div class="item-res">
                    <div class="res-cover" (click)="openPlayer(item, 'animation')" [ngStyle]="{'background-image': 'url(' + item.cover + ')' }">
                      <div class="res-icon">
                        <i nz-icon nzType="play-circle" nzTheme="twotone"></i>
                      </div>
                    </div>
                    <div class="res-name">{{item.name}}</div>
                    <div class="res-type">动画片</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </nz-tab>

        <nz-tab nzTitle="Quiz">
          <ng-container *ngIf="book.quiz.length == 0">
            <h5>暂无Quiz资源</h5>
          </ng-container>
          <ng-container *ngIf="book.quiz.length != 0">
            <div class="quiz-item" *ngFor="let quiz of book.quiz; let idx = index;">
              <h5>{{idx + 1}}. {{quiz.content.question}}</h5>
              <ul>
                <li *ngFor="let option of quiz.content.options; let i = index;"
                    [ngClass]="{right: quiz.content.answer.includes(i) && quiz.isShowAnswer }">{{this.indexToLetter(i)}}. {{option}}</li>
              </ul>
              <button nz-button nzType="primary" class="answer-button" (click)="showAnswer(quiz)">
                <i nz-icon *ngIf="!quiz.isShowAnswer" nzType="eye" nzTheme="outline"></i>
                <i nz-icon *ngIf="quiz.isShowAnswer" nzType="eye-invisible" nzTheme="outline"></i>
                {{!quiz.isShowAnswer?'显示':'关闭'}}答案
              </button>
            </div>
          </ng-container>

        </nz-tab>
      </nz-tabset>


    </div>
  </div>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle=""
  nzWidth="70%"
  [nzClosable]="false"
  (nzOnCancel)="handleModalCancel()"
  [nzMaskClosable]="true">
  <ng-container *nzModalContent>
  <div *ngIf="modalData&&mediaFlag=='listen'" class="res-cover" [ngStyle]="{'background-image': 'url(' + modalData.cover + ')' }">
    <video
    (pause)="mediaPause()"
    (waiting)="mediaPause()"
    (playing)="mediaPlay()"
    (ended)="mediaStop()"
    (error)="mediaStop()"
    [src]="modalData?.url" controls autoplay style="width: 100%;"></video>
  </div>
  <video
    *ngIf="modalData&&mediaFlag=='animation'"
    (pause)="mediaPause()"
    (waiting)="mediaPause()"
    (playing)="mediaPlay()"
    (ended)="mediaStop()"
    (error)="mediaStop()"
   [src]="modalData?.url" controls autoplay style="width: 100%;"></video>
  </ng-container>
</nz-modal>

