<div class="table-operations" >
    <button nz-button nzType="primary" [nzSize]="'large'"
            [routerLink]="'/admin/classes/'+classId+'/statistics'">
        <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    <button nz-button nzType="primary" [nzSize]="'large'"
            [routerLink]="'/admin/classes/'+classId+'/statistics/monthly'">月度统计</button>
</div>
<div class="table-operations" style="display: flex;flex-direction: row">
    <div style="padding-top: .2rem;">统计项：</div>
    <nz-select style="width: 120px;margin-right: 1rem;" [(ngModel)]="selectedValue" nzPlaceHolder="Choose">
        <nz-option nzValue="listen" nzLabel="听音"></nz-option>
        <nz-option nzValue="animation" nzLabel="动画"></nz-option>
        <nz-option nzValue="grading" nzLabel="分级"></nz-option>
        <nz-option nzValue="read" nzLabel="课外"></nz-option>
        <nz-option nzValue="dubbing" nzLabel="配音"></nz-option>
        <nz-option nzValue="cam_show" nzLabel="视频秀"></nz-option>
    </nz-select>
    <label style="margin-right: 1rem;">
    年份：
    <nz-year-picker [(ngModel)]="selectedYearValue" (ngModelChange)="onYearChange($event)" nzPlaceHolder="选择年份"></nz-year-picker>
    </label>
    <label style="margin-right: 1rem;">
        季度：
    <nz-select style="width: 120px;margin-right: 1rem;" [(ngModel)]="selectedQuarterValue" nzPlaceHolder="Choose">
        <nz-option nzValue="1" nzLabel="第一季度"></nz-option>
        <nz-option nzValue="2" nzLabel="第二季度"></nz-option>
        <nz-option nzValue="3" nzLabel="第三季度"></nz-option>
        <nz-option nzValue="4" nzLabel="第四季度"></nz-option>
    </nz-select>
    </label>
    <!--<nz-range-picker [(ngModel)]="dateRange"-->
    <!--[nzDateRender]="tplRender"-->
    <!--style="margin-right: 1rem"-->
    <!--(ngModelChange)="onRangeChange($event)"></nz-range-picker>-->
    <!--<ng-template #tplRender let-current>-->
    <!--<div class="ant-calendar-date"  [class.border]="current.getDay() === 1">-->
    <!--{{ current.getDate() }}-->
    <!--</div>-->
    <!--</ng-template>-->

    <!--<app-nz-week-range (weekRangeChanged)="weekRangeChanged($event)"></app-nz-week-range>-->


    <button nz-button nzType="primary" style="margin-right: 2rem" (click)="filterRangeData()">查看</button>


</div>
<nz-table #classTable [nzData]="displayData"
          [nzLoading]="loading"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalDataCount">
    <thead (nzSortChange)="sort($event)" nzSingleSort>
    <tr>
        <!--<th>id</th>-->
        <th>姓名</th>
        <th>检查项目</th>

        <!--<th nzShowSort nzSortKey="animation">动画</th>-->
        <!--<th nzShowSort nzSortKey="read">阅读</th>-->
        <!--<th nzShowSort nzSortKey="lottery">奖券</th>-->
        <!--<th nzShowSort nzSortKey="grading">分级作业</th>-->
        <!--<th nzShowSort nzSortKey="video">视频</th>-->

        <th>第一月</th>
        <th>第二月</th>
        <th>第三月</th>
        <th style="text-align: center;">本季汇总</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <!--<td>{{data.id}}</td>-->
        <td>{{data.name}}</td>
        <td>{{showItem}}</td>
        <td>{{data.col0}} </td>
        <td>{{data.col1}} </td>
        <td>{{data.col2}} </td>
        <td style="text-align: center">{{data.total}}</td>
    </tr>
    </tbody>
</nz-table>
