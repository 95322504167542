import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private nzNotificationService: NzNotificationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(
        catchError((error) => {
          console.log('error-interceptor error', error);
          this.nzNotificationService.error('Server Error', `${error.message}`, {nzDuration: 0});
          return throwError(error);
        }),
      );
  }
}
