// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { OrderService } from '../../../../../../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { UserService } from '../../../../../../services/user.service';
import { PAY_STATUS, UserType } from '../../../../../../model/Const';
interface ICLassObj {
  user_id: string;
  user_name: string;
  phone: string;
  class_name: string;
  operator_name: string;
  createon: string;
  days: string;
  expired: string;
}

@Component({
  selector: 'app-admin-orders-index',
  templateUrl: './orders-index.component.html',
  styleUrls: ['./orders-index.component.scss']
})
export class OrdersIndexComponent implements OnInit {
  dataSet: ICLassObj[] = [];
  downloading = false;
  totalNum = 0;
  currentPageIndex = 1;
  promo = null;
  total_quantity = 0;
  accounts_message = '';
  // subsidy_quota = 0;
  isSA = false;
  searchValue = '';
  query = {};
  dropSearchMenu = false;

  @ViewChild('dropdown', {static: false})
  dropdown: NzDropdownMenuComponent;

  @ViewChild('rejectContent', {static: true})
  rejectContent: TemplateRef<{}>;
  rejectReason = '';
  selectMonth = null;

  orderStatisticTableData = null;
  currentStatisticPageIndex = 1;
  // @ViewChild('subsidyContent', {static: true})
  // subsidyContent: TemplateRef<{}>;


  // checkSubsidyLoadingTxt = 'loading';
  // checkSubsidyModelOpen = new EventEmitter();

  // for consume table
  consumeTableData = {
    rows: [],
    total: 0,
    currentPage: 0,
  };
  consumeTableDataCache = {};
  // consumeTableDate = [];
  // currentConsumePageIndex = 0;
  // totalConsumeNum = 0;
  visible = false;

  tableLoading = false;

  statisticQuery = {};

  listOfStatus = [
    { text: PAY_STATUS.DESC[PAY_STATUS.NO_PAY], value: PAY_STATUS.NO_PAY },
    { text: PAY_STATUS.DESC[PAY_STATUS.PAID], value: PAY_STATUS.PAID },
    { text: PAY_STATUS.DESC[PAY_STATUS.EXPIRED], value: PAY_STATUS.EXPIRED },
    { text: PAY_STATUS.DESC[PAY_STATUS.CLOSED], value: PAY_STATUS.CLOSED },
    { text: PAY_STATUS.DESC[PAY_STATUS.PAYING], value: PAY_STATUS.PAYING }];

  constructor(private orderService: OrderService,
              private nzContextMenuService: NzContextMenuService,
              private modalService: NzModalService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    const info = this.userService.getUserInfo();
    this.isSA = info.type === UserType.SUPER_ADMIN;
    // this.checkSubsidyModelOpen.subscribe((evt) => {
    //   console.log('modal open');
    // });
  }

  downloadConsumeData(event: any) {
    this.downloading = true;
    const url = this.orderService.getConsumeData({page: 'all'}).then(res => {
      console.log(res);
      const data = [];
      data.push(`学生ID,开通学生姓名,手机号,班级,开通人,开通时间,账号类型,账号到期时间`);
      for (const row of res.rows) {
        row.user_id = !row.user_id ? '' : row.user_id;
        row.user_name = !row.user_name ? '' : row.user_name;
        row.phone = !row.phone ? '' : row.phone;
        row.class_name = !row.class_name ? '回收站或无班级' : row.class_name;
        row.operator_name = !row.operator_name ? '' : row.operator_name;
        row.createon = !row.createon ? '' : row.createon;
        row.days = +row.days ? row.days + '天账号' : '';
        row.expired = !row.expired ? '' : row.expired;

        // tslint:disable-next-line:max-line-length
        data.push(`${row.user_id},${row.user_name},${row.phone},${row.class_name},${row.operator_name},${row.createon},${row.days},${row.expired}`);
      }
      const str_data = data.join('\n');
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), str_data], { type: 'text/csv;charset=utf-8' });
      const durl = window.URL.createObjectURL(blob);
      // window.location.href= durl;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = durl;
      // const s = `${this.formatDate(this.dateRange[0], '/')}`;
      // const e = `${this.formatDate(this.dateRange[1], '/')}`;
      const now = new Date();
      a.download = `Consume${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}.csv`;
      a.click();
      // window.URL.revokeObjectURL(durl);
      a.remove();
      this.downloading = false;
    }).catch((err: any) => {
      this.downloading = false;
    });
  }



  ngOnInit() {
    this.loadData(1);

  }
  loadConsumeData(page) {
    console.log('loadConsumeData', page);
    if (!page || isNaN(page)) {
      page = 1;
    }
    if (this.consumeTableDataCache[page]) {
      this.consumeTableData = JSON.parse(JSON.stringify(this.consumeTableDataCache[page])) ;
      return;
    }
    this.query['page'] = page;
    this.tableLoading = true;
    this.orderService.getConsumeData(this.query).then(data => {
      console.log(data);
      this.tableLoading = false;
      this.consumeTableData = data;
      // this.consumeTableDataCache[data.currentPage] = data;
    });
  }
  loadData(page) {
    if (!page || isNaN(page)) {
      page = 1;
    }
    if (this.searchValue) {
      this.query['user_name'] = this.searchValue;
      this.query['page'] = 1;
    } else {
      this.query['page'] = page;
    }

    this.orderService.getOrders(page, this.query).then(data => {
      console.log(data);
      this.dataSet = data.rows as ICLassObj[];
      this.totalNum = data.total;
      this.currentPageIndex = page;
      this.promo = data.promo;
      this.accounts_message = data.accounts_message;
      this.total_quantity = data.total_quantity;
    });
  }
  filterStatus(evt) {
    console.log(evt);
    this.query['status'] = evt;
    this.loadData(1);
    this.dropSearchMenu = false;
  }
  searchName() {
    this.loadData(1);
    if (this.dropdown['closeMenu'] && this.dropdown['closeMenu'].call) {
      this.dropdown['closeMenu']();
    }
    // this.nzContextMenuService.close();
    this.visible = false;
  }
  reset() {
    console.log('1111');
    this.searchValue = '';


    this.query = {};
    if (this.dropdown['closeMenu'] && this.dropdown['closeMenu'].call) {
      this.dropdown['closeMenu']();
    }
    this.nzContextMenuService.close();
    this.loadData(1);
    // this.dropdown.nzDropdownMenu.visible$
  }
  createCheckModal(title = '提示', rejectContent, data?: {}, okCb?: Function, errCb?: Function): NzModalRef {
    const self = this;
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: rejectContent,
      nzComponentParams: data,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: '关闭',
          type: 'default',
          loading: false,
          onClick(): void {
            if (errCb) {
              errCb();
            }
            modal.destroy();
          }
        },
        {
          label: '确定',
          type: 'primary',
          loading: false,
          onClick(): void {
            // console.log(modal);
            // const m = modal.getInstance();
            // m.nzFooter[0].disabled = true;
            // this.loading = true;
            /*
            self.orderService.changeSubsidyStatus(data).then(resp => {
              this.loading = false;
              self.modalService.closeAll();
              self.loadData(self.currentPageIndex);
              modal.destroy();
            }).catch(err => {
              this.nzMessageService.error(err.message);
              this.loading = false;
            });*/
            if (okCb) {
              okCb(modal);
            }

          }
        },
      ]
    });
    return modal;
  }
  /*
  checkSubsidy(id) {
    console.log(id);

    const self = this;
    const modal = this.modalService.create({
      nzTitle: '补贴审核',
      nzContent: OrderInfoComponent,
      nzComponentParams: {id},
      nzClosable: false,
      // nzOkText: '通过',
      // nzCancelText: '驳回',
      nzFooter: [
        {
          label: '通过',
          type: 'primary',
          loading: false,
          onClick(): void {

            self.modalService.confirm({
              nzTitle: '提示',
              nzContent: '确认添加补贴吗',
              nzOnOk: () => {
                this.loading = true;
                self.orderService.changeSubsidyStatus({
                  id,
                  status: 2
                }).then(data => {
                  this.loading = false;
                  self.modalService.closeAll();
                  self.loadData(self.currentPageIndex);
                }).catch(err => {
                  this.nzMessageService.error(err.message);
                  this.loading = false;
                });
              },
              nzOnCancel: () => {
              }
            });
          }

        },
        {
          label: '驳回',
          type: 'danger',
          loading: false,
          onClick(): void {
            this.loading = true;
            const m = modal.getInstance();
            m.nzFooter[0].disabled = true;
            m.nzFooter[2].disabled = true;
            this.rejectReason = '';
            const okCb = (reject_mod) => {

              self.orderService.changeSubsidyStatus({
                id,
                status: 1,
                reason: self.rejectReason
              }).then(data => {
                this.loading = false;
                self.modalService.closeAll();
                self.loadData(self.currentPageIndex);
                reject_mod.destory();
              }).catch(err => {
                this.nzMessageService.error(err.message);
                this.loading = false;
              });


            }
            const errCb = () => {
              this.loading = false;
              m.nzFooter[0].disabled = false;
              m.nzFooter[2].disabled = false;
            }
            const mod = self.createCheckModal(
              '驳回原因',
              self.rejectContent, {id}, okCb, errCb);
            // const m = mod.getContentComponent();
            // mod.afterClose.subscribe(res => {
            //   self.orderService.changeSubsidyStatus({
            //     id,
            //     status: 1,
            //     reason: self.rejectReason
            //   }).then(data => {
            //     this.loading = false;
            //     self.modalService.closeAll();
            //     self.loadData(self.currentPageIndex);
            //   }).catch(err => {
            //     this.nzMessageService.error(err.message);
            //     this.loading = false;
            //   });
            // });

          }
        },
        // {
        //   label: 'async load',
        //   type: 'dashed',
        //   onClick: () => new Promise(resolve => setTimeout(resolve, 2000))
        // }
        {
          label: '关闭',
          shape: 'default',
          onClick: () => modal.destroy()
        },
      ],
      nzAfterOpen: this.checkSubsidyModelOpen,
      // nzOnCancel: () => {
      //
      // },
      // nzOnOk: () => {
      //   console.log(modal);
      //   this.orderService.getOrderInfo(id).then(order => {
      //     const m = modal.getInstance();
      //     m.nzContent = 'sssss';
      //   });
      //
      //   return false;
      //   // modal.nzContent = '1'
      // }
    });
  }
  reSubmitSubsidy(id) {
    // const mod = this.createCheckModal('提交信息', SubsidyProofComponent, {id});
    // // const m = mod.getContentComponent();
    // mod.afterClose.subscribe(res => {
    //    console.log(9, res);
    // });

    const self = this;
    const modal = this.modalService.create({
      nzTitle: '提交信息',
      nzContent: SubsidyProofComponent,
      nzComponentParams: {id},
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: '关闭',
          type: 'default',
          loading: false,
          onClick(): void {
            modal.destroy();
          }
        },
        {
          label: '确定',
          type: 'primary',
          loading: false,
          onClick: function(componentInstance) {
            this.loading = true;
            const data = {id, subsidyDesc: componentInstance.subsidyDesc, subsidyPicList: componentInstance.subsidyPicList }
            console.log(data);
            self.orderService.reSubmitSybsidy(data).then(() => {
              self.modalService.closeAll();
              self.loadData(self.currentPageIndex);
            }).catch(err => {
              this.loading = false;
            });
            // componentInstance!.title = 'title in inner component is changed';
          }
        },
      ]
    });
    return modal;
  } */
  tabIndexChanged(evt) {
    console.log('tabIndexChanged', evt);
    if (evt === 1) {
      if (this.isSA && !this.orderStatisticTableData) {
        this.onSelectAll();
      } else {
        this.loadConsumeData(this.consumeTableData.currentPage || 1);
      }

    }
  }
  onSelectAll() {
    // console.log('onSelectAll', evt);
    this.selectMonth = null;
    this.statisticQuery['dt'] = 'all';
    this.statisticQuery['page'] = 1;
    this.loadOrderStatistic(1);
  }
  onMonthChange(evt) {
    if (!evt) {
      return;
    }
    // console.log('onMonthChange', evt);
    // selectMonth
    // evt = new Date();
    // evt.getFullYear()
    this.statisticQuery['dt'] = `${evt.getFullYear()}-${evt.getMonth() + 1}`;
    this.statisticQuery['page'] = 1;
    this.loadOrderStatistic(1);
  }
  loadOrderStatistic(page) {
    // currentStatisticPageIndex
    this.statisticQuery['page'] = page;
    this.tableLoading = true;
    this.orderService.getStatistic(this.statisticQuery).then(res => {
      this.tableLoading = false;
      console.log(res);
      this.orderStatisticTableData = res;
    }).catch(e => {
      this.tableLoading = false;
      this.nzMessageService.error(e.message);
    });
  }
}
