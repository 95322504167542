import { Injectable } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';

@Injectable()
export class PreviousRouteService {

  private previousUrl: string | undefined;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;

      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }
  public goBack(query: Params, url: string) {
    console.log(query, url);
    if (!this.previousUrl) {
      this.previousUrl = url;
    }
    if (this.previousUrl) {
      this.router.navigate([this.previousUrl] , {queryParams: query});
    } else {
      window.history.go(-1);
    }

  }
}
