<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'"
                [queryParams]="{ page: returnPage }"
                [routerLink]="'/admin/classes/'+classId+'/students/index'">
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>

        <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/classes/'+classId+'/students/add/batch_add'">
            <i nz-icon nzType="team" nzTheme="outline"></i>批量添加学生
        </button>
    </div>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm($event,validateForm.value)">
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>学生姓名</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入学生姓名！">
                <input nz-input formControlName="name" placeholder="学生姓名">
                <!--<div nzErrorTip *ngIf="validateForm.get('name').dirty && validateForm.get('name').errors  ">-->
                    <!--<ng-container *ngIf="validateForm.get('name').hasError('required')">-->
                        <!--请正确输入学生姓名！-->
                    <!--</ng-container>-->
                <!--</div>-->
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" >学生性别</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback  class="sex">
                <nz-radio-group nzName="sex" formControlName="sex">
                    <label nz-radio nzValue="1" >男</label>
                    <label nz-radio nzValue="0" >女</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" >生日</nz-form-label>
            <nz-form-control [nzSpan]="12" >
                <nz-date-picker formControlName="birthday"></nz-date-picker>
            </nz-form-control>

        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>学生电话</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="phoneErrorTpl">
                <input nz-input
                        style="width: 100%"
                        nz-input
                        formControlName="phone"
                        placeholder="学生电话">
                    <ng-template #phoneErrorTpl let-control>
                        <ng-container *ngIf="validateForm.get('phone')?.hasError('dup_students')">
                            <div [innerHTML]="validateForm.get('phone')?.getError('message')"></div>
                        </ng-container>
                        <ng-container *ngIf="validateForm.get('phone')?.hasError('dup_phone')">
                            不能使用该手机号！
                        </ng-container>
                        <ng-container *ngIf="validateForm.get('phone')?.hasError('required')">
                            请正确输入学生电话！
                        </ng-container>
                    </ng-template>

            </nz-form-control>
        </nz-form-item>
        <div nz-row *ngIf="mergePhoneConfirm">
            <div nz-col [nzSpan]="7" style="text-align: right;">确认添加: </div>
            <br>
            <div nz-col [nzSpan]="12">
                <nz-switch (click)="sureMergeFn($event)"></nz-switch>
            </div>
        </div>

        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <button style="margin-right:1rem;" nz-button nzType="primary" [disabled]="!validateForm.valid">提交</button>
                <button nz-button (click)="resetForm($event)">清空</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
