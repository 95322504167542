<button style="margin: 0 8px;" [disabled]="disabled" nz-button (click)="showModal()"><span>Move...</span></button>

<nz-modal
  class="move-dialog"
  [(nzVisible)]="isVisible"
  nzTitle="Move"
  [nzFooter]="modalFooter"
  (nzOnCancel)="handleCancel()"
  (nzAfterOpen)="onHandleOpen()"
  (nzAfterClose)="onHandleClose()"

>
  <ng-container *nzModalContent>
  <div class="tree-wrap">
    <nz-tree [(ngModel)]="nodes"
             [nzAsyncData]="true"
             [nzShowLine]="true"
             (nzClick)="onNodeClick($event)"
             [nzExpandedKeys]="expandKeys"
             (nzExpandChange)="mouseAction($event)">
      <ng-template #nzTreeTemplate let-node>
          <span class="custom-node" [class.active]="node.isSelected">
            <span [class.active]="node.isSelected">
              <i nz-icon nzType="folder" nzTheme="outline" *ngIf="!node.isExpanded" (click)="openFolder(node)"></i>
              <i nz-icon nzType="folder-open" nzTheme="outline" *ngIf="node.isExpanded" (click)="openFolder(node)"></i>
              <span class="folder-name">{{node.title}}</span>
            </span>
          </span>
      </ng-template>

    </nz-tree>
  </div>

  </ng-container>
  <ng-template #modalFooter>
    <span class="target-brief">Move to: {{folderName}}</span>
    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="handleOk()" [disabled]="!targetFolder">Ok</button>
  </ng-template>
</nz-modal>
