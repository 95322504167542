<div class="table-operations" >
    <ng-container *ngIf="user && user?.type === constUserType.SUPER_ADMIN">
        <button nz-button nzType="primary"
                [nzSize]="'large'"
                [routerLink]="'/admin/elp-speaker/firmware/add'">
            <i nz-icon nzType="plus" nzTheme="outline"></i>添加固件
        </button>
    </ng-container>
    <nz-alert style="margin-top:20px" nzType="warning" nzMessage="不升级白名单用`00:00:00:00:00:00`占位符"></nz-alert>



    <nz-modal
            [(nzVisible)]="searchBoxVisible"
            nzTitle="查询结果"
            nzContent="{{searchResult}}"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleOk()"
    >
    </nz-modal>

</div>
<nz-table #classTable [nzData]="displayData"
          [nzLoading]="loadingData"
          [nzFrontPagination]="false"
          [nzPageSize]="displayData.length">
    <thead>
    <tr>
        <th>id</th>
        <th>version</th>
        <th>remark</th>
        <th style="text-align: center;width: 15rem;">操作</th>
    </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let data of displayData">
        <tr>
            <td>{{data.id}}</td>
            <td>{{data.ota_version}}</td>
            <td>{{data.remark}}</td>
            <td style="width: 18rem;">
                <div class="operations">
                    <a nz-popconfirm nzPopconfirmTitle="确定删除？"
                       (nzOnConfirm)="delete(data.id)" >
                        删除
                    </a>
                    <a (click)="onExpandChange(data, !Boolean(data.expand))">白名单</a>
                </div>
            </td>
        </tr>
        <tr [nzExpand]="Boolean(data.expand)">
            <div>
                <textarea rows="4" nz-input [(ngModel)]="data.whitelist"></textarea>
                <div>
                    <button (click)="saveWhitelist(data)" nz-button nzType="primary">保存</button>
                </div>
            </div>
        </tr>
    </ng-container>
    </tbody>
</nz-table>
