import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { DragElement } from './drag-element';


@Directive({
  selector: '[appCustomDrag]'
})
export class CustomDragDirective implements AfterViewInit, OnDestroy {
  // @ts-ignore
  @Input() constraint: () => [number, number] = null;
  // @ts-ignore
  @Input() b4Down: (DragElement) => boolean = null;
  // @ts-ignore
  @Input() move: (pos: number) => void = null;
  // @ts-ignore
  @Input() start: (event: MouseEvent) => void = null;

  private Element: any;
  // @ts-ignore
  private dd: DragElement;
  constructor(Element: ElementRef) {
    this.Element = Element;

  }
  ngOnDestroy() {
    if (this.dd) {
      this.dd.dispose();
    }
  }

  ngAfterViewInit() {
    if (!this.Element.nativeElement) {
      return;
    }
    this.dd = new DragElement(this.Element.nativeElement, {
      constraint: this.constraint,
      b4Down: this.b4Down,
      start: this.start
    });
    if (this.move) {
      this.dd.onMove.subscribe(this.move);
    }
  }
}
