// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserType } from '../../../../../model/Const';


@Component({
  selector: 'app-class-statistics-detail',
  templateUrl: './statistics-detail.component.html',
  styleUrls: ['./statistics-detail.component.scss']
})
export class ClassStatisticsDetailComponent implements OnInit {
  displayData: {
    id: number,
    date: number,
    listen: number,
    animation: number,
    read: number,
    grading: number,
    cam_show: number,
    dubbing: number,
    lottery: number
  }[] = [];
  totalDataCount = 0;
  currentPageIndex = 1;
  user = null;
  constUserType = UserType;
  userId = 0;
  classId = 0;

  studentInfo: {name: string} = {name: ''};

  dateRange = [];
  downloading = false;
  // statisticsTerms = [];


  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    this.activatedRoute.params.subscribe( params => {
      if (params['user_id'] && parseInt(params['user_id'], 10) === params['user_id'] * 1) {
        this.userId = parseInt(params['user_id'], 10);
      }
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.dateRange = [
      // `${this.formatDate()} 00:00:00`,
      // `${this.formatDate()} 23:59:59`
      // @ts-ignore
      new Date(),
      // @ts-ignore
      new Date()
    ];
  }
  formatDate (d?: any, sep = '-') {
    if (typeof d === 'string') {
      d = new Date(d);
    } else if (typeof d === 'object') {
    } else {
      d = new Date();
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}${sep}${((d.getMonth() + 1) + '').padStart(2, '0')}${sep}${(d.getDate() + '').padStart(2, '0')}`;
  }
  ngOnInit() {

    this.loadData(1);

  }
  downloadUserStatisticData() {
    const start = `${this.formatDate(this.dateRange[0])}` ;
    const end = `${this.formatDate(this.dateRange[1])}`;
    this.clsService.fetchUserReports(this.userId, 'all', {start, end}).then(res => {
      console.log(res);
      const data = [];
      const rows = res.rows;
      const student_info = res.student_info;

      data.push(`日期,听音,动画,分级作业,课外,配音,视频秀,奖券`);
      for (const row of rows) {
        row.name = !row.name ? '' : row.name;
        row.listen = !row.listen ? '' : row.listen;
        row.animation = !row.animation ? '' : row.animation;
        row.grading = !row.grading ? '' : row.grading;
        row.read = !row.read ? '' : row.read;
        row.dubbing = !row.dubbing ? '' : row.dubbing;
        row.cam_show = !row.cam_show ? '' : row.cam_show;
        row.lottery = !row.lottery ? '' : row.lottery;
        data.push(`${row.date},${row.listen},${row.animation},${row.grading},${row.read},${row.dubbing},${row.cam_show},${row.lottery}`);
      }
      const str_data = data.join('\n');
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), str_data], { type: 'text/csv;charset=utf-8' });
      const durl = window.URL.createObjectURL(blob);
      // window.location.href= durl;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = durl;
      const s = `${this.formatDate(this.dateRange[0], '')}`;
      const e = `${this.formatDate(this.dateRange[1], '')}`;
      a.download = `${student_info.name}(${s}-${e}).csv`;
      a.click();
      // window.URL.revokeObjectURL(durl);
      a.remove();
      this.downloading = false;
    }).catch(err => {
      this.downloading = false;
    });
  }
  loadData(page: number) {

    this.clsService.fetchUserReports(this.userId, page, {}).then(data => {
      this.displayData = data.rows || [];
      this.totalDataCount = data.total || 0;
      this.studentInfo = data.student_info;
      this.currentPageIndex = page;
    });
  }
  backToClassUsers() {
    this.router.navigate([`admin/classes/${this.classId}/statistics`]);
  }



}
