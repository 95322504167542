// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { OrderService } from '../../../../../services/order.service';

@Component({
  selector: 'app-modal-subsidy-proof-component',
  template: `
    <div>
        <textarea style="margin-bottom: 1rem" rows="4" nz-input [(ngModel)]="subsidyDesc"></textarea>
        <div  class="preview-list" >
            <div nz-col [nzSpan]="8" class="preview-list-item" *ngFor="let subsidyItem of subsidyPicList; let i = index;">
                <div class="sub-item" [ngStyle]="{'background-image': 'url('+subsidyItem.url+')'}"></div>
                <div class="upload-list-item-info">
                            <span class="ant-upload-list-item-actions actions" style="">
                                <i nz-icon nzType="eye" nzTheme="outline" (click)="previewImage(subsidyItem)"></i>
                                <i nz-icon nzType="delete" nzTheme="outline" (click)="deletePreviewImage(i)"></i>
                            </span>
                </div>
            </div>

            <app-upload-image-with-preview
                    *ngIf="subsidyPicList.length < 20"
                    [picPreview]="false"
                    style="width: 100px;display: inline-block;"
                    [displayText]="null"
                    [picUrl]="tmpPicUrl"
                    (imageUploaded)="onImageUploaded($event)"
                    displayIcon="plus"
                    displayRatioWidth="1"
                    displayRatioHeight="1"></app-upload-image-with-preview>
        </div>
        <nz-modal
                [nzVisible]="previewVisible"
                (nzOnCancel)="previewVisible = false"
        >
<!--            [nzContent]="modalContent"-->
            <ng-container *nzModalContent>
<!--            <ng-template #modalContent>-->
                <img [src]="previewImgUrl" [ngStyle]="{ width: '100%' }" />
<!--            </ng-template>-->
            </ng-container>
        </nz-modal>
    </div>
    
    
  `,
  styles: [

    `.preview-list{
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          color: rgba(0,0,0,.65);
          font-size: 14px;
          font-variant: tabular-nums;
          line-height: 1.5;
          list-style: none;
          font-feature-settings: 'tnum';
          zoom: 1;
      }
      .preview-list::after, .preview-list::before {
          display: table;
          content: '';
      }
      .preview-list-item{
          position: relative;
          padding: 8px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          float: left;
          width: 104px;
          height: 104px;
          margin: 0 8px 8px 0;
      }
      .sub-item{
          position: static;
          display: block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          width: 100%;
          height: 100%;
      }
      .upload-list-item-info{
          position: absolute;
          height: 100%;
          overflow: hidden;
          width: 100%;
          top: 0px;
          left: 0px;
          transition: background-color .3s;
      }
      .actions i{
          cursor: pointer;
      }
      .actions{
          position: absolute;
          right: 0;
      }
    `
  ]
})
export class SubsidyProofComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @Input() id;
  subsidyPicList: any[] = [];
  previewVisible = false;
  previewImgUrl = '';
  tmpPicUrl = null;
  subsidyDesc = '';

  constructor(private modal: NzModalRef, private orderService: OrderService) {

  }
  ngOnInit(): void {
    // this.orderService.getOrderInfo(this.id).then(order => {
    //   console.log(order);
    //   // const m = this.modal.getInstance();
    //   // m.nzContent = 'sssss';
    // });
    // const m = this.modal.getInstance();
    // m.afterClose.subscribe((rrr) => {
    //   console.log(8, rrr);
    // });
  }
  destroyModal(): void {
    // this.modal.destroy({ subsidyDesc: this.subsidyDesc, subsidyPicList: this.subsidyPicList });
  }
  ngOnDestroy(): void {
    // this.modal.destroy({ subsidyDesc: this.subsidyDesc, subsidyPicList: this.subsidyPicList });
  }

  onImageUploaded(evt) {
    console.log(evt.url);
    this.tmpPicUrl = null;
    this.subsidyPicList.push(evt);
  }
  previewImage(file) {
    this.previewImgUrl = file.url;
    this.previewVisible = true;
  }
  deletePreviewImage(idx) {
    this.subsidyPicList.splice(idx, 1);
  }
}
