<div class="table-operations" >
    <button nz-button nzType="primary" [nzSize]="'large'"
            [routerLink]="'/admin/classes/index'">
        <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    <!--<button nz-button nzType="primary" [nzSize]="'large'"-->
            <!--[routerLink]="'/admin/classes/'+classId+'/statistics/monthly'">月度统计</button>-->
    <!--<button nz-button nzType="primary" [nzSize]="'large'"-->
            <!--[routerLink]="'/admin/classes/'+classId+'/statistics/quarter'">季度统计</button>-->

</div>
<div class="table-operations" style="display: flex;flex-direction: row">

    <!--<div style="padding-top: .2rem;">时间范围：</div>-->
    <!--<nz-range-picker [(ngModel)]="dateRange"-->
                     <!--style="margin-right: 1rem"-->
                     <!--(ngModelChange)="onRangeChange($event)"></nz-range-picker>-->
    <!--<button nz-button nzType="primary" style="margin-right: 2rem" (click)="filterRangeData()">查看</button>-->



    <div class="title-box">
        <label style="">{{className}}</label>
        <label style=""> | {{teacher}}</label>
    </div>

    <div class="excel-box">
        <button style="margin-right: 2rem;" nz-button nzType="primary" [nzSize]="'default'"
                [nzLoading]="calReporting" [disabled]="pageType != 1"
                (click)="reCalcReportData()">重新计算</button>

        <button style="margin-right: 2rem;" nz-button nzType="primary" [nzSize]="'default'"
                [nzLoading]="downloading"
                (click)="downloadStatisticData()">excel 导出</button>

    </div>



    <!--<div style="position: absolute; right: 1rem;">-->
        <!--<button nz-button nzType="primary" style="margin-right: 1rem" (click)="setFilterRange(0)">本周</button>-->
        <!--<button nz-button nzType="primary" style="margin-right: 1rem" (click)="setFilterRange(1)">本月</button>-->
        <!--<button nz-button nzType="primary" style="margin-right: 1rem" (click)="setFilterRange(2)">本季</button>-->
    <!--</div>-->
</div>

<!--<div *ngIf="pageType == 3" class="report-rate-box" >-->

    <!--<div style="padding-top: .1rem;">时间范围：</div>-->
    <!--<nz-range-picker [(ngModel)]="dateRange"-->
                     <!--style="margin-right: 1rem;"-->
                     <!--(ngModelChange)="onRangeChange($event)"></nz-range-picker>-->
    <!--<button nz-button nzType="primary" style="margin-right: 2rem" (click)="filterRangeData()">查看</button>-->

    <!--&lt;!&ndash;<div class="excel-box">&ndash;&gt;-->

        <!--&lt;!&ndash;<button style="margin-right: 2rem;" nz-button nzType="primary" [nzSize]="'default'"&ndash;&gt;-->
                <!--&lt;!&ndash;[nzLoading]="downloading"&ndash;&gt;-->
                <!--&lt;!&ndash;(click)="downloadStatisticData()">excel 导出</button>&ndash;&gt;-->

    <!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->

<div class="table-operations" style="display: flex;flex-direction: row" >
    <!--<button nz-button nzType="primary" [nzSize]="'large'"-->
            <!--[nzLoading]="downloading"-->
            <!--(click)="downloadStatisticData()">下载数据</button>-->


    <ul nz-menu nzMode="horizontal">
        <li nz-menu-item nzSelected (click)="changeReportPage(0)">
            <!--<i nz-icon type="profile"></i>-->
            今日汇报
        </li>
        <li nz-menu-item (click)="changeReportPage(1)">
            <!--<i nz-icon type="profile"></i>-->
            周汇报
        </li>
        <li nz-menu-item (click)="changeReportPage(2)">
            <!--<i nz-icon type="profile"></i>-->
            月汇报
        </li>
        <li nz-menu-item (click)="changeReportPage(3)">
            自定义
        </li>
    </ul>


    <div *ngIf="pageType == 1" class="dropdown-date">
        <a nz-dropdown [nzDropdownMenu]="dateArrayMenu"> {{dateArray.length > 0 ? dateArray[dateArrayIndex].dateStr : ''}} <i nz-icon nzType="down"></i> </a>
        <nz-dropdown-menu #dateArrayMenu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
                <li *ngFor="let item of dateArray; let i = index"
                    nz-menu-item (click)="dropdownClick(i)" >
                    <a >{{item.dateStr}}</a>
                </li>
            </ul>
        </nz-dropdown-menu>

    </div>



    <div *ngIf="pageType == 0" class="report-rate-box">
        <div class="report-rate-left" >今日汇报完成比率：{{today_report_rate}}%</div>
        <div class="report-rate-right">今日分级完成比率：{{today_grading_rate}}%</div>
    </div>

    <div *ngIf="pageType == 1" class="report-rate-box">
        <div class="report-rate-left"  >周汇报率：{{weekRateData.report_rate || 0}}%</div>
        <div class="report-rate-center">分级周完成率：{{ weekRateData.grading_rate || 0}}%</div>
        <div class="report-rate-center">视频或音频周完成率：{{weekRateData.cam_show_rate || 0}}%</div>
        <div class="report-rate-center">周配音完成率：{{weekRateData.dubbing_rate || 0}}%</div>
        <div class="report-rate-right" >听音7小时比率：{{weekRateData.listen_fulfill_rate || 0}}%</div>
    </div>


    <div *ngIf="pageType == 3" class="report-rate-box" style="font-size: 15px;">

        <div style="padding-top: .1rem;">时间范围：</div>
        <nz-range-picker [(ngModel)]="dateRange"
                         style="margin-right: 1rem;"
                         (ngModelChange)="onRangeChange($event)"></nz-range-picker>
        <button nz-button nzType="primary" style="margin-right: 2rem" (click)="filterRangeData()">查看</button>

    </div>

</div>
<nz-table #classTable [nzData]="displayData"
          [nzLoading]="loading"
          [nzFrontPagination]="true"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          [nzTotal]="totalDataCount">
<!--    [nzPageSize]="10"-->
<!--    (nzPageIndexChange)="loadData($event)"-->
    <thead (nzSortChange)="sort($event)" nzSingleSort>
    <tr>
        <th>id</th>
        <th>姓名</th>
        <th nzShowSort nzSortKey="listen">听音</th>
        <th nzShowSort nzSortKey="animation">动画</th>
        <th nzShowSort nzSortKey="grading">分级作业</th>
        <th nzShowSort nzSortKey="read">课外</th>
        <th nzShowSort nzSortKey="dubbing">配音</th>
        <th nzShowSort nzSortKey="cam_show">视频秀</th>
        <th nzShowSort nzSortKey="lottery">奖券</th>

        <th style="text-align: center;">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <td>{{data.id}}</td>
        <td>{{data.name}}</td>
        <td>{{data.listen || 0}} </td>
        <td>{{data.animation || 0}} </td>
        <td>{{data.grading || 0}} </td>
        <td>{{data.read || 0}} </td>
        <td>{{data.dubbing || 0}} </td>
        <td>{{data.cam_show || 0}} </td>
        <td>{{data.lottery || 0}} </td>

        <td>
            <div class="operations">
                <a [routerLink]="'/admin/classes/' + classId + '/user/' + data.id + '/statistics'">详情</a>
            </div>
        </td>
    </tr>
    </tbody>
</nz-table>
