import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { SchoolService } from '../../../../../services/school.service';

@Component({
  selector: 'app-students-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class StudentsAddComponent implements OnInit {
  validateForm: UntypedFormGroup;
  isLoadingTeachers = false;
  teacherList: any[] = [];
  selectedTeacher = null;
  editUserId = null;
  classId = 0;
  returnPage = 1;
  mergePhoneConfirm = false;
  sureMergeVal = false;
  private lastPhoneError: any;
  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query['page'] && parseInt(query['page'], 10) === query['page'] * 1) {
        this.returnPage = parseInt(query['page'], 10);
      }
    });
    this.validateForm = this.fb.group({
      name: [ '', [ Validators.required ] ],
      birthday: [ ''],
      sex: [null],
      phone: ['', [ Validators.required, Validators.pattern(/^1[3456789]\d{9}$/) ], [ this.phoneAsyncValidator ] ],
    });
    this.activatedRoute.params.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        // @ts-ignore
        this.editUserId = parseInt(params['id'], 10);
      }
    });
  }
  phoneAsyncValidator = (control: UntypedFormControl) => new Observable((observer: Observer<ValidationErrors>) => {
    const data = {phone: control.value};
    if (this.editUserId) {
        // @ts-ignore
      data['id'] = this.editUserId;
    }
    this.userService.checkStudentExist(data).then(resp => {
      // console.log(99, resp);

      if (resp && resp.length) {
        this.mergePhoneConfirm = true;
        // this.lastPhoneError = ;
        const  _m = [];
        _m.push(`<p>已经存在相同电话号的学生</p>`);
        for (const u of resp) {
          _m.push(`<p><b>${u.name}</b>，<b>${u.school_name}</b> 班级：<b>${u.class_name}</b></p>`);
        }


        const message = _m.join('');
        observer.next({ error: true, dup_students: true, message });
      } else {
        // this.lastPhoneError = null;
        this.mergePhoneConfirm = false;
        // @ts-ignore
        observer.next(null);
      }
      observer.complete();
    }).catch(err => {
      console.log(err);
      observer.next({ error: true, dup_phone: true, message: err.message });
      observer.complete();
    });
    // setTimeout(() => {
    //   if (control.value === 'JasonWood') {
    //     observer.next({ error: true, duplicated: true });
    //   } else {
    //     observer.next(null);
    //   }
    //   observer.complete();
    // }, 1000);
  })
  ngOnInit() {
    if (this.editUserId) {
      this.userService.fetchStudent(this.editUserId).then(td => {
        this.validateForm.controls['name'].setValue(td.name);
        this.validateForm.controls['birthday'].setValue(td.birthday);
        this.validateForm.controls['sex'].setValue(td.sex + '');
        // this.teacherSexValue = td.sex + '';
        this.validateForm.controls['phone'].setValue(td.phone);
      });
    }
  }
  formatDate (d: any) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }
  checkPhone(phone: string) {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      return false;
    }
    return true;
  }
  // @ts-ignore
  submitForm = ($event, value) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }

    if (value.birthday) {
      value.birthday = this.formatDate(value.birthday);
    } else {
      delete value['birthday'];
    }
    if (this.editUserId) {
      this.userService.saveStudent(this.classId, this.editUserId, value).then( data => {
        this.router.navigate([`/admin/classes/${this.classId}/students/index`], {queryParams: { page: this.returnPage }});
      });
    } else {
      this.userService.addStudent(this.classId, value).then( data => {
        this.router.navigate([`/admin/classes/${this.classId}/students/index`], {queryParams: { page: this.returnPage }});
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
  }
  sureMergeFn(evt: any) {
    // @ts-ignore
    if (this.validateForm.controls.phone.errors) {
      // @ts-ignore
      this.lastPhoneError = this.validateForm.controls.phone.errors;
      // @ts-ignore
      this.validateForm.controls.phone.setErrors(null);
    } else {
      // @ts-ignore
      this.validateForm.controls.phone.setErrors(this.lastPhoneError);
      this.lastPhoneError = null;
    }

    console.log('sureMergeFn');
  }

}
