<div class="position-relative">
  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
    <input type="text" [value]="itemUrl" readonly nz-input placeholder="" />
  </nz-input-group>
  <ng-template #suffixIconButton>
    <nz-upload [nzAccept]="accept"
               [nzShowUploadList]="false"
               [nzBeforeUpload]="customUpload">
      <a nz-button nzType="primary" nzSearch>
        <i *ngIf="!uploading" nz-icon nzType="upload"></i>
        <i *ngIf="uploading" nz-icon nzType="loading"></i>
      </a>
    </nz-upload>
  </ng-template>
</div>
