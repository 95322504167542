<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'"
                [routerLink]="'/admin/elp-speaker/firmware/index'"
            >
            <!--(click)="goBack()"-->
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    </div>
    <nz-alert style="margin:20px 0" nzType="warning" nzMessage="不升级白名单用`00:00:00:00:00:00`占位符"></nz-alert>


    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm($event,validateForm.value)">
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>ota版本</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入ota版本">
                <input nz-input
                       formControlName="version" placeholder="ota版本，230609.1格式">
<!--                (ngModelChange)="onVersionChange($event)"-->
            </nz-form-control>
        </nz-form-item>
        <ng-container *ngIf="this.validateForm.controls['version'].value.length" >
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>ota.bin</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <app-upload-file
                            [itemUrl]="this.validateForm.controls['ota_url'].value"
                            endsWith="_ota.bin"
                            (itemUploaded)="otaBinUploaded($event)"
                            [path]="'/speaker/firmware/elp-v1/' + validateForm.controls['version'].value + '/'"
                            [name]="validateForm.controls['version'].value+'_ota'"
                    ></app-upload-file>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>ota checksum</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback>
                        <input type="text" nz-input formControlName="ota_checksum" #otaChecksumEl/>
                </nz-form-control>
                <a nz-button nzType="primary" (click)="changeValToHex(otaChecksumEl, 'ota_checksum')"><i nz-icon nzType="calculator"></i></a>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>data.bin</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <app-upload-file
                            [itemUrl]="this.validateForm.controls['res_url'].value"
                            endsWith="_data.bin"
                            (itemUploaded)="resBinUploaded($event)"
                            [path]="'/speaker/firmware/elp-v1/' + validateForm.controls['version'].value + '/'"
                            [name]="validateForm.controls['version'].value+'_data'"
                    ></app-upload-file>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" nzRequired>data checksum</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback>
                    <input type="text" nz-input formControlName="res_checksum" #resChecksumEl/>
                </nz-form-control>
                <a nz-button nzType="primary" (click)="changeValToHex(resChecksumEl, 'res_checksum')"><i nz-icon nzType="calculator"></i></a>
            </nz-form-item>


        <nz-form-item>
            <nz-form-label [nzSpan]="7" >版本说明</nz-form-label>
            <nz-form-control [nzSpan]="12" >
                <textarea formControlName="remark" nz-input rows="2" placeholder="版本说明"></textarea>
            </nz-form-control>
        </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" >白名单</nz-form-label>
                <nz-form-control [nzSpan]="12" >
                    <textarea formControlName="whitelist" nz-input rows="2" placeholder="白名单"></textarea>
                </nz-form-control>
            </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <button style="margin-right:1rem;" nz-button nzType="primary" [nzLoading]="isSaving" [disabled]="!validateForm.valid">Submit</button>
            </nz-form-control>
        </nz-form-item>
        </ng-container>
    </form>
</div>
