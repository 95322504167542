import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { StorybookService } from '../../../../../services/storybook.service';

@Component({
  selector: 'app-storybook-index',
  templateUrl: './storybook-index.component.html',
  styleUrls: ['./storybook-index.component.scss']
})
export class StorybookIndexComponent implements OnInit {
  // audio = new Audio();
  allChecked = false;
  disabledDeleteButton = true;
  checkedNumber = 0;
  displayData: any[] = [];
  operating = false;
  dataSet: any[] = [];
  indeterminate = false;
  pageIndex = 1;
  totalRow = 0;
  loading = false;
  constructor(
    private storybookService: StorybookService,
    private router: Router ,
    private nzMessageService: NzMessageService
  ) {
  }

  currentPageDataChange($event: any): void {
    this.displayData = $event;
  }

  refreshStatus(evt?:any): void {
    const allChecked = this.displayData.every((value: any) => value.checked === true);
    const allUnChecked = this.displayData.every((value: any) => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    this.disabledDeleteButton = !this.dataSet.some((value: any) => value.checked);
    this.checkedNumber = this.dataSet.filter((value: any) => value.checked).length;
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  batchDeleteData(): void {
    this.operating = true;
    const del_rows = this.dataSet.filter(value =>  {
      if (value.checked ===  true) {
        this.delete(value.id);
      }
    });
  }
  getPageData(page: any) {
    this.loading = true;
    this.storybookService.fetchPageData(page).then((data) => {
      this.totalRow = data.total;
      this.dataSet = data.rows;
      this.loading = false;
    }).catch((e) => {
      this.loading = false;
      this.nzMessageService.error('数据获取失败!');
    });
  }
  ngOnInit(): void {
    this.getPageData(1);
  }


  delete(id: any) {
    this.loading = true;
    this.storybookService.delete(id).then((data) => {
      this.getPageData(this.pageIndex);
      this.operating = false;
      this.disabledDeleteButton = true;
    }).catch((e) => {
      this.loading = false;
    });
  }
  edit(id: any) {
    this.router.navigate([`/admin/storybook/make/${id}`]);
  }
  preview(id: any) {
    this.router.navigate([`/admin/storybook/preview/${id}`]);
  }
}
