import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { OrderService } from '../../../../../../services/order.service';

@Component({
  selector: 'app-modal-order-info-component',
  template: `
    <div>
        <div *ngIf="!orderInfo" style="margin: 0 auto;text-align: center">
            <nz-spin nzSimple></nz-spin>
        </div>
        <div *ngIf="orderInfo">
            <div>
                {{orderInfo.subsidy_desc}}
            </div>
            <div *ngFor="let pic of orderInfo.subsidy_info.pics; let i = index" style="text-align: center;">
                <img [src]="pic.url" alt="">
                <nz-divider *ngIf="i !== orderInfo.subsidy_info.pics.length - 1"></nz-divider>
            </div>
            
        </div>
    </div>
    
    
  `
})
export class OrderInfoComponent implements OnInit {
  // @ts-ignore
  @Input() id;
  isVisible = false;
  orderInfo = null;
  reason = '';
  constructor(private modal: NzModalRef, private orderService: OrderService) {

  }
  ngOnInit(): void {
    this.orderService.getOrderInfo(this.id).then(order => {
      console.log(order);
      this.orderInfo = order;
      // const m = this.modal.getInstance();
      // m.nzContent = 'sssss';
    });
  }
  handleOk(): void {
    this.modal.destroy({ data: this.reason });
  }
}
