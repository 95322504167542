import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class TabletService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  import(data: any) {
    return super.post(`/tablet/mac/import`, data);
  }
  list(query: {pageIndex: number, pageSize?: number, mac?: string}) {
    const searchParams = new URLSearchParams();
    // @ts-ignore
    Object.keys(query).forEach(key => searchParams.append(key, query[key]));
    return super.get(`/tablet/mac/list?${searchParams.toString()}`);
  }
  sync(mac: string) {
    mac = mac.trim();
    return super.post(`/tablet/mac/sync`, {mac});
  }
  reset(mac: string) {
    mac = mac.trim();
    return super.post(`/tablet/mac/reset`, {mac});
  }
  search(query: {key: string, value: string}) {
    const searchParams = new URLSearchParams();
    // @ts-ignore
    Object.keys(query).forEach(key => searchParams.append(key, query[key]));
    return super.get(`/tablet/mac/search?${searchParams.toString()}`);
  }
  addContract({mac, contract, phone, id}: {mac: string, contract: string, phone: string, id: number}) {
    return super.post(`/tablet/mac/add/contract`, {
      mac, contract, phone, id
    });
  }

  singleSystemList(query: {pageIndex: number, pageSize?: number, sn?: string, order?: string, way?: string}) {
    const searchParams = new URLSearchParams();
    // @ts-ignore
    Object.keys(query).forEach(key => searchParams.append(key, query[key]));
    return super.get(`/tablet/single-system/list?${searchParams.toString()}`);
  }

  setSingleSystemExpired({sn, expired_at, price, remark}: {sn: string, expired_at: string, price: number, remark?: string}) {
    return super.post(`/tablet/single-system/update`, {
      sn, expired_at, price, remark
    });
  }












}
