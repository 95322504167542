import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SystemService } from '../../../../../services/system.service';
import { UserService } from '../../../../../services/user.service';


@Component({
  selector: 'app-admin-search-index',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class AdminSearchComponent implements OnInit {
  rows: any = [];
  inputPhone = '';
  currentType = '';
  opRemoveStus = {};
  opUserMapList = {};
  constructor(private sysSrv: SystemService,
              private userSrv: UserService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }

  ngOnInit() {


  }
  doSearch(type: any) {
    this.inputPhone = this.inputPhone.trim();
    if (!this.inputPhone) {
      return;
    }
    this.currentType = type;
    this.opUserMapList = {};
    this.opRemoveStus = {};
    if (type === 'phone') {
      this.sysSrv.searchUserByPhone(this.inputPhone).then(r => {
        // const sameId = r.every( s => s.type === '学生');
        if (r.length > 1) {
          r.forEach((s: any) => {
            // @ts-ignore
            if (!this.opRemoveStus[s.uid]) {
              // @ts-ignore
              this.opRemoveStus[s.uid] = 0;
            }
            // @ts-ignore
            this.opRemoveStus[s.uid] += 1;
            // @ts-ignore
            if (!this.opUserMapList[s.uid]) {
              // @ts-ignore
              this.opUserMapList[s.uid] = [];
            }
            // @ts-ignore
            if (this.opRemoveStus[s.uid] > 1) {
              // @ts-ignore
              this.opUserMapList[s.uid].push({
                title: '解除班级关系',
                fn: this.removeUserFromClass.bind(this, s.class_id, s.uid),
                callback: this.doSearch.bind(this, this.currentType)
              });

            }
          });

        }

        this.rows = r;
        console.log(r);
      });
    } else if (type === 'school') {
      this.sysSrv.searchUserSchoolByName(this.inputPhone).then(r => {
        this.rows = r;
        console.log(r);
      });
    }

  }
  removeUserFromClass(class_id: any, user_id: any) {
    this.userSrv.removeUserFromClass(class_id, user_id).then(r => {
      this.doSearch(this.currentType);
    });
  }
  opUser(op: any) {
    console.log(33);
    // @ts-ignore
    op.fn().then(r => {
      op.callback();
    });
  }
}
