import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user.service';
import {PermissionService} from '../services/permission.service';

@Injectable()
export class UserAuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private permission: PermissionService,
    private router: Router) {}

  canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('check user ', this.userService.isValidUser(router));

    if (this.userService.isValidUser(router) ) {
      const m = this.permission.filterMenu({url: state.url});
      if (!m) {
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
  }
}
