import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GroupsService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  fetchAll() {
    return super.get(`/v2/groups`).then((resp) => {
      return resp;
    });
  }
  fetchLevels(groupId: string|number, type: string|number) {
    return super.get(`/v2/${groupId}/${type}`).then((resp) => {
      return resp;
    });
  }

}
