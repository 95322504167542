<!--
<ng-container *ngIf="promoTitle">
    <i nz-icon nzType="fire" nzTheme="fill"></i>{{promoDesc}}
    <nz-divider nzText="{{promoTitle}}" nzOrientation="left"></nz-divider>
</ng-container>
<ng-container *ngIf="!promoTitle">
    <i nz-icon nzType="fire" nzTheme="fill"></i>app名额购买
    <nz-divider nzText="名额购买" nzOrientation="left"></nz-divider>
</ng-container>
-->
<nz-alert *ngIf="promoTitle"
        nzShowIcon
        nzIconType="fire"
        nzType="success"
        [nzMessage]="promoTitle"
        [nzDescription]="promoDesc"
>
</nz-alert>

<app-wx-payment #WXPaymentElement
                orderUrl="/orders/processing"
                checkOrderUrl="/orders/check/status"
                [paySuccessCallback]="paySuccessCallback"
                [currentPrice]="calcPrice"
                [orderData]="{quantity: this.inputQuantity}"
                [checkoutConfirmMsg]="checkoutConfirmMsg"></app-wx-payment>
<div>
    <div style="margin: 0 auto;width: 450px;" >
        <div nz-row>
            <div nz-col [nzSpan]="4" class="ant-form-item-label">
                <label>购买数量</label>
            </div>
            <div nz-col [nzSpan]=" 14" class="ant-form-item-control">
                <nz-input-number
                  #quotaElement
                        [(ngModel)]="inputQuantity"
                        [nzMin]="1"
                        [nzMax]="1000"
                  (ngModelChange)="onNumberChange($event)"
                        [nzStep]="1"></nz-input-number>
                <!--<nz-input-number-->
                  <!--#quotaElement-->
                  <!--style="width: 150px"-->
                  <!--nz-input-->
                  <!--[maxLength]="4"-->
                  <!--[minLength]="1"-->
                  <!--[ngModel]="inputQuantity"-->
                  <!--(ngModelChange)="onNumberChange($event)"-->
                <!--/>-->
                <!--<nz-input-number-->
                  <!--#quotaElement-->
                        <!--style="width: 150px"-->
                        <!--(ngModelChange)="onNumberChange($event)"-->
                        <!--[(ngModel)]="inputQuantity"-->
                        <!--[nzMin]="1" [nzStep]="1"></nz-input-number>-->

                <!--<input-->
                  <!--#quotaElement-->
                  <!--style="width: 150px"-->
                  <!--nz-input-->
                  <!--[ngModel]="inputQuantity"-->
                  <!--placeholder="Input a number"-->
                  <!--(ngModelChange)="onNumberChange($event)"-->
                <!--/>-->
            </div>
        </div>
        <div nz-row>
            <div nz-col [nzSpan]="4" class="ant-form-item-label">
                <label>单价</label>
            </div>
            <div nz-col [nzSpan]=" 14" class="ant-form-item-control">
                <span style="width: 150px;display: inline-block">
                    <!--<del *ngIf="originUnitPrice != currentUnitPrice" style="margin-right: .2rem;">{{originUnitPrice}}元</del>-->
                    <span nz-text nzType="warning" style="font-size:1.2rem;font-weight: 500;">{{currentUnitPrice}}元</span>
                </span>
                <span style="display: inline-block" nz-text nzType="danger" *ngIf="promoRate">{{promoRate}}折优惠中</span>
            </div>
        </div>
        <div nz-row>
            <div nz-col [nzSpan]="4" class="ant-form-item-label">
                <label>时长</label>
            </div>
            <div nz-col [nzSpan]=" 14" class="ant-form-item-control">
                <span style="width: 150px;display: inline-block">
                    <span nz-text nzType="warning" style="font-size:1.2rem;font-weight: 500;">{{accountDays}}天</span>
                </span>
            </div>
        </div>
        <div nz-row>
            <div nz-col [nzSpan]="4" class="ant-form-item-label">
                <label>总价</label>
            </div>
            <div nz-col [nzSpan]=" 14" class="ant-form-item-control">
                <span style="width: 150px;display: inline-block">{{calcPrice}}</span>
                <p style="min-height: 22px;
                        margin-top: -2px;
                        color: rgba(0,0,0,.45);
                        font-size: 14px;
                        line-height: 1.5;
                        transition: color .3s cubic-bezier(.215,.61,.355,1);"   *ngIf="giftAccounts > 0">赠送{{giftAccounts}}帐号，共计 {{giftAccounts + inputQuantity}}名额</p>
            </div>
        </div>
        <!--
        <div nz-row>
            <div nz-col [nzSpan]="4" class="ant-form-item-label">
                <label>申请补贴</label>
            </div>
            <div nz-col [nzSpan]=" 14" class="ant-form-item-control">
                <label nz-checkbox [(ngModel)]="has_subsidy">是否申请补贴</label>
            </div>
        </div>
        <div nz-row *ngIf="has_subsidy">
            <div nz-col [nzSpan]="4" class="ant-form-item-label">
                <label>补贴证明</label>
            </div>
            <div nz-col [nzSpan]="18" class="ant-form-item-control">
                <div nz-row>
                    <textarea style="margin-bottom: 1rem" rows="4" nz-input [(ngModel)]="subsidyDesc"></textarea>
                    <div  class="preview-list" >
                        <div nz-col [nzSpan]="8" class="preview-list-item" *ngFor="let subsidyItem of subsidyPicList; let i = index;">
                            <div class="sub-item" [ngStyle]="{'background-image': 'url('+subsidyItem.url+')'}"></div>
                            <div class="upload-list-item-info">
                            <span class="ant-upload-list-item-actions actions" style="">
                                <i nz-icon nzType="eye" nzTheme="outline" (click)="previewImage(subsidyItem)"></i>
                                <i nz-icon nzType="delete" nzTheme="outline" (click)="deletePreviewImage(i)"></i>
                            </span>
                            </div>
                        </div>

                        <app-upload-image-with-preview
                                *ngIf="subsidyPicList.length < 20"
                                [picPreview]="false"
                                style="width: 100px;display: inline-block;"
                                [displayText]="null"
                                [picUrl]="tmpPicUrl"
                                (imageUploaded)="onImageUploaded($event)"
                                displayIcon="plus"
                                displayRatioWidth="1"
                                displayRatioHeight="1"></app-upload-image-with-preview>
                    </div>
                    <nz-modal
                            [nzVisible]="previewVisible"
                            [nzContent]="modalContent"
                            [nzFooter]="null"
                            (nzOnCancel)="previewVisible = false"
                    >
                    <ng-template #modalContent>
                        <img [src]="previewImgUrl" [ngStyle]="{ width: '100%' }" />
                    </ng-template>
                    </nz-modal>
                </div>
            </div>
        </div>
        -->
        <div nz-row>
            <div nz-col [nzSpan]="14" [nzOffset]="4">
                <button [disabled]="!(inputQuantity > 0)"  [nzLoading]="calcing" nz-button nzType="primary" (click)="checkout()">购买</button>

            </div>
        </div>
    </div>
    <!--
    <form nz-form
            [nzLayout]="'horizontal'"
            [formGroup]="giftForm"
            (ngSubmit)="submitForm()" >
        <nz-form-item style="margin: 0">
            <nz-form-label [nzSpan]="4">购买数量</nz-form-label>
            <nz-form-control [nzSpan]=" 14" nzErrorTip="请正确输入购买数量!">
                <nz-input-number
                        #quotaElement
                        style="width:150px;"
                         formControlName="quota"
                         (keydown)="onNumberChange($event)"
                         [nzMin]="1" [nzStep]="1"
                         placeholder="输入购买数量"></nz-input-number>



            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-control [nzSpan]="14" [nzOffset]="4">

            </nz-form-control>
        </nz-form-item>
    </form>
    -->
</div>
