<div class="table-operations" >
    <button nz-button nzType="primary" [nzSize]="'large'"
            (click)="backToClassUsers()">
        <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
        <div class="report-rate-box" style="display: inline-block;float: right;">

            <div style="padding-top: .1rem;">时间范围：</div>
            <nz-range-picker [(ngModel)]="dateRange"
                             style="margin-right: 1rem;"></nz-range-picker>
            <button style="margin-right: 2rem;" nz-button nzType="primary" [nzSize]="'default'"
                    [nzLoading]="downloading"
                    (click)="downloadUserStatisticData()">excel 导出</button>

        </div>
</div>
<h4>{{studentInfo && studentInfo.name}}统计信息</h4>
<nz-table #classTable [nzData]="displayData"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalDataCount">
    <thead>
    <tr>
        <th>id</th>
        <th>日期</th>
        <th>听音</th>
        <th>动画</th>
        <th>课外</th>
        <th>分级作业</th>
        <th>视频</th>
        <th>配音</th>
        <th>奖券</th>
        <!--<th>操作</th>-->
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <td>{{data.id}}</td>
        <td>{{data.date}}</td>
        <td>{{data.listen}} </td>
        <td>{{data.animation}} </td>
        <td>{{data.read}} </td>
        <td>{{data.grading}} </td>
        <td>{{data.cam_show}} </td>
        <td>{{data.dubbing}} </td>
        <td>{{data.lottery}} </td>
        <!--<td>-->
            <!--<div class="operations">-->
                <!--<a>详情</a>-->
            <!--</div>-->
        <!--</td>-->
    </tr>
    </tbody>
</nz-table>
