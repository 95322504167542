<nz-modal
        [(nzVisible)]="checkoutConfirm"
        nzTitle="确认购买"
        nzOkText="确定"
        nzCancelText="返回"
        nzMaskClosable="false"
        nzClosable="false"
        [nzOkLoading]="orderProcessing"
        [nzCancelDisabled]="orderProcessing"
        (nzOnCancel)="handleConfirmCancel()"
        (nzOnOk)="handleConfirmOk()">
    <ng-container *nzModalContent>
    <div class="confirmMessage">{{checkoutConfirmMsg}}</div>
    <p><span style="font-size: 24px;">总计消费</span> <span style="font-size: 16px;"> {{this.currentPrice}}</span> </p>
    </ng-container>
</nz-modal>
<nz-modal
        [(nzVisible)]="wxpayInfo"
        nzTitle="扫码支付"
        nzOkText="确定"
        nzCancelText="返回"
        nzClosable="false"
        nzMaskClosable="false"
        [nzFooter]="modalPayFooter">
    <ng-container *nzModalContent>
    <div style="text-align:center">
        <p>订单：{{wxpayInfo ? wxpayInfo.prepay_id : ''}}</p>
        <p style="color:#f50">仅支持微信扫码支付，请在两分钟内购买，否则订单将失效</p>
        <qrcode *ngIf="wxpayInfo"
                id="qrcode"
                [qrdata]="wxpayInfo ? wxpayInfo.code_url : ''"
                [errorCorrectionLevel]="'H'"
                [width]="400"
        ></qrcode>
        <p style="font-size: 16px;margin-top: 16px">总计消费 <b>{{this.currentPrice}}</b></p>
    </div>
    </ng-container>
    <ng-template #modalPayFooter>
        <button nz-button nzType="default" (click)="closePayModal()">关闭</button>
    </ng-template>
</nz-modal>
<!--<button nz-button nzType="primary" (click)="checkout()">购买</button>-->
