import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { SchoolService } from '../../../../../services/school.service';

@Component({
  selector: 'app-schools-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class SchoolsAddComponent implements OnInit {
  validateForm: UntypedFormGroup;

  editSchoolId = null;
  returnListPage = 1;


  allConfigChecked = false;
  configIndeterminate = true;
  checkConfigOptions = [
    { label: '允许查看绘本', value: 'books', checked: true  }
  ];

  appConfig = {
    splash_url: '',
    logo_url: '',
    bg_url: '',
    qrcode_url: ''
  };

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    console.log(2222);
    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      console.log(e[0].urlAfterRedirects); // previous url
    });
    this.validateForm = this.fb.group({
      school_name: [ '', [ Validators.required ] ],
      name: [ '', [ Validators.required ] ],
      sex: [null],
      phone: ['', [ Validators.required, Validators.pattern(/^1[3456789]\d{9}$/) ], [ this.phoneAsyncValidator ] ],
      config: [],
      about: [ '', [ Validators.required ] ],
      address: [ '', [ Validators.required ] ],
      customized:  [null]
    });
    // @ts-ignore
    this.validateForm.controls.customized.valueChanges.subscribe((val) => {
      if (val) {
        this.validateForm.get('about')!.setValidators(Validators.required);
        this.validateForm.get('about')!.markAsDirty();
        this.validateForm.get('about')!.updateValueAndValidity();

        this.validateForm.get('address')!.setValidators(Validators.required);
        this.validateForm.get('address')!.markAsDirty();
        this.validateForm.get('about')!.updateValueAndValidity();

      } else {
        this.validateForm.get('about')!.clearValidators();
        this.validateForm.get('about')!.markAsPristine();
        this.validateForm.get('about')!.updateValueAndValidity();

        this.validateForm.get('address')!.clearValidators();
        this.validateForm.get('address')!.markAsPristine();
        this.validateForm.get('address')!.updateValueAndValidity();
      }
    });
    this.activatedRoute.params.subscribe( (params: any) => {
      if (params.id && parseInt(params.id, 10) === params.id * 1) {
        // @ts-ignore
        this.editSchoolId = parseInt(params.id, 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.page && parseInt(query.page, 10) === query.page * 1) {
        this.returnListPage = parseInt(query.page, 10);
      }
    });
  }
  phoneAsyncValidator = (control: UntypedFormControl) => new Observable((observer: Observer<ValidationErrors>) => {
    const data = {phone: control.value};
    if (this.editSchoolId) {
      // @ts-ignore
      data['id'] = this.editSchoolId;
    }
    // console.log('check 1');
    this.schoolService.checkSchoolExist(data).then(resp => {
      // console.log('check 1', resp);
      if (resp && resp.length) {
        observer.next({ error: true, duplicated: true });
      } else {
        // @ts-ignore
        observer.next(null);
      }
      observer.complete();
    });
    // setTimeout(() => {
    //   if (control.value === 'JasonWood') {
    //     observer.next({ error: true, duplicated: true });
    //   } else {
    //     observer.next(null);
    //   }
    //   observer.complete();
    // }, 1000);
  })
  ngOnInit() {
    if (this.editSchoolId) {
      this.userService.fetchSchoolAndAdmin(this.editSchoolId).then(td => {
        this.validateForm.controls['school_name'].setValue(td.school_name);
        this.validateForm.controls['name'].setValue(td.name);
        // this.validateForm.controls['birthday'].setValue(td.birthday);
        this.validateForm.controls['sex'].setValue(td.sex + '');
        // this.teacherSexValue = td.sex + '';
        this.validateForm.controls['phone'].setValue(td.phone);
        // this.validateForm.controls['config'].setValue(td.phone);
        if (!td.school_config) {
          td.school_config = [];
        }
        td.school_config.forEach((c: any) => {
          const item = this.checkConfigOptions.find(hc => {
              return c.value === hc.value;
          }) as any;
          item.checked = c.checked;
        });
        // this.checkConfigOptions = [
        //   { label: '允许查看绘本', value: 'books', checked: false }
        // ];


        this.validateForm.controls['customized'].setValue(td.customized);
        this.validateForm.controls['address'].setValue(td.address);
        this.validateForm.controls['about'].setValue(td.about);
        this.appConfig = td.app_config || {};
      });
      // @ts-ignore
      window['form'] = this.validateForm;
    }
  }
  formatDate (d: Date|string) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }
  checkPhone(phone: string) {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      return false;
    }
    return true;
  }
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
    value.appConfig = this.appConfig;
    if (this.editSchoolId) {
      this.userService.saveSchool(this.editSchoolId, value).then( data => {
        this.router.navigate(['/admin/schools/index']);
      });
    } else {
      this.userService.addSchool(value).then( data => {
        this.router.navigate(['/admin/schools/index']);
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
  }

  onSplashUploadSuccess (evt: any) {
    this.appConfig.splash_url = evt.url;
  }
  onLogoUploadSuccess (evt: any) {
    this.appConfig.logo_url = evt.url;
  }
  onQrcodeUploadSuccess (evt: any) {
    this.appConfig.qrcode_url = evt.url;
  }
  onBgUploadSuccess (evt: any) {
    this.appConfig.bg_url = evt.url;
  }

}
