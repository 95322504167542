import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PermissionService extends BaseService {
  user = null;

  constructor(protected override http: HttpClient, private userService: UserService, protected override router: Router) {
    super(http, router);
  }

  filterMenu(node: any) {
    const links = ['/admin/books', '/user/books'];
    // console.log( node.url);
    if ( node.url === '/login') {
      return true;
    }
    const user = this.userService.getUserInfo();
    if (!user) {
      return false;
    }
    if (!user.school_config) {
      user.school_config = [];
    }
    // const cfg = user.school_config.find(c => {
    //   return c.value === 'books';
    // });
    const cfg = true;
    if (cfg) {
      // return cfg.checked;
      if (links.includes(node.url)) {
        // return cfg.checked;
        return true;
      }
    } else {
      if (links.includes(node.url)) {
        return false;
      }
    }
    return true;
  }



}
