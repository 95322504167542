import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LevelsService } from '../../../../services/levels.service';
import { GroupsService } from '../../../../services/groups.service';
import { StorybookService } from '../../../../services/storybook.service';


@Component({
  selector: 'app-user-index',
  templateUrl: './user-books.component.html',
  styleUrls: ['./user-books.component.scss'],
})
export class UserBooksComponent implements OnInit {
  user: any;
  schoolName = '';
  userName = '';
  classes = [];
  pageIndex = 1;
  totalRow = 0;
  currentClassId = null;
  currentGroupId = '';
  currentLevelId = null;

  allGroups: any[] = [];
  allLevels: any[] = [];

  throttle = 300;
  scrollDistance = 1;

  currentLevelBooksPage = 1;
  books: any[] = [];
  loading = false;

  sWord = '';

  viewType = 'classes';

  searchView = false;

  loadingNewBooks = false;
  booksCache = {};
  currentBooksPageNum = null;
  constructor(
    private modalService: NzModalService,
    private bookService: StorybookService,
    private userService: UserService,
    private levelService: LevelsService,
    private groupService: GroupsService,
    private activatedRoute: ActivatedRoute ,
    private router: Router ,
    private nzMessageService: NzMessageService,
  ) {
  }
  displayData: Array<{ name: string; phone: string; status: string }> = [];
  dataSet = [];

  currentPageDataChange($event: Array<{ name: string; phone: string; status: string }>): void {
    this.displayData = $event;
  }





  ngOnInit(): void {

    // check if student ,viewTyoe always books
    // @ts-ignore
    this.viewType = this.activatedRoute.snapshot.data.view;

    const info = this.userService.getUserInfo();
    this.schoolName = info.school_name;
    this.userName = info.name;
    this.loading = true;
    this.groupService.fetchAll().then(data => {
      console.log('this.groupService.fetchAl');
      this.allGroups = data;

      if (data[0]) {
        this.currentGroupId = `${data[0].id}|${data[0].type}`;
        this.getLevelsBooks();
        /*this.groupService.fetchLevels(data[0].id, data[0].type ).then(lvls => {
          this.allLevels = lvls;
          if (lvls[0]) {
            this.chooseLevel( lvls[0].id);
          }

        });*/
      }

      /*this.allLevels = data.levels;
      if (data.groups[0]) {
        this.currentGroupId = data.groups[0].id;
        this.chooseLevel( 0);
      }*/
    });


  }
  chooseLevel( lvl: any) {
    this.searchView = false;
    this.books = [];
    this.currentLevelId = lvl.id;
    this.currentLevelBooksPage = 1;
    const gid_type = this.currentGroupId.split('|');
    this.loading = true;

    let fn = 'fetchBooksByGroupAndLevel';
    // let groupId = gid_type[0];
    let  arg1 = this.currentLevelId;
    if (gid_type[1] === 'cartoon_block') {
      fn = 'fetchBooksByBlock';
      arg1 = lvl.name;
    }
    // fetchBooksByBlock
    // @ts-ignore
    this.bookService[fn](arg1, this.currentLevelBooksPage).then( data => {
      this.books = data.rows;
      this.currentBooksPageNum = data.pageNum ;
      this.loading = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      this.loading = false;
    });
  }
  getLevelsBooks() {
    const gid_type = this.currentGroupId.split('|');

    this.loading = true;
    this.groupService.fetchLevels(gid_type[0], gid_type[1]).then(lvls => {
      this.allLevels = lvls;
      const item = this.allGroups.find(el => {
        // @ts-ignore
        return +el.id === +gid_type[0];
      });
      if (lvls[0]) {
        this.chooseLevel( lvls[0]);
      } else if (item) {
        // @ts-ignore
        this.chooseLevel( {id: item.id, name: item.name});
      } else {

        this.books = [];
        this.loading = false;
      }

    });
  }
  changeGroup(evt: any) {
    this.currentGroupId = evt;
    const gid_type = evt.split('|');

    this.books = [];
    // console.log('changeGroup', evt);
    this.currentLevelBooksPage = 1;
    /*
    let fn = 'fetchLevels';
    let groupId = gid_type[0];
    if (gid_type[1] === 'cartoon_block') {
      fn = 'fetchBooksByBlock';
      groupId = 'cartoon_block';
    }
    this.groupService[fn](groupId, this.currentLevelBooksPage).then(lvls => {
      this.allLevels = lvls;
      if (lvls[0]) {
        this.chooseLevel( lvls[0]);
      } else {
        this.books = [];
      }
    });*/
    this.getLevelsBooks();

    // this.chooseLevel(0);
  }
  changeClass(evt: any, class_id: any) {
    this.currentClassId = class_id;
    this.showClassNumber(0);
  }
  showClassNumber(evt: any) {
    if (!this.currentClassId) {
      return;
    }
    const page = evt + 1;
    this.userService.fetchClassMember(this.currentClassId, page).then(data => {
      this.totalRow = data.total;
      this.dataSet = data.rows;
    });
  }
  changeView(type: any) {
    // console.log(type);
    this.viewType = type;

  }
  onScrollDown(evt: any) {
    if (this.searchView) {
      return;
    }
    // console.log(1, 'scrolled down!!', this.currentBooksPageNum , this.currentLevelBooksPage);
    if (this.currentBooksPageNum === this.currentLevelBooksPage) {
      return ;
    }
    this.loadingNewBooks = true;
    this.currentLevelBooksPage += 1;
    // @ts-ignore
    this.bookService.fetchBooksByGroupAndLevel(this.currentLevelId, this.currentLevelBooksPage).then( data => {
      // console.log(222, data);
      // @ts-ignore
      this.books.push(...data.rows)  ;
      this.currentBooksPageNum = data.pageNum ;
      this.loadingNewBooks = false;
      // console.log(2, 'scrolled down!!', this.currentBooksPageNum , this.currentLevelBooksPage);
    }).catch(err => {
      this.nzMessageService.error(err.message);
    });
  }
  searchBooks(evt: any) {
    // console.log('searchBooks');
    if (evt.type === 'click' || (evt.type === 'keyup' && evt.keyCode === 13)) {
      const word = this.sWord.trim();
      if (!word) {
        return;
      }
      this.searchView = true;
      this.bookService.searchByTitle(word).then(data => {
        this.books = data.rows;
      });
    }
  }
  openBook (book_id: any) {
    // this.router.navigate([`/storybook/${book_id}`]);
    window.open(`/#/storybook/${book_id}`, '_blank');
  }

  showBookResource(book: any) {
    const video = book.videos.length;
    const audios = book.audios.length;
    const quiz = book.quiz.length;
    if (video || audios || quiz) {
      // const url = this.router.createUrlTree([`/user/books/${book.storybook_id}/resource`]);
      // window.open('/#' + url.toString(), '_blank');
      // this.router.navigate([`/user/books/${book.storybook_id}/resource`]);
      window.open(`/#/user/books/${book.storybook_id}/resource`, '_blank');

    } else {
      this.emptyResourceNotice();
    }

  }
  emptyResourceNotice() {
    this.modalService.info({
      nzTitle: '提示',
      nzContent: '<p>该绘本下暂无音视频资源</p>',
      nzOnOk: () => console.log('Info OK')
    });
  }
}
