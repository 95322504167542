import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StorybookService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  fetchBooksByGroupAndLevel( level_id: string|number, page: string|number) {
    // return super.get(`/groups/${group_id}/levels/${level_id}/storybooks/page/${page}`).then((resp) => {
    //   return resp;
    // });

    return super.get(`/levels/${level_id}/courseware/page/${page}`).then((resp) => {
      return resp;
    });
  }
  fetchBooksByBlock(block: string|number, page: string|number) {

    return super.get(`/cartoonblock/courseware/page/${page}?block=${block}`).then((resp) => {
      return resp;
    });
  }
  searchByTitle(word: string|number) {
    word = encodeURIComponent(word);
    // return super.get(`/storybooks/search/${word}`).then(resp => {
    //   return resp;
    // });
    return super.get(`/courseware/search?kwds=${word}&page=all`).then(resp => {
      return resp;
    });
  }
  addResource(data: any) {
    return super.post(`/storybooks/add-resource`, data).then((resp) => {
      return resp;
    });
  }
  save(id: string|number, content: any) {
    return super.post(`/storybooks/save`, {id, content}).then((resp) => {
      return resp;
    });
  }
  fetch(id: string|number) {
      // return super.get(`/storybooks/${id}`).then((resp) => {
      //   return resp;
      // });
    return super.get(`/courseware/${id}`).then((resp) => {
      return resp;
    });
  }
  fetchBookResource(id: string|number) {
    // return super.get(`/storybooks/${id}`).then((resp) => {
    //   return resp;
    // });
    return super.get(`/courseware/resource/${id}`).then((resp) => {
      return resp;
    });
  }
  fetchPageData(page: string|number) {
    if (!page) {
      page = 1;
    }
      return super.get(`/storybooks/page/${page}`).then((resp) => {
        return resp;
      });
  }
  delete (id: string|number) {
    return super.remove(`/storybooks/${id}`).then((resp) => {
      return resp;
    });
  }
}
