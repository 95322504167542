import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ElpService } from '../../../../../../../services/elp.service';

@Component({
  selector: 'app-elp-firmware-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ElpFirmwareAddComponent implements OnInit, OnDestroy {
  validateForm: UntypedFormGroup;
  otaVersion = '';
  hasVersionNumber = false;
  otaBinUrl = '';
  resBinUrl = '';
  // @ts-ignore
  @ViewChild('otaChecksumEl', {static: true}) otaChecksumEl: ElementRef;

  isSaving = false;


  constructor(private fb: UntypedFormBuilder,
              private elpFirmwareService: ElpService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.validateForm = this.fb.group({
      version: [ '', [ Validators.required ] ],
      // ota_version: [ '', [ Validators.required ] ],
      // res_version: [ '', [ Validators.required ] ],
      ota_url   : [ '', [ Validators.required ] ],
      res_url   : [ '', [ Validators.required ] ],
      ota_checksum   : [ '', [ Validators.required ] ],
      res_checksum   : [ '', [ Validators.required ] ],
      remark   : [ '' ],
      whitelist   : [ '' ],
    });
    // this.activatedRoute.params.subscribe( params => {
    //   if (params.id && parseInt(params.id, 10) === params.id * 1) {
    //     this.editClassId = parseInt(params.id, 10);
    //   }
    // });
    // this.activatedRoute.queryParams.subscribe( query => {
    //   if (query.page && parseInt(query.page, 10) === query.page * 1) {
    //     this.returnPage = parseInt(query.page, 10);
    //   }
    // });
  }
  ngOnDestroy() {
    // this.suber.unsubscribe();
  }
  ngOnInit() {
    // this.suber = this.router.events
    //   .pipe(filter((e: any) => e instanceof RoutesRecognized),
    //     pairwise()
    //   ).subscribe((e: any) => {
    //   this.prevUrl = e[0].urlAfterRedirects; // previous url
    // });

    // this.suber = this.router.events
    //   .subscribe((event) => {
    //     if (event instanceof NavigationStart) {
    //       console.log('previousUrl', this.router.url);
    //     }
    //   });

    // const info = this.userService.getUserInfo();
    // this.schoolService.fetchAllTeachersBySchool().then(data => {
    //   data.map(r => {
    //     r.display_name = `${r.name} | ${r.phone}`;
    //   });
    //   this.teacherList = data;
    //   if (this.editClassId) {
    //     this.clsService.fetchClass(this.editClassId).then(td => {
    //       this.validateForm.controls['name'].setValue(td.name);
    //       this.validateForm.controls['teacher_id'].setValue(td.teacher_id);
    //       this.validateForm.controls['comment'].setValue(td.comment);
    //     });
    //   }
    // });


  }

  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
    value.whitelist = value.whitelist.split(/[| ,，  ]+/)
      .map((m: string) => m.trim())
      .filter((m: string) => !!m)
      .join(', ');
    console.log(value);
    if (!this.validateForm.valid) {
      return;
    }
    this.isSaving = true;
    this.elpFirmwareService.addElpFirmware(value).then( data => {
      this.isSaving = false;
      this.router.navigate(['/admin/elp-speaker/firmware/index']);
    }).catch((e: any) => {
      this.isSaving = false;
      const msg = e.message || 'Something wrong';
      this.nzMessageService.error(`发生错误：${msg}`);

    });

    // if (this.editClassId) {
    //   this.schoolService.saveClass(this.editClassId, value).then( data => {
    //     this.router.navigate(['/admin/classes/index'], {queryParams: { page: this.returnPage }});
    //   });
    // } else {
    //   this.schoolService.addClass(value).then( data => {
    //     this.router.navigate(['/admin/classes/index'], {queryParams: { page: this.returnPage }});
    //   });
    // }
  }

  // onVersionChange(value: string) {
  //   console.log('onVersionChange', value);
  //   // if (this.validateForm.controls['ota_version'].value == value) {
  //   //   return;
  //   // }
  //   // this.hasVersionNumber = value.length > 0;
  //   // this.otaVersion = value;
  //   // if (value.length === 0) {
  //   //   this.validateForm.reset();
  //   //   return;
  //   // }
  //   // this.validateForm.controls['ota_version'].setValue(value);
  //   // this.validateForm.controls['version'].setValue(value);
  // }


  otaBinUploaded(evt: any) {
    // console.log('otaBinUploaded', evt);
    this.otaBinUrl = evt.url.replace('https://', 'http://');
    this.validateForm.controls['ota_url'].setValue(evt.url);
  }
  resBinUploaded(evt: any) {
    // console.log('otaBinUploaded', evt);
    this.resBinUrl = evt.url.replace('https://', 'http://');
    this.validateForm.controls['res_url'].setValue(evt.url);
  }
  changeValToHex(el: any, name: any) {
    console.log(el.value);
    if (!el.value) {
      return;
    }
    const val = this.hexToDecimal(el.value);
    this.validateForm.controls[name].setValue(val);
  }
  // checksumUploaded(evt) {
  //   // console.log('otaBinUploaded', evt);
  //   this.checksumUrl = evt.url;
  //   this.validateForm.controls['res_url'].setValue(evt.url);
  // }
  // onOtaChecksumChange(value: string) {
  //   this.validateForm.controls['ota_checksum'].setValue(value);
  // }
  // onResChecksumChange(value: string) {
  //   this.validateForm.controls['res_checksum'].setValue(value);
  // }

  hexToDecimal(val: any) {
    return parseInt(val, 16);
  }
  decimalToHex(val: any) {
    // @ts-ignore
    return `${val}`.toString(16).padStart(6, '0');
  }
  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
  }


}
