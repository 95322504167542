<div>
<!--    (ngSubmit)="submitForm()"-->
    <div class="toolbar">
        <a nz-button nzType="primary" [routerLink]="['/admin/tablet-manage/index']">返回列表</a>
    </div>
    <form nz-form [formGroup]="validateForm" >
        <nz-form-item>
            <nz-form-label [nzSpan]="5" nzRequired nzFor="brand">Brand</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Please input brand!">
                <nz-select
                        id="brand"
                        formControlName="brand"
                        nzPlaceHolder="Select a brand"
                        (ngModelChange)="brandChange($event)"
                >
                    <nz-option nzValue="incar_710" nzLabel="incar_710"></nz-option>
                    <nz-option nzValue="incar" nzLabel="incar"></nz-option>
                    <nz-option nzValue="unisoc" nzLabel="unisoc"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5" nzRequired nzFor="model">Model</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Please input model!">
                <nz-select
                        id="model"
                        formControlName="model"
                        nzPlaceHolder="Select a model"
                >
                    <nz-option *ngFor="let m of models" [nzValue]="m" [nzLabel]="m"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
<!--        <nz-form-item>-->
<!--            <nz-form-label [nzSpan]="5" nzRequired nzFor="model">Model</nz-form-label>-->
<!--            <nz-form-control [nzSpan]="12" nzErrorTip="Please select type!">-->
<!--                <nz-radio-group formControlName="type">-->
<!--                    <label nz-radio [nzValue]="0">双系统</label>-->
<!--                    <label nz-radio [nzValue]="1">单系统</label>-->
<!--                </nz-radio-group>-->
<!--            </nz-form-control>-->
<!--        </nz-form-item>-->
<!--        <nz-form-item *ngIf="validateForm.value.type==1">-->
<!--            <nz-form-label [nzSpan]="5" nzRequired nzFor="model">日期</nz-form-label>-->
<!--            <nz-form-control [nzSpan]="12" nzErrorTip="Please select start and end date!">-->
<!--                <nz-range-picker formControlName="agoAndAfter" ></nz-range-picker>-->
<!--            </nz-form-control>-->
<!--        </nz-form-item>-->
        <nz-form-item>
            <nz-form-label [nzSpan]="5" nzRequired nzFor="sn">SN</nz-form-label>
            <nz-form-control [nzSpan]="12" nzErrorTip="Please input SN!">
                <code>mac sn两列以空格/tab分割</code>
                <textarea
                        nz-input
                        id="sn"
                        formControlName="sn"
                        placeholder="Paste in SN"
                        [nzAutosize]="{ minRows: 20 }"
                ></textarea>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row class="register-area">
<!--            [disabled]="!validateForm.valid"-->
            <nz-form-control [nzSpan]="14" [nzOffset]="6">
                <button [nzLoading]="isSubmitting"
                        (click)="submitForm()" nz-button nzType="primary">保存</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>

