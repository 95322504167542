<div class="page">

        <div class="video-upload-box" [ngStyle]="{display: currentStep === 0 ? 'flex': 'none'}">
<!--            <nz-spin style=" overflow: hidden;width: 100%; height: 100%;" [nzSpinning]="!!editId">-->
            <nz-alert style="margin-bottom: 2em;flex: 0 auto" nzBanner nzType="warning" [nzDescription]="warnContentDescription" [nzMessage]="warnContent"></nz-alert>
            <ng-template #warnContent>
                严禁上传违规/不健康/低俗等内容，违者下架并封号处理!
            </ng-template>
            <ng-template #warnContentDescription>
                上传即表示您已阅读并同意 <a target="_blank" href='/assets/html/qupeiyin.html'>《全阅读服务协议》</a>
            </ng-template>
            <div style="flex: 1 auto;display: block;
            position: relative;
            height: 500px;
            display: flex;
            justify-content: center;align-items: center;">
                <div class="box-16-9">
                    <app-upload-video
                                [videoUrl]="safeUrl(DUBData.video_url)"
                                [limit]="30"
                                (videoUploaded)="onVideoUploaded($event)"
                                (videoUploadFailure)="onVideoUploadFailure()"></app-upload-video>
                </div>
            </div>
<!--            </nz-spin>-->
        </div>

    <div [ngStyle]="{display: currentStep === 1 ? 'block': 'none'}" >
        <nz-page-header nzBackIcon (nzBack)="goBackStep()" *ngIf="!editId">
            <nz-page-header-title>返回</nz-page-header-title>
            <nz-page-header-subtitle>重新选择视频文件</nz-page-header-subtitle>
            <nz-page-header-extra>
                <button (click)="goNextStep()" [disabled]="!DUBData.timePointData || DUBData.timePointData.length == 0 || isInvaildSents()" nz-button [nzSize]="'large'" nzType="primary">下一步<i nz-icon nzType="right"></i></button>
            </nz-page-header-extra>
        </nz-page-header>
        <nz-page-header *ngIf="editId">
            <nz-page-header-extra>
                <button (click)="goNextStep()" [disabled]="!DUBData.timePointData || DUBData.timePointData.length == 0 || isInvaildSents()" nz-button [nzSize]="'large'" nzType="primary">下一步<i nz-icon nzType="right"></i></button>
            </nz-page-header-extra>
        </nz-page-header>

        <nz-divider></nz-divider>
        <nz-spin style=" overflow: hidden;width: 100%; height: 100%;" [nzSpinning]="isLoadingVideoData">
        <div class="video-box">
            <div class="video-wrapper">
                <video #videoElement></video>
                <div class="loading" [ngStyle]="{display: isVideoWaiting ? 'flex': 'none'}">
                    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        </div>

        <nz-divider></nz-divider>
        <div class="button-box"  >
            <div class="content">
                <div class="center" >
                    <div style="display: flex; line-height: 36px;">
                        <span style="width: 300px;margin-right: 20px">{{currentAudioTime}}/{{currentAudioDuration}}</span>
                        <div style="display: flex; line-height: 36px;">

                            <span>播放速度：</span>&nbsp;
                            <span  style="width: 150px;">
              <nz-slider [(ngModel)]="playbackRate"
                         (ngModelChange)="changePlaybackRate($event)"
                         [nzMax]="2" [nzMin]="0.25" [nzStep]="0.25"></nz-slider>
            </span>

                        </div>
                        &nbsp;
                        <!--                    <app-audio-recorder [audioUrl]="DUBData.video_url"-->
                        <!--                                        (audioUploaded)="onAudioUploaded($event)"></app-audio-recorder>-->
                        &nbsp;
                        <div style="position: absolute;right: 0;">
                        <button nz-button nzSize="default"
                                nzType="primary"
                                (click)="togglePlay($event)">
                            <ng-container *ngIf="isPlaying"><i nz-icon nzType="pause" nzTheme="outline"></i>暂停</ng-container>
                            <ng-container *ngIf="!isPlaying"><i nz-icon nzType="caret-right" nzTheme="outline"></i>播放</ng-container>
                        </button>
                        &nbsp;
                        <button nz-button nzSize="default"
                                nzType="primary"
                                id="enterbtn"
                                [disabled]="!canSetTimeBlock"
                                (click)="setTimestampPhase(null, true)">
                            <!-- setTimestampPhase setTimestampPoint-->
<!--                            {{isSettingTimeBlockPoint ? '设置结束点' : '设置起始点'}}-->
                            插入配音
                        </button>
                        &nbsp;
<!--                        <button nz-button nzSize="default"-->
<!--                                nzType="danger"-->
<!--                                (click)="saveUserData()"><i nz-icon nzType="cloud-upload" nzTheme="outline"></i>保存</button>-->
                        </div>
                    </div>



                </div>
                <div class="timestamp-container">
                    <ng-template #insertLineContentTemplate>
                        <div>
                            <p>Content</p>
                            <p>Content</p>
                        </div>
                    </ng-template>
                    <div class="timestamp-line"
                         (click)="selectTimePoint(i)"
                         [ngClass]="{selected: selectHighlightTimePointIndex === i}"
                         *ngFor="let item of DUBData.timePointData; let i = index">
                        <div class="time-tag" [ngClass]="{warn: item.warn}">
                            [{{convertTimeToTag(item.startTime) || ''}}
                            ~
                            {{convertTimeToTag(item.endTime) ||  ''}}]
                        </div>

<!--                        <div class="add-line" style="margin-right: 12px;">-->
<!--                            <button nz-tooltip nzTooltipTitle="向后插入一行" nz-button nzType="danger" nzSize="small" nzShape="circle"-->
<!--                                    (click)="insertTimePoint(i)">-->
<!--                                <i nz-icon nzType="plus" nzTheme="outline"></i>-->
<!--                            </button>-->
<!--                        </div>-->
                        <div class="time-content">
                            <!--              <app-upload-image-with-preview-->
                            <!--                [picUrl]="item.data"-->
                            <!--                (imageUploaded)="onImageUploadSuccess($event)"-->
                            <!--              ></app-upload-image-with-preview>-->
                            <input nz-input (blur)="lostFocus($event)" [(ngModel)]="item.text" />
                        </div>
<!--                        <div class="line-break">-->
<!--                            <label nz-checkbox nz-tooltip nzTitle="添加换行" [(ngModel)]="item.newLine"></label>-->
<!--                        </div>-->
                        <div class="time-del">
                            <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="removeTimePoint(i)">
                                <i nz-icon nzType="delete" nzTheme="outline"></i>
                            </button>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="wave-player" [ngStyle]="{visibility: DUBData.video_url ? 'visible' : 'hidden'}" >

            <div class="scroll-tool">
                <canvas #rulerCanvasEl style="height:80px;"></canvas>
                <div class="thumbs-bar">
                    <canvas #canvasEl [ngStyle]="{height: waveHeight + 'px'}"></canvas>
                    <div class="point-line" [ngStyle]="{height: waveHeight + 'px'}">
                        <div
                                *ngFor="let item of DUBData.timePointData; let i = index" class="time-block"
                                [ngClass]="{selected: selectHighlightTimePointIndex === i}"
                                [ngStyle]="{left: timeToPosition(item.startTime) + 'px',
                     width:  durationToWidth(item.endTime - item.startTime) + 'px',
                     zIndex: selectHighlightTimePointIndex === i ? 1 : 0}"
                                [attr.data-index]="i"
                                appCustomDrag
                                [b4Down]="highlightSelect()"
                                [move]="blockOnMove()"
                                [constraint]="blockMoveRange()" >
                            <div class="block-dd dd-left " appCustomDrag
                                 [attr.data-index]="i"
                                 [b4Down]="blockLeftB4Down()"
                                 [constraint]="blockLeftRange()"
                                 [start]="blockLeftStart()"
                                 [move]="blockLeftMove()">
                                <i nz-icon nzType="pause" nzTheme="outline"></i>
                            </div>
                            <div class="block-dd dd-right" appCustomDrag
                                 [attr.data-index]="i"
                                 [b4Down]="blockRightB4Down()"
                                 [constraint]="blockRightRange()"
                                 [start]="blockRightStart()"
                                 [move]="blockRightMove()">
                                <i nz-icon nzType="pause" nzTheme="outline"></i>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="time-line"
                     appCustomDrag
                     style="transform: translateX(0px);"
                     [move]="ctrlBarOnMove"
                     #timeLineEl >
                    <div class="ctrl-bar drag-bar">
                    </div>
                    <div class="play-bar"></div>
                </div>

            </div>

            <div *ngIf="false">
                <nz-slider nzRange style="flex: 1" [nzTipFormatter]="formatter" [nzStep]="0.01" [nzMax]="timeRangeObj.max" [nzMin]="timeRangeObj.min"
                           (nzOnAfterChange)="timeRangeAfterChange($event)"
                           [(ngModel)]="timeRangeSelector"></nz-slider>
                <div style="width: 100%;
    position: relative;
    height: 30px;
    display: flex;
    flex-direction: row-reverse;">
                    <button [disabled]="!isScaleTimeLine" style="margin-right: 8px;" nz-button nzSize="small"
                            (click)="restoreTimeLine()"
                            nzType="primary">
                        返回</button>
                    <button style="margin-right: 8px;" nz-button nzSize="small"
                            (click)="scaleTimeLine()"
                            nzType="primary">
                        缩放时间轴</button>
                </div>
            </div>

        </div>
        </nz-spin>
    </div>
    <div [ngStyle]="{display: currentStep === 2 ? 'block': 'none'}">
        <nz-spin style=" overflow: hidden;width: 100%; height: 100%;" [nzSpinning]="isLoadingFormMeta">
        <nz-page-header nzBackIcon (nzBack)="goBackStep()">
            <nz-page-header-title>返回</nz-page-header-title>
            <nz-page-header-subtitle>重新编辑视频配音</nz-page-header-subtitle>
        </nz-page-header>
        <nz-divider></nz-divider>
        <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="cover">视频预览</nz-form-label>
                <nz-form-control [nzSm]="8" [nzXs]="24" >
                    <app-upload-video
                            [videoUrl]="DUBData.video_url"
                            [allowUpload]="false"
                            (videoUploaded)="onVideoUploaded($event)"
                            (videoUploadFailure)="onVideoUploadFailure()"
                            (extractVideoCover)="onVideoCoverExtract($event)"></app-upload-video>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="cover">封面</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确设置封面">
                    <img *ngIf="DUBData.cover_url" class="video_cover" [src]="DUBData.cover_url" alt="">
                    <div *ngIf="!DUBData.cover_url">请从视频截取封面图片</div>
                    <input  style="display: none" nz-input formControlName="cover" id="cover" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="title">标题</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确输入标题">
                    <input nz-input formControlName="title" id="title" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="level">难度</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确输入标题">
                    <nz-radio-group formControlName="level">
                        <label nz-radio [nzValue]="o.id" *ngFor="let o of formLevelOptions">{{ o.name }}</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="kind">类型</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确选择类型">
<!--                        (ngModelChange)="console.log(formKindOptions)"-->
                    <nz-checkbox-group formControlName="kind" [(ngModel)]="formKindOptions"></nz-checkbox-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="theme">主题</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确选择主题">
<!--                    (ngModelChange)="console.log(formThemeOptions)"-->
                    <nz-checkbox-group formControlName="theme" [(ngModel)]="formThemeOptions"></nz-checkbox-group>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="theme">视频简介</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确填写视频简介">
                    <textarea nz-input formControlName="intro" id="intro"></textarea>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="theme">句型/语法</nz-form-label>
                <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="请正确填写句型/语法">
                    <textarea nz-input formControlName="syntax" id="syntax"></textarea>
                </nz-form-control>
            </nz-form-item>


            <nz-form-item>
                <nz-form-control [nzSpan]="8" [nzOffset]="4">
                    <button nz-button nzType="primary">提交</button>
                </nz-form-control>
            </nz-form-item>
        </form>
        </nz-spin>
    </div>

</div>

