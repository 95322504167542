import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appRepeatRender]'
})
export class RepeatRenderDirective {
  resize = false; // 是否因为resize而触发的render, 否则为ngFor finish时触发
  @Output()
  render = new EventEmitter();

  @Input()
  repeatTotal: number | undefined;

  @Input()
  set repeatIndex(value: number) {
    // @ts-ignore
    if (value === this.repeatTotal - 1) {
      this.resize = true;
      setTimeout(() => {
        this.render.emit(this.resize);
      }, 0);
    }
  }

  constructor() {
  }

}
