import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LevelsService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  fetchLevelTree() {
    return super.get(`/levels/tree`).then((resp) => {
      return resp;
    });
  }
  fetchLevels() {
    return super.get(`/levels`).then((resp) => {
      return resp;
    });
  }
  fetchChildrenLevels(parent: string|number) {
    return super.get(`/levels/tree/${parent}`).then((resp) => {
      return resp;
    });
  }

}
