import { ElpService } from '../../../../../../services/elp.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-elp-trace-index',
  template: `
      <div class="table-operations">
          <button nz-button nzType="primary" [nzSize]="'large'" (click)="addDevice()">
              <i nz-icon nzType="file-add" nzTheme="twotone"></i>添加采集设备</button>
      </div> 
    <nz-table #nzTable 
              [nzLoading]="loadingData" 
              [nzData]="listOfDisplayData"
              nzSize="small"
              [nzTotal]="total"
              [nzPageSize]="pageSize"
              [nzPageIndex]="pageIndex"
              [nzFrontPagination]="false"
              nzTableLayout="fixed">
      <thead>
        <tr>
          <th nzWidth="600px">
            设备
<!--            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">-->
<!--              <span nz-icon nzType="search"></span>-->
<!--            </nz-filter-trigger>-->
          </th>
          <th>状态</th>
          <th nzWidth="200px">记录时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of nzTable.data">
          <td>{{ data.p_device_id }}</td>
            <td  [ngStyle]="{'color': data.status? 'green' : 'red' }">{{ data.status }}</td>
          <td>{{ data.start_at }}</td>
          <td>
              <a *ngIf="data.status" nz-button [nzSize]="'small'" nzType="link" (click)="doTrace(data.p_device_id, false)">取消</a>
              <a *ngIf="!data.status" nz-button [nzSize]="'small'" nzType="link" (click)="doTrace(data.p_device_id, true)">开始</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a  nz-button [nzSize]="'small'" nzType="link" (click)="doUpload(data.p_device_id)">召回</a>
          </td>
        </tr>
      </tbody>
    </nz-table>
    
      <ng-template #traceDeviceIdInput>
          <div style="text-align: center">
              device_id: <input type="text" nz-input placeholder="Search name" [(ngModel)]="traceDeviceId" />
          </div>
      </ng-template>
  `,
  styles: [
    `
      .search-box {
        padding: 8px;
      }

      .search-box input {
        width: 188px;
        margin-bottom: 8px;
        display: block;
      }

      .search-box button {
        width: 90px;
      }

      .search-button {
        margin-right: 8px;
      }
    `
  ]
})

export class ElpTraceIndexComponent {
  searchValue = '';
  // visible = false;

  listOfDisplayData = [];
  total = 0;
  loadingData = false;
  pageSize = 50;
  pageIndex = 1;
  private currentParams: any = {};

  @ViewChild('traceDeviceIdInput', {static: true})
  traceDeviceIdInput: TemplateRef<{}> | undefined;
  traceDeviceId: string | undefined;
  constructor(private elpService: ElpService,
              private modalService: NzModalService,
              private nzMessageService: NzMessageService) {

  }
  ngOnInit() {
    this.loadData({pageIndex: 1});
  }
  doTrace(p_device_id: string, trace: boolean) {
    this.elpService.traceDevice(p_device_id , trace).then(r => {
      this.loadData({pageIndex: 1});
    }).catch(err => {
      this.nzMessageService.error(err.message);
    });
  }
  doUpload(device_id: string) {
    this.elpService.uploadDeviceLog(device_id).then(r => {
      // this.loadData({pageIndex: 1});
      this.nzMessageService.success('命令下发成功');
    }).catch(err => {
      this.nzMessageService.error(err.message);
    });
  }

  addDevice() {
    // @ts-ignore
    const modal = this.modalService.create({
      nzMaskClosable: false,
      nzClosable: false,
      nzTitle: '设置设备ID',
      nzContent: this.traceDeviceIdInput,
      // nzComponentParams: data,
      // nzMaskClosable: false,
      // nzClosable: false,
      nzClassName: 'input-device-id-modal',
      nzWidth: 400,
      nzFooter: [
        {
          label: '通过',
          type: 'primary',
          loading: false,
          onClick: () =>  {
            console.log(this.traceDeviceId);
            // if (!this.traceDeviceId || !/zcw\-elp\-v1\-636267d4222a8601d40379f0\-[a-zA-Z0-9]{32}/.test(this.traceDeviceId)) {
            //   this.nzMessageService.error('device_id 格式有误');
            //   return;
            // }
            if (!this.traceDeviceId) {
              this.nzMessageService.error('device_id 格式有误');
              return;
            }
            modal.config.nzFooter[0].loading = true;
            modal.config.nzFooter[0].disabled = true;
            modal.config.nzFooter[1].disabled = true;
            this.elpService.traceDevice(this.traceDeviceId , true).then((res: any) => {
              this.traceDeviceId = '';
              modal.config.nzFooter[0].loading = false;
              modal.config.nzFooter[0].disabled = false;
              modal.config.nzFooter[1].disabled = false;
              this.loadData({pageIndex: 1});
              modal.destroy();
            }).catch((err: any) => {
              this.nzMessageService.error(err.message);
            });
          }

        },
        {
          label: '关闭',
          type: 'default',
          onClick: () => modal.destroy()
        },
      ],
    });
  }
  reset(): void {
    this.searchValue = '';
    this.listOfDisplayData = [] ;
  }
  /*
  onQueryParamsChange(params: any): void {
    console.log(params);
    if (!params['identifier']) {
      params['identifier'] = this.searchValue;
    }
    if (!params['identifier']) {
      return;
    }
    this.currentParams = params;
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort && sort.find((item: any) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadData(this.currentParams);
  }
  search() {
    this.currentParams['identifier'] = this.searchValue;
    this.onQueryParamsChange({
      page: 1,
      identifier: this.searchValue
    });
    this.loadingData = true;

  }
  */
  loadData(query: {pageIndex: number, pageSize?: number}) {
    if (!query.pageSize) {
      query.pageSize = this.pageSize;
    }
    this.loadingData = true;
    // this.visible = false;
    this.elpService.fetchDebugDeviceList(query).then((res: any) => {
      console.log(res);
      this.listOfDisplayData = res.rows;
      this.total = res.total;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      this.loadingData = false;
    });
  }

}
