// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UserType } from '../../../../../../../model/Const';
import { ElpService } from '../../../../../../../services/elp.service';


@Component({
  selector: 'app-elp-firmware-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class ElpFirmwareIndexComponent implements OnInit, AfterViewInit {
  displayData: any[] = [];
  currentPageIndex = 1;
  user: any = null;
  constUserType = UserType;
  deleteingClasses = false;

  disabledButton = true;
  allChecked = false;
  indeterminate = false;
  checkedNumber = 0;

  searchBoxVisible = false;
  searchLoading = false;
  searchValue = '';
  searchResult = '';

  loadingData = false;
  private school_id = 0;

  constructor(private userService: UserService,
              private elpFirmwareService: ElpService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    this.activatedRoute.queryParams.subscribe( query => {
      if (query.school && parseInt(query.school, 10) === query.school * 1) {
        this.school_id = parseInt(query.school, 10);
        // if (this.user.type === this.constUserType.SUPER_ADMIN) {
        //   this.user = {
        //     ...this.user,
        //     school_id
        //   };
        // }
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query.page && parseInt(query.page, 10) === query.page * 1) {
        this.currentPageIndex = parseInt(query.page, 10);
      }
    });
  }

  ngOnInit() {


  }
  ngAfterViewInit() {
    this.loadData(this.currentPageIndex || 1);
  }
  onExpandChange(data: number, checked: boolean): void {
    data.expand = checked;
  }
  loadData(page) {
    this.loadingData = true;

    this.elpFirmwareService.fetchAllElpFirmware().then(firmwares => {
      this.displayData = firmwares;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(`错误：${err.message}`);
      this.loadingData = false;
    });

  }

  delete(id) {
    // this.router.navigate([`/admin/elp-speaker/firmware/edit/${id}`]);
    this.loadingData = true;
    this.elpFirmwareService.remove(`/speaker/device/firmware/${id}`).then(firmwares => {
      this.loadData(this.currentPageIndex || 1);
    }).catch((err: any) => {
      this.nzMessageService.error(`错误：${err.message}`);
      this.loadingData = false;
    });
  }
  saveWhitelist(data) {
    const whitelist = data.whitelist.split(/[| ,，  ]+/).map(m => m.trim()).filter(m => !!m);
    this.elpFirmwareService.saveElpFirmwareWhitelist(data.id, whitelist).then(firmwares => {
      this.loadData(this.currentPageIndex || 1);
    }).catch((err: any) => {
      this.nzMessageService.error(`错误：${err.message}`);
      this.loadingData = false;
    });
  }

  handleCancel() {
    this.searchBoxVisible = false;
  }
  handleOk() {
    this.searchBoxVisible = false;
  }

  protected readonly Boolean = Boolean;
  protected readonly Boolean = Boolean;
  protected readonly Boolean = Boolean;
}
