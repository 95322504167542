import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class MediaService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  getAudioSeriesList(page: string|number) {
    return super.get(`/media-series/audio/page/${page}`);
  }
  getVideoSeriesList(page: string|number) {
    return super.get(`/media-series/video/page/${page}`);
  }
  addAudioSeries(data: any) {
    return super.post(`/media-series/audio`, data);
  }
  updateAudioSeries(id: string|number, data: any) {
    return super.post(`/media-series/audio/${id}`, data);
  }

  updateVideoSeries(id: string|number, data: any) {
    return super.post(`/media-series/video/${id}`, data);
  }
  addVideoSeries (data: any) {
    return super.post(`/media-series/video`, data);
  }
  fetchAudioById(id: string|number) {
    return super.get(`/media-series/audio/${id}`);
  }
  fetchVideoById(id: string|number) {
    return super.get(`/media-series/video/${id}`);
  }
  removeVideoById(id: string|number) {
    return super.remove(`/media-series/video/${id}`);
  }
  removeAudioById(id: string|number) {
    return super.remove(`/media-series/audio/${id}`);
  }
}
