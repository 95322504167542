import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class OrderService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
    // console.log(22222222222222)
    // debugger
    // this.getInfoPromise = this.get('/user/getInfo');
  }
  getOrderInfo (id: string|number) {
    return super.get(`/order/${id}`);
  }
  getConsumeData(query: any) {
    let url = `/orders/consumes`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }

    return super.get(url);
  }
  getOrders(page: string|number, query: any) {
    let url = `/orders`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }

    return super.get(url);
  }
  changeSubsidyStatus(data: any) {
    return super.post(`/orders/subsidy/status`, data);
  }

  getPrice(quantity: any) {
    return super.post(`/orders/calc-price`, {quantity});

  }

  reSubmitSybsidy(data: any) {
    return super.post(`/orders/subsidy/proof`, data);
  }

  getStatistic(query: any) {
    return super.get(`/orders/statistics?dt=${query.dt}&page=${query.page}`);
  }

  manualAddOrder(data: any) {
    return super.post(`/orders/manual/add`, data);
  }

}
