import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class StudentService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  fetchStudentsBySchoolId(page: string|number, query = null, school_id: number|string = 0, class_id: number|string = 0) {
    let url = `/schools/students/page/${page}`;
    const searchParams = new URLSearchParams();
    if (school_id) {
      searchParams.set('school_id', `${school_id}`);
    }
    if (class_id) {
      searchParams.set('class_id', `${class_id}`);
    }
    if (query) {
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
    }
    url += `?${searchParams.toString()}`;

    return super.get(url);
  }
  fetchStudentsByClassId(id: string|number, page: string|number) {
    return super.get(`/classes/${id}/users/page/${page}`);
  }
  changeClass(class_id: string|number, user_ids: any) {
    return this.post(`/class/change/student/class`, {
      class_id,
      users: user_ids
    });
  }










}
