// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzContextMenuService, NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { UserService } from '../../../../../../services/user.service';
import { GiftsService } from '../../../../../../services/gifts.service';

@Component({
  selector: 'app-admin-gifts-index',
  templateUrl: './gifts-index.component.html',
  styleUrls: ['./gifts-index.component.scss']
})
export class GiftsIndexComponent implements OnInit {
  dataSet: any[] = [];
  totalNum = 0;
  currentPageIndex = 1;
  promo = null;
  total_quantity = 0;
  accounts_message = '';
  // subsidy_quota = 0;
  isSA = false;
  searchValue = '';
  query = {};
  dropSearchMenu = false;
  @ViewChild('dropdown', {static: false})
  dropdown: NzDropdownMenuComponent;

  @ViewChild('rejectContent', {static: true})
  rejectContent: TemplateRef<{}>;
  rejectReason = '';
  selectMonth = null;

  orderStatisticTableData = null;
  currentStatisticPageIndex = 1;
  // @ViewChild('subsidyContent', {static: true})
  // subsidyContent: TemplateRef<{}>;


  // checkSubsidyLoadingTxt = 'loading';
  // checkSubsidyModelOpen = new EventEmitter();



  statisticQuery = {};
  giftsDeliveryCache = null;
  giftsListCache = null;
  giftsListLoading = false;
  giftsDeliveryLoading = false;

  currentGiftsListPageIndex = 1;
  currentGiftsDeliveryPageIndex = 1;
  giftsDeliveryTableData: {total: number, rows: any[]} = {total: 0, rows: []};
  giftsListTableData: {total: number, rows: any[]} = {total: 0, rows: []};


  searchNameValue = '';
  searchPhoneValue = '';
  searchStatusValue: any[] = [];


  listOfFilterStatus = [
    { text: '已发货', value: 1 },
    { text: '未发货', value: 0},
   ];

  constructor(private giftsService: GiftsService,
              private nzContextMenuService: NzContextMenuService,
              private modalService: NzModalService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    const info = this.userService.getUserInfo();
    // this.isSA = info.type === UserType.SUPER_ADMIN;
    // this.checkSubsidyModelOpen.subscribe((evt) => {
    //   console.log('modal open');
    // });
  }

  ngOnInit() {
    this.loadGiftsListData('all');

  }
  loadGiftsListData(page) {
    this.giftsListLoading = true;
    if (!page || (page !== 'all' && isNaN(page))) {
      page = 1;
    }


    this.giftsService.getGifts(page).then(data => {
      console.log(data);
      this.giftsListCache = data;
      this.giftsListTableData = data;
      this.giftsListLoading = false;
      this.currentGiftsListPageIndex = data.currentPage;

    }).catch(e => {
      this.giftsListLoading = false;
      this.nzMessageService.error('加载失败请刷新');
    });
  }
  deleteGift(id) {
    this.giftsListLoading = true;
    this.giftsService.deleteGift(id).then((res) => {
      this.loadGiftsListData('all');
    }).catch((err) => {
      this.giftsListLoading = false;
      this.nzMessageService.error('删除失败请刷新');
    });
  }
  tabIndexChanged(evt) {
    console.log(evt);
    if (evt === 1) {
      this.giftsDeliveryLoading = true;
      setTimeout(() => {
        this.loadGiftsDeliveryData(this.currentGiftsDeliveryPageIndex);
      }, 300);
    } else {
      this.giftsListLoading = true;
      setTimeout(() => {
      this.loadGiftsListData('all');
      }, 300);
    }
  }
  loadGiftsDeliveryData(page) {
    this.giftsDeliveryLoading = true;
    if (!page || (page !== 'all' && isNaN(page))) {
      page = 1;
    }
    const query = this.getFilterQuery();
    this.giftsService.getGiftsDeliveryStatus(page, query).then(data => {
      this.giftsDeliveryLoading = false;
      this.giftsDeliveryTableData = data;
    }).catch(e => {
      this.giftsDeliveryLoading = false;
      this.nzMessageService.error('列表加载失败，清刷新重试');
    });

  }
  changeGiftDeliveryStatus(id, status) {
    this.giftsDeliveryLoading = true;
    this.giftsService.changeGiftsDeliveryStatus(id, status).then(data => {
      this.giftsDeliveryLoading = false;
      this.loadGiftsDeliveryData(this.currentGiftsDeliveryPageIndex);
    }).catch(e => {
      this.giftsDeliveryLoading = false;
      this.nzMessageService.error('列表加载失败，清刷新重试');
    });
  }
  getFilterQuery() {
    return {
      name: this.searchNameValue,
      phone: this.searchPhoneValue,
      status: this.searchStatusValue
    };
  }
  resetSearchName() {
    this.searchNameValue = '';
  }
  searchName() {
    this.loadGiftsDeliveryData(1);
  }
  resetSearchPhone() {
    this.searchPhoneValue = '';
  }
  searchPhone() {
    this.loadGiftsDeliveryData(1);
  }

  filterStatusChange(value: string[]) {
    this.searchStatusValue = value;
    // const query = this.getFilterQuery();
    // console.log(query);
    this.loadGiftsDeliveryData(1);
  }
}
