// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderService } from '../../../../../../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzInputNumberComponent } from 'ng-zorro-antd/input-number';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WxPaymentComponent } from '../../../../../common/wx-payment/wx-payment.component';
import { PromotionService } from '../../../../../../services/promotion.service';

@Component({
  selector: 'app-admin-orders-buy',
  templateUrl: './orders-buy.component.html',
  styleUrls: ['./orders-buy.component.scss']
})
export class OrdersBuyComponent implements OnInit {
  promoTitle = null;
  promoDesc = null;
  promoRate = null;
  price = 0;
  originUnitPrice = 0 ;
  currentUnitPrice = 0;
  accountDays = 0;
  calcPrice = '0元';
  giftAccounts = 0;
  buyForm: UntypedFormGroup;
  inputQuantity = 0;
  calcPriceProcess = null;
  calcPriceProcessTimer = null;

  // has_subsidy = false;
  // subsidyDesc = '';
  // tmpPicUrl = null;

  previewImgUrl = '';
  previewVisible = false;

  subsidyPicList = [

  ];

  checkoutConfirmMsg = '';

  @ViewChild('WXPaymentElement', {static: true}) WXPaymentElement: WxPaymentComponent;
  @ViewChild('quotaElement', {static: true}) quotaElement: NzInputNumberComponent;

  calcing = false;

  constructor(private modalService: NzModalService,
              private promoService: PromotionService,
              private orderService: OrderService,
              private fb: UntypedFormBuilder,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }

  ngOnInit() {
    this.buyForm = this.fb.group({
      formLayout: ['horizontal'],
      quota: [null, [Validators.required, Validators.min(1)]],
    });
    this.promoService.getCurrentPromotion().then(data => {
      console.log(data);
      this.promoTitle = data.title;
      this.promoDesc = data.desc;
      // this.promoRate = (1 - data.price_rate / 100) * 10 ;
      this.originUnitPrice = data.originUnitPrice / 100 ;
      this.currentUnitPrice = data.currentUnitPrice / 100;
      this.accountDays = data.days;
    });
  }
  submitForm() {

  }
  onNumberChange(val: string): void {
    // val = val.key;
    // console.log(val);
    val = val + '';
    val = val.replace(/[+-]/g, '');
    if (val.startsWith('0')) {
      val = val.substr(1);
    }
    if (+val === 0 || isNaN(+val)) {
      this.quotaElement.inputElement.nativeElement.value = '1';
      this.modalService.error({
        nzTitle: '错误',
        nzContent: '请选择填写您希望购买的人数'
      });
      return;
    }
    this.updateValue(val);

    // this.updateValue(val);
    // const num = this.giftForm.controls['quota'].value;
    // this.orderService.getPrice(num).then(r => {
    //   this.isCalculating = false;
    //   this.currentPrice = r;
    // });
  }

  updateValue(value: string): void {
    this.giftAccounts = 0;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (value === '') {
      this.quotaElement.inputElement.nativeElement.value = '';
      this.calcPrice = '0元';
      return;
    }
    if ((!isNaN(+value) && reg.test(value)) || value === '+' || value === '-') {
      this.inputQuantity = +value;
      if (this.calcPriceProcessTimer) {
        if (this.calcPriceProcess) {
          this.calcPriceProcess?.cancel();
        }
        clearTimeout(this.calcPriceProcessTimer);
        this.calcPriceProcessTimer = null;
      }
      this.calcPriceProcessTimer = setTimeout(() => {
        this.calcPrice = '计算中......';
        if (this.calcPriceProcess) {
          this.calcPriceProcess?.cancel();
        }
        this.calcing = true;
        this.calcPriceProcess = this.orderService.getPrice(this.inputQuantity);
        this.calcPriceProcess?.then((data: any) => {
          this.calcPrice = `${data.price / 100}元`;
          this.giftAccounts = data.giftAccounts;
          this.calcing = false;
        }).catch((err: any) => {
          this.calcing = false;
        });
      } , 500);

    } else {
      this.quotaElement.inputElement.nativeElement.value = '1';
    }
    // this.quotaElement.inputElement.nativeElement.value = this.inputQuantity + '';
  }
  checkout() {
    if (!this.inputQuantity) {
        this.modalService.error({
          nzTitle: '错误',
          nzContent: '请选择填写您希望购买的人数'
        });
        return;
      }
    const msg = [ `您一共购买${this.inputQuantity}个名额`];
    if (this.giftAccounts) {
      msg.push(`赠送${this.giftAccounts}个名额`);
    }
    msg.push(`需要支付${this.calcPrice}`);
    this.checkoutConfirmMsg = `${msg.join(',')}.`;
    this.WXPaymentElement.checkout();
  }
  /*
  onImageUploaded(evt) {
    console.log(evt.url);
    this.tmpPicUrl = null;
    this.subsidyPicList.push(evt);
  }
  previewImage(file) {
    this.previewImgUrl = file.url;
    this.previewVisible = true;
  }
  deletePreviewImage(idx) {
    this.subsidyPicList.splice(idx, 1);
  }*/
  paySuccessCallback = () => {
    this.router.navigate(['/admin/account/index']);
  }
}
