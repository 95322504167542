import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'resource'
})
export class ResourcePipe implements PipeTransform {

  transform(value: any, coursewareSid?: string): string {
    let ret = value ? `/api/media-resource/${value}/file` : '';
    return ret;
  }

}
