<div class="d-flex">
  <div class="p-btn-record d-flex">
    <div class="btn-clear" style="cursor: pointer" (click)="onBtnClearAudio()" *ngIf="withRmBtn && (audioUrl || audioBlob)">
<!--      <fa-icon icon="times"></fa-icon>-->
      <i nz-icon nzType="delete" nzTheme="outline"></i>
    </div>
    <div class="btn-record" *ngIf="type===Type.RECORD && !isUploading"
         [class.p-recording]="isRecording"
         (click)="onBtnRecord()">
<!--      <fa-icon icon="microphone"></fa-icon>-->
        <i nz-icon nzType="audio" nzTheme="outline"></i>
      Record Audio
    </div>
    <nz-upload
        [nzShowUploadList]="false"
        nzAccept = ".mp3"
        [nzBeforeUpload]="customUpload"
        (nzChange)="handleChange()">

      <div class="btn-upload" [ngClass]="{'has-clear': withRmBtn && (audioUrl || audioBlob)}" *ngIf="type===Type.UPLOAD && !isUploading">
<!--        <fa-icon icon="upload"></fa-icon>-->
          <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>
        Upload Audio
      </div>
    </nz-upload>
    <div class="p-upload-progress-bg" *ngIf="isUploading">
      <div class="i-bg" [style.width]="progress+'%'"></div>
      <div class="i-text">
<!--        <fa-icon icon="cloud-upload-alt"></fa-icon>-->
          <i nz-icon nzType="cloud-upload" nzTheme="outline"></i>
        Uploading...
      </div>
    </div>


    <div *ngIf="audioUrl && needRemove; then truthyTemplate else falsyTemplate"></div>

    <ng-template #truthyTemplate >

      <div class="btn-delete" (click)="onBtnDeleteAudio()">
<!--        <fa-icon icon="close"></fa-icon>-->
          <i nz-icon nzType="close-circle" nzTheme="outline"></i>
      </div>

    </ng-template>

    <ng-template #falsyTemplate>
      <div class="btn-switch" (click)="onBtnSwitchType()">
          <i nz-icon nzType="setting" nzTheme="outline"></i>
      </div>
    </ng-template>




  </div>

  <div class="p-progress ml-2" (click)="onBtnPlay()" *ngIf="audioUrl || audioBlob">
    <nz-progress [nzPercent]="percent" [nzWidth]="30" [nzFormat]="progressText"
                 nzType="circle"></nz-progress>
    <div class="p-btn-play" [style.left]="isPlaying?'8px':''">
<!--      <fa-icon [icon]="playIcon"></fa-icon>-->
        <i nz-icon [nzType]="playIcon" nzTheme="outline"></i>
    </div>
  </div>
</div>
