import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { HWResourceService as ResourceService } from '../../../services/huawei-resource.service';
import { UserService } from '../../../services/user.service';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnDestroy {
  uploading = false;
  progress = 0;

  @Input()
  errors = false;
  @Input()
  accept = '';
  @Input()
  endsWith = '';

  @Input()
  itemUrl = '';

  @Input()
  limit = 0;

  @Input()
  path = '';

  @Input()
  name = '';

  @Input()
  before: ((item: any) => any) | undefined;

  @Output()
  itemUploaded = new EventEmitter();
  @Output()
  itemUploadFailure = new EventEmitter();

  // @ts-ignore
  @ViewChild('uploadHolder', {static: true}) uploadHolder: ElementRef;

  constructor(private nzMessageService: NzMessageService,
              private http: HttpClient,
              private userService: UserService,
              private resService: ResourceService) {
  }


  customUpload = (item: any) => {
    if (!item) {
      return false;
    }
    this.itemUrl = '';
    // this.beforeUpload(item.file as any);
    if (this.before && typeof this.before === 'function') {
      const beforeResult = this.before(item);
      if (!beforeResult) {
        return false;
      }
      if (beforeResult.name) {
        this.name = beforeResult.name;
      }
      if (beforeResult.path) {
        this.path = beforeResult.path;
      }
    }
    if (!this.checkSelectFile(item)) {
      return false;
    }
    this.uploading = true;
    this.progress = 0;
    // this.resService.doUpload(item as any,  /*ResType.PIC ,*/ path,
    //   this.doProgress , this.uploadSuccess , this.uploadFailure );
    this.resService.doUpload({file: item, /* type: ResType,*/
      osspath: this.path,
      hash: this.name,
      progress_cb: this.doProgress,
      success_cb: this.uploadSuccess,
      error_cb: this.uploadFailure});
    return false;
  }
  holderClick(e: any) {
    this.uploadHolder.nativeElement.click();
  }
  checkSelectFile(file: any) {
    console.log('file', file.name);
    if (this.endsWith) {
      if (!file.name.endsWith(this.endsWith)) {
        this.nzMessageService.error(`file name must be ends with ${this.endsWith}`);
        return false;
      }
    }
    const isFile = [this.accept].includes(file.type);
    if (this.accept && !isFile) {
      this.nzMessageService.error(`You can only upload ${this.accept}`);
      return false;
    }
    if (this.limit > 0 ) {
      const isOverSize = file.size / 1024 / 1024 > this.limit;
      if (!isOverSize) {
        this.nzMessageService.error(`file must smaller than ${this.limit}MB!`);
        return false;
      }
    }

    return true;
  }

  uploadSuccess = (name: string, hash: string, url: string, file?: File, inOSS = false) => {

    this.nzMessageService.info('Upload Success');
    // this.updateStatus(false);
    this.uploading = false;

    this.itemUploaded.emit({ url});
  }
  uploadFailure = (err: any, file: any) => {
    this.uploading = false;
    if (err.name && err.name === 'cancel') {
      return;
    }
    console.log(err);
    this.nzMessageService.error('Upload Error ' + err.message);
    this.itemUploadFailure.emit(file);
  }

  doProgress = function (p: number) {
    if (p > 1) {
      p = 1;
    }
    if (p < 0) {
      p = 0;
    }
    // console.log(Math.floor(p * 100));
    // @ts-ignore
    this.progress =  Math.floor(p * 100);
  };
  ngOnDestroy () {
  }
  /*
    beforeUpload = (file: File) => {
      // console.log('beforeUpload');
      const isImg = ['image/jpeg', 'image/png', 'image/jpeg', 'image/gif', 'image/bmp'].includes(file.type);
      if (!isImg) {
        this.nzMessageService.error('You can only upload Image file (jpg|gif|png|bmp)');
        return;
      }
      const isGif = !['image/jpeg', 'image/png', 'image/jpeg', 'image/bmp'].includes(file.type);
      const delta = isGif ? 20 : 5;
      const isOverSize = file.size / 1024 / 1024 < delta;
      if (!isOverSize) {
        this.nzMessageService.error(`${isGif ? 'Gif' : 'Image'} must smaller than ${delta}MB!`);
        return;
      }
      this.uploading = true;
      this.resService.checkFileHash(file).then((hash) => {
        const ext = file.name.substr(file.name.lastIndexOf('.') );
        this.resService.checkOSSObject(hash + ext).then((res) => {
          const {info, client} = res;
          if (!client && !info) {
            this.nzMessageService.error('system error');
            return ;
          }
          if (info) {
            let url = info.res.requestUrls[0];
            if ( url.indexOf('?') > -1 ) {
              url = url.substring(0, url.indexOf('?'));
            }
            this.picUrl = url;
          } else if (client) {
            this.uploadFile(client, file, hash);
          }
        });
      });

      return isImg && isOverSize;
    }
    async uploadFile (client, file, hash) {
      console.log('uploadFile', hash);
      const ext = file.name.substr(file.name.lastIndexOf('.') );

      const key = hash + ext; // `${this.item.u_id}_${hash}_${file.name}`;
      // console.log(file.name + ' => ' + key);
      // const self = this;
      const user_info = this.userService.getUserInfo();
      return await client.multipartUpload(key, file, {
        meta: {
          userId: user_info.id,
          fileName: encodeURI(file.name),
          fileMd5: key
        },
        progress: this.doProgress.bind(this)
      }).then((resp) => {
        let url = resp.res.requestUrls[0];
        if ( url.indexOf('?') > -1 ) {
          url = url.substring(0, url.indexOf('?'));
        }

        this.resService.add({
          user_id: user_info.id,
          name: file.name,
          hash: hash,
          url:  url,
          size: file.size,
          type: 0
        }).then((res) => {
          this.nzMessageService.info('Upload Success');
          this.uploading = false;
          this.picUrl = url;
          this.imageUploaded.emit(res);
        });

      }).catch((e) => {
        this.uploading = false;
        if (e.name && e.name === 'cancel') {
          return;
        }
        console.log(e);
        this.nzMessageService.error('Upload Error ' + e.message);
      });
    }
    doProgress (p) {
      if (p > 1) {
        p = 1;
      }
      if (p < 0) {
        p = 0;
      }
      this.progress =  Math.floor(p * 100);
    } */
  handlePreview() {

  }
}
