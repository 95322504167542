import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { Const } from '../config/const';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable()
export class TransformInterceptor implements HttpInterceptor {
  constructor(private router: Router, private nzMessageService: NzMessageService) {
  }

  isAbsPath(url: string) {
    return url.startsWith('http://') || url.startsWith('https://');
  }
  // @ts-ignore
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Ken 2018-07-02 15:16 自动添加api前缀
    let url = req.url;
    let reqUrl = {
      origin: window.location.origin
    };
    if (this.isAbsPath(url)) {
      reqUrl = new URL(url);
    }

    if (!this.isAbsPath(url) && !url.startsWith('/api')) {
      url = '/api' + url;
    }
    const newReq = req.clone({
      url
    });

    // @ts-ignore
    return next.handle(newReq)
      .pipe(
        // @ts-ignore
        map((res: HttpResponse<any>) => {
          if (reqUrl.origin !== window.location.origin) {
            return res;
          }
          const body = _.get(res, 'body');
          const code = _.get(body, 'code');

          if (res instanceof HttpResponse) {
            // console.log('resp',  res)
            if (body.code === 500) {
              if (body.errorCode === 401 || body.errorCode === 1035) {
                localStorage.removeItem('token');
                // console.log(88888)
                this.nzMessageService.info(body.message);
                this.router.navigate(['/login']);
                return;
              }
            }
          }

          // Ken 2018-07-02 13:34 存在code且不为200, 说明报错
          if (code && code !== 200) {
            if (_.get(body, 'errorCode') === Const.ERROR_CODE.TOKEN_ERROR) {
              localStorage.removeItem('token');
              this.router.navigate(['/login']);
            }
            throw body;

          }
          // Ken 2018-07-02 13:59 如果code=200, 则直接取data的数据
          _.set(res, 'body', _.get(body, 'data'));
          return res;
        }),
      );
  }
}
