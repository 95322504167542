<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'"
                [queryParams]="{ page: returnPage }"
                [routerLink]="'/admin/classes/index'"
            >
            <!--(click)="goBack()"-->
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    </div>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm($event,validateForm.value)">
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>班级名称</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入班级名称！">
                <input nz-input formControlName="name" placeholder="班级的名称">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>任课教师</nz-form-label>
            <nz-form-control [nzSpan]="12" nzerrortip="teacherErrorTpl">
                <nz-select style="width: 100%;" nzPlaceHolder="选择任课教师" formControlName="teacher_id">
                    <ng-container *ngFor="let o of teacherList">
                        <nz-option *ngIf="!isLoadingTeachers" [nzValue]="o.id" [nzLabel]="o.display_name"></nz-option>
                    </ng-container>
                    <nz-option *ngIf="isLoadingTeachers" nzDisabled nzCustomContent>
                        <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                    </nz-option>
                </nz-select>
                <ng-template #teacherErrorTpl let-control>
                    <ng-container *ngIf="validateForm.get('teacher_id')?.hasError('teacher_id')">
                        请正确选择教师!
                    </ng-container>
                    <ng-container *ngIf="validateForm.get('teacher_id')?.hasError('required')">
                        请正确选择教师!
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" >备注</nz-form-label>
            <nz-form-control [nzSpan]="12" >
                <textarea formControlName="comment" nz-input rows="2" placeholder="备注信息"></textarea>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <button style="margin-right:1rem;" nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
                <button nz-button (click)="resetForm($event)">Reset</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
