import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { TrashService } from '../../../../../services/trash.service';

@Component({
  selector: 'app-trash-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class TrashIndexComponent implements OnInit {
  displayData: any[] = [];
  totalNumber = 0;
  currentPageIndex = 1;
  loadingData = false;
  schoolId = 0;
  showClassTeacher = false;
  restoreDelId = 0;
  constructor(private userService: UserService,
              private trashSvc: TrashService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }

  ngOnInit() {
    const info = this.userService.getUserInfo();
    this.schoolId = info.school_id;
    this.loadData(1);
  }
  loadData(page: any) {
    this.restoreDelId = 0;
    this.loadingData = true;
    this.showClassTeacher = false;
    this.trashSvc.getItemsList(page).then(items => {
      this.displayData = items.rows;
      this.totalNumber = items.total;
      this.currentPageIndex = page;
      this.loadingData = false;
    }).catch((err: any) => {
      this.loadingData = false;
      this.nzMessageService.error(`发生错误：${err.message}`);
    });
  }
  restoreStudent(trash_id: any) {
    this.restoreDelId = trash_id;
    this.loadingData = true;
    this.showClassTeacher = true;
  }
  deleteTeacher(id: any) {
    this.userService.removeTeacher(id).then(classes => {
      if (classes.code === 200) {
        this.loadData(1);
        this.currentPageIndex = 1;
      } else {
        this.nzMessageService.error(classes.message);
      }
    }).catch(e => {
      this.nzMessageService.error(e.message);
    });
  }
  restoreStudentCancel () {
    console.log('restoreStudentCancel');
    this.showClassTeacher = false;
  }
  dataLoaded() {
    this.loadingData = false;
  }
  restoreStudentOk (classId: any) {
    console.log('restoreStudentOk');
    this.showClassTeacher = false;
    this.loadingData = true;
    this.trashSvc.restore(this.restoreDelId, classId).then(r => {
      this.loadData(1);
    }).catch((err: any) => {
      this.nzMessageService.error(err.message || '发生错误');
    });
  }
}

