// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserType } from '../../../../../model/Const';


@Component({
  selector: 'app-class-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class ClassIndexComponent implements OnInit {
  displayData: {
    class_id: any,
    class_name: any,
    teacher: any,
  }[] = [];
  totalClasses = 0;
  currentPageIndex = 1;
  user: any = null;
  constUserType = UserType;
  deleteingClasses = false;

  disabledButton = true;
  allChecked = false;
  indeterminate = false;
  checkedNumber = 0;

  searchBoxVisible = false;
  searchLoading = false;
  searchValue = '';
  searchResult = '';

  loadingData = false;
  private school_id = 0;

  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.school && parseInt(query.school, 10) === query.school * 1) {
        this.school_id = parseInt(query.school, 10);
        // if (this.user.type === this.constUserType.SUPER_ADMIN) {
        //   this.user = {
        //     ...this.user,
        //     school_id
        //   };
        // }
      }
    });
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.page && parseInt(query.page, 10) === query.page * 1) {
        this.currentPageIndex = parseInt(query.page, 10);
      }
    });
  }

  ngOnInit() {
    this.loadData(this.currentPageIndex || 1);

  }
  loadData(page: number) {
    this.loadingData = true;
    let srv: any = this.schoolService;
    // @ts-ignore
    if (this.user.type === UserType.TEACHER) {
      srv = this.clsService;
    }
    srv.fetchClasses('all', {school_id: this.school_id}).then((classes: any) => {
      this.displayData = classes.rows;
      this.totalClasses = classes.total;
      this.currentPageIndex = page;
      this.setTmpNumId();
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(`错误：${err.message}`);
      this.loadingData = false;
    });

  }
  deleteClass(id: any) {
    console.log('delete class');
    this.schoolService.removeClassBySchool(id).then(classes => {
      this.loadData(1);
      this.currentPageIndex = 1;
    }).catch(e => {
      this.nzMessageService.error(e.message);
    });
  }
  editClass(id: any) {
    this.router.navigate([`/admin/classes/edit/${id}`] , {queryParams: { page: this.currentPageIndex }});
  }
  manageStudents(class_id: any) {
    this.router.navigate([`/admin/classes/${class_id}/students`] , {queryParams: { page: this.currentPageIndex }});
  }
  statisticStudents(class_id: any) {
    this.router.navigate([`/admin/classes/${class_id}/statistics`] , {queryParams: { page: this.currentPageIndex }});
  }

  deleteClasses() {
    this.deleteingClasses = true;
    const cids = this.displayData.filter((value: any) => value.checked)
      .map((r: any) => {
        return r.class_id;
      });
    console.log('teacher delete class');
    this.schoolService.removeClassesBySchool(cids).then(classes => {
      if (classes && classes.code === 500) {
        this.nzMessageService.error(classes.message);
        // this.refreshStatus();
        // return;
      }

      this.loadData(1);
      this.currentPageIndex = 1;
      this.refreshStatus();

      this.deleteingClasses = false;
    }).catch(e => {
      this.deleteingClasses = false;
      this.nzMessageService.error(e.message);
    });
  }

  checkAll(value: boolean): void {
    this.displayData.forEach((data: any) => data.checked = value);
    this.refreshStatus();
  }
  refreshStatus(): void {
    const allChecked = this.displayData.every((value: any) => value.checked === true);
    const allUnChecked = this.displayData.every((value: any) => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    this.disabledButton = !this.displayData.some((value: any) => value.checked);
    this.checkedNumber = this.displayData.filter((value: any) => value.checked).length;
  }

  searchBtnClick() {
    const regu = /^1[3456789]\d{9}$/;
    const re = new RegExp(regu);
    if (!re.test(this.searchValue)) {
      this.nzMessageService.error('电话号格式有误!');
      return;
    }
    // console.log('23423423423423423423')
    const data = {phone: this.searchValue};
    this.searchLoading = true;

    this.userService.checkUserExist(data).then(resps => {
      console.log(98, resps);
      this.searchLoading = false;
      if (!resps) {
        this.nzMessageService.error('查无此人!');
        return;
      } else {
        this.searchBoxVisible = true;
        const msg = [];
        for (const resp of resps) {
          let _msg = `姓名:${resp.name} \&nbsp\&nbsp\&nbsp 电话:${resp.phone}`;
          if (resp.class_name) {
            _msg += ` \&nbsp\&nbsp\&nbsp 班级:${resp.class_name} \&nbsp\&nbsp\&nbsp 老师:${resp.teacher_name}`;
          }
          msg.push(_msg);
        }
        this.searchResult = msg.join('<br>');
        /*`姓名:${resp.name} \&nbsp\&nbsp\&nbsp 电话:${resp.phone}`;
      if (resp.class_name) {
        this.searchResult += ` \&nbsp\&nbsp\&nbsp 班级:${resp.class_name} \&nbsp\&nbsp\&nbsp 老师:${resp.teacher_name}`;
      }*/
      }

    });
  }
  handleCancel() {
    this.searchBoxVisible = false;
  }
  handleOk() {
    this.searchBoxVisible = false;
  }
  setTmpNumId() {
    for (let i = 0; i < this.displayData.length; i++) {
      // @ts-ignore
      this.displayData[i].numId = i + 1 + (this.currentPageIndex - 1) * 10;
    }
  }
}
