import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ElpService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  fetchAllElpFirmware() {
    return super.get(`/speaker/device/firmware/list`);
  }



  addElpFirmware(value: any) {
    return super.post(`/speaker/device/firmware/new`, value);
  }
  saveElpFirmwareWhitelist(id: any, whitelist: any) {
    return super.post(`/speaker/device/firmware/${id}/whitelist`, {whitelist} );
  }

  fetchLogList(query: {pageIndex: number, pageSize?: number, device_id?: string}) {
    const searchParams = new URLSearchParams();
    // @ts-ignore
    Object.keys(query).forEach(key => searchParams.append(key, query[key]));
    return super.get(`/speaker/device/log/list?${searchParams.toString()}`);
  }
  fetchDebugDeviceList(query: {pageIndex: number, pageSize?: number}) {
    const searchParams = new URLSearchParams();
    // @ts-ignore
    Object.keys(query).forEach(key => searchParams.append(key, query[key]));
    return super.get(`/speaker/device/trace/list?${searchParams.toString()}`);
  }
  traceDevice(device_id: string, trace: boolean) {
    return super.post(`/speaker/device/trace`, {device_id, trace});
  }
  uploadDeviceLog(device_id: string) {
    return super.post(`/speaker/log/fetch`, {device_id});
  }











}
