<nz-week-picker
        [nzAllowClear]="false"
        [nzShowToday]="false"
        [(ngModel)]="startValue"
        nzPlaceHolder="Start"
        [nzDisabledDate]="disabledSunday"
        (ngModelChange)="onStartChange($event)"
        (nzOnOpenChange)="handleStartOpenChange($event)">
</nz-week-picker>
-
<nz-week-picker
        [nzDisabled]="true"
        [nzShowToday]="false"
        [nzAllowClear]="false"
        [nzDisabledDate]="disabledDate"
        style="margin-right: 1rem"
        [(ngModel)]="endValue"
        nzPlaceHolder="End"
        [nzOpen]="endOpen"
        (ngModelChange)="onEndChange($event)"
        (nzOnOpenChange)="handleEndOpenChange($event)">
</nz-week-picker>