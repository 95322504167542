import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from '../../../../../../services/user.service';
import { PromotionService } from '../../../../../../services/promotion.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-promotions-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class PromotionsAddComponent implements OnInit {
  // @ts-ignore
  validateForm: UntypedFormGroup;
  promoId = null;
  submiting = false;
  constructor(private pSrv: PromotionService,
              private fb: UntypedFormBuilder,
              private modalService: NzModalService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    const info = this.userService.getUserInfo();

    this.activatedRoute.params.subscribe( (params: any) => {
      if (params.id && parseInt(params.id, 10) === params.id * 1) {
        // @ts-ignore
        this.promoId = parseInt(params.id, 10);
      }
    });

  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      title: ['', [Validators.required]],
      desc: ['', [Validators.required]],
      rangePickerTime: ['', [Validators.required]],
      school_type: ['0', [Validators.required, Validators.min(0), Validators.max(2)]],
      promo_price: ['0', [Validators.required, Validators.min(0.01), Validators.max(999)]],
      from_quantity: ['0', [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0)]],
      gift_quantity: ['0', [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0)]],
      days: ['365', [Validators.required, Validators.pattern(/^\d+$/), Validators.min(0)]],
    });
    if (this.promoId) {
      this.pSrv.getPromotion(this.promoId).then(r => {
        this.validateForm.controls['title'].setValue(r.title);
        this.validateForm.controls['desc'].setValue(r.desc);
        this.validateForm.controls['rangePickerTime'].setValue([r.start_date, r.end_date]);
        this.validateForm.controls['school_type'].setValue(`${r.school_type}`);
        this.validateForm.controls['promo_price'].setValue(r.promo_price);
        this.validateForm.controls['from_quantity'].setValue(r.from_quantity);
        this.validateForm.controls['gift_quantity'].setValue(r.gift_quantity);
        this.validateForm.controls['days'].setValue(r.days);

      });
    }

  }
  submitForm() {

    const data = {
      title: this.validateForm.value['title'],
      desc: this.validateForm.value['desc'],
      school_type: this.validateForm.value['school_type'],
      start_date: new Date(this.validateForm.value['rangePickerTime'][0]).getTime() ,
      end_date: new Date(this.validateForm.value['rangePickerTime'][1]).getTime() ,
      promo_price: this.validateForm.value['promo_price'],
      from_quantity: this.validateForm.value['from_quantity'],
      gift_quantity: this.validateForm.value['gift_quantity'],
      days: this.validateForm.value['days'],
    };
    console.log(this.validateForm.value,  data);
    this.submiting = true;
    if (this.promoId) {
      // @ts-ignore
      data['id'] = this.promoId;
    }
    this.pSrv.addPromotion(data).then(res => {
      this.submiting = false;
      this.router.navigate([`/admin/promotions/index`]);
    }).catch((err: any) => {
      this.submiting = false;
    });
  }

}
