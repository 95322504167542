import { EventEmitter } from '@angular/core';

export class VideoDelegate {
  videoEl = null;
  public videoPlayTimestamp = new EventEmitter();
  public videoDataLoaded = new EventEmitter();
  public videoPlayBarPosition = new EventEmitter();
  public videoPlayEnd = new EventEmitter();
  public videoLoadError = new EventEmitter();
  public videoWaiting = new EventEmitter();
  public videoPlaying = new EventEmitter();
  // @ts-ignore
  syncAudioCurrentTimeId: number = null;
  // private arrayBuffer = null;
  formatter = new Intl.NumberFormat('en', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
    useGrouping: false,
  });
  // @ts-ignore
  private playToTime: number;
  constructor(videoElement: HTMLVideoElement) {
    // @ts-ignore
    this.videoEl = videoElement;
    // @ts-ignore
    this.videoEl.onloadeddata = this.onVideoDataLoaded.bind(this);
    // @ts-ignore
    this.videoEl.onplay = this.onVideoPlay.bind(this);
    // @ts-ignore
    this.videoEl.onpause = this.onVideoPause.bind(this);
    // @ts-ignore
    this.videoEl.ontimeupdate = this.onVideoTimeUpdate.bind(this);
    // @ts-ignore
    this.videoEl.onratechange = this.onVideoRateChange.bind(this);
    // @ts-ignore
    this.videoEl.onended = this.onVideoEnded.bind(this);
    // @ts-ignore
    this.videoEl.onerror = (e) => {
      this.videoLoadError.emit(e);
    };
    // @ts-ignore
    this.videoEl.onwaiting = (e) => {
      console.log('onwaiting', e);
      this.videoWaiting.emit(e);
    };

    // this.videoEl.onsuspend = (e) => {
    //   console.log('onsuspend', e);
    //   // this.videoWaiting.emit(e);
    // };
    // @ts-ignore
    this.videoEl.ontimeupdate = (e: any) => {
      // console.log('ontimeupdate', e);
      this.videoPlaying.emit(e);
    };

  }
  set playbackRate(val: number) {
    // @ts-ignore
    this.videoEl.playbackRate = val;
  }

  set src(val) {
    // @ts-ignore
    this.videoEl.src = val;
  }
  get src() {
    // @ts-ignore
    return this.videoEl.src;
  }
  convertTagToTime(tag: string) {
    tag = tag.replace('[', '');
    tag = tag.replace(']', '');
    const parts = tag.split(':');
    let h = 0;
    let m = 0;
    let s = 0;
    if (parts.length === 3) {
      h = parseInt(parts[0], 10);
      m = parseInt(parts[1], 10);
      s = parseInt(parts[2], 10);
    } else if (parts.length === 2) {
      m = parseInt(parts[0], 10);
      s = parseFloat(parts[1] );
    }

    return h * 60 * 60 + m * 60 + s;
  }
  convertTimeToTag(time: number, withBracket = true): string {
    if (time === undefined) {
      return '';
    }
    const hh = Math.floor(time / 60 / 60)
      .toString()
      .padStart(2, '0');
    const mm = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const ss = this.formatter.format(time % 60);

    return withBracket ? `[${hh}:${mm}:${ss}]` : `${hh}:${mm}:${ss}`;
  }
  // setSource(ab) {
  //   this.arrayBuffer = ab;
  //   const blob = new Blob([ab], { type: 'audio/wav' });
  //   this.videoEl.src = URL.createObjectURL(blob);
  // }
  // getDataBuffer() {
  //   return this.arrayBuffer;
  // }

  // getBufferClip(start, end) {
  //   return this.arrayBuffer.slice(start * this.arrayBuffer.length, end * this.arrayBuffer.length);
  // }
  load() {
    // @ts-ignore
    this.videoEl.load();
  }
  syncAudioCurrentTime() {
    // @ts-ignore
    const currentTime = this.videoEl.currentTime;

    this.videoPlayTimestamp.emit({
      timeFormat: this.convertTimeToTag(currentTime, false),
      currentTime
    });
    if (this.playToTime &&  currentTime >= this.playToTime) {
      this.pause();
      // @ts-ignore
      this.playToTime = null;
      return;
    }
    this.syncAudioCurrentTimeId = requestAnimationFrame(() => {
      this.syncAudioCurrentTime();
    });
  }
  onVideoDataLoaded(evt: any) {
    console.log('onVideoDataLoaded', evt);
    this.videoDataLoaded.emit();
  }
  onVideoPlay() {
    this.syncAudioCurrentTimeId = requestAnimationFrame(() => {
      this.syncAudioCurrentTime();
    });
    console.log('onVideoPlay');
  }
  onVideoPause() {
    console.log('onVideoPause');
    cancelAnimationFrame(this.syncAudioCurrentTimeId);
  }
  onVideoEnded() {
    console.log('onVideoEnded');
    this.videoPlayEnd.emit();
    cancelAnimationFrame(this.syncAudioCurrentTimeId);
  }
  onVideoTimeUpdate() {
    // console.log('onVideoTimeUpdate', this.convertTimeToTag(this.videoEl.currentTime));
    // this.videoPlayTimestamp.emit(this.convertTimeToTag(this.videoEl.currentTime));
  }
  onVideoRateChange() {
    console.log('onVideoRateChange');
  }
  get isPlaying() {
    // @ts-ignore
    return !!(this.videoEl.currentTime > 0
      // @ts-ignore
      && !this.videoEl.paused
      // @ts-ignore
      && !this.videoEl.ended
      // @ts-ignore
      && this.videoEl.readyState > 2);
  }

  currentTimeFormatted(time?: number) {
    // @ts-ignore
    let t = this.videoEl.currentTime;
    if (typeof time !== 'undefined') {
      t = time;
    }
    return this.convertTimeToTag(t, false);
  }

  get currentTime() {
    // @ts-ignore
    return this.videoEl.currentTime;
  }
  set currentTime(val) {
    this.videoPlayBarPosition.emit({
      time: val
    });
    // @ts-ignore
    this.videoEl.currentTime = val;
  }
  get dimensions () {
    // @ts-ignore
    return {height: this.videoEl.videoHeight, width: this.videoEl.videoWidth};
  }
  get duration() {
    // @ts-ignore
    return this.videoEl.duration;
  }
  get durationFormatted() {
    // @ts-ignore
    return this.convertTimeToTag(this.videoEl.duration, false);
  }
  get currentSrc() {
    // @ts-ignore
    return this.videoEl.currentSrc;
  }
  pause() {
    // @ts-ignore
    this.videoEl.pause();
  }
  async play(beginTime?: number, toTime?: number) {
    // this.playTo
    if (beginTime) {
      // @ts-ignore
      this.videoEl.currentTime = beginTime;
    }
    if (toTime) {
      this.playToTime = toTime;
    } else {
      // @ts-ignore
      this.playToTime = null;
    }
    // @ts-ignore
    return this.videoEl.play();
  }
}
