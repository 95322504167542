import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class SchoolService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  addClass(class_data: any) {
    return super.post('/class/add', class_data);
  }
  saveClass(id: string|number, class_data: any) {
    return super.post(`/class/edit/${id}`, class_data);
  }
  fetchTeachersBySchool( page: string|number) {
    return super.get(`/school/teachers/page/${page}`).then((resp) => {
      return resp;
    });
  }
  fetchAllTeachersBySchool() {
    return super.get(`/school/teachers/all`).then((resp) => {
      return resp;
    });
  }
  moveClassToSchool(data: any) {
    return super.post(`/system/update-classes-school`, data);
  }
  fetchClasses(page: string|number, query: any) {
    let url = `/school/classes/page/${page}`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }
  removeClassBySchool(id: string|number) {
    return super.remove(`/school/class/${id}`);
  }
  removeClassesBySchool(ids: any) {
    return super.post(`/school/delete/classes`, {ids});
  }
  fetchAllSchoolBySA(page: string|number, query: any) {
    let url = `/schools/page/${page}`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }

    return super.get(url);
  }
  disableSchoolBySA(school_id: string|number) {
    return super.remove(`/schools/${school_id}`);
  }
  enableSchoolBySA(school_id: string|number) {
    return super.post(`/schools/${school_id}`, {});
  }
  setSchoolTypeBySA(school_id: string|number, type: any) {
    return super.post(`/schools/${school_id}/type`, {type});
  }
  checkSchoolExist(data: any) {
    return super.post('/school/check', data);
  }

  getRemainAccountInfo() {
    return super.get('/school/remain-account');
  }
  setSettings(school_id: string|number, settings: any) {
    return super.post(`/school/${school_id}/settings`, {settings});
  }
}
