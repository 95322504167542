<div class="teacher-class-view">
  <div class="view-body">
    <app-user-header (onChangeType)="changeView($event)"></app-user-header>
    <div class="content book">
      <div class="group">
        <nz-select style="width: 180px;margin-right: 12px;"
                   (ngModelChange)="changeGroup($event)"
                   [(ngModel)]="currentGroupId" >
          <nz-option *ngFor="let c of allGroups; let idx = index;" [nzValue]="c.id+'|'+c.type" [nzLabel]="c.name"></nz-option>
        </nz-select>
        <nz-input-group class="search-input" nzSearch [nzSuffix]="suffixIconButton" style="width: 200px">
          <input type="text" nz-input placeholder="输入绘本名称" [(ngModel)]="sWord"  (keyup)="searchBooks($event)">
        </nz-input-group>
        <ng-template #suffixIconButton>
          <button style="background: #ffd600;border-color: #d8d8d8;line-height: 1.2;"
                  (click)="searchBooks($event)"
                  nz-button nzType="primary" nzSearch><i nz-icon nzType="search" nzTheme="outline"></i></button>
        </ng-template>
      </div>
      <div class="level-bar">
        <ul class="levels">
          <!--<li (click)="chooseLevel(0)" [ngClass]="{'active': currentLevelId === 0}" ><div>全部</div></li>-->
          <li class="item" *ngFor="let l of allLevels"
              [ngClass]="{'active': currentLevelId === l.id}"
              (click)="chooseLevel(l)"><div>{{l.name}}</div></li>
        </ul>

      </div>

        <div class="books" style="flex: 1 1 auto;overflow-x: hidden;"
             [scrollWindow]="false"
             infinite-scroll
             [infiniteScrollDistance]="scrollDistance"
             [infiniteScrollThrottle]="throttle"
             (scrolled)="onScrollDown($event)">


          <div class="books-container" >
            <ng-container *ngIf="loading">
              <div style="text-align: center;padding-top: 2rem;">
                <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
              </div>
            </ng-container>

            <ng-container *ngIf="loading==false && books.length == 0">
              <h4 style="text-align: center">该分类下暂无绘本</h4>
            </ng-container>
            <div nz-row nzGutter="16" *ngIf="loading==false &&  books.length != 0">
                <div nz-col class="gutter-row" nzSpan="6" *ngFor="let book of books">
                  <div class="gutter-box " style="cursor: pointer"  (click)="openBook(book.storybook_id)">
                    <div class="cover k-16-9">
                      <img src="{{book.cover}}" alt="">
                    </div>
                    <div class="book-name">{{book.title}}</div>
                  </div>
                  <div class="text-center">

                    <!--[routerLink]="'/user/books/' + book.storybook_id+'/resource'"-->
                    <!--[target]="'_blank'"-->
                    <button
                            (click)="showBookResource(book)"
                            class="res-btn"
                            style="color: #333;
                     text-decoration: none;
                     border-radius: 6px;
                     background: #ffd600;
                     line-height: 1.2;
                     border: none;
                     padding: 4px 8px;
                     margin-top: 8px;">相关资源</button>
                  </div>

                </div>
              </div>

          </div>
          <div [ngStyle]="{'display': loadingNewBooks ? 'block' : 'none'}" class="loading-holder">
            <i nz-icon [nzType]="'sync'"></i><span>loading......</span></div>
        </div>


    </div>
  </div>
</div>

