<div class="table-operations">
    <button nz-button nzType="primary" [nzSize]="'large'">
        <i nz-icon nzType="usergroup-add" nzTheme="outline"></i>添加班级</button>
</div>
<nz-table #classTable [nzData]="displayData">
    <thead>
    <tr>
        <th>id</th>
        <th>班级</th>
        <th>教师</th>
        <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <td>{{data.id}}</td>
        <td>{{data.title}}</td>
        <td>{{data.teacher}}</td>
        <td></td>
    </tr>
    </tbody>
</nz-table>
