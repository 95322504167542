import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { OrderService } from '../../../../../services/order.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-manual-add-order-component',
  template: `
      <form nz-form 
            #manualOrderFormEl 
            [formGroup]="manualOrderForm" 
            (ngSubmit)="submitForm($event, manualOrderForm.value)">
          <nz-form-item>
              <nz-form-label [nzSpan]="7" nzRequired>录入数量</nz-form-label>
              <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="quantityErrorTpl">
                  <nz-input-number style="width: 200px"  
                                   formControlName="quantity" 
                                   [maxlength]="4"  
                                   [nzMax]="9999" [nzMin]="1" [nzStep]="1"></nz-input-number>
                  <ng-template #quantityErrorTpl let-control>
                      <ng-container *ngIf="control.hasError('email')">
                          The input is not valid E-mail!
                      </ng-container>
                      <ng-container *ngIf="control?.hasError('required')">
                          请正确填写学校购买数量
                      </ng-container>
                  </ng-template>
              </nz-form-control>
          </nz-form-item>
          <nz-form-item>
              <nz-form-label [nzSpan]="7" nzRequired>支付金额</nz-form-label>
              <div>
                  <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确填写用户支付金额">
                      <!--<input nz-input type="text" formControlName="price" />-->
                      <nz-input-number style="width: 200px" 
                                       formControlName="price"
                                       [maxlength]="6"
                                       [nzMax]="999999" 
                                       [nzMin]="0" 
                                       [nzStep]="0.01"></nz-input-number>
                  </nz-form-control>
              </div>
          </nz-form-item>
          <nz-form-item>
              <nz-form-label [nzSpan]="7" nzRequired>使用天数</nz-form-label>
              <div>
                  <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="P请正确填写本次订单的用户使用时间">
                      <!--<input nz-input type="text" formControlName="days" />-->
                      <nz-input-number style="width: 200px"  
                                       formControlName="days"
                                       [maxlength]="3"
                                       [nzMax]="999" 
                                       [nzMin]="1" [nzStep]="1"></nz-input-number>
                  </nz-form-control>
              </div>
          </nz-form-item>
          <nz-form-item>
              <nz-form-label [nzSpan]="7" nzRequired>备注</nz-form-label>
              <nz-form-control [nzSpan]="12" nzErrorTip="请正确输入本次订单的备注!">
                  <textarea formControlName="comment" nz-input rows="2" placeholder="write any thing"></textarea>
              </nz-form-control>
          </nz-form-item>
          <nz-divider style="margin-left: -24px;
    margin-right: -24px;
    width: 110%;"></nz-divider>
          <nz-form-item>
              <nz-form-control [nzSpan]="24" style="text-align: right">
                  <button nz-button (click)="close($event)" style="margin-right: 1rem">关闭</button>
                  <button nz-button nzType="primary" [nzLoading]="submitting" [disabled]="!manualOrderForm.valid">确定</button>
              </nz-form-control>
          </nz-form-item>
      </form>
    
    
  `,
  styles: [

    `
    `
  ]
})
export class ModalManualAddOrderComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @Input() school_id;
  manualOrderForm: UntypedFormGroup;
  @ViewChild('manualOrderFormEl', {static: true})
    // @ts-ignore
  manualOrderFormEl: ElementRef;
  submitting = false;

  constructor(private modal: NzModalRef,
              private orderService: OrderService,
              private nzMessageService: NzMessageService,
              private fb: UntypedFormBuilder) {
    this.manualOrderForm = this.fb.group({
      quantity: ['', [Validators.required, Validators.min(1), Validators.pattern(/\d+/)]],
      price: ['', [Validators.pattern(/^\d+(,\d{3})*(\.\d{1,2})?$/), Validators.required, Validators.min(0)]],
      days: ['', [Validators.required, Validators.min(1), Validators.pattern(/\d+/)]],
      comment: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    // this.orderService.getOrderInfo(this.id).then(order => {
    //   console.log(order);
    //   // const m = this.modal.getInstance();
    //   // m.nzContent = 'sssss';
    // });
    // const m = this.modal.getInstance();
    // m.afterClose.subscribe((rrr) => {
    //   console.log(8, rrr);
    // });
    this.submitting = false;
  }

  ngOnDestroy(): void {
    // console.log(22222);
    // this.modal.destroy({ subsidyDesc: this.subsidyDesc, subsidyPicList: this.subsidyPicList });
  }
  close(e: any) {
    e.preventDefault();
    this.manualOrderForm.reset();
    for (const key in this.manualOrderForm.controls) {
      this.manualOrderForm.controls[key].markAsPristine();
      this.manualOrderForm.controls[key].updateValueAndValidity();
    }
    // console.log(1111111);
    this.modal.destroy(false);
    // this.modal.triggerCancel();
  }
  submitForm(evt: any, value: any): void {
    for (const key in this.manualOrderForm.controls) {
      this.manualOrderForm.controls[key].markAsDirty();
      this.manualOrderForm.controls[key].updateValueAndValidity();
    }
    console.log(value);
    if (this.manualOrderForm.status !== 'VALID') {
      return;
    }
    this.submitting = true;
    this.orderService.manualAddOrder({...value, school_id: this.school_id }).then(r => {
      this.submitting = false;
      this.modal.destroy();
      this.nzMessageService.success('补录成功');
    }).catch(e => {
      this.nzMessageService.error(e.message);
      this.submitting = false;
      this.modal.destroy();
    });
  }
}
