import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SystemService } from '../../../services/system.service';


@Component({
  selector: 'app-add-students',
  templateUrl: './add-students.component.html',
  styleUrls: ['./add-students.component.scss']
  // providers: [NzRangePickerComponent],
})
export class AddStudentsComponent /*extends  NzRangePickerComponent*/ implements OnInit, OnDestroy, AfterViewInit {

  students = '';
  isSaving = false;
  @Input()
  classId = 0;

  @Output()
  addOk = new EventEmitter();
  constructor( private sysSrv: SystemService,
               private nzMessageService: NzMessageService) {

  }


  ngOnInit() {
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
  }
  save() {
    this.isSaving = true;
    // console.log(this.students);
    // console.log(this.selectedClass.id);
    // console.log(this.selectedTeacher.teacher_id);
    // console.log(this.selectedSchool.id);
    const students = this.students.split('\n');
    const new_s = [];
    let line = 0;
    for (let s of students) {
      line ++;
      s = s.trim();
      s = s.replace(/[\t ,#，]/g, '|');
      const ps = s.split('|');
      if (ps.length !== 2) {
        this.nzMessageService.error(`格式错误，${line}行，不能正确分割出手机号和名字`);
        this.isSaving = false;
        return;
      }
      let p = ps[0];
      p = p.trim();
      if (!/^1[3456789]\d{9}$/.test(p)) {
        this.nzMessageService.error(`格式错误，${line}行，手机号错误`);
        this.isSaving = false;
        return;
      }
      let n = ps[1];
      n = n.trim();
      if (!n) {
        this.nzMessageService.error(`格式错误，${line}行，拆分名字错误`);
        this.isSaving = false;
        return;
      }
      new_s.push({
        name: n,
        phone: p
      });
    }

    this.sysSrv.addStudents(this.classId, new_s ).then(r => {
      this.isSaving = false;
      this.nzMessageService.success('保存成功');
    }).catch(e => {
      console.log(e);
      this.isSaving = false;
      this.nzMessageService.error(e.message + (e.data ? e.data.message : ''), { nzDuration: 10000 });
    });

  }
}
