import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ElsService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
    // console.log(22222222222222)
    // debugger
    // this.getInfoPromise = this.get('/user/getInfo');
  }



  elsSearch(data: any) {
    return super.post(`/els-mgr/user/audit`, data);
  }

}
