<div class="table-operations">
    <button nz-button nzType="primary"
            [queryParams]="{ page: returnPage }"
            [routerLink]="'/admin/classes/index'"
            [nzSize]="'large'">
        <!--(click)="prService.goBack({ page: returnPage }, '/admin/classes/index')"-->


        <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    <button *ngIf="false" [nzLoading]="openclassing"
            nz-button nzType="default"
            [nzSize]="'large'" (click)="grantAllStudentsInClass()">
        <i nz-icon nzType="team" nzTheme="outline"></i>
        开通班级
    </button>

    <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/classes/'+classId+'/students/add'">
        <i nz-icon nzType="team" nzTheme="outline"></i>添加新学生</button>


    <!--<button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/classes/'+classId+'/students/batch_add'">-->
        <!--<i nz-icon type="team" theme="outline"></i>批量添加学生-->
    <!--</button>-->



    <button  nz-button nzType="dashed"  [nzSize]="'large'"
            [nzLoading]="isLoadingTeacherCLass"
            (click)="changeStudentClass()">
        <i nz-icon nzType="team" nzTheme="outline"></i>调整班级</button>
<!--    <button  nz-button nzType="dashed"  [nzSize]="'large'"-->
<!--             *ngIf="isSA"-->
<!--             [nzLoading]="isLoadingTeacherCLass"-->
<!--             (click)="changeStudentClass()">-->
<!--        <i nz-icon nzType="team" nzTheme="outline"></i>转校</button>-->

    <nz-modal [(nzVisible)]="classChangeVisible" nzTitle="调整班级" (nzOnCancel)="closeChangeStudentClass()" (nzOnOk)="handleChangeOk()">
        <ng-container *nzModalContent>
        <div class="gutter-example">
            <div nz-row nzGutter="16">
                <div nz-col class="gutter-row" nzSpan="14">
                    <div class="gutter-box">
                        <nz-select style="width: 100%;"
                                   [(ngModel)]="selectedTeacher"
                                   (ngModelChange)="doSelectTeacher($event)"
                                   nzPlaceHolder="Select Teacher" nzAllowClear>
                            <nz-option *ngFor="let t of teacherList" [nzValue]="t" [nzLabel]="t.name+'|'+t.phone"></nz-option>
                        </nz-select>
                    </div>
                </div>
                <div nz-col class="gutter-row" nzSpan="10">
                    <div class="gutter-box">
                        <nz-select style="width: 100%;"
                                   [(ngModel)]="selectedClass"
                                   nzPlaceHolder="Select Class" nzAllowClear>
                            <nz-option *ngFor="let c of classList" [nzValue]="c" [nzLabel]="c.name"></nz-option>
                        </nz-select>
                    </div>
                </div>
            </div>
        </div>
        </ng-container>
    </nz-modal>


<!--    <nz-modal [(nzVisible)]="confirmChangeVisible" nzTitle="调整班级" *ngIf="this.confirmChangeVisible"-->
<!--                [nzOkLoading]="changeClassButtonLoading"-->
<!--              (nzOnCancel)="closeConfirm()"-->
<!--              (nzOnOk)="sureConfirm()">-->
<!--        <ng-container *nzModalContent>-->
<!--        <h1 style="color: red" page="3">是否确认？</h1>-->
<!--        <label >-->
<!--            {{changeClassText}}-->
<!--        </label>-->
<!--        &lt;!&ndash;<h3>{{selectedTeacher.name}} to {{selectedClass.name}}</h3>&ndash;&gt;-->
<!--        </ng-container>-->
<!--    </nz-modal>-->






    <!-- a nz-popconfirm nzTitle="确定批量删除学生？"
       (nzOnConfirm)="deleteStudents()" >
        <button nz-button nzType="danger" [nzSize]="'large'" [disabled]="disabledButton">
            <i nz-icon nzType="team" nzTheme="outline"></i>
            批量删除学生</button>
    </a -->

    <!--<ng-container *ngIf="schoolMaster">-->


     <!---->



    <!--</ng-container>-->
    <!--<ng-container *ngIf="showBatchAddBtn">-->
        <!--<nz-upload-->
                <!--[nzAccept]="'*.csv'"-->
                <!--[nzBeforeUpload]="batchAddStudents"-->
                <!--nzAction="/users/batch-add-users">-->
            <!--<button nz-button [nzSize]="'large'">-->
                <!--<i nz-icon type="upload"></i><span>批量添加学生</span>-->
            <!--</button>-->
        <!--</nz-upload>-->
        <!--<nz-modal [(nzVisible)]="errStudentDataVisible" nzTitle="错误学生数据" (nzOnCancel)="handleErrStuCancel()" (nzOnOk)="handleErrStuOk()">-->
            <!--<p style="color: red">请记录后再点击退出或确认，点击后自动加载新数据</p>-->
            <!--<p *ngFor="let d of errStus">{{d}}</p>-->
        <!--</nz-modal>-->
    <!--</ng-container>-->
    <!--&lt;!&ndash;<button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/class/'+classId+'/students/add'">&ndash;&gt;-->
        <!--&lt;!&ndash;<i nz-icon type="team" theme="outline"></i>添加已有学生</button>&ndash;&gt;-->





</div>
<!--<nz-modal [(nzVisible)]="deleteStudentConfirm" nzTitle="确认学生数据" (nzOnCancel)="closeDeleteStudentConfirm()" (nzOnOk)="sureDeleteStudentConfirm()">-->
<!--    <ng-container *nzModalContent>-->
<!--    <p style="color: red">确定将学生移除该班级吗？</p>-->
<!--    <p style="color: red">确认移除吗？？？</p>-->
<!--    </ng-container>-->
<!--</nz-modal>-->
<div *ngIf="className" class="table-operations">
    <h3>{{className}} | {{teacher}}</h3>
    <span style="color: #999;" *ngIf="classComment">备注： {{classComment}}</span>
</div>
<nz-table #classTable [nzData]="displayData"
          [nzFrontPagination]="false"
          [nzPageSize]="10"
          [nzLoading]="loadingData"
          [(nzPageIndex)]="currentPageIndex"
          (nzPageIndexChange)="loadData($event)"
          [nzTotal]="totalClasses">
    <thead>
    <tr>
        <th nzShowCheckbox [(nzChecked)]="allChecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="checkAll($event)"></th>
        <!--<th>序号</th>-->
        <th>ID</th>
        <th>姓名</th>
        <th>电话</th>
        <th *ngIf="PAYG_TYPE != schoolType">APP开通状态/开通操作人</th>
        <th>金币总数</th>
        <th style="text-align: center">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <td nzShowCheckbox [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <!--<td>{{data.numId}}</td>-->
        <td>{{data.user_id}}</td>
        <td>{{data.user_name}}</td>
        <td>{{data.phone}}</td>
        <td *ngIf="PAYG_TYPE != schoolType">
            <span *ngIf="data.isExpired">未开通
                <span *ngIf="isAccountManager" >,<a style="color: #1890ff" (click)="grantUser(data.user_id)">开通APP使用权限</a></span>
                <a *ngIf="!isAccountManager">,请联系校长开通APP使用权限</a>
            </span>
            <span *ngIf="!data.isExpired">{{data.expired}}到期/{{data.operator}}/<a  *ngIf="isAccountManager" style="color: #1890ff" (click)="grantUser(data.user_id)">续费</a></span>
        </td>
        <td>{{data.coins||0}}</td>
        <td style="width: 10rem;">
            <div class="operations">
                <!--
                <ng-container *ngIf="isAccountManager && data.isExpired">
                    <a nz-popconfirm nzTitle="确定开通该学生？"
                       (nzOnConfirm)="grantUser(data.id)">开通</a>
                </ng-container>
                -->

                <a (click)="editStudent(data.user_id)">编辑</a>
                <a nz-popconfirm nzPopconfirmTitle="确定删除学生？"
                   (nzOnConfirm)="deleteStudentReConfirm(data)" >
                    删除
                </a>

            </div>

        </td>
    </tr>
    </tbody>
</nz-table>

<nz-modal [(nzVisible)]="accountInfoSuccess"
          nzOkText="开通"
          [nzOkLoading]="loadingData"
          [nzOkDisabled]="accountDaysTypeRemainCount == 0"
          (nzOnCancel)="handleCancelOpenAccountDialog()" (nzOnOk)="handleOpenAccount()"
          nzTitle="开通提示" >
    <ng-container *nzModalContent>
    <div nz-row style="margin-bottom: 2rem" *ngIf="accountInfo && accountInfo.length != 0">
        <div nz-col [nzSpan]="4">可用帐号：</div>
        <div nz-col [nzSpan]="20">
            <nz-radio-group  [(ngModel)]="accountDaysType" (ngModelChange)="changeOpenStudentAccount($event)" >
                <label *ngFor="let t of accountInfo" nz-radio
                       nzValue="{{t.days}}">{{t.days}}天(剩余{{t.count}}人)</label>
            </nz-radio-group>
        </div>
    </div>


    <div *ngIf="accountInfo && accountInfo.length == 0" style="margin-bottom: 2rem">
        无法开通学生，剩余帐号数量不足（剩余0），请校长进行购买！
    </div>

    <nz-alert nzType="warning"
              nzMessage="提示"
              [nzDescription]="'帐号开通后，不可撤销/不可退款！如果您是老师，请确认您的学生已经缴费，只有交费的学生才可以开通帐号！'"
    ></nz-alert>
    </ng-container>
</nz-modal>
<nz-modal [(nzVisible)]="accountInfoError">bbbb</nz-modal>


