import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ClassService } from '../../../../../services/class.service';
import { StudentService } from '../../../../../services/student.service';
import { SystemService } from '../../../../../services/system.service';
import { UserType } from '../../../../../model/Const';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-student-super-manage-index',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class StudentsSuperManageIndexComponent implements OnInit {
  displayData: any[] = [];
  totalNum = 0;
  currentPageIndex = 1;
  classId = 0;
  className = '';
  classComment = '';
  errStudentDataVisible = false;
  errStus = [];
  showBatchAddBtn = false;

  disabledButton = true;
  allChecked = false;
  indeterminate = false;
  checkedNumber = 0;

  classChangeVisible = false;
  isLoadingTeacherCLass = false;

  teacherList: any[] = [];
  classList: any[] = [];
  teacherCache = {};
  classCache = {};

  selectedTeacher = null;
  selectedClass = null;
  confirmChangeVisible = false;
  returnPage = 1;

  deleteStudentConfirm = false;

  delStudent: any = null;

  changeClassText = '';
  teacher = '';
  userInfo: any;
  // @ts-ignore
  private allListPage: boolean;
  loadingData = false;

  UserType = UserType;


  searchNameValue = '';
  searchPhoneValue = '';
  searchSchoolNameValue = '';
  // @ts-ignore
  @ViewChild('dropdown', {static: false}) phoneSearchMenu: NzDropDownModule;
  // @ts-ignore
  @ViewChild('dropdown1', {static: false}) nameSearchMenu: NzDropDownModule;
  // @ts-ignore
  @ViewChild('dropdownSchool', {static: false}) schoolnameSearchMenu: NzDropDownModule;

  querySearch = {};
  private currentSchoolId = 0;


  constructor(private userService: UserService,
              private stuService: StudentService,
              private clsService: ClassService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private sysSrv: SystemService,
              private modal: NzModalService,
              private nzMessageService: NzMessageService) {
    this.userInfo = this.userService.getUserInfo();
    // this.schoolMaster = this.userInfo.type === UserType.SCHOOL_ADMIN;
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query['page'] && parseInt(query['page'], 10) === query['page'] * 1) {
        this.returnPage = parseInt(query['page'], 10);
      }
    });
    const info = this.userService.getUserInfo();
    if (info.school_id) {
      this.currentSchoolId = info.school_id;
    }
  }

  ngOnInit() {
    this.loadData(this.currentPageIndex);
    // this.loadClassData();
  }
  loadData(page: string|number) {
    this.loadingData = true;
    // @ts-ignore
    this.stuService.fetchStudentsBySchoolId(page, this.querySearch, this.userInfo.school_id).then(students => {
      console.log('学校花名册', students.rows);
      this.displayData = students.rows;
      this.totalNum = students.total;
      // this.className = students.class_name;
      // this.classComment = students.class_comment;
      // @ts-ignore
      this.currentPageIndex = page;
      this.refreshStatus();

      this.loadingData = false;
    }).catch(err => {
      this.nzMessageService.error(`error: ${err.message}`);
    });
  }

  loadClassData() {
    const srv = this.clsService;
    this.clsService.fetchClassAndTeacher(this.classId).then(data => {
      this.className = data[0].class_name;
      this.teacher = data[0].teacher;
    });
  }
  closeDeleteStudentConfirm() {
    this.delStudent = null;
    this.deleteStudentConfirm = false;
  }
  sureDeleteStudentConfirm() {
    if (!this.delStudent) {
      return;
    }
    this.userService.delete(this.delStudent.class_id, this.delStudent.user_id).then(classes => {
      this.loadData(this.currentPageIndex);
      // this.currentPageIndex = 1;
      this.deleteStudentConfirm = false;
    }).catch(e => {
      this.deleteStudentConfirm = false;
      this.nzMessageService.error(e.message);
    });
    this.delStudent = null;
  }
  deleteStudentReConfirm(data: any) {
    this.delStudent = data;
    this.deleteStudentConfirm = true;
    this.modal.confirm({
      nzTitle: '确认删除学生数据',
      nzContent: `<b style="color: red;">如删除学生，则该学生的汇报数据，作业数据将全部清零</b>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.sureDeleteStudentConfirm(),
      nzCancelText: 'No'
    });
  }
  deleteStudents() {
    // @ts-ignore
    const ids = this.displayData.filter(value => value.checked).map( u => u.user_id);
    this.userService.removeStudents(this.classId, ids).then(classes => {
      this.loadData(this.currentPageIndex);
      // this.currentPageIndex = 1;
    });
  }
  editStudent(classId: string|number, id: string|number) {
    this.router.navigate([`/admin/classes/${classId}/students/edit/${id}`]);
  }
  batchAddStudents = (file: File) => {
    const data = new FormData();
    data.append('file', file);
    this.userService.batchAddUsers(this.classId , data).then(r => {
      console.log(r);
      this.errStus = r;
      this.errStudentDataVisible = true;
    });
    return false;
  }
  handleErrStuCancel() {
    this.loadData(1);
    this.errStudentDataVisible = false;
  }
  handleErrStuOk()  {
    this.loadData(1);
    this.errStudentDataVisible = false;
  }
  checkAll(value: boolean): void {
    // @ts-ignore
    this.displayData.forEach(data => data.checked = value);
    this.refreshStatus();
  }
  refreshStatus(): void {
    // @ts-ignore
    const allChecked = this.displayData.every((value: any) => value.checked === true);
    // @ts-ignore
    const allUnChecked = this.displayData.every((value: any) => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
    // @ts-ignore
    this.disabledButton = !this.displayData.some((value: any) => value.checked);
    // @ts-ignore
    this.checkedNumber = this.displayData.filter((value: any) => value.checked).length;
  }
  changeStudentSchool() {
    if (this.checkedNumber > 0) {
      // if (Object.keys(this.teacherCache).length && Object.keys(this.classCache).length) {
      //   // this.classChangeVisible = true;
      //   return;
      // }
      // @ts-ignore
      const checked = this.displayData.filter(value => value.checked);
      // @ts-ignore
      const users_id = checked.map(r => r.user_id);
      const school_id = prompt('转移到其他学校的学校id（请在学校列表里搜索）');
      if (school_id) {
        this.sysSrv.updateStudentSchool(school_id, users_id).then(data => {
          this.loadData(this.currentPageIndex);
        }).catch(err => {
          this.nzMessageService.error(err.message);
        });
      }
    }
  }
  changeStudentClass() {
    if (this.checkedNumber > 0) {
      // if (Object.keys(this.teacherCache).length && Object.keys(this.classCache).length) {
      //   this.classChangeVisible = true;
      //   return;
      // }

      const info = this.userService.getUserInfo();
      // @ts-ignore
      const checked = this.displayData.filter(value => value.checked);
      // @ts-ignore
      let school_ids = checked.map(r => r.school_id).filter(r => !!r);
      school_ids = Array.from(new Set(school_ids));
      if (school_ids.length > 1) {
        this.nzMessageService.error('所选学生在多个不同学校');
        return;
      }
      this.isLoadingTeacherCLass = true;
      this.classChangeVisible = true;
      this.selectedTeacher = null;
      this.selectedClass = null;
      this.sysSrv.loadTeacherAndClasses(school_ids[0]).then(result => {

        this.classCache = {};
        console.log('result:', result);
// @ts-ignore
        this.teacherCache[info.school_id] = result.teachers;
        for (const k of Object.keys(result.teachers)) {
          const t = result.teachers[k];
          // @ts-ignore
          if (this.classCache[t.teacher_id]) {
            // @ts-ignore
            this.classCache[t.teacher_id] = [...this.classCache[t.teacher_id], ...result.classes[t.teacher_id]];
          } else {
            // @ts-ignore
            this.classCache[t.teacher_id] = result.classes[t.teacher_id] ? result.classes[t.teacher_id] : [] ;
          }
        }
        this.teacherList = Object.values(result.teachers);

        this.isLoadingTeacherCLass = false;

      });
    } else {
      this.nzMessageService.error('请先勾选要调整的学生');
    }
  }
  closeChangeStudentClass() {
    this.classChangeVisible = false;
    this.isLoadingTeacherCLass = false;
  }
  doSelectTeacher(teacher: any) {
    this.selectedTeacher = teacher;
    this.classList = [];
    this.selectedClass = null;
    // @ts-ignore
    if (this.classCache[teacher.teacher_id]) {
      // @ts-ignore
      this.classList = this.classCache[teacher.teacher_id] as any;
    } else {
      alert('no classes for this teacher');
    }
  }
  setChangeClassText() {
      let str = '将 ';
      let len = 0;
      for (let i = 0; i <  this.displayData.length; i++) {
        // @ts-ignore
          if (this.displayData[i].checked) {
              len ++ ;
            // @ts-ignore
              str += (this.displayData[i].name || this.displayData[i].user_name) + ' 、';
          }
      }
      str = str.substr(0, str.length - 1);
    // @ts-ignore
      str += `移动到 ${this.selectedTeacher.name} 老师的 ${this.selectedClass.name} 吗？`;
      this.changeClassText = str;
  }
  handleChangeOk() {
    this.confirmChangeVisible = true;
    this.setChangeClassText();
    this.modal.confirm({
      nzTitle: '调整班级',
      nzContent: `<b style="color: red;">${this.changeClassText}</b>`,
      nzOkText: 'Yes',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.sureConfirm(),
      nzCancelText: 'No'
    });
  }
  closeConfirm() {
    this.confirmChangeVisible = false;
  }
  sureConfirm() {
    console.log('in sureConfirm');
    // @ts-ignore
    if (!this.selectedClass.id) {
      this.nzMessageService.error('请正确选择班级');
      return;
    }
    // @ts-ignore
    const uids = this.displayData.filter(value => value.checked).map(r => {
      return {
        user_id: r.user_id,
        class_id: r.class_id,
        school_id: r.school_id,
        row_id: r.class_user_row_id
      };
    });
    console.log(this.selectedClass);
    console.log(uids);
    // @ts-ignore
    this.stuService.changeClass(this.selectedClass.id, uids).then(r => {
      this.loadData(this.currentPageIndex);
      this.confirmChangeVisible = false;
      this.classChangeVisible = false;

    }).catch(e => {
      this.nzMessageService.error(e.message);
    });
  }


  reCalcStudentLotteryConfirm(user_id: any) {
    this.loadingData = true;
    this.sysSrv.calcUserLottery(user_id).then(r => {
      console.log(r);
      this.loadData(this.currentPageIndex);
    }).catch(e => {
      this.nzMessageService.error(e.message);
      this.loadingData = false;
    });
  }



  goClassStudentsList(class_id: string|number) {
    // this.router.navigate([`/admin/classes/edit/${class_id}`], { queryParams: {page: this.currentPageIndex}});
    this.router.navigate([`/admin/classes/${class_id}/students/index`], { queryParams: {page: this.currentPageIndex}});
  }



  resetName() {
    this.searchNameValue = '';
    // @ts-ignore
    this.nameSearchMenu['closeMenu']();
  }

  resetPhone() {
    this.searchPhoneValue = '';
    // @ts-ignore
    this.phoneSearchMenu['closeMenu']();
  }

  resetSchool() {
    this.searchSchoolNameValue = '';
    // @ts-ignore
    this.phoneSearchMenu['closeMenu']();
  }


  search() {
    // @ts-ignore
    this.querySearch ['name'] = this.searchNameValue;
    // @ts-ignore
    this.querySearch ['phone'] = this.searchPhoneValue;
    this.loadData(this.currentPageIndex);
    // @ts-ignore
    this.phoneSearchMenu ['closeMenu']();
    // @ts-ignore
    this.nameSearchMenu['closeMenu']();
  }

  searchSchool() {
    // @ts-ignore
    this.querySearch ['name'] = this.searchNameValue;
    // @ts-ignore
    this.querySearch ['phone'] = this.searchPhoneValue;
    // @ts-ignore
    this.querySearch ['school'] = this.searchSchoolNameValue;

    this.loadData(this.currentPageIndex);
    // @ts-ignore
    this.phoneSearchMenu ['closeMenu']();
    // @ts-ignore
    this.nameSearchMenu['closeMenu']();
    // @ts-ignore
    this.schoolnameSearchMenu['closeMenu']();
  }

}
