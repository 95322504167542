// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from '../../../../../../services/user.service';
import { PromotionService } from '../../../../../../services/promotion.service';

@Component({
  selector: 'app-admin-promotions-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class PromotionsIndexComponent implements OnInit {
  dataSet: any[] = [];
  totalNum = 0;
  currentPageIndex = 1;
  loadingData = false;



  constructor(private pSrv: PromotionService,
              private modalService: NzModalService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    const info = this.userService.getUserInfo();

  }

  ngOnInit() {
    this.loadData(1);

  }
  loadData(page) {
    this.loadingData = true;

    this.pSrv.getPromotions(page).then(data => {
      console.log(data);
      this.dataSet = data.rows;
      this.totalNum = data.total;
      this.currentPageIndex = page;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(`错误：${err.message}`);
      this.loadingData = false;
    });

  }
  deletePromotion (id) {
    this.pSrv.deletePromotion(id).then(data => {
      this.loadData(this.currentPageIndex);
    }).catch((err: any) => {
      this.nzMessageService.error(`错误：${err.message}`);
    });

  }
  onEdit(id) {
    this.router.navigate([`/admin/promotions/edit/${id}`]);
  }

}
