import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

// import {NzI18nService, NzMessageService, NzRangePickerComponent} from 'ng-zorro-antd';

@Component({
  selector: 'app-nz-week-range',
  templateUrl: './week-range.component.html',
  styleUrls: ['./week-range.component.scss']
  // providers: [NzRangePickerComponent],
})
export class WeekRangeComponent /*extends  NzRangePickerComponent*/ implements OnInit, OnDestroy, AfterViewInit {
  //
  startValue: any = null;
  endValue: any = null;
  endOpen = false;

  @Output()
  weekRangeChanged = new EventEmitter();
  constructor( ) {

  }
  /* disabledStartDate = (startValue: Date): boolean => {
    console.log(22, startValue)
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() > this.endValue.getTime();
  }

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  } */
  getWeekRangeDay (d: Date) {
    const dt = new Date(d);
    const currentWeekDay = dt.getDay();
    const lessDays = currentWeekDay === 0 ? 6 : currentWeekDay - 1;
    const start = new Date(new Date(dt).setDate(dt.getDate() - lessDays));
    const end = new Date(new Date(start).setDate(start.getDate() + 6));
    return {start, end};
  }
  getWeekNumber(d: Date) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    // @ts-ignore
    const weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }



  getWeekOfYear(now: any) {
    if (!now) {
      now = new Date();
    }

    const jan: any = new Date(now.getFullYear(), 0, 1);
    const today: any = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const dayOfYear = ((today - jan + 86400000) / 86400000);
    return Math.ceil(dayOfYear / 7);


    // const onejan: any = new Date(new Date().getFullYear(), 0, 1);
    // const week = Math.ceil( (((now - onejan) / 86400000) + onejan.getDay() + 1) / 7 );
    // return week;
  }
  onStartChange(date: Date): void {
    console.log('onStartChange', date);
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    } else {
      this.endOpen = false;
    }
    console.log('handleStartOpenChange', open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    const ss = this.getWeekNumber(this.startValue);
    const s = this.getWeekOfYear(this.startValue);
    const e = this.getWeekOfYear(this.endValue);
    console.log(open, this.startValue, s, this.endValue, e);
    const d = {
      [s]: this.startValue,
      [e]: this.endValue
    };
    const ws = [s, e];
    ws.sort();
    // const _tmp = new Date(this.startValue);
    // @ts-ignore
    this.startValue = d[ws[0] + ''];
    // @ts-ignore
    this.endValue = d[ws[1] + ''];
    this.endOpen = open;
    const {start} = this.getWeekRangeDay(this.startValue);
    const {end} = this.getWeekRangeDay(this.endValue);
    this.weekRangeChanged.emit({start, end});
  }
  disabledSunday = (current: Date): boolean => {

    return current.getDay() === 0 ;
  }
  disabledDate = (current: Date): boolean => {
    const {start, end} = this.getWeekRangeDay(this.startValue);
    // const curr = new Date();
    // start.setMonth(start.getMonth() + 1);
    // const first = this.getWeekFirstDay(this.startValue);
    // const firstDate = new Date(first);
    // const max_last = new Date(start.setDate(start.getDay() + 35));
    const cond1 = current < start;
    start.setDate(start.getDate() + 35);
    return current.getDay() === 0 || cond1 || current > start;
  }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
  }
}
