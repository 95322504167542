import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { SchoolService } from '../../../../../services/school.service';
import { SCHOOL_TYPE } from '../../../../../model/Const';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ModalManualAddOrderComponent } from './manual-add-order.component';

@Component({
  selector: 'app-schools-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class SchoolsIndexComponent implements OnInit {
  displayData: any[] = [];
  allSchoolData = null;
  totalNum = 0;
  currentPageIndex = 1;
  loading = false;
  listOfType = [
    { text: '直营校', value: SCHOOL_TYPE.OWNED },
    { text: '加盟校', value: SCHOOL_TYPE.FRANCHISED },
    { text: '按量付费校', value: SCHOOL_TYPE.PAYG },
    { text: '未设置', value: SCHOOL_TYPE.NONE }
  ];

  searchObj = {};
  searchValue = '';



  isSplitSchoolVisible = false;
  isSplitSchoolLoading = false;
  selectedSplitTargetSchool = 0;
  optionSchoolList: any[] = [];
  displaySearchSchoolTips = false;
  isLoadingSplitSchoolClasses = false;
  // displaySplitSchoolClasses = false;
  splitSchoolClassList: any[] = [];
  splitSchoolFrom = 0;
  splitSchoolClassWithTeacher = false;

  splitSchoolClassStorage = [];
  isSplittingSchool = false;


// @ts-ignore
  @ViewChild('dropdown', {static: false}) schoolNameSearchMenu: NzDropDownModule;

  constructor(private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private modalService: NzModalService,
              private nzMessageService: NzMessageService) {
    this.activatedRoute.queryParams.subscribe( query => {
      if (query['page'] && parseInt(query['page'], 10) === query['page'] * 1) {
        this.currentPageIndex = parseInt(query['page'], 10);
      }
    });
    // @ts-ignore
    console.log(this.schoolNameSearchMenu);
    // @ts-ignore
    window.test = this;
  }

  ngOnInit() {
    this.loadData(this.currentPageIndex);
  }
  loadData(page: any) {
    this.loading = true;
    this.schoolService.fetchAllSchoolBySA('all', this.searchObj).then(data => {
      // console.log(99999, this.searchObj);
      this.displayData = data.rows;
      if (!this.allSchoolData) {
        this.allSchoolData = data.rows;
      }
      this.totalNum = data.total;
      this.currentPageIndex = page;
      this.loading = false;
    }).catch(e => {
      this.loading = false;
    });
  }
  splitSchool(id: any) {
    this.isSplitSchoolVisible = true;
    this.splitSchoolFrom = id;

    this.isLoadingSplitSchoolClasses = true;
    // this.displaySplitSchoolClasses = true;
    this.schoolService.fetchClasses('all', {
      school_id: this.splitSchoolFrom
    }).then((classes) => {
      this.isLoadingSplitSchoolClasses = false;
      console.log(classes);
      this.splitSchoolClassList = classes.rows;
    });


  }
  onSearchSplitTargetSchool(value: any) {
    if (value && value.length > 1) {
      const ss = this.allSchoolData || [];
      this.optionSchoolList = ss.filter((item: any) => (item.school_name.indexOf(value) > -1 && item.id !== this.splitSchoolFrom));
      this.displaySearchSchoolTips = false;
    } else {
      this.optionSchoolList = [];
      this.displaySearchSchoolTips = true;
    }
  }
  __displaySchoolClasses() {
    console.log(this.selectedSplitTargetSchool);
    this.isLoadingSplitSchoolClasses = true;
    // this.displaySplitSchoolClasses = true;
    this.schoolService.fetchClasses('all', {
      school_id: this.splitSchoolFrom
    }).then((classes) => {
      this.isLoadingSplitSchoolClasses = false;
      console.log(classes);
      this.splitSchoolClassList = classes.rows;
    });
  }
  selectSplitSchool(school: any) {
    console.log('selectSplitSchool', school);
  }
  changeSplitSchool(school: any) {
    console.log('changeSplitSchool', school);
    // this.displaySplitSchoolClasses = false;
  }
  selectSplitSchoolClass(evt: any) {
    console.log('selectSplitSchoolClass', evt);
  }
  changeSplitSchoolClass(evt: any) {
    console.log('changeSplitSchoolClass', evt);
    if (evt.from === 'left' && evt.to === 'right') {
      this.splitSchoolClassStorage = this.splitSchoolClassStorage.concat(evt.list);
    }
    if (evt.from === 'right' && evt.to === 'left') {
      evt.list.forEach((r: any) => {
        const idx = this.splitSchoolClassStorage.findIndex((h: any) => {
          return h.class_id === r.class_id;
        });
        this.splitSchoolClassStorage.splice(idx, 1);
      });

    }
    console.log(this.splitSchoolClassStorage);
  }

  closeSplitSchoolModal() {
    this.isSplitSchoolVisible = false;
    this.isSplitSchoolVisible = false;
    this.isSplitSchoolLoading = false;
    this.selectedSplitTargetSchool = 0;
    this.optionSchoolList = [];
    this.displaySearchSchoolTips = false;
    this.isLoadingSplitSchoolClasses = false;
    this.splitSchoolClassList = [];
    this.splitSchoolFrom = 0;
    this.splitSchoolClassWithTeacher = false;

    this.splitSchoolClassStorage = [];
  }
  doSplitSchool() {
    // console.log(this.selectedSplitTargetSchool);
    if (!this.splitSchoolClassStorage.length) {
      this.nzMessageService.error('请选择目标班级');
      return;
    }
    const classes = this.splitSchoolClassStorage.map((c: any) => c.class_id);
    if (!this.selectedSplitTargetSchool) {
      this.nzMessageService.error('请选择目标学校');
      return;
    }
    this.isSplittingSchool = true;

    this.schoolService.moveClassToSchool({
      school_id: this.selectedSplitTargetSchool,
      classes,
      // withTeacher: this.splitSchoolClassWithTeacher,
    }).then(() => {
      this.nzMessageService.success('转移成功');
      this.isSplittingSchool = false;
      this.closeSplitSchoolModal();
    }).catch(e => {
      this.nzMessageService.error('转移失败');
      this.isSplittingSchool = false;
    });
  }



  disableSchool(id: any) {
    this.schoolService.disableSchoolBySA(id).then(data => {
      this.loadData(this.currentPageIndex);
      // this.currentPageIndex = 1;
    }).catch(e => {
      this.loading = false;
    });
  }
  enableSchool(id: any) {
    this.schoolService.enableSchoolBySA(id).then(data => {
      this.loadData(this.currentPageIndex);
      // this.currentPageIndex = 1;
    }).catch(e => {
      this.loading = false;
    });
  }
  editSchool(id: any) {
    this.router.navigate([`/admin/schools/edit/${id}`], {queryParams: { page: this.currentPageIndex }});
  }
  filterType(evt: any): void {
    console.log(evt);
    // @ts-ignore
    this.searchObj['type'] = evt;
    this.loadData(1);
    // this.listOfSearchName = listOfSearchName;
    // this.searchAddress = searchAddress;
    // this.search();
  }
  changeType(id: any, type: any) {
    this.loading = true;
    this.schoolService.setSchoolTypeBySA(id, type).then(data => {
      this.loadData(this.currentPageIndex);
    }).catch(e => {
      this.loading = false;
    });
  }
  search() {
    // @ts-ignore
    if (this.schoolNameSearchMenu['closeMenu'] && this.schoolNameSearchMenu['closeMenu'].call) {
      // @ts-ignore
      this.schoolNameSearchMenu['closeMenu']();
    }
    // @ts-ignore
    this.searchObj['search'] = this.searchValue;
    this.loadData(1);
  }
  reset() {
    // @ts-ignore
    if (this.schoolNameSearchMenu['closeMenu'] && this.schoolNameSearchMenu['closeMenu'].call) {
      // @ts-ignore
      this.schoolNameSearchMenu['closeMenu']();
    }
    this.searchValue = '';
    // @ts-ignore
    delete this.searchObj['search'];
    this.loadData(1);
  }

  toggleMicroAppStatus(school_id: string|number, microAppStatus: number) {
    if (microAppStatus === 0) {
      microAppStatus = 1;
    } else {
      microAppStatus = 0;
    }
    this.schoolService.setSettings(school_id, {microAppStatus}).then(data => {
      this.loadData(this.currentPageIndex);
    });
  }


  addSchoolOrders(school_id: any) {
    const mod = this.createCheckModal('补录信息', ModalManualAddOrderComponent, {school_id});
    // const m = mod.getContentComponent();
    mod.afterClose.subscribe(res => {
       console.log(9, res);
    });
  }
  createCheckModal(title = '提示', tplContent: any, data?: {}, okCb?: Function, errCb?: Function): NzModalRef {
    const self = this;
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: tplContent,
      nzComponentParams: data,
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null
      /*
      nzFooter: [
        {
          label: '关闭',
          type: 'default',
          loading: false,
          onClick(): void {
            if (errCb) {
              errCb();
            }
            modal.destroy();
          }
        },
        {
          label: '确定',
          type: 'primary',
          loading: false,
          onClick(): void {
            console.log(modal);
            // const m = modal.getInstance();
            // m.nzFooter[0].disabled = true;
            // this.loading = true;
            if (okCb) {
                okCb(modal);
            }

          }
        },
      ]
      */
    });
    return modal;
  }
  /*
  checkSubsidy(id) {
    console.log(id);

    const self = this;
    const modal = this.modalService.create({
      nzTitle: '补贴审核',
      nzContent: OrderInfoComponent,
      nzComponentParams: {id},
      nzClosable: false,
      // nzOkText: '通过',
      // nzCancelText: '驳回',
      nzFooter: [
        {
          label: '通过',
          type: 'primary',
          loading: false,
          onClick(): void {

            self.modalService.confirm({
              nzTitle: '提示',
              nzContent: '确认添加补贴吗',
              nzOnOk: () => {
                this.loading = true;
                self.orderService.changeSubsidyStatus({
                  id,
                  status: 2
                }).then(data => {
                  this.loading = false;
                  self.modalService.closeAll();
                  self.loadData(self.currentPageIndex);
                }).catch(err => {
                  this.nzMessageService.error(err.message);
                  this.loading = false;
                });
              },
              nzOnCancel: () => {
              }
            });
          }

        },
        {
          label: '驳回',
          type: 'danger',
          loading: false,
          onClick(): void {
            this.loading = true;
            const m = modal.getInstance();
            m.nzFooter[0].disabled = true;
            m.nzFooter[2].disabled = true;
            this.rejectReason = '';
            const okCb = (reject_mod) => {

              self.orderService.changeSubsidyStatus({
                id,
                status: 1,
                reason: self.rejectReason
              }).then(data => {
                this.loading = false;
                self.modalService.closeAll();
                self.loadData(self.currentPageIndex);
                reject_mod.destory();
              }).catch(err => {
                this.nzMessageService.error(err.message);
                this.loading = false;
              });


            }
            const errCb = () => {
              this.loading = false;
              m.nzFooter[0].disabled = false;
              m.nzFooter[2].disabled = false;
            }
            const mod = self.createCheckModal(
              '驳回原因',
              self.rejectContent, {id}, okCb, errCb);
            // const m = mod.getContentComponent();
            // mod.afterClose.subscribe(res => {
            //   self.orderService.changeSubsidyStatus({
            //     id,
            //     status: 1,
            //     reason: self.rejectReason
            //   }).then(data => {
            //     this.loading = false;
            //     self.modalService.closeAll();
            //     self.loadData(self.currentPageIndex);
            //   }).catch(err => {
            //     this.nzMessageService.error(err.message);
            //     this.loading = false;
            //   });
            // });

          }
        },
        // {
        //   label: 'async load',
        //   type: 'dashed',
        //   onClick: () => new Promise(resolve => setTimeout(resolve, 2000))
        // }
        {
          label: '关闭',
          shape: 'default',
          onClick: () => modal.destroy()
        },
      ],
      nzAfterOpen: this.checkSubsidyModelOpen,
      // nzOnCancel: () => {
      //
      // },
      // nzOnOk: () => {
      //   console.log(modal);
      //   this.orderService.getOrderInfo(id).then(order => {
      //     const m = modal.getInstance();
      //     m.nzContent = 'sssss';
      //   });
      //
      //   return false;
      //   // modal.nzContent = '1'
      // }
    });
  }
  reSubmitSubsidy(id) {
    // const mod = this.createCheckModal('提交信息', SubsidyProofComponent, {id});
    // // const m = mod.getContentComponent();
    // mod.afterClose.subscribe(res => {
    //    console.log(9, res);
    // });

    const self = this;
    const modal = this.modalService.create({
      nzTitle: '提交信息',
      nzContent: SubsidyProofComponent,
      nzComponentParams: {id},
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: [
        {
          label: '关闭',
          type: 'default',
          loading: false,
          onClick(): void {
            modal.destroy();
          }
        },
        {
          label: '确定',
          type: 'primary',
          loading: false,
          onClick: function(componentInstance) {
            this.loading = true;
            const data = {id, subsidyDesc: componentInstance.subsidyDesc, subsidyPicList: componentInstance.subsidyPicList }
            console.log(data);
            self.orderService.reSubmitSybsidy(data).then(() => {
              self.modalService.closeAll();
              self.loadData(self.currentPageIndex);
            }).catch(err => {
              this.loading = false;
            });
            // componentInstance!.title = 'title in inner component is changed';
          }
        },
      ]
    });
    return modal;
  } */
}
