/* tslint:disable max-line-length */
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { Injectable, Injector, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AdminLoginComponent } from './components/admin/login/login.component';
// import {HomeComponent} from './components/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors';
import { GoBackDirective } from './directives/go-back.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';


// NzMessageService NzModalService
// import { NzMessageService } from 'ng-zorro-antd/message';
// import { NzModalService } from 'ng-zorro-antd/modal';
import { AllReadingV2NgZorroAntdModule } from './ng-zorro-antd.module';

// import { NgZorroAntdModule } from 'ng-zorro-antd/public_api';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { DatePipe, NgOptimizedImage, registerLocaleData } from '@angular/common';
import { AutofocusSelectDirective } from './directives/autofocus-select.directive';
import { NoContentComponent } from './components/no-content/no-content.component';
import { UploadImageWithPreviewComponent } from './components/common/upload-image-with-preview/upload-image-with-preview.component';
import { UploadAudioComponent } from './components/common/upload-audio/upload-audio.component';
import { ResourcePipe } from './pipes/resource.pipe';
import { BackgroundImagePipe } from './pipes/background-image.pipe';
import { AdminHeaderComponent } from './components/admin/header/admin-header.component';
import { MoveDialogComponent } from './components/common/move-dialog/move-dialog.component';
import { UploadVideoComponent } from './components/common/upload-video/upload-video.component';
import { BrLineBreakerPipe } from './pipes/br-line-breaker.pipe';
import { TimePipe } from './pipes/time.pipe';
import { RepeatRenderDirective } from './directives/repeat-render.directive';
// import { LottieAnimationViewModule } from 'ng-lottie';
import { AdminComponent } from './components/admin/admin.component';
import { IndexComponent } from './components/admin/pages/index/index.component';
import { AdminAuthGuard } from './guard/admin-auth.guard';

import zh from '@angular/common/locales/zh';
import { NotFoundComponent } from './components/admin/404/not-found.component';
import { Globals } from './services/globals';
import { AdminBreadcrumbComponent } from './components/admin/breadcrumb/admin-breadcrumb.component';
import { StorybookAddComponent } from './components/admin/pages/storybook/add/storybook-add.component';
import { StorybookIndexComponent } from './components/admin/pages/storybook/index/storybook-index.component';
// import {TinyEditorComponent} from './components/common/tiny-editor/tiny-editor.component';
import { StorybookPreviewComponent } from './components/admin/pages/storybook/preview/storybook-preview.component';
import { StorybookMakeComponent } from './components/admin/pages/storybook/make/storybook-make.component';
import { OcrIndexComponent } from './components/admin/pages/ocr/index/ocr-index.component';
import { UserLoginComponent } from './components/frontend/pages/login/user-login.component';
import { UserClassesComponent } from './components/frontend/pages/classes/user-classes.component';
import { UserHeaderComponent } from './components/frontend/header/user-header.component';
import { UserAuthGuard } from './guard/user-auth.guard';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserBookPlayerComponent } from './components/frontend/pages/book-player/user-book-player.component';
import { ClassIndexComponent } from './components/admin/pages/classes/index/index.component';
import { TeachingIndexComponent } from './components/admin/pages/teachingmaterial/index/index.component';
import { ClassAddComponent } from './components/admin/pages/classes/add/add.component';
import { TeachersAddComponent } from './components/admin/pages/teachers/add/add.component';
import { TeachersIndexComponent } from './components/admin/pages/teachers/index/index.component';
import { UserBooksComponent } from './components/frontend/pages/books/user-books.component';
import { StudentsIndexComponent } from './components/admin/pages/students/index/index.component';
import { StudentsAddComponent } from './components/admin/pages/students/add/add.component';
import { SchoolsIndexComponent } from './components/admin/pages/schools/index/index.component';
import { SchoolsAddComponent } from './components/admin/pages/schools/add/add.component';
import { ClassStatisticsComponent } from './components/admin/pages/classes/statistics/statistics.component';
import { ClassStatisticsDetailComponent } from './components/admin/pages/classes/statistics-detail/statistics-detail.component';
import { ResourcesAudioIndexComponent } from './components/admin/pages/resources/audio/index/index.component';
import { ResourcesAudioAddComponent } from './components/admin/pages/resources/audio/add/add.component';
import { NgxSortableModule } from 'ngx-sortable';
import { ResourcesVideoAddComponent } from './components/admin/pages/resources/video/add/add.component';
import { ResourcesVideoIndexComponent } from './components/admin/pages/resources/video/index/index.component';
import { ClassStatisticsQuarterComponent } from './components/admin/pages/classes/statistics-quarter/statistics-quarter.component';
import { ClassStatisticsMonthlyComponent } from './components/admin/pages/classes/statistics-monthly/statistics-monthly.component';
import { WeekRangeComponent } from './components/common/week-range/week-range.component';
import { TabletManageIndexComponent } from './components/admin/pages/admin/tablet-manage/index/index.component';
import { AdminSearchComponent } from './components/admin/pages/admin/search/search.component';
import { AdminBatchAddUserComponent } from './components/admin/pages/admin/batch-add-user/batch-add-user.component';
import { AppManagerComponent } from './components/admin/pages/admin/app-manager/app-manager.component';
import { AddStudentsComponent } from './components/common/add-students/add-students.component';
import { StudentsBatchAddComponent } from './components/admin/pages/students/batch_add/batch_add.component';
import { AdminUserSettingComponent } from './components/admin/pages/admin/user-setting/user-setting.component';
import { OrdersIndexComponent } from './components/admin/pages/admin/orders/index/orders-index.component';
import { OrdersBuyComponent } from './components/admin/pages/admin/orders/buy/orders-buy.component';
import { WxPaymentComponent } from './components/common/wx-payment/wx-payment.component';
import { QRCodeModule } from 'angularx-qrcode';
import { OrderInfoComponent } from './components/admin/pages/admin/orders/index/order-info.component';
import { StudentsManageIndexComponent } from './components/admin/pages/students/manage/manage.component';
import { SubsidyProofComponent } from './components/admin/pages/admin/orders/subsidy-proof.component';
import { PreviousRouteService } from './services/previous-route.service';
import { PromotionsIndexComponent } from './components/admin/pages/admin/promotions/index/index.component';
import { PromotionsAddComponent } from './components/admin/pages/admin/promotions/add/add.component';
import { StudentsSuperManageIndexComponent } from './components/admin/pages/students/super-manage/manage.component';
import { UserBooksResourceComponent } from './components/frontend/pages/resource/user-books-resource.component';
import { ModalManualAddOrderComponent } from './components/admin/pages/schools/index/manual-add-order.component';
import { TrashIndexComponent } from './components/admin/pages/trash/index/index.component';
import { TeacherClassSelectorComponent } from './components/common/teacher-class-selector/teacher-class-selector.component';
import { GiftsIndexComponent } from './components/admin/pages/admin/coin-gift-storage/index/gifts-index.component';
import { GiftsAddComponent } from './components/admin/pages/admin/coin-gift-storage/add/gifts-add.component';
import { UserAuditIndexComponent } from './components/admin/pages/admin/user-audit/index/index.component';
import { LessonResourceVideoThemeComponent } from './components/admin/pages/admin/lesson-resource/theme/video.component';
import { LessonResourceAudioThemeComponent } from './components/admin/pages/admin/lesson-resource/theme/audio.component';
import { QupeiyinIndexComponent } from './components/admin/pages/admin/qupeiyin/index/qupeiyin-index.component';
import { QupeiyinFormComponent } from './components/admin/pages/admin/qupeiyin/form/qupeiyin-form.component';
import { ServiceLocator } from './services/locator.service';
import { ConfirmDeactivateGuard } from './guard/confirm-deactivate.guard';
import { CustomDragDirective } from './directives/drag.directive';
import { VideoCategoriesComponent } from './components/admin/pages/admin/pad/video-categories/video-categories.component';
import { PadVideoListComponent } from './components/admin/pages/admin/pad/video-list/pad-video-list.component';
// import {ServiceLocator} from './locator.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AudioRecorderComponent } from './components/common/audio-recorder/audio-recorder.component';
import { AppLogComponent } from './components/admin/pages/log-manager/app-log/app-log.component';
import { ElpFirmwareAddComponent } from './components/admin/pages/admin/elp-speaker/firmware/add/add.component';
import { ElpFirmwareIndexComponent } from './components/admin/pages/admin/elp-speaker/firmware/index/index.component';
import { UploadFileComponent } from './components/common/upload-file/upload-file.component';
import { TabletManageImportComponent } from './components/admin/pages/admin/tablet-manage/import/index.component';
import { ElpLogIndexComponent } from './components/admin/pages/admin/elp-speaker/logs/index.component';
import { TabletManageSingleIndexComponent } from './components/admin/pages/admin/tablet-manage-single/index/index.component';
import { ElpTraceIndexComponent } from './components/admin/pages/admin/elp-speaker/trace/index.component';
// import { ConfigCenterEdit } from './components/admin/pages/admin/config-center/edit/index.component';
// import { ConfigCenterIndex } from './components/admin/pages/admin/config-center/index/index.component';

registerLocaleData(zh);
const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    override overrides = <any>{
    'swipe': {velocity: 0.4, threshold: 20} // override default settings
  };
  // options: {};
}

const HammerProvider = {
  provide: HAMMER_GESTURE_CONFIG,
  useClass: MyHammerConfig
};

@NgModule({
    declarations: [
        AppComponent,
        CustomDragDirective,
        GoBackDirective,
        AutofocusSelectDirective,
        NoContentComponent,
        UploadImageWithPreviewComponent,
        UploadAudioComponent,
        AudioRecorderComponent,
        UploadFileComponent,
        UploadVideoComponent,
        ResourcePipe,
        BackgroundImagePipe,
        AdminHeaderComponent,
        MoveDialogComponent,
        BrLineBreakerPipe,
        TimePipe,
        RepeatRenderDirective,
        AdminBreadcrumbComponent,
        // TinyEditorComponent,
        NotFoundComponent,
        AdminComponent,
        AdminLoginComponent,
        IndexComponent,
        StorybookIndexComponent,
        StorybookAddComponent,
        StorybookPreviewComponent,
        StorybookMakeComponent,
        OcrIndexComponent,
        UserLoginComponent,
        UserHeaderComponent,
        UserClassesComponent,
        UserBookPlayerComponent,
        UserBooksComponent,
        ClassIndexComponent,
        TeachingIndexComponent,
        ClassAddComponent,
        TeachersAddComponent,
        TeachersIndexComponent,
        StudentsAddComponent,
        StudentsBatchAddComponent,
        StudentsIndexComponent,
        SchoolsAddComponent,
        SchoolsIndexComponent,
        ClassStatisticsComponent,
        ClassStatisticsDetailComponent,
        ResourcesAudioAddComponent,
        ResourcesAudioIndexComponent,
        ResourcesVideoAddComponent,
        ResourcesVideoIndexComponent,
        ClassStatisticsQuarterComponent,
        ClassStatisticsMonthlyComponent,
        WeekRangeComponent,
        WxPaymentComponent,
        TabletManageIndexComponent,
        AdminSearchComponent,
        AdminBatchAddUserComponent,
        AppManagerComponent,
        AddStudentsComponent,
        AdminUserSettingComponent,
        OrdersIndexComponent,
        OrdersBuyComponent,
        OrderInfoComponent,
        StudentsManageIndexComponent,
        SubsidyProofComponent,
        PromotionsIndexComponent,
        PromotionsAddComponent,
        StudentsSuperManageIndexComponent,
        UserBooksResourceComponent,
        ModalManualAddOrderComponent,
        TrashIndexComponent,
        TeacherClassSelectorComponent,
        GiftsIndexComponent,
        GiftsAddComponent,
        UserAuditIndexComponent,
        LessonResourceVideoThemeComponent,
        LessonResourceAudioThemeComponent,
        QupeiyinFormComponent,
        QupeiyinIndexComponent,
        PadVideoListComponent,
        VideoCategoriesComponent,
        AppLogComponent,
        ElpFirmwareAddComponent,
        ElpFirmwareIndexComponent,
        ElpLogIndexComponent,
        ElpTraceIndexComponent,
        TabletManageImportComponent,
        TabletManageSingleIndexComponent,
        // ConfigCenterEdit,
        // ConfigCenterIndex,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // NgZorroAntdModule,

    InfiniteScrollModule,
    NgxSortableModule,
    // LottieAnimationViewModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    QRCodeModule,
    ScrollingModule,
    DragDropModule,
    AllReadingV2NgZorroAntdModule,
    NgOptimizedImage
  ],
    providers: [
        httpInterceptorProviders,
        ConfirmDeactivateGuard,
        HammerProvider,
        { provide: NZ_I18N, useValue: zh_CN },
        { provide: NZ_ICONS, useValue: icons },
        AdminAuthGuard,
        UserAuthGuard,
        PreviousRouteService,
        Globals, DatePipe
        /* , JwtHelperService */
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {    // Create global Service Injector.
    ServiceLocator.injector = this.injector;
  }
}
