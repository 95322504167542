import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { ActivatedRoute, Router } from '@angular/router';
import { StorybookService } from '../../../../../services/storybook.service';

@Component({
  selector: 'app-storybook-preview',
  templateUrl: './storybook-preview.component.html',
  styleUrls: ['./storybook-preview.component.scss']
})
export class StorybookPreviewComponent implements OnInit, OnDestroy {
  storybookId = 0;
  resource: any = {};
  content: any = [];
  id = 0;
  title = '';
  text = '';
  audio = new Audio();
  maxDuration = 0;
  playTime = 0;
  loadedAudioMetaData = false;
  formatedPlayTime = '';
  timePoints = [];
  currentPageIndex = 0;
  // @ts-ignore
  @ViewChild('carousel', {static: true}) carousel: NzCarouselComponent;
  constructor(
    private storybookService: StorybookService,
    private msg: NzMessageService,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.activateRouter.params.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        this.storybookId = parseInt(params['id'], 10);

      }
    });
  }

  ngOnInit() {
    this.storybookService.fetch(this.storybookId).then(data => {
      this.resource = data.resource;
      this.content = data.content;
      console.log(data.content);
      // this.timePoints = this.content.map((row) => {
      //   return {start: row.start, end: row.end};
      // });
      this.id = data.id;
      this.title = data.title;
      this.audio.src = data.resource.audio;
      this.audio.addEventListener('loadedmetadata', this.audioLoadedMetaData);
      this.audio.addEventListener('loadeddata', this.audioCanPlay);
      this.audio.addEventListener('timeupdate', this.updateAudioTime);



      this.audio.addEventListener('error', (error) => {
        this.msg.error('audio file load error');
      });
    }).catch(e => {
      this.msg.error('get data error');
    });
  }
  audioCanPlay = () => {
    this.loadedAudioMetaData = true;
    const item = this.content[this.currentPageIndex];
    if (this.audio.readyState > 3) {
      // this.audio.currentTime = item.start;
      // this.audio.play();
      this.goAudioClip(this.currentPageIndex);
    }
  }
  audioLoadedMetaData = () => {
    console.log(this.audio.duration);
    this.maxDuration = parseInt(this.audio.duration + '', 10);

  }
  updateAudioTime = () => {
    this.playTime = parseInt(this.audio.currentTime + '', 10);
    this.formatTime(this.playTime);
    if (this.currentPageIndex === (this.content.length - 1)) {
      this.audio.pause();
      return;
    }
    const item = this.content[this.currentPageIndex];
    if (item.end === this.playTime) {
      this.currentPageIndex += 1;
      if (this.currentPageIndex > (this.content.length - 1)) {
        this.currentPageIndex = (this.content.length - 1);
      }
      this.goAudioClip(this.currentPageIndex);
      // this.carousel.goTo(this.currentPageIndex);
    }
  }
  goAudioClip(idx: number) {
    this.audio.pause();
    const item = this.content[idx];
    this.audio.currentTime = item.start;
    // this.audio.currentTime = item.start;
    this.audio.play();
    this.text = item.text;
    this.carousel.goTo(idx);
  }
  formatTime(time: number) {
    const sec = time % 60;
    const min = Math.floor(time / 60);
    const hour = Math.floor(time / (60 * 60));
    let t = `${min}:${sec}`;
    if (hour) {
      t = `${hour}:${t}`;
    }
    this.formatedPlayTime = t;
  }
  onBeforeChangeSlider(evt: any) {
    console.log(evt);
  }
  goNextSlider(evt: any) {
    this.currentPageIndex += 1;
    if (this.currentPageIndex >= this.content.length - 1) {
      this.currentPageIndex = this.content.length - 1;
    }
    this.goAudioClip(this.currentPageIndex);

  }
  goBackSlider (evt: any) {
    this.currentPageIndex -= 1;
    if (this.currentPageIndex <= 0) {
      this.currentPageIndex = 0;
    }
    this.goAudioClip(this.currentPageIndex);
  }
  ngOnDestroy() {
    this.audio.src = '';
  }
}
