// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, NavigationEnd, NavigationError, Params, PRIMARY_OUTLET, Router} from '@angular/router';
interface IBreadcrumb {
  label: string;
  params: Params;
  url: string;
}
@Component({
  selector: 'app-admin-breadcrumb',
  templateUrl: './admin-breadcrumb.component.html',
  styleUrls: ['./admin-breadcrumb.component.scss']
})
export class AdminBreadcrumbComponent implements OnInit {
  public  breadcrumbs: IBreadcrumb[];
  constructor(
    private activateRouter: ActivatedRoute,
    private router: Router,
  ) {
    this.breadcrumbs = [{label: '首页', params: {}, url: '/admin'}];
    this.router.events.subscribe((val) => {

      if (val instanceof NavigationError) {
        return;
      }
      // TODO check token expire and role
      if (val instanceof NavigationEnd) {
        const root  = this.activateRouter.root;
        this.breadcrumbs.concat(this.getBreadcrumbs(root)) ;
        console.log(this.breadcrumbs);
      }
    });
  }

  ngOnInit() {


  }
  getBreadcrumbs(router: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = [] ): IBreadcrumb[] {
    const children = router.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for ( const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (!child.snapshot.data.hasOwnProperty('breadcrumb')) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      const routeURL = child.snapshot.url.map(segment => segment.path).join('/');
      url += `/${routeURL}`;
      breadcrumbs.push ({
        label: child.snapshot.data['breadcrumb'],
        params: child.snapshot.params,
        url: url
      });
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }

}
