<div class="user-setting">

    <div *ngIf="!changePwFlag">


        <div class="table-operations">
            <button nz-button nzType="primary" [nzSize]="'large'" (click)="changePw()">
                修改密码
            </button>
        </div>


        <form class="form-warp" style="margin-top: 2rem;" nz-form [formGroup]="validateForm" (ngSubmit)="submitForm($event,validateForm.value)">
            <nz-form-item>
                <nz-form-label [nzSpan]="7" >{{nameTitle}}</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="请正确输入姓名">
                    <input nz-input formControlName="name" placeholder="姓名"  style="width: 200px">
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="7" >{{phoneTitle}}</nz-form-label>
                <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="phoneErrorTpl">
                    <input nz-input
                           style="width: 200px"
                           nz-input
                           formControlName="phone"
                           placeholder="电话">
                    <ng-template #phoneErrorTpl let-control>
                        <ng-container *ngIf="validateForm.get('phone')?.hasError('duplicated')">
                            此电话已存在！
                        </ng-container>
                        <ng-container *ngIf="validateForm.get('phone')?.hasError('required')">
                            请正确输入电话！
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item>
                <nz-form-control [nzOffset]="9" [nzSpan]="11">
                    <button nz-button nzSize="'large'" nzType="primary" [disabled]="!validateForm.valid">更改</button>
                </nz-form-control>
            </nz-form-item>
        </form>


        <nz-modal
                [(nzVisible)]="tipVisible"
                nzTitle="提示"
                nzContent="更改后密码将重置为电话后4位，是否确认更改？"
                (nzOnCancel)="handleCancel()"
                (nzOnOk)="handleOk()"
                [nzOkLoading]="isOkLoading"
        >
            <p>Modal Content</p>
        </nz-modal>


    </div>

    <div *ngIf="changePwFlag">



        <form class="form-warp" style="margin-top: 2rem;" nz-form [formGroup]="pwValidateForm"
              (ngSubmit)="pwSubmitForm($event,pwValidateForm.value)">


                <nz-form-item style="width: 100%">
                    <nz-form-label [nzSpan]="7" >{{pwDataArr[0].title}}</nz-form-label>
                    <nz-form-control [nzSpan]="17" nzHasFeedback nzErrorTip="请输入正确的密码！">

                        <!--<nz-input-group [nzSuffix]="suffixTemplate"  style="width: 40%">-->
                        <nz-input-group  style="width: 200px">
                            <input

                                    formControlName="pwOld"
                                    [type]="pwDataArr[0].visible ? 'text' : 'password'"
                                    nz-input
                                    placeholder="input password"
                                    [(ngModel)]="pwDataArr[0].value"
                            />
                        </nz-input-group>
                        <!--<ng-template #suffixTemplate>-->
                            <!--<i nz-icon [nzType]="pwVisibleArr[0].visible ? 'eye-invisible' : 'eye'"-->
                               <!--(click)="pwVisibleArr[0].visible = !pwVisibleArr[0].visible"></i>-->
                        <!--</ng-template>-->

                        <!--<input nz-input formControlName="name" placeholder="姓名"  style="width: 200px">-->


                        <!--<nz-form-explain *ngIf="pwValidateForm.get('pwOld').dirty && pwValidateForm.get('pwOld').errors  ">-->
                            <!--<ng-container *ngIf="pwValidateForm.get('pwOld').hasError('required')">-->
                                <!--请输入正确的密码！-->
                            <!--</ng-container>-->
                        <!--</nz-form-explain>-->
                    </nz-form-control>
                </nz-form-item>


            <nz-form-item style="width: 100%">
                <nz-form-label [nzSpan]="7" >{{pwDataArr[1].title}}</nz-form-label>
                <nz-form-control [nzSpan]="17" nzHasFeedback [nzErrorTip]="pwErrorTip">

                    <nz-input-group style="width: 200px">
                        <input

                                formControlName="pwNew"
                                [type]="pwDataArr[1].visible ? 'text' : 'password'"
                                nz-input
                                placeholder="input password"
                                [(ngModel)]="pwDataArr[1].value"
                                (ngModelChange)="validateConfirmPassword()"
                        />
                    </nz-input-group>

                    <!--<nz-form-explain *ngIf="pwValidateForm.get('pwNew').dirty && pwValidateForm.get('pwNew').errors  ">-->
                        <!--<ng-container *ngIf="pwValidateForm.get('pwNew').hasError('required')">-->
                            <!--请输入正确的密码！-->
                        <!--</ng-container>-->
                    <!--</nz-form-explain>-->
                </nz-form-control>
            </nz-form-item>


            <nz-form-item style="width: 100%">
                <nz-form-label [nzSpan]="7" >{{pwDataArr[2].title}}</nz-form-label>
                <nz-form-control [nzSpan]="17" nzHasFeedback [nzErrorTip]="pwdErrorTpl">

                    <nz-input-group style="width: 200px" >
                        <input

                                formControlName="pwConfirm"
                                [type]="pwDataArr[2].visible ? 'text' : 'password'"
                                nz-input
                                placeholder="input password"
                                [(ngModel)]="pwDataArr[2].value"
                        />
                    </nz-input-group>
                    <ng-template #pwdErrorTpl let-control>
                        <ng-container *ngIf="validateForm.get('pwConfirm')?.hasError('required')">
                            Please confirm your password!
                        </ng-container>
                        <ng-container *ngIf="pwValidateForm.get('pwConfirm').hasError('confirm')">
                            与新密码不匹配！
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>



            <nz-form-item  style=" margin-left: 20px; width: 200px; " >
                <nz-form-control style="display: flex; align-items: center; justify-content: center;" >
                    <button style="margin-left: 10px; margin-right: 10px" nz-button nzSize="'large'" nzType="primary" [disabled]="!pwValidateForm.valid">更改</button>

                    <button style="margin-left: 10px; margin-right: 10px" nz-button nzSize="'large'" nzType="primary" (click)="changePwFlag = false;" >取消</button>

                </nz-form-control>
            </nz-form-item>


        </form>




    </div>

    <!--<div class="setting-content">-->
        <!--<nz-form-label class="setting-label" nzRequired>管理员姓名</nz-form-label>-->
        <!--<input style="width: 200px" nz-input-->
               <!--placeholder="{{user.name}}"-->
               <!--[(ngModel)]="curUserName"-->
               <!--[disabled]="!editFlag"-->
               <!--(blur)="nameSave()"-->
               <!--(keypress)="onKeyPress($event)"/>-->
    <!--</div>-->
    <!---->
    <!--<div class="setting-content">-->
        <!--<nz-form-label class="setting-label" nzRequired>电话</nz-form-label>-->
        <!--<input style="width: 200px" nz-input placeholder="{{user.phone}}"-->
               <!--[(ngModel)]="curPhone"-->
               <!--[disabled]="!editFlag"-->
               <!--(blur)="phoneSave()"-->
               <!--(keypress)="onKeyPress($event)"/>-->
    <!--</div>-->

    <!--<div class="setting-content">-->
        <!--<button class="setting-submit-box" nz-button nzType="primary"-->
                <!--[nzSize]="'default'" (click)="editClick()"-->
                <!--[nzLoading]="loading">-->
            <!--<i nz-icon type="edit"></i>编辑-->
        <!--</button>-->
    <!--</div>-->


</div>
