<div style="margin: 0;padding: 0" [ngStyle]="{padding: videoGroup == PAD_VIDEO_GROUP.USER ? '20px' : '0'}">
<div class="table-operations">
    <button nz-button nzType="primary" [nzSize]="'large'" (click)="openDialog(0)">
        添加根目录
    </button>
</div>
<!--    https://stackblitz.com/edit/cdk-drop-list-scroll?file=app%2Fcdk-drag-drop-sorting-example.ts-->
<!--
<nz-table #dataTable
          [nzLoading]="tableLoading"
          [nzData]="dataSet"
          [nzFrontPagination]="false"
          [nzShowPagination]="false"
          >
    <thead>
    <tr>
        <th>ID</th>
        <th [width]="120">封面</th>
        <th>名称</th>
        <th>操作</th>
    </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)">
    <tr *ngFor="let data of dataSet" cdkDrag>
        <td>{{data.id}}</td>
        <td>
            <img class='cate-cover' [src]="data.cover" alt="">
        </td>
        <td>{{data.name}}</td>
        <td>
            <div class="operations">
                <a [routerLink]=" routePrefix + '/pad/video/category/'+data.id">资源管理</a>
                <a (click)="editCategory(data)">编辑</a>
                <a nz-popconfirm nzPopconfirmTitle="删除分类后，分类下内容都会删除，确定删除分类？"
                   (nzOnConfirm)="deleteCategory(data.id)" >
                    删除
                </a>

            </div>
        </td>
    </tr>
    </tbody>
</nz-table>
-->


    <nz-spin [nzSpinning]="tableLoading">
    <nz-tree #nzTreeComponent
             [nzData]="dataSet"
             [nzShowExpand]="false"
             nzDraggable nzBlockNode nzShowLine
             [nzTreeTemplate]="nzTreeTemplate"
             (nzDblClick)="openFolder($event)"
             [nzExpandedKeys]="defaultExpandedKeys"
             (nzOnDrop)="onTreeDrop($event)"></nz-tree>

    <ng-template #nzTreeTemplate let-node let-origin="origin">
      <span class="custom-node">
        <div *ngIf="!node.isLeaf"  class="folder-content">
          <span nz-icon class="folder-icon" [nzType]="node.isExpanded ? 'folder-open' : 'folder'" (click)="openFolder(node)"></span>
          <span class="folder-cover"><img [src]="node.origin.cover" alt="" width="48" height="48" /></span>
          <span class="folder-name">{{ node.origin.name }}</span>
<!--          <span class="folder-desc">created by {{ origin.author | lowercase }}</span>-->
        </div>
          <div class="folder-action">
              <a nz-button [nzSize]="'small'" nzType="link" (click)="showModifyModal(node)">编辑</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a nz-button [nzSize]="'small'" nzType="link" (click)="openDialog(node.origin.id)">添加子分类</a>
              <nz-divider nzType="vertical"></nz-divider>
              <a nz-button [nzSize]="'small'" nzType="link"  (click)="resourceManage(node)">文件管理</a>
<!--              <a [routerLink]=" routePrefix + '/pad/video/category/'+node.origin.id">资源管理</a>-->
              <nz-divider nzType="vertical"></nz-divider>
              <a nz-button [nzSize]="'small'" nzDanger
                 nz-popconfirm nzPopconfirmTitle="该目录及其所有内容都将删除？"
                 (nzOnConfirm)="deleteCategory(node.origin.id)" nzType="link">删除</a>
          </div>
<!--        <span *ngIf="node.isLeaf" (contextmenu)="contextMenu($event, menu)">-->
<!--          <span nz-icon nzType="file"></span>-->
<!--          <span class="file-name">{{ node.title }}</span>-->
<!--          <span class="file-desc">modified by {{ origin.author | lowercase }}</span>-->
<!--        </span>-->
      </span>
    </ng-template>
    </nz-spin>
<nz-modal
        [(nzVisible)]="isAddVideoCategoriesModalVisible"
        nzTitle="添加视频分类"
        nzOkText="Ok"
        nzCancelText="Cancel"
        [nzClosable]="false"
        [nzMaskClosable]="false"

        [nzOkLoading]="isSavingForm"
        [nzCancelLoading]="isSavingForm"
        (nzOnOk)="saveDialog()"
        (nzOnCancel)="closeDialog()"
>
    <ng-container *nzModalContent>
    <form nz-form #addVideoFormElement="ngForm" [formGroup]="addVideoForm" (ngSubmit)="submitForm()">

        <nz-form-item class="term-item" >
            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="category">分类</nz-form-label>
            <nz-form-control
                    [nzSm]="14"
                    [nzSm]="24"
                    nzErrorTip="请设置分类"
            >

                <input type="text" nz-input
                       [formControlName]="'name'"/>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item class="term-item" >
            <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="category">封面</nz-form-label>
            <nz-form-control
                    [nzSm]="14"
                    [nzSm]="24"
                    nzErrorTip="请设置封面"
            >
                <app-upload-image-with-preview
                        [picUrl]="addVideoForm.get('cover')?.value"
                        [picItem]="null"
                        (imageUploaded)="onImageUploadSuccess($event)"
                        [errors]="addVideoForm.get('cover')?.dirty && addVideoForm.get('cover')?.status=='INVALID'"></app-upload-image-with-preview>
            </nz-form-control>
        </nz-form-item>
    </form>
    </ng-container>
</nz-modal>
</div>




