<div>
    <nz-alert
            nzType="info"
            nzMessage="Help"
            [nzDescription]="helpTips"
    >
    </nz-alert>
    <ng-template #helpTips>
        <div>client-type：WX_APP, BROWSER, student, teacher</div>
        <div>client: ios, android</div>
        <div>log_type: api, custom, uncaught, slow-query</div>
        <div>作业homework/v2/item/score</div>
        <div>手机上报时长/api/media/daily/statistics</div>
        <div>音箱在线上报时长/api/homework/item/review</div>
        <div>音箱离线上报时长/api/homework/item/offline/records</div>
        <div>小程序汇报/api/reports/user/:user_id/report/:date</div>
        <div>音箱离线/api/mqtt/device/status/disconnected</div>
        <div>音箱上线/api/mqtt/device/status/connected</div>
        <div>音箱下载歌曲/api/homework/item/start</div>
        <div>音箱下载完成/api/homework/item/download</div>
        <div>音箱下载错误/api/homework/item/error</div>
        <div>音箱删除歌曲/api/homework/item/delete</div>
    </ng-template>
    <p></p>
<!--    <div style="display: flex; display:none">-->
<!--        <label nz-col style="width: 100px;-->
<!--    text-align: right;-->
<!--    margin-right: 1em;-->
<!--    line-height: 32px;">Auth: </label>-->
<!--        <nz-input-group nzCompact nz-col>-->
<!--            <input type="text" nz-input [ngModel]="elaUser" style="width: 150px;" />-->
<!--            <input type="text" nz-input [ngModel]="elaPass" style="width: 150px;" />-->
<!--        </nz-input-group>-->
<!--    </div>-->
    <form nz-form [formGroup]="queryForm" (ngSubmit)="submitForm()">
        <ng-container *ngFor="let group of groups">
            <nz-divider [nzText]="group.title" nzOrientation="left"></nz-divider>
            <div>
                <nz-form-item class="term-item" *ngFor="let control of this[group.name+'Controls']; let i = index">
                    <nz-form-control
                            [nzXs]="24"
                            [nzSm]="24"
                            nzErrorTip="Please input or delete this field."
                    >
                        <nz-input-group nzCompact>
<!--                            <nz-select *ngIf="group.name === 'base'" style="width:120px;"-->
<!--                                    [ngModel]="'data.id'"-->
<!--                                    [formControlName]="group.name + 'TermName' + control.index">-->
<!--                                <nz-option  *ngFor="let f of baseFields" [nzLabel]="f.name" [nzValue]="f.value"></nz-option>-->
<!--                            </nz-select>-->
                            <nz-select nzShowSearch style="width:120px;"
                                    [nzServerSearch]="false"
                                    nzPlaceHolder="input search text"
                                       [nzAutoClearSearchValue]="true"
                                       [nzMaxMultipleCount]="1"
                                       [nzMaxTagCount]="1"
                                    [nzShowArrow]="false"
                                       (ngModelChange)="setSearchTerm($event)"
                                    [nzFilterOption]="nzFilterOption"
                                    (nzOnSearch)="search(group.name, control.index, $event)"
                                    [formControlName]="group.name + 'TermName' + control.index">
                                <nz-option *ngFor="let o of baseFields" [nzLabel]="o.name" [nzValue]="o.value"> </nz-option>
                            </nz-select>

                            <input type="text" nz-input
                                   [formControlName]="group.name + 'TermValue' + control.index"
                                   style="width:50%;" />
                            <nz-select [ngModel]="'term'"
                                       [formControlName]="group.name + 'OpType' + control.index">
                                <nz-option [nzLabel]="'确切'" [nzValue]="'term'"></nz-option>
                                <nz-option [nzLabel]="'包含'" [nzValue]="'query_string'"></nz-option>
                            </nz-select>
                        </nz-input-group>
                        <!--
                        <nz-input-group
                                [nzAddOnBefore]="baseFieldTemplate"
                                [nzAddOnAfter]="addOnAfterTemplate"
                                nzCompact>
                            <ng-template #baseFieldTemplate>
                                <nz-select style="width:100px;"
                                           [formControlName]="group.name + 'TermName' + control.index"
                                           [ngModel]="'data.id'"
                                           class="base-term-name">
                                    <nz-option  *ngFor="let f of baseFields" [nzLabel]="f.name" [nzValue]="f.value"></nz-option>
                                </nz-select>
                            </ng-template>
                            <ng-template #addOnAfterTemplate>
                                <nz-select style="width:70px;" [ngModel]="'term'" [formControlName]="group.name + 'OpType' + control.index">
                                    <nz-option [nzLabel]="'确切'" [nzValue]="'term'"></nz-option>
                                    <nz-option [nzLabel]="'包含'" [nzValue]="'match_phrase'"></nz-option>
                                </nz-select>
                            </ng-template>
                            <input [formControlName]="group.name + 'TermValue' + control.index"  nz-input />
                        </nz-input-group>
                        -->

<!--                        [ngStyle]="{visibility: group.name === 'base' ? (this[group.name+'Controls'].length > 1 ? 'visible' : 'hidden') : 'visible'}"-->
                        <i nz-icon nzType="minus-circle-o" class="dynamic-delete-button"
                           (click)="removeField(group.name, control, $event)"></i>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item  class="term-add">
                    <nz-form-control>
                        <button nz-button nzType="dashed" class="add-button" (click)="addField(group.name, $event)">
                            <i nz-icon nzType="plus"></i>
                            添加条件
                        </button>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </ng-container>
        <nz-divider [nzText]="'时间范围'" nzOrientation="left"></nz-divider>
        <nz-form-item>
            <nz-form-label nzRequired>时间范围</nz-form-label>
            <nz-form-control>
                <nz-range-picker nzShowTime formControlName="rangePickerTime"></nz-range-picker>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control  [nzSm]="24" [nzXs]="24">
                <button nz-button nzType="primary">Submit</button>
            </nz-form-control>
        </nz-form-item>


    </form>
    <hr>
    <p>共：{{allHitNumber}} hits</p>
    <nz-checkbox-group [(ngModel)]="tableHeaders" (ngModelChange)="changeTableHeaders(tableHeaders)"></nz-checkbox-group>
    <nz-table [nzLoading]="loadingTable"  #basicTable [nzData]="resultTable" [nzScroll]="{ x: 'auto' }">
        <thead>
        <tr>
            <th nzLeft="0px" [nzWidth]="'100px'">startTime</th>
            <th *ngIf="!selectedTableHeaders.length">data</th>
            <th *ngFor="let h of selectedTableHeaders">
                {{h.label}}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of basicTable.data">
            <td nzLeft="0px">{{ row.startTime }}</td>
            <td *ngIf="!selectedTableHeaders.length">
                <div style="text-align: left!important;" >{{stringify(row.json)}}</div>
            </td>
            <td *ngFor="let h of selectedTableHeaders">
                {{ getFieldData(row.json, h.value)}}
            </td>
        </tr>
        </tbody>
    </nz-table>
</div>

