import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../../services/school.service';
import { TrashService } from '../../../../../../services/trash.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ElsService } from '../../../../../../services/els.service';

interface controlInstanceType {
  [key: string]: string;
}


@Component({
  selector: 'app-user-audit-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class UserAuditIndexComponent implements OnInit {


  // @ts-ignore
  queryForm: UntypedFormGroup;



  baseControls: Array<{ index: number;  term: controlInstanceType }> = [];
  ORControls: Array<{ index: number;  term: controlInstanceType }> = [];

  baseFields = [{
    name: 'id',
    value: 'id'
  }, {
    name: 'method',
    value: 'method'
  }, {
    name: 'name',
    value: 'name'
  }, {
    name: 'phone',
    value: 'phone'
  }, {
    name: 'school name',
    value: 'school_name'
  }, {
    name: 'school id',
    value: 'school_id'
  }, {
      name: 'route',
      value: 'route'
  }, {
    name: 'url',
    value: 'url'
  }, {
    name: 'client-type',
    value: 'header.client-type'
  }, {
    name: 'version',
    value: 'header.version'
  }];

  groups = [
    {
      name: 'base',
      title: '基础查询',
    },
    {
      name: 'OR',
      title: '条件查询'
    }
  ];
  dateFormat = 'yyyy-MM-dd';

  resultTable = [];
  tableHeaders = [];
  selectedTableHeaders = [];
  allHitNumber = 0;
  stringify = JSON.stringify;


  selectedValue = null;
  listOfOption: Array<{ value: string; name: string }> = [];
  loadingTable = false;

  lastSearchValue = '';

  // elaUser = 'elastic';
  // elaPass = 'iPlayabc123';
  nzFilterOption = (inputValue: string, option: any) => {
    // console.log(3, inputValue, option);
    return option.nzValue.startsWith(inputValue);
  }


  constructor(private userService: UserService,
              private elsService: ElsService,
              private http: HttpClient,
              private fb: UntypedFormBuilder,
              private trashSvc: TrashService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }
  allKeys(data: any, p: any) {
    const keyCaches: any[] = [];
    // @ts-ignore
    const keyify = (obj: any, prefix: any) => Object.keys(obj).reduce((res, el) => {


      if (Array.isArray(obj[el])) {
        return res;
      } else if (typeof obj[el] === 'object' && obj[el] !== null) {
        return [...res, ...keyify(obj[el], prefix + el + '.')];
      }
      return [...res, prefix + el];
    }, []);
    let keys = new Set();
    if (Array.isArray(data)) {
      data.forEach(d => {
        if (d.route) {
          if (keyCaches.includes(d.route)) {
            return;
          } else {
            keyCaches.push(d.route);
          }
        }
        const ks = keyify(d, p);
        // @ts-ignore
        keys = new Set<any>([...Array.from(keys), ...ks]);
      });
    } else {
      keys = new Set<any>(keyify(data, p));
    }
    return keys;
  }
  getFieldData(obj: any, key: any) {
    const keys = key.split('.');
    let data = obj;
    keys.forEach((k: any) => {
      if (data[k]) {
        data = data[k];
      } else {
        data = '';
      }
    });
    return JSON.stringify(data);

  }
  changeTableHeaders(value: {checked: boolean}[]) {
    // console.log(value);
    // @ts-ignore
    this.selectedTableHeaders = value.filter(v => v.checked);
  }
  setSearchTerm(tags: any) {
    const idx = this.baseFields.findIndex((it) => it.value === tags );
    console.log(2, tags, idx);
    if (idx < 0) {
      this.baseFields.push({
        name: tags,
        value: tags
      });
    }

    // if (last) {
    //   console.log(3, tags);
    //   this.queryForm.controls.baseTermName0.setValue(tags);
    // }
  }
  search(type: any, index: any, value: string): void {
    console.log(1, value);
    if (!value) {
      this.queryForm.controls[`${type}TermName${index}`].setValue(this.lastSearchValue);
      return;
    }
    this.lastSearchValue = value;
    // this.selectedValue = ''
    // this.queryForm.controls.baseTermName0.setValue([]);
    this.listOfOption = this.baseFields;
  }
  ngOnInit(): void {
    this.queryForm = this.fb.group({
      rangePickerTime: [[]],
    });

    this.addField('base');
  }
  getOpQuery(op: any, field: any, val: any) {
    if (field === 'method') {
      val = val.toUpperCase();
    }
    if (field === 'route') {
      val = val.replace(/\//g, '\\/');
    }
    if (field === 'url') {
      val = val.replace(/\//g, '\\/');
    }
    let opQuery = {
      [op]: {
        [`data.${field}`]: val
      }
    };
    if (op === 'query_string') {
      opQuery = {
        [op]: {
          query: `*${val.trim()}*`,
          default_field: `data.${field}`
        }
      };
    }
    return opQuery;
  }
  submitForm(): void {
    for (const i in this.queryForm.controls) {
      this.queryForm.controls[i].markAsDirty();
      this.queryForm.controls[i].updateValueAndValidity();
    }
    console.log(this.queryForm.value);
    if (this.queryForm.status === 'VALID') {
      const ks = Object.keys(this.queryForm.controls);
      const baseCacheIdx: any[] = [];
      const ORCacheIdx: any[] = [];
      const mustQ = [];
      const shouldQ = [];

      for (const k of ks) {
        const match = /\d+/.exec(k);
        if (!match) {
          continue;
        }
        const idx = parseInt(match[0], 10);
        if (k.startsWith('base')) {
          if (baseCacheIdx.includes(idx)) {
            continue;
          }
          const field = this.queryForm.controls[`baseTermName${idx}`].value;
          let val = this.queryForm.controls[`baseTermValue${idx}`].value;
          const op = this.queryForm.controls[`baseOpType${idx}`].value;
          // if (field === 'method') {
          //   val = val.toUpperCase();
          // }
          if (!val || !val.trim()) {
            continue;
          }
          val = val.trim();
          const opQuery = this.getOpQuery(op, field, val.trim());
          mustQ.push(opQuery);


          baseCacheIdx.push(idx);
        }
        if (k.startsWith('OR')) {
          if (ORCacheIdx.includes(idx)) {
            continue;
          }

          const field = this.queryForm.controls[`ORTermName${idx}`].value;
          let val = this.queryForm.controls[`ORTermValue${idx}`].value;
          const op = this.queryForm.controls[`OROpType${idx}`].value;

          if (!val || !val.trim()) {
            continue;
          }
          val = val.trim();
          const opQuery = this.getOpQuery(op, field, val.trim());

          shouldQ.push(opQuery);

          ORCacheIdx.push(idx);
        }

      }
      if (Object.keys(shouldQ).length > 0) {
        mustQ.push({
          bool: {
            should: shouldQ
          }
        });
      }
      // @ts-ignore
      const range = this.queryForm.controls.rangePickerTime.value;
      let start: any = range[0];
      let end: any = range[1];
      const tzoffset = (new Date()).getTimezoneOffset() * 60000;
      if (!start) {
        start = new Date();
        start.setMinutes(start.getMinutes() - 30);

      }
      start = (new Date(start - tzoffset));

      if (!end) {
        end  = new Date();
      }
      end = (new Date(end - tzoffset));
      const query = {
        '_source': {
          'include': [
            '@timestamp',
            'data',
            'startTime'
          ],
          'exclude': [
            'data.ip'
          ]
        },
        'query': {
          'bool': {
            'filter': {
              'range': {
                'startTime': {
                  'gte': start.toISOString(),
                  'lte': end.toISOString()
                }
              }
            },
            'must': mustQ
          }
        },
        'size': 500,
        'from': 0,
        'sort': [
          {
            'data.start_dt': {
              'order': 'desc',
              'unmapped_type': 'keyword'
            }
          }
        ]
      };
      console.dir(JSON.stringify(query));

      this.loadingTable = true;
      this.resultTable = [];
      this.tableHeaders = [];
      this.selectedTableHeaders = [];

      // let headers = new HttpHeaders();
      // headers = headers.set('Content-Type', 'application/json');
      // headers = headers.set('Authorization', 'Basic ' + btoa(`${this.elaUser}:${this.elaPass}`));
      //
      // const httpOptions = {
      //   headers: headers
      // };
      // this.http.post('http://es-cn-zvp26mi560006f4x7.public.elasticsearch.aliyuncs.com:9200/filebeat-*/_search'
      //   , query
      // , httpOptions).subscribe


      this.elsService.elsSearch(query).then
      ((res: any) => {
        console.log(res);
        this.loadingTable = false;
        this.allHitNumber = res.hits.total.value;
        // @ts-ignore
        const rows = [];
        if (!res.hits || !res.hits.hits) {
          this.nzMessageService.error('查询失败！');
          return;
        }
        // @ts-ignore
        const aks = this.allKeys(res.hits.hits.map((r: any) => {
          // r._source.data.startTime = r._source.startTime;
          rows.push({
            startTime: r._source.startTime,
            json: r._source.data
          });
          return r._source.data;
        }), '');
        // @ts-ignore
        this.tableHeaders = Array.from(aks).map(k => {
          return { label: k, value: k };
        });
        // @ts-ignore
        this.tableHeaders.push({ label: 'body', value: 'body' });
        // @ts-ignore
        this.tableHeaders.push({ label: 'query', value: 'query' });
        // @ts-ignore
        this.tableHeaders.push({ label: 'extends', value: 'extends' });
        // @ts-ignore
        this.resultTable = rows;
        // @ts-ignore
        console.log(rows);
      }, (err) => {
        this.loadingTable = false;
        console.log(err);
      });

    } else {

    }
  }
  toJson(v: any) {
    return JSON.stringify(v);
  }
  addField(type: any, e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const arrName = `${type}Controls`;
    // @ts-ignore
    const index = this[arrName].length > 0 ? this[arrName][this[arrName].length - 1].index + 1 : 0;

    const control = {
      index,
      term: {
        baseTermValue: '',
        baseTermName: '',
        opType: 'term'
      }
    };
    // @ts-ignore
    const idx = this[arrName].push(control);
    // @ts-ignore
    console.log(this[arrName][this[arrName].length - 1]);
    this.queryForm.addControl(
      `${type}TermValue${index}`,
      new UntypedFormControl(null, Validators.required)
    );
    this.queryForm.addControl(
      `${type}TermName${index}`,
      new UntypedFormControl(null, Validators.required)
    );
    this.queryForm.addControl(
      `${type}OpType${index}`,
      new UntypedFormControl(null, Validators.required)
    );
  }

  removeField(type: string, i: { index: number;  term: controlInstanceType }, e: MouseEvent): void {
    e.preventDefault();
    let limit = 1;
    if (type === 'OR') {
      limit = 0;
    }
    // this[`${type}Controls`].length > limit
    const fields = (Object.keys(this.queryForm.controls).length - 1) / 3;
    if ( fields > 1) {
      // @ts-ignore
      const index = this[`${type}Controls`].indexOf(i);
      // @ts-ignore
      this[`${type}Controls`].splice(index, 1);
      // @ts-ignore
      console.log(this[`${type}Controls`]);
      this.queryForm.removeControl(`${type}TermValue${i.index}`);
      this.queryForm.removeControl(`${type}TermName${i.index}`);
      this.queryForm.removeControl(`${type}OpType${i.index}`);
    }
  }
}

