

<nz-tabset nzSize="large" (nzSelectedIndexChange)="tabIndexChanged($event)">
    <nz-tab nzTitle="购买情况">
        <div class="table-operations">
            <ng-container *ngIf="!isSA">
                <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/account/buy'">
                    <i nz-icon nzType="pay-circle" nzTheme="outline"></i>购买APP学生使用名额
                </button>

                <span nz-text>
            <code>名额：{{accounts_message}}</code>
        </span>

                <span nz-text nzType="warning" *ngIf="promo">
            <i nz-icon nzType="fire" nzTheme="fill"></i>{{promo.title}}
        </span>
            </ng-container>
            <!--
            <ng-container *ngIf="isSA">
                <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/account/buy'">
                    <i nz-icon nzType="pay-circle" nzTheme="outline"></i>手动补录
                </button>
            </ng-container>
            -->
        </div>
        <nz-table #ordersTable
                  [nzData]="dataSet"
                  [nzFrontPagination]="false"
                  [(nzPageIndex)]="currentPageIndex"
                  (nzPageIndexChange)="loadData($event)"
                  [nzTotal]="totalNum"
                  [nzPageSize]="10">
<!--            nzSimple-->
<!--            -->
            <thead>
            <tr>
                <th>ID</th>
                <th *ngIf="isSA">学校</th>
                <th>购买名额</th>
                <th>赠送名额</th>
                <th>剩余名额</th>
                <th>消费金额</th>
                <th>订单号</th>
                <th>购买时间</th>
                <th
                        nzShowFilter
                        [nzFilters]="listOfStatus"
                        (nzFilterChange)="filterStatus($event)">订单状态</th>
                <!--<th>审核状态</th>-->
                <!--<th *ngIf="isSA">补贴状态</th>-->
                <th *ngIf="isSA" nzCustomFilter>操作人
                    <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
                        <i nz-icon nzType="search"></i>
                    </nz-filter-trigger>
<!--                    <i-->
<!--                        class="ant-table-filter-icon"-->
<!--                        nz-icon-->
<!--                        nz-dropdown-->
<!--                        #dropdown="nzDropdown"-->
<!--                        nzType="search"-->
<!--                        [nzDropdownMenu]="menu"-->
<!--                        [class.ant-table-filter-open]="dropdown.nzVisible"-->
<!--                        nzTrigger="click"-->
<!--                        nzPlacement="bottomRight"-->
<!--                        [nzClickHide]="false"-->
<!--                        nzTableFilter-->
<!--                ></i>-->
                </th>

                <th *ngIf="!isSA">操作人</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of ordersTable.data">
                <td>{{data.id}}</td>
                <td *ngIf="isSA">{{data.school_name}}</td>
                <td>{{data.quantity}}人</td>
                <td>{{data.gift_quantity}}人</td>
                <td>{{data.total_quantity}}人</td>
                <td>{{data.price}}</td>
                <td>{{data.wx_transaction_id}}</td>
                <td>{{data.updateon}}</td>
                <td>{{data.status}}</td>

                <!--<td *ngIf="isSA">-->
                <!--<ng-container *ngIf="data.status === '已支付'">-->
                <!--<span *ngIf="!data.has_subsidy" nz-text><code>未申请补贴</code></span>-->
                <!--<button *ngIf="data.has_subsidy && data.subsidy_status != 2" nz-button [nzSize]="'small'"-->
                <!--nzType="danger"-->
                <!--(click)="checkSubsidy(data.id)"-->
                <!--&gt;-->
                <!--<span *ngIf="data.subsidy_status ==0">补贴审核</span>-->
                <!--<span *ngIf="data.subsidy_status ==1">重新审核</span>-->
                <!--</button>-->
                <!--<span *ngIf="data.subsidy_status ==2">审核通过</span>-->
                <!--&lt;!&ndash;(click)="checkSubsidy(data.id)"&ndash;&gt;-->
                <!--&lt;!&ndash;[routerLink]="'/admin/order-info/'+data.id"&ndash;&gt;-->
                <!--</ng-container>-->
                <!--</td>-->
                <!--<td>-->
                <!--<div *ngIf="data.has_subsidy && data.status === '已支付'">-->
                <!--<div *ngIf="data.subsidy_status === 0">-->
                <!--未审核-->
                <!--</div>-->
                <!--<div *ngIf="data.subsidy_status === 1">-->
                <!--<span nz-text nzType="danger">{{data.subsidy_reject_reason}}</span>-->
                <!--<a *ngIf="!isSA" (click)="reSubmitSubsidy(data.id)">重新申请</a>-->
                <!--</div>-->
                <!--<div *ngIf="data.subsidy_status === 2">-->
                <!--补贴已到帐-->
                <!--</div>-->
                <!--</div>-->
                <!--</td>-->

                <td>{{data.user_name}}</td>
            </tr>
            </tbody>
        </nz-table>

        <nz-dropdown-menu #menu="nzDropdownMenu">
            <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchName()" class="search-button">
                    Search
                </button>
                <button nz-button nzSize="small" (click)="reset()">Reset</button>
            </div>
            </div>
        </nz-dropdown-menu>
        <ng-template #rejectContent>
            <input type="text" nz-input placeholder="驳回原因"
                   [(ngModel)]="rejectReason" />
        </ng-template>
    </nz-tab>

    <nz-tab  *ngIf="!isSA" nzTitle="账号开通明细">
        <div class="table-operations">
            <ng-container>
                <button nz-button nzType="primary"
                        [nzLoading]="downloading"
                        [nzSize]="'large'" (click)="downloadConsumeData($event)">
                    导出全部数据
                </button>
                <span nz-text>
                    <code>提示：开通明细仅保留最近6个月的数据</code>
                </span>
            </ng-container>
        </div>
        <nz-table #consumeTable
                  [nzData]="consumeTableData.rows"
                  [nzFrontPagination]="false"
                  [(nzPageIndex)]="consumeTableData.currentPage"
                  (nzPageIndexChange)="loadConsumeData($event)"
                  [nzTotal]="consumeTableData.total"
                  [nzLoading]="tableLoading"
                  [nzPageSize]="10">
            <!--            nzSimple-->
            <!--            -->
            <thead>
            <tr>
                <th>学生ID</th>
                <th>开通学生姓名</th>
                <th>手机号</th>
                <th>班级</th>
                <th>开通人</th>
                <th>开通时间</th>
                <th>账号类型</th>
                <th>账号到期时间</th>

            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of consumeTable.data">
                <td>{{data.user_id}}</td>
                <td>{{data.user_name}}</td>
                <td>{{data.phone}}</td>
                <td>{{data.class_name ? data.class_name : '回收站或无班级'}}</td>
                <td>{{data.operator_name}}</td>
                <td>{{data.createon}}</td>
                <td>{{+data.days ? data.days+'天账号' : '--'}}</td>
                <td>{{data.expired}}</td>
            </tr>
            </tbody>
        </nz-table>

    </nz-tab>

    <nz-tab *ngIf="isSA" nzTitle="加盟校开通情况">
        <div nz-row>
            <button nz-button [nzSize]="'default'" nzType="primary" (click)="onSelectAll()">全部</button>
            <nz-divider nzType="vertical"></nz-divider>
            <nz-month-picker
                    [(ngModel)]="selectMonth"
                    (ngModelChange)="onMonthChange($event)"
                    nzPlaceHolder="Select month"
            ></nz-month-picker>
        </div>


        <!--<p>本月已开通：人，本月开通总额：元</p>-->
        <p></p>
        <nz-table #orderStatisticTable nzSize="small"
                  [nzFrontPagination]="false"
                  [nzPageSize]="20"
                  [(nzPageIndex)]="currentStatisticPageIndex"
                  (nzPageIndexChange)="loadOrderStatistic($event)"
                  [nzTotal]="orderStatisticTableData ? orderStatisticTableData.total : 0"
                  [nzLoading]="tableLoading"
                  [nzData]="orderStatisticTableData ? orderStatisticTableData.rows : []">
            <thead>
            <tr>
                <th>日期</th>
                <th>帐号数</th>
                <th>开通总金额</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of orderStatisticTable.data">
                <td>{{ data.create_date.substr(0, 10) }}</td>
                <td>{{ data.number }}</td>
                <td>{{ data.price }}</td>
            </tr>
            </tbody>
        </nz-table>
    </nz-tab>
</nz-tabset>




<!--<ng-template #subsidyContent>-->
    <!--<app-modal-subsidy-proof-component></app-modal-subsidy-proof-component>-->
<!--</ng-template>-->


