<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'"
                [queryParams]="{ page: returnPage }"
                [routerLink]="'/admin/classes/'+classId+'/students/index'">
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    </div>


    <div class="batch-add-page">

        <div class="title">
            <span class="title-text">班级名称: {{className}}</span>
            <span class="title-text">教师姓名: {{teacher}}</span>
        </div>

        <div class="form-warp">

            <div class="upload-content">
                1、
                <label class="upload-template-label" (click)="downloadTemplate()">模版下载</label>
                说明：请下载模板，按照模板格式填写学员信息
            </div>

            <div class="upload-content">
                2、
                <label class="upload-label"> 上传文件: </label>
                <input class="upload-box" nz-input placeholder="文件名称" [(ngModel)]="uploadFileName" [disabled]="true" />

                <nz-upload
                        nzAction="/user/temp-resource"
                        [nzAccept] = "'.xls, .xlsx'"
                        [nzShowUploadList]="false"
                        [nzBeforeUpload]="beforeUpload"
                        (nzChange)="handleChange($event)"
                >

                    <button nz-button nzType="primary" [nzLoading]="loading"> 上传文件 </button>
                </nz-upload>

                <!--<i *ngIf="loading" style="margin-left:2%;" nz-icon type="loading"></i>-->
            </div>

            <div class="upload-content">
                3、文件预览：
            </div>

            <div class="upload-content">


                <table style="margin-left: 100px">


                    <div *ngFor="let item of personTable; let i = index">
                        <tr *ngIf="i == 0">
                            <td style="font-weight: 700">添加序号</td>
                            <td style="font-weight: 700">姓名</td>
                            <td style="font-weight: 700">电话</td>
                        </tr>

                        <tr>
                            <td>{{i+1}}</td>
                            <td>{{item && item['姓名']}}</td>
                            <td>{{item && item['电话']}}</td>
                        </tr>
                    </div>


                </table>


            </div>



            <div *ngIf="personTable.length > 0" class="upload-content">
                <button style="margin-left: 220px" nzSize="large"  nz-button nzType="primary" [nzLoading]="isSaving" (click)="submitBtnClick()" > 提交 </button>
            </div>

        </div>

    </div>

</div>

<nz-modal [(nzVisible)]="errMsg" nzTitle="添加完毕，错误学生:" (nzOnCancel)="closeError()" (nzOnOk)="closeError()">
    <ng-container *nzModalContent>
    <p style="font-size: 2rem;color:red;" *ngFor="let l of errlist">{{l}}</p>
    </ng-container>
</nz-modal>


<div #customizeDiv class="customizeDiv"></div>


