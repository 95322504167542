<div class="add-class">
    <div class="table-operations">
        <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/teachers/index'">
            <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    </div>
    <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm($event,validateForm.value)">
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>教师姓名</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback nzErrorTip="">
                <input nz-input formControlName="name" placeholder="请正确输入教师姓名！">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7">教师性别</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback  class="sex">
                <nz-radio-group nzName="sex" formControlName="sex">
                    <label nz-radio nzValue="1" >男</label>
                    <label nz-radio nzValue="0" >女</label>
                </nz-radio-group>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" >生日</nz-form-label>
            <nz-form-control [nzSpan]="12" >
                <nz-date-picker formControlName="birthday"></nz-date-picker>
            </nz-form-control>

        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="7" nzRequired>教师电话</nz-form-label>
            <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="phoneErrorTpl">
                <input nz-input
                        style="width: 100%"
                        nz-input
                        formControlName="phone"
                        placeholder="教师电话">
                <ng-template #phoneErrorTpl let-control>
                    <ng-container *ngIf="validateForm.get('phone')?.hasError('duplicated')">
                        教师电话已存在！
                    </ng-container>
                    <ng-container *ngIf="validateForm.get('phone')?.hasError('required')">
                        请正确输入教师电话！
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzOffset]="7" [nzSpan]="12">
                <button style="margin-right:1rem;" nz-button nzType="primary" [disabled]="!validateForm.valid">提交</button>
                <button nz-button (click)="resetForm($event)">清空</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
