import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LevelsService } from '../../../../services/levels.service';
import { GroupsService } from '../../../../services/groups.service';
import { StorybookService } from '../../../../services/storybook.service';
import { UserType } from '../../../../model/Const';
import { ClassService } from '../../../../services/class.service';


@Component({
  selector: 'app-user-index',
  templateUrl: './user-classes.component.html',
  styleUrls: ['./user-classes.component.scss'],
})
export class UserClassesComponent implements OnInit {
  user: any;
  schoolName = '';
  userName = '';
  classes: any[] = [];
  pageIndex = 1;
  totalRow = 0;
  currentClassId = null;
  books = [];


  viewType = 'classes';

  constructor(
    private clsService: ClassService,
    private bookService: StorybookService,
    private userService: UserService,
    private levelService: LevelsService,
    private groupService: GroupsService,
    private activatedRoute: ActivatedRoute ,
    private router: Router ,
    private nzMessageService: NzMessageService,
  ) {
  }
  displayData: Array<{ name: string; phone: string; status: string }> = [];
  dataSet: any[] = [];






  ngOnInit(): void {
    // check if student ,viewTyoe always books
    // @ts-ignore
    this.viewType = this.activatedRoute.snapshot.data.view;
    const info = this.userService.getUserInfo();
    this.schoolName = info.school_name;
    this.userName = info.name;

    if (info.type === UserType.TEACHER) {
      this.clsService.fetchAllClasses().then(resp => {
        this.classes = resp;
        this.currentClassId = resp[0].class_id;
        this.showClassNumber(0);
      });
    }




  }
  /*chooseLevel( lvl) {
    this.books = [];
    this.currentLevelId = lvl;
    this.currentLevelBooksPage = 1;
    this.bookService.fetchBooksByGroupAndLevel(this.currentGroupId, this.currentLevelId, this.currentLevelBooksPage).then( data => {
      // console.log(222, data);
      this.books = data.rows;
    });
  }

  changeGroup(evt) {
    this.currentGroupId = evt;
    if ( this.allLevels[evt] && this.allLevels[evt][0]) {

    }
    this.books = [];
    console.log('changeGroup', evt);
    this.currentLevelBooksPage = 1;
    this.chooseLevel(0);
  }*/
  changeClass(evt: any, class_id: any) {
    this.currentClassId = class_id;
    this.showClassNumber(0);
  }
  showClassNumber(evt: any) {
    if (!this.currentClassId) {
      return;
    }
    const page = evt + 1;
    this.userService.fetchClassMember(this.currentClassId, page).then(data => {
      this.totalRow = data.total;
      this.dataSet = data.rows;
    });
  }
  changeView(type: any) {
    console.log(type);
    this.viewType = type;

  }


}
