import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SystemService } from '../../../services/system.service';
import { StudentService } from '../../../services/student.service';
import { ClassService } from '../../../services/class.service';
import { UserService } from '../../../services/user.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-teacher-class-selector',
  templateUrl: './teacher-class-selector.component.html',
  styleUrls: ['./teacher-class-selector.component.scss']
  // providers: [NzRangePickerComponent],
})
export class TeacherClassSelectorComponent /*extends  NzRangePickerComponent*/ implements OnInit, OnDestroy, AfterViewInit {
  constructor( private sysSrv: SystemService,
               private stuService: StudentService,
               private clsService: ClassService,
               private userService: UserService,
               private modal: NzModalService,
               private nzMessageService: NzMessageService) {

  }

  TeacherData = null;
  ClassData = null;
  teacherCache = {};
  classCache = {};

  // classChangeVisible = false;
  isLoadingTeacherCLass = false;
  confirmChangeVisible = false;
  changeClassText = '';
  selectedTeacher = null;
  selectedClass = null;
  teacherList: any[] = [];
  classList: any[] = [];

  @Input()
  schoolId = 0;

  @Input()
  title = '';

  @Input()
  visibility = false;

  @Output()
  ok = new EventEmitter();
  @Output()
  cancel = new EventEmitter();
  @Output()
  loaded = new EventEmitter();

  ngOnInit() {
    this.loadClassData();
  }
  ngAfterViewInit() {

  }

  ngOnDestroy() {
  }


  loadClassData() {
    this.selectedTeacher = null;
    this.selectedClass = null;
    if (Object.keys(this.teacherCache).length && Object.keys(this.classCache).length) {
      this.visibility = true;
      this.loaded.emit();
      return;
    }
    // this.isLoadingTeacherCLass = true;
    // const info = this.userService.getUserInfo();
    this.sysSrv.loadTeacherAndClasses(this.schoolId).then(result => {
      // this.visibility = true;

      console.log('result:', result);
      // @ts-ignore
      this.teacherCache[this.schoolId] = result.teachers;
      for (const k of Object.keys(result.teachers)) {
        const t = result.teachers[k];
        // @ts-ignore
        if (this.classCache[t.teacher_id]) {
          // @ts-ignore
          this.classCache[t.teacher_id] = [...this.classCache[t.teacher_id], ...result.classes[t.teacher_id]];
        } else {
          // @ts-ignore
          this.classCache[t.teacher_id] = result.classes[t.teacher_id] ? result.classes[t.teacher_id] : [] ;
        }
      }
      this.teacherList = Object.values(result.teachers);
      this.loaded.emit();
    }).catch(err => {
      this.nzMessageService.error(err.message || '数据加载错误');
      this.loaded.emit();
    });
  }
  handleChangeOk() {
    console.log('handleChangeOk');
    if (this.selectedTeacher && this.selectedClass) {
      // @ts-ignore
      this.changeClassText = `${this.selectedTeacher.name} 老师的 ${this.selectedClass.name} 班`;
      this.confirmChangeVisible = true;
      this.modal.confirm({
        nzTitle: '是否确认?',
        nzContent: `<b style="color: red;">${this.changeClassText}</b>`,
        nzOkText: 'Yes',
        nzOkType: 'primary',
        nzOkDanger: true,
        nzOnOk: () => this.sureConfirm(),
        nzCancelText: 'No',
        // nzOnCancel: () => console.log('Cancel')
      });
    } else {
      this.nzMessageService.error('请正确选择教师和班级');
    }
  }


  closeChangeStudentClass() {
    console.log('closeChangeStudentClass');
    // this.isLoadingTeacherCLass = false;
    this.visibility = false;
    this.cancel.emit();
    this.loaded.emit();
  }

  doSelectTeacher(teacher: any) {
    this.selectedTeacher = teacher;
    this.classList = [];
    this.selectedClass = null;
    // @ts-ignore
    if (this.classCache[teacher.teacher_id]) {
      // @ts-ignore
      this.classList = this.classCache[teacher.teacher_id] as any;
    } else {
      alert('no classes for this teacher');
    }

  }

  closeConfirm () {
    this.confirmChangeVisible = false;
  }
  sureConfirm() {
    this.confirmChangeVisible = false;
    // @ts-ignore
    this.ok.emit(this.selectedClass.id);
  }
}
