<div class="login-view">
  <div class="content">
    <div class="p-title">后台管理</div>
    <div>
      <input class="username p-input" placeholder="用户名"
             (keydown.enter)="onBtnLogin()"
             [(ngModel)]="user.phone"/>
    </div>
    <div>
      <input class="password p-input" type="password" placeholder="密码"
             (keydown.enter)="onBtnLogin()"
             [(ngModel)]="user.password"/>
    </div>
    <button class="p-submit" (click)="onBtnLogin()" [disabled]="btnDisable">{{loginLabel}}</button>
  </div>
</div>

