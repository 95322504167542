<div>
    <div class="video-uploader" [ngClass]="{'has-video': videoUrl||checking||uploading}">
<!--        <svg viewBox="0 0 16 9"></svg>-->
        <div class="opt-box">
            <nz-upload class="p-video-uploader opt-btn"

               title="点击上传"
               *ngIf="allowUpload && (!uploading && !checking)"
               [nzShowUploadList]="false"
               [nzBeforeUpload]="beforeUpload"
               nzAccept = ".mp4"
               #NzUploadBtn>
      <!--<button type="button" nz-button nzType="default" [disabled]="disableSelectFile">-->
        <!--<i class="anticon anticon-plus"></i><span>Select Video</span>-->
      <!--</button>-->

      <div class="p-box d-flex align-items-center" [ngClass]="{error: !!errors}">
        <div class="p-upload-icon">
            <i nz-icon nzType="upload" nzTheme="outline"></i>
          <div class="m-3"></div>
          <span class="upload-info">{{message}}</span>
          <!--<div class="mt-5 p-progress-bar" *ngIf="uploading">-->
          <!--<div class="p-progress-bg" [style.width]="progress*0.2+'rem'"></div>-->
          <!--<div class="p-progress-value">{{progress}}%</div>-->
          <!--</div>-->
        </div>




      </div>


    </nz-upload>
            <div class="extract-cover opt-btn" (click)="extraCover()" *ngIf="extractVideoCover.observers.length && (!uploading && !checking)" title="提取截图">
            <i nz-icon nzType="camera" nzTheme="outline"></i>
        </div>
        </div>
        <div class="p-preview" *ngIf="videoUrl" >
            <video crossorigin="anonymous" [src]="videoUrl" controls #videoNode></video>
        </div>
    <div class="p-upload-check-bg" *ngIf="checking">
        <div class="i-bg" [style.width]="progress+'%'"></div>
        <div class="i-text">
<!--            <fa name="cloud-upload"></fa>-->
            <i nz-icon nzType="loading" nzTheme="outline"></i>Checking...
        </div>
    </div>
    <div class="p-upload-progress-bg" *ngIf="uploading">
        <div class="progress-wrapper">
            <div class="i-bg" [style.width]="progress+'%'"></div>
        </div>

        <div class="i-text">
<!--            <fa name="cloud-upload"></fa>-->
            Uploading...
        </div>
    </div>
</div>
</div>
    <!--
   <nz-divider></nz-divider>

   <div [style.display]="!uploading?'none':''" style="position:relative">
     <div style="width: calc( 100% - 20px);">
       <nz-progress [nzPercent]="progress">
       </nz-progress>
     </div>
     <i (click)="cancelUpload()" class="anticon anticon-close-circle" style="position: absolute;top: 50%;right: 0;transform: translateY(-50%);cursor: pointer"></i>
   </div>
   -->
<!--    <div [style.display]="!checkVideoExists?'none':''">-->
<!--      <span><i nz-icon nzType="loading" nzTheme="outline"></i> checking file to upload</span>-->
<!--    </div>-->







