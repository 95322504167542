// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { StorybookService } from '../../../../../services/storybook.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { NzSliderComponent } from 'ng-zorro-antd/slider';

@Component({
  selector: 'app-storybook-make',
  templateUrl: './storybook-make.component.html',
  styleUrls: ['./storybook-make.component.scss'],
})
export class StorybookMakeComponent implements OnInit {
  storybookId = 0;
  resource: any = {};
  id = 0;
  title = '';
  audio = new Audio();
  previewAudioObj = new Audio();
  previewPlayingIdx = -1;
  maxDuration = 100;
  range: [number, number] = [0, 10];
  isPlaying = false;
  loadedAudioMetaData = false;
  playTime = 0;
  formatedPlayTime = '';
  isSetRangeStartPoint = false;
  isSetRangeEndPoint = false;
  rangePoints: any[] = [];
  text = '';
  // @ts-ignore
  @ViewChild('audioSlider', {static: true}) audioSlider: NzSliderComponent;
  // @ts-ignore
  @ViewChild('carousel', {static: true}) carousel: NzCarouselComponent;
  // @ts-ignore
  @ViewChild('editorPage', {static: true}) editorPage: ElementRef;
  // @ts-ignore
  @ViewChild('pointbar', {static: true}) pointbar: ElementRef;


  currentSlideIdx = 0;
  storyPageData: any[] = [];
  // _tmpStoryData = {};

  currentTrackRange = [0, 20];
  // editMode = false;
  currentEditIdx = 0;

  _scale = 1;

  constructor(
      private storybookService: StorybookService,
    private msg: NzMessageService,
    private activateRouter: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    this.activateRouter.params.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        this.storybookId = parseInt(params['id'], 10);

      }
      // window.maker = this;
    });

    // this.audio.onerror((err: any) => {
    //   this.msg.error('audio file load error');
    // });
  }

  ngOnInit() {
    this.storybookService.fetch(this.storybookId).then(data => {
      this.resource = data.resource;
      this.id = data.id;
      this.title = data.title;
      this.audio.src = data.resource.audio;

      this.storyPageData = [];
      if (data.content && data.content.length) {
        this.previewPlayingIdx = 0;
        this.storyPageData = data.content;


      }

      this.previewAudioObj.src = data.resource.audio;
      this.audio.addEventListener('loadedmetadata', this.audioLoadedMetaData);



      this.audio.addEventListener('error', (error) => {
        this.msg.error('audio file load error');
      });
    }).catch(e => {
      this.msg.error('get data error');
    });
    // document.addEventListener('keyup', this.setShortcut);
    this.setShortcut();
  }
  playAudio() {
    this.audio.play();
  }
  setShortcut = () => {
    let ctrlPress = false;
    window.onkeydown = ( e ) => {
      // @ts-ignore
      if (document.activeElement.tagName === 'TEXTAREA') {
        return;
      }
      // e.stopImmediatePropagation();
      // e.stopPropagation();
      // e.preventDefault();
      // console.log(e)
      // pressedKeys.push( e.which || e.keyCode );
      if (e.which === 91 || e.which === 93) {
        ctrlPress = true;
      }
      if (ctrlPress && e.which === 39) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        this.nextCarousel();
      }
      if (ctrlPress && e.which === 37) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        this.prevCarousel();
      }
      if (ctrlPress && e.which === 13) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        this.saveBook();
      }
    };
    window.onkeyup =  (e) => {
      // @ts-ignore
      if (document.activeElement.tagName === 'TEXTAREA') {
        return;
      }
      // console.log(355123123, e);
      if (e.keyCode === 8 ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        // console.log('space');
        this.clearCurrentClip(true, true);
      }
      if (e.keyCode === 13 ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        // console.log('enter');
        // if (this.isSetRangeStartPoint && this.isSetRangeEndPoint) {
        //   this.addClip();
        //   return;
        // }
        this.setRange();
      }
      if (e.keyCode === 32 ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        // console.log('space');
        this.playOrPause();
      }
      if (e.keyCode === 39 ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        // console.log('right');
        let t = this.playTime + 1;
        if (t > this.maxDuration) {
          t = this.maxDuration;
        }
        this.playTime = t;
        this.audio.currentTime = this.playTime;
        this.formatTime(this.playTime);
      }
      if (e.keyCode === 37 ) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
        // console.log('left');
        let t = this.playTime - 1;
        if (t < 0) {
          t = 0;
        }
        this.playTime = t;
        this.audio.currentTime = this.playTime;
        this.formatTime(this.playTime);
      }
      if (e.keyCode === 91 || e.keyCode === 93) {
        ctrlPress = false;
      }
      // if (e.keyCode === 39 && (e.ctrlKey || e.metaKey)) {
      //   console.log('cmd + right');
      // }
      // if (e.keyCode === 37 && (e.ctrlKey || e.metaKey)) {
      //   console.log('cmd + left');
      // }
      // if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      //   console.log('cmd + enter');
      // }


    };

  }
  removeImage(idx: number) {
    this.storyPageData = this.storyPageData.splice(1, 1);
  }
  clearCurrentClip(clearDara?: boolean, clearMark?: boolean) {
    this.rangePoints.length = 0;
    const handlers = this.audioSlider.slider.nativeElement.querySelectorAll('.ant-slider-handle');
    for (let i = 0 ; i < handlers.length; i++) {
      const hn: any = handlers[i];
      hn.style.display = 'none';
    }
    const bar: any = this.audioSlider.slider.nativeElement.querySelector('.ant-slider-track');
    bar.style.display = 'none';

    this.isSetRangeStartPoint = false;
    this.isSetRangeEndPoint = false;
    this.text = '';
    if (clearMark) {
      const mark = this.pointbar.nativeElement.querySelector(`.mark-${this.currentEditIdx}`);
      if (mark) {
        mark.remove();
      }
    }

    if (clearDara) {
      delete this.storyPageData[this.currentEditIdx];
    }

  }
  addClip(evt?: any) {
    // this.storyPageData.push({
    //   text: this.text,
    //   start: this.rangePoints[0].time,
    //   end: this.rangePoints[1].time,
    //   pic: this.content.pics[this.currentSlideIdx],
    //   audio: this.content.audio
    // });

    // this.storyPageData = [].concat(this.storyPageData, {
    //   text: this.text,
    //   start: this.currentTrackRange[0],
    //   end: this.currentTrackRange[1],
    //   pic: this.resource.pics[this.currentSlideIdx],
    //   audio: this.resource.audio
    // });
    const t = [this.currentTrackRange[0], this.currentTrackRange[1]];
    t.sort();
    // @ts-ignore
    this.storyPageData[this.currentEditIdx] = {
      text: this.text,
      start: t[0],
      end: t[1],
      pic: this.resource.pics[this.currentSlideIdx],
      audio: this.resource.audio
    };


    this.clearCurrentClip(false, true);
    this.createMarkPoint(this.currentTrackRange[1]);
    this.nextCarousel();
    this.currentEditIdx += 1;
    if (this.currentEditIdx >= (this.resource.pics.length - 1)) {
      this.currentEditIdx = this.resource.pics.length - 1;
    }

    console.log(this.storyPageData);
  }
  createMarkPoint(pos: number, idx?: number) {
    const p = document.createElement('div');
    if (!idx) {
      idx = this.currentEditIdx;
    }

    p.className = `point mark-${idx}`;
    const perc = (pos / this.maxDuration) * 100;
    p.style.left = `${perc}%`;
    this.pointbar.nativeElement.appendChild(p);
  }
  getHandlers() {
    const sort = (a: any, b: any) => {
      const av = parseInt(a.style.left, 10);
      const bv = parseInt(b.style.left, 10);
      return av - bv;
    };
    const handlers = this.audioSlider.slider.nativeElement.querySelectorAll('.ant-slider-handle');
    const _handlers = [handlers[0], handlers[1]];
    _handlers.sort(sort);
    return _handlers;
  }
  setRange() {
    const handlers = this.getHandlers();
    for (let i = 0 ; i < handlers.length; i++) {
      const hn: any = handlers[i];
      const style = window.getComputedStyle(hn);
      if (style.display === 'none') {

        if (!this.isSetRangeStartPoint) {

          let item = this.storyPageData[this.currentEditIdx];
          if (!item) {
            item = {
              text: this.text,
              start: this.playTime,
              // end: this.currentTrackRange[1],
              pic: this.resource.pics[this.currentSlideIdx],
              audio: this.resource.audio
            };
          }
          this.storyPageData[this.currentEditIdx] = item;

          hn.style.display = 'block';
          const perc = (this.playTime / this.maxDuration) * 100;
          hn.style.left = `${perc}%`;
          this.rangePoints.push({prec: perc, time: this.playTime});
          this.isSetRangeStartPoint = true;
          this.currentTrackRange[0] = this.playTime;
          this.currentTrackRange = [this.currentTrackRange[0], this.currentTrackRange[1]];
          this.playAudio();
          this.isPlaying = true;
          break;
        } else {
          if (this.currentTrackRange[0] === this.playTime) {
            return;
          }
          let item = this.storyPageData[this.currentEditIdx];
          if (!item) {
            item = {
              text: this.text,
              end: this.playTime,
              // end: this.currentTrackRange[1],
              pic: this.resource.pics[this.currentSlideIdx],
              audio: this.resource.audio
            };
          }
          this.storyPageData[this.currentEditIdx] = item;

          hn.style.display = 'block';
          const perc = (this.playTime / this.maxDuration) * 100;
          hn.style.left = `${perc}%`;
          this.rangePoints.push({prec: perc, time: this.playTime});
          this.isSetRangeEndPoint = true;
          this.currentTrackRange[1] = this.playTime;
          this.currentTrackRange = [this.currentTrackRange[0], this.currentTrackRange[1]];
        }

      }
    }
    if (this.isSetRangeStartPoint && this.isSetRangeEndPoint) {
      const bar: any = this.audioSlider.slider.nativeElement.querySelector('.ant-slider-track');
      bar.style.display = 'block';


      const a_percent = (this.currentTrackRange[0] / this.maxDuration) * 100;
      const b_percent = (this.currentTrackRange[1] / this.maxDuration) * 100;
      this.rangePoints = [{prec: a_percent, time: this.currentTrackRange[0]}, {prec: b_percent, time: this.currentTrackRange[1]}];



      this.rangePoints.sort((a, b) => {
        return a.prec - b.prec;
      });
      bar.style.left = `${this.rangePoints[0].prec}%`;
      bar.style.width = `calc(${this.rangePoints[1].prec - this.rangePoints[0].prec}% + 2px)`;
      this.audio.pause();
      this.isPlaying = false;
      this.addClip();
    }
  }
  changeText(evt?: any) {
    if (this.storyPageData[this.currentEditIdx]) {
      const item = this.storyPageData[this.currentEditIdx];
      item.text = this.text;
      this.storyPageData[this.currentEditIdx] = item;
    }
  }
  audioSeek(evt: any) {
    const track = evt.target;
    this.audio.currentTime = track.value;
    this.maxDuration = parseInt(this.audio.duration + '', 10);
    this.formatTime(track.value);
    this.playTime = parseInt(track.value, 10);
  }
  playOrPause() {
    this.audio.removeEventListener('timeupdate', this.updateAudioTime);
    this.audio.addEventListener('timeupdate', this.updateAudioTime);
    if (this.isPlaying) {
      this.audio.pause();
      this.isPlaying = false;
    } else {
      this.playAudio();
      this.isPlaying = true;
    }
  }
  audioLoadedMetaData = () => {
    console.log(this.audio.duration);
    // this.maxDuration = parseInt(this.audio.duration + '', 10);
    this.maxDuration = parseInt(this.audio.duration * this._scale + '', 10);
    this.loadedAudioMetaData = true;
    for (let i = 0; i < this.storyPageData.length ; i++) {
      const item = this.storyPageData[i];
      this.createMarkPoint(item.end, i);
    }
    this.startPreviewEdit(0);
  }
  updateAudioTime = () => {
    this.playTime = parseInt(this.audio.currentTime + '', 10);
    this.formatTime(this.playTime);
  }
  formatSliderTip = (val: any) => {
    return this.timeFormater(val);
  }
  timeFormater(time: number) {
    const sec = time % 60;
    const min = Math.floor(time / 60);
    const hour = Math.floor(time / (60 * 60));
    let t = `${min}:${sec}`;
    if (hour) {
      t = `${hour}:${t}`;
    }
    return t;
  }
  formatTime(time: number) {
    this.formatedPlayTime = this.timeFormater(time);
  }
  beforeChangePic(evt: any) {
    // console.log('beforeChangePic', evt);
    this.changeText();
    if (this.storyPageData[evt.to]) {
      this.startPreviewEdit(evt.to);
    } else {
      this.clearCurrentClip(false, false);
    }

  }
  prevCarousel(evt?: any) {
    let idx = this.currentSlideIdx - 1;
    if (idx < 0) {
      idx = 0;
    }

    this.carousel.goTo(idx);
    this.currentSlideIdx = idx;
    // this.carousel.pre();
  }
  nextCarousel(evt?: any) {
    let idx = this.currentSlideIdx + 1;
    if (idx > (this.resource.pics.length - 1)) {
      idx = (this.resource.pics.length - 1);
    }
    this.carousel.goTo(idx);
    this.currentSlideIdx = idx;
    // this.carousel.next();
  }
  onSliderChange(evt: any) {
    // console.log('onSliderChange', evt);
      this.formatTime(evt[1]);
      let item = this.storyPageData[this.currentEditIdx] as any;
      if (!item) {
        item = {
          text: this.text,
          // start: this.currentTrackRange[0],
          // end: this.currentTrackRange[1],
          pic: this.resource.pics[this.currentSlideIdx],
          audio: this.resource.audio
        };
      }
    if (this.isSetRangeStartPoint && this.isSetRangeEndPoint) {
      item.start = this.currentTrackRange[0];
      item.end = this.currentTrackRange[1];
      this.currentTrackRange = [this.currentTrackRange[0], this.currentTrackRange[1]];
    }
    if (this.isSetRangeEndPoint) {
      // const mark = this.pointbar.nativeElement.querySelector(`.mark-${this.currentEditIdx}`);
      // if (mark) {
      //   mark.style.left = `${(this.currentTrackRange[1] / this.maxDuration) * 100}%`;
      // }
    }
    // @ts-ignore
      this.storyPageData[this.currentEditIdx] = item;
  }
  previewAudioCB () {
    const item = this.storyPageData[this.previewPlayingIdx] as any;
    if (this.audio.currentTime === item.end) {
      this.audio.pause();
    }
  }
  startPreviewAudio(idx: number) {
    this.previewPlayingIdx = idx;
    const item = this.storyPageData[idx] as any;
    console.log('previewAudio', item);
    this.audio.currentTime = item.start;


    this.audio.addEventListener('timeupdate', this.previewAudioCB);
    this.playAudio();

  }
  stopPreviewAudio(idx: number) {
    this.audio.removeEventListener('timeupdate', this.previewAudioCB);
    this.audio.pause();
    this.previewPlayingIdx = -1;
  }
  startPreviewEdit(idx: number) {
    // this.editMode = true;
    this.currentEditIdx = idx;
    const item = this.storyPageData[idx] as any;
    if (!item) {
      return;
    }
    this.text = item ? item.text : '';
    const handlers = this.audioSlider.slider.nativeElement.querySelectorAll('.ant-slider-handle');
    const bar: any = this.audioSlider.slider.nativeElement.querySelector('.ant-slider-track');
    const a: any = handlers[0];
    const b: any = handlers[1];
    a.style.display = 'block';
    b.style.display = 'block';
    bar.style.display = 'block';
    const a_percent = (item.start / this.maxDuration) * 100;
    const b_percent = (item.end / this.maxDuration) * 100;
    // a.style.left = `${a_percent}%`;
    // b.style.left = `${b_percent}%`;
    // bar.style.width = `calc(${b_percent - a_percent}% + 2px)`;
    // bar.style.left = `${a_percent}%`;
    this.currentTrackRange = [item.start, item.end];

    this.isSetRangeStartPoint = true;
    this.isSetRangeEndPoint = true;

    // @ts-ignore
    this.rangePoints = [{prec: a_percent, time: item.start}, {prec: b_percent, time: item.end}];
    this.audio.currentTime = item.start;
    this.playTime = item.start;
    this.formatTime(item.start);

  }
  saveClip() {
    const item = this.storyPageData[this.currentEditIdx] as any;
    const handlers = this.getHandlers();
    // const a: any = handlers[0];
    // const b: any = handlers[1];
    // const a_left = parseInt(a.style.left, 10) / 100;
    // const b_left = parseInt(b.style.left, 10) / 100;
    // const a_val = a_left * this.maxDuration; // Math.round(a_left * this.maxDuration);
    // const b_val = b_left * this.maxDuration; // Math.round(b_left * this.maxDuration);
    item.text = this.text;
    item.start = this.currentTrackRange[0];
    item.end = this.currentTrackRange[1];
    // @ts-ignore
    this.storyPageData[this.currentEditIdx] = item;
    this.currentEditIdx = -1;
    for (let i = 0 ; i < handlers.length; i++) {
      const hn: any = handlers[i];
      hn.style.display = 'none';
    }
    const bar: any = this.audioSlider.slider.nativeElement.querySelector('.ant-slider-track');
    bar.style.display = 'none';
  }
  saveBook(evt?: any) {
    const bookData = this.storyPageData.filter((item: any) => {
      if (typeof item.start === 'undefined') {
        return false;
      }
      if (typeof item.end === 'undefined') {
        item.end = this.maxDuration;
      }
      return true;
    });
    this.storybookService.save(this.storybookId, bookData).then(data => {
      if (data === 'ok') {
        this.router.navigate([`/admin/storybook/index`]);
      }
    });
  }
}
