<nz-layout class = 'admin-layout'>
  <!--<nz-sider nzCollapsible-->
            <!--[nzBreakpoint]="'sm'"-->
            <!--[(nzCollapsed)]="isCollapsed"-->
            <!--[nzTrigger]="triggerTemplate">-->
    <!--<div class="logo">-->
    <!--</div>-->
    <!--<ul nz-menu [nzTheme]="'dark'" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed">-->
      <!--<ng-container *ngFor="let tab of tabs">-->
        <!--<ng-container *ngIf="tab.tree.length">-->
          <!--<li nz-submenu [nzOpen] ="isActive(tab)">-->
          <!--<span title>-->
            <!--<i *ngIf="tab.icon" nz-icon type="{{tab.icon}}" theme="outline"></i>-->
            <!--<span class="nav-text a">{{tab.name}}</span>-->
          <!--</span>-->
          <!--<ng-container *ngIf="tab.tree.length">-->
            <!--<ul>-->
              <!--<ng-container *ngIf="filterMenu(tab)" >-->
                <!--<li nz-menu-item *ngFor="let m of tab.tree"-->
                    <!--[ngClass]="{'ant-menu-item-selected': shouldHighlight(m)}" >-->
                  <!--&lt;!&ndash;[routerLinkActive]="'ant-menu-item-selected'"&ndash;&gt;-->
                  <!--<a [routerLink]="m.url">-->
                    <!--<i *ngIf="m.icon" class="{{m.icon}}"></i>-->
                    <!--{{m.name}}-->
                  <!--</a>-->
                <!--</li>-->
              <!--</ng-container>-->

            <!--</ul>-->
          <!--</ng-container>-->
          <!--</li>-->
        <!--</ng-container>-->
        <!--<ng-container *ngIf="filterMenu(tab)">-->
          <!--<ng-container *ngIf="!tab.tree.length">-->
            <!--<li nz-menu-item-->
                <!--[ngClass]="{'ant-menu-item-selected': shouldHighlight(tab)}">-->
              <!--&lt;!&ndash;[routerLinkActive]="'ant-menu-item-selected'"&ndash;&gt;-->
              <!--<a [routerLink]="tab.url">-->
                <!--<i *ngIf="tab.icon" nz-icon type="{{tab.icon}}" theme="outline"></i>-->
                <!--<span class="nav-text b">{{tab.name}}</span>-->
              <!--</a>-->
            <!--</li>-->
          <!--</ng-container>-->
        <!--</ng-container>-->
      <!--</ng-container>-->




      <!--&lt;!&ndash;<li nz-submenu>&ndash;&gt;-->
        <!--&lt;!&ndash;<span title><i class="anticon anticon-user"></i><span class="nav-text">User</span></span>&ndash;&gt;-->
        <!--&lt;!&ndash;<ul>&ndash;&gt;-->
          <!--&lt;!&ndash;<li nz-menu-item>Tom</li>&ndash;&gt;-->
          <!--&lt;!&ndash;<li nz-menu-item>Bill</li>&ndash;&gt;-->
          <!--&lt;!&ndash;<li nz-menu-item>Alex</li>&ndash;&gt;-->
        <!--&lt;!&ndash;</ul>&ndash;&gt;-->
      <!--&lt;!&ndash;</li>&ndash;&gt;-->
      <!--&lt;!&ndash;<li nz-submenu>&ndash;&gt;-->
        <!--&lt;!&ndash;<span title><i class="anticon anticon-team"></i><span class="nav-text">Team</span></span>&ndash;&gt;-->
        <!--&lt;!&ndash;<ul>&ndash;&gt;-->
          <!--&lt;!&ndash;<li nz-menu-item>Team 1</li>&ndash;&gt;-->
          <!--&lt;!&ndash;<li nz-menu-item>Team 2</li>&ndash;&gt;-->
        <!--&lt;!&ndash;</ul>&ndash;&gt;-->
      <!--&lt;!&ndash;</li>&ndash;&gt;-->
      <!--&lt;!&ndash;<li nz-menu-item><span><i class="anticon anticon-file"></i><span class="nav-text">File</span></span></li>&ndash;&gt;-->
    <!--</ul>-->
  <!--</nz-sider>-->
  <nz-layout style="position: relative;">
    <app-admin-header  (sideEvent)="sideEvent($event)"></app-admin-header>

    <nz-layout>
      <nz-sider nzCollapsible
                [nzBreakpoint]="'sm'"
                [(nzCollapsed)]="isCollapsed"
                [nzTrigger]="triggerTemplate">
        <!--<div class="logo">-->
        <!--</div>-->
        <ul nz-menu [nzTheme]="'dark'" [nzMode]="'inline'" [nzInlineCollapsed]="isCollapsed">
          <ng-container *ngFor="let tab of tabs">
            <ng-container *ngIf="tab.tree.length">
              <li nz-submenu [nzOpen] ="isActive(tab)">
          <span title>
            <i *ngIf="tab.icon" nz-icon nzType="{{tab.icon}}" nzTheme="outline"></i>
            <span class="nav-text a">{{tab.name}}</span>
          </span>
                <ng-container *ngIf="tab.tree.length">
                  <ul>
                    <ng-container *ngIf="filterMenu(tab)" >
                      <li nz-menu-item *ngFor="let m of tab.tree" bbb
                          [ngClass]="{'ant-menu-item-selected': shouldHighlight(m)}" >
                        <!--[routerLinkActive]="'ant-menu-item-selected'"-->
                        <a [routerLink]="m.url">
                          <i *ngIf="m.icon" class="{{m.icon}}"></i>
                          {{m.name}}
                        </a>
                      </li>
                    </ng-container>

                  </ul>
                </ng-container>
              </li>
            </ng-container>
            <ng-container *ngIf="filterMenu(tab)">
              <ng-container *ngIf="!tab.tree.length">
                <li nz-menu-item aaa
                    [ngClass]="{'ant-menu-item-selected': shouldHighlight(tab)}">
                  <!--[routerLinkActive]="'ant-menu-item-selected'"-->
                  <a [routerLink]="tab.url">
                    <i *ngIf="tab.icon" nz-icon nzType="{{tab.icon}}" nzTheme="outline"></i>
                    <!--<i nz-icon nzType="gift" nzTheme="outline"></i>-->
                    <span class="nav-text b">{{tab.name}}</span>
                  </a>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>


        </ul>
      </nz-sider>


      <nz-content class="admin-main">
        <nz-breadcrumb style="margin:16px 0;">
          <nz-breadcrumb-item *ngFor="let bc of breadcrumb">{{bc}}</nz-breadcrumb-item>
        </nz-breadcrumb>
        <div class="admin-body" style="padding: 24px;background: #fff;margin-bottom: 24px;">
          <router-outlet></router-outlet>
        </div>
      </nz-content>


    </nz-layout>

    <!--<nz-content class="admin-main">-->
      <!--<nz-breadcrumb style="margin:16px 0;">-->
        <!--<nz-breadcrumb-item *ngFor="let bc of breadcrumb">{{bc}}</nz-breadcrumb-item>-->
      <!--</nz-breadcrumb>-->
      <!--<div style="padding:24px; background: #fff; min-height: 360px;">-->
        <!--<router-outlet></router-outlet>-->
      <!--</div>-->
    <!--</nz-content>-->
    <!--<nz-footer style="text-align: center;"> ©2018 Implement By iPlayABC</nz-footer>-->
  </nz-layout>
</nz-layout>
<ng-template #trigger>
  <i class="anticon anticon-up"></i>
</ng-template>
