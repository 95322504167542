

<nz-tabset nzSize="large" (nzSelectedIndexChange)="tabIndexChanged($event)">
    <nz-tab nzTitle="奖品管理">
        <div class="table-operations">
<!--            <ng-container *ngIf="!isSA">-->
                <button nz-button nzType="primary" [nzSize]="'large'" [routerLink]="'/admin/gifts/add'">
                    <i nz-icon nzType="gift" nzTheme="outline"></i>添加奖品
                </button>


<!--            </ng-container>-->
        </div>
        <nz-table #giftsTable
                  [nzLoading]="giftsListLoading"
                  [nzData]="giftsListTableData ? giftsListTableData?.rows: []"
                  [nzFrontPagination]="false"
                  [(nzPageIndex)]="currentGiftsListPageIndex"
                  (nzPageIndexChange)="loadGiftsListData($event)"
                  [nzTotal]="giftsListTableData ? giftsListTableData.total: 0">
            <!--            nzSimple-->
            <!--            -->
            <thead>
            <tr>
                <th>奖品名称</th>
                <th>奖品图片</th>
                <th>奖品库存</th>
                <th>所需金币</th>
                <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of giftsTable.data">
                <td>{{data.name}}</td>
                <td><img style="height: 80px" [src]="data.cover" alt=""></td>
                <td>{{data.amount}}</td>
                <td>{{data.price}}</td>
                <td>
                    <button nz-button style='margin-right: 1em;' nzType="primary"
                            [nzSize]="'small'"
                            [routerLink]="'/admin/gifts/edit/'+data.id">编辑</button>
                    <button nz-button nzSize="small" nzType="danger"
                            nz-popconfirm
                            nzPopconfirmTitle="确认删除奖品吗?"
                            nzPopconfirmPlacement="bottom"
                            (nzOnConfirm)="deleteGift(data.id)">删除</button>
                </td>
            </tr>
            </tbody>
        </nz-table>

    </nz-tab>

    <nz-tab  nzTitle="奖品发货记录">
<!--        <div nz-row>-->
<!--            <button nz-button [nzSize]="'default'" nzType="primary" (click)="onSelectAll()">批量发货</button>-->
<!--            <nz-divider nzType="vertical"></nz-divider>-->

<!--        </div>-->


        <p></p>
        <nz-table #giftDeliveryStatusTable
                  [nzFrontPagination]="false"
                  [nzPageSize]="10"
                  [(nzPageIndex)]="currentGiftsDeliveryPageIndex"
                  (nzPageIndexChange)="loadGiftsDeliveryData($event)"
                  [nzTotal]="giftsDeliveryTableData ? giftsDeliveryTableData.total : 0"
                  [nzLoading]="giftsDeliveryLoading"
                  [nzData]="giftsDeliveryTableData ? giftsDeliveryTableData.rows : []">
            <thead>
            <tr>
                <th nzCustomFilter>
                    姓名
                    <i
                        nz-th-extra
                        class="ant-table-filter-icon"
                        nz-icon
                        nz-dropdown
                        #nameDropdown="nzDropdown"
                        nzType="search"
                        [nzDropdownMenu]="nameSearch"
                        [class.ant-table-filter-open]="nameDropdown.nzVisible"
                        nzTrigger="click"
                        nzPlacement="bottomRight"
                        [nzClickHide]="true"
                        nzTableFilter
                    ></i>
                </th>
                <th>班级</th>
                <th>教师</th>
                <th nzCustomFilter>
                    学生手机号
                    <i
                            nz-th-extra
                            class="ant-table-filter-icon"
                            nz-icon
                            nz-dropdown
                            #phoneDropdown="nzDropdown"
                            nzType="search"
                            [nzDropdownMenu]="phoneSearch"
                            [class.ant-table-filter-open]="phoneDropdown.nzVisible"
                            nzTrigger="click"
                            nzPlacement="bottomRight"
                            [nzClickHide]="true"
                            nzTableFilter
                    ></i>
                </th>
                <th>奖品名称</th>
                <th>申请日期</th>
                <th nzShowFilter
                    [nzFilters]="listOfFilterStatus"
                    (nzFilterChange)="filterStatusChange($event)">状态</th>
                <th>发货时间</th>
                <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of giftDeliveryStatusTable.data">
                <td>{{ data.user_name }}</td>
                <td>{{ data.class_name }}</td>
                <td>{{ data.teacher_name }}</td>
                <td>{{ data.user_phone }}</td>
                <td>{{ data.gift_name }}</td>
                <td>{{ data.createon }}</td>
                <td>
                    <ng-container *ngIf="data.delivery_status == 1">
                        <span>已发货</span>
                    </ng-container>
                    <ng-container *ngIf="data.delivery_status == 0">
                        <span style="color: orangered">未发货</span>
                    </ng-container>
                </td>
                <td>{{ data.delivery_time ? data.delivery_time : '--' }}</td>
                <td>
                    <button *ngIf="data.delivery_status == 0"
                            nz-button style='margin-right: 1em;' nzType="primary"
                            nz-button nzSize="small"
                            nz-popconfirm
                            nzPopconfirmTitle="确认奖品发货吗?"
                            nzPopconfirmPlacement="bottom"
                            (nzOnConfirm)="changeGiftDeliveryStatus(data.id, 1)">发货</button>
                    <button *ngIf="data.delivery_status == 1"
                            nz-button nzSize="small" nzType="danger"
                            nz-popconfirm
                            nzPopconfirmTitle="确认奖品退货吗?"
                            nzPopconfirmPlacement="bottom"
                            (nzOnConfirm)="changeGiftDeliveryStatus(data.id, -1)">退货</button>
                </td>
            </tr>
            </tbody>
        </nz-table>
        <nz-dropdown-menu #nameSearch="nzDropdownMenu">
            <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="搜索姓名" [(ngModel)]="searchNameValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchName()" class="search-button">
                    Search
                </button>
                <button nz-button nzSize="small" (click)="resetSearchName()">Reset</button>
            </div>
            </div>
        </nz-dropdown-menu>
        <nz-dropdown-menu #phoneSearch="nzDropdownMenu">
            <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="搜索手机号" [(ngModel)]="searchPhoneValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchPhone()" class="search-button">
                    Search
                </button>
                <button nz-button nzSize="small" (click)="resetSearchPhone()">Reset</button>
            </div>
            </div>
        </nz-dropdown-menu>
    </nz-tab>
</nz-tabset>





