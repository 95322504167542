

<nz-modal [(nzVisible)]="visibility" [nzTitle]="title" (nzOnCancel)="closeChangeStudentClass()" (nzOnOk)="handleChangeOk()">
    <ng-container *nzModalContent>
    <div class="gutter-example">
        <div nz-row nzGutter="16">
            <div nz-col class="gutter-row" nzSpan="14">
                <div class="gutter-box">
                    <nz-select style="width: 100%;"
                               [(ngModel)]="selectedTeacher"
                               (ngModelChange)="doSelectTeacher($event)"
                               nzPlaceHolder="Select Teacher" nzAllowClear>
                        <nz-option *ngFor="let t of teacherList" [nzValue]="t" [nzLabel]="t.name+'|'+t.phone"></nz-option>
                    </nz-select>
                </div>
            </div>
            <div nz-col class="gutter-row" nzSpan="10">
                <div class="gutter-box">
                    <nz-select style="width: 100%;"
                               [(ngModel)]="selectedClass"
                               nzPlaceHolder="Select Class" nzAllowClear>
                        <nz-option *ngFor="let c of classList" [nzValue]="c" [nzLabel]="c.name"></nz-option>
                    </nz-select>
                </div>
            </div>
        </div>
    </div>
    </ng-container>
</nz-modal>


<!--<nz-modal [(nzVisible)]="confirmChangeVisible" [nzTitle]="title"-->
<!--          *ngIf="this.confirmChangeVisible"-->
<!--          (nzOnCancel)="closeConfirm()"-->
<!--          (nzOnOk)="sureConfirm()">-->
<!--    <ng-container *nzModalContent>-->
<!--    <h3 style="color: red" page="2">是否确认？</h3>-->
<!--    <label >-->
<!--        {{changeClassText}}-->
<!--    </label>-->
<!--    &lt;!&ndash;<h3>{{selectedTeacher.name}} to {{selectedClass.name}}</h3>&ndash;&gt;-->
<!--    </ng-container>-->
<!--</nz-modal>-->
