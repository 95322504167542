<div class="table-operations" >
    <ng-container *ngIf="user && user?.type === constUserType.SCHOOL_ADMIN">
        <button nz-button nzType="primary"
                [nzSize]="'large'"
                [routerLink]="'/admin/classes/add'">
            <i nz-icon nzType="team" nzTheme="outline"></i>添加班级
        </button>
    </ng-container>
    <a nz-popconfirm nzPopconfirmTitle="确定批量删除学生？"
       (nzOnConfirm)="deleteClasses()" >
        <button nz-button nzType="primary" [nzLoading]="deleteingClasses" [nzSize]="'large'"  [disabled]="disabledButton">
            <i nz-icon nzType="team" nzTheme="outline"></i>删除班级</button>
    </a>


    <div style="float: right; width: 270px; height: 40px; display: flex">
        <nz-input-group nzSearch   [nzAddOnAfter]="suffixIconButton">
        <input
                class="search-input"
                placeholder="学生手机号查询"
                nz-input
                [(ngModel)]="searchValue"
        />
        </nz-input-group>
        <ng-template #suffixIconButton>
            <button nz-button
                    nzSearch
                    nzType="primary"
                    (click)="searchBtnClick()" [nzLoading]="searchLoading">
                <span nz-icon nzType="search"></span>
            </button>
        </ng-template>
    </div>

    <nz-modal
            [(nzVisible)]="searchBoxVisible"
            nzTitle="查询结果"
            nzContent="{{searchResult}}"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleOk()"
    >
    </nz-modal>

</div>
<nz-table #classTable [nzData]="displayData"
          [nzFrontPagination]="true"
          [nzPageSize]="10"
          [nzLoading]="loadingData"
          [(nzPageIndex)]="currentPageIndex"
          [nzTotal]="totalClasses">
    <thead>
    <tr>
        <th nzShowCheckbox [(nzChecked)]="allChecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="checkAll($event)"></th>
        <!--<th>id</th>-->
        <th>序号</th>
        <th>班级</th>
        <th>教师</th>
        <th style="text-align: center;width: 15rem;">操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of classTable.data">
        <td nzShowCheckbox [(nzChecked)]="data.checked" (nzCheckedChange)="refreshStatus()"></td>
        <td>{{data.class_id}}</td>
        <!--        <td>{{data.numId}}</td>-->
        <td>{{data.class_name}}</td>
        <td>{{data.teacher}}</td>
        <td style="width: 18rem;">
            <div class="operations">
                <a nz-popconfirm nzPopconfirmTitle="确定删除班级？"
                   *ngIf="user && user.type >= constUserType.SCHOOL_ADMIN"
                   (nzOnConfirm)="deleteClass(data.class_id)" >
                    删除
                </a>
                <a (click)="editClass(data.class_id)"
                   *ngIf="user && user.type >= constUserType.SCHOOL_ADMIN" >编辑</a>
                <a (click)="manageStudents(data.class_id)">学员管理</a>
                <a (click)="statisticStudents(data.class_id)">学情统计</a>
            </div>

        </td>
    </tr>
    </tbody>
</nz-table>
