<nz-upload class="p-audio-uploader"
           [nzShowUploadList]="false"
           nzAccept = ".mp3"
           [nzBeforeUpload]="customUpload">
  <button nz-button type="button" *ngIf="showUploadBtn"  [ngClass]="{error: !!errors}">
    <i class="anticon anticon-upload"></i><span>Upload Audio</span>
  </button>
</nz-upload>
<!--<nz-divider *ngIf="showUploadBtn"></nz-divider>-->
<nz-progress *ngIf="uploading" [nzPercent]="progress"></nz-progress>
<audio *ngIf="audioUrl && !uploading" [src]="audioUrl" controls></audio>
