<div class="article-page">
  <div  class="article-add">
  <div class="toolbar">
      <a [routerLink]="['/admin/storybook/add']" >
        <button nz-button nzType="primary" nzSize="large">
          <i nz-icon nzType="plus" nzTheme="outline"></i>添加新绘本
        </button>
      </a>
      <a nz-popconfirm nzPopconfirmTitle="确定批量删除所选文章?" (nzOnConfirm)="batchDeleteData()" >
        <button nz-button nzSize="large"
                [nzLoading]="operating"
                [disabled]="disabledDeleteButton">
          <i nz-icon nzType="delete" nzTheme="outline"></i>
          批量删除
        </button>
      </a>
  </div>
  <nz-table
    class="article-add-table"
    #rowSelectionTable
    [nzData]="dataSet"
    nzFrontPagination = "false"
    [(nzPageIndex)] = "pageIndex"
    [nzTotal]="totalRow"
    [nzLoading]="loading"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
    (nzPageIndexChange)="getPageData($event)"
    (nzPageSizeChange)="refreshStatus()">
    <thead>
    <tr>
      <th nzShowCheckbox [(nzChecked)]="allChecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="checkAll($event)"></th>
      <th>标题</th>
      <th>等级</th>
      <th>页数</th>
      <th>操作</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
      <td nzShowCheckbox [(nzChecked)]="data && data.checked" (nzCheckedChange)="refreshStatus($event)"></td>
      <td style="max-width: 350px; min-width: 200px;">{{data.title}}</td>
      <td>
        {{data.level_name ? data.level_name : '----'}}
      </td>
      <td>
        {{data.content ? data.content.length : 0}}
      </td>
      <td class="operation">
        <a (click)="edit(data.id)">编辑</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a nz-popconfirm nzPlacement="topRight" nzPopconfirmTitle="确定除所选文章?" (nzOnConfirm)="delete(data.id)">删除</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a (click)="preview(data.id)" >预览</a>
      </td>
    </tr>
    </tbody>
  </nz-table>
  </div>
</div>
