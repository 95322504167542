import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';

@Component({
  selector: 'app-teachers-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class TeachersIndexComponent implements OnInit {
  displayData: any[] = [];
  totalTeacher = 0;
  currentPageIndex = 1;
  loadingData = false;
  constructor(private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
  }

  ngOnInit() {
    this.loadData(1);
  }
  loadData(page: any) {
    this.loadingData = true;
    this.schoolService.fetchTeachersBySchool(page).then(classes => {
      this.displayData = classes.rows;
      this.totalTeacher = classes.total;
      this.currentPageIndex = page;
      this.loadingData = false;
      this.setTmpNumId();
    }).catch(err => {
      this.loadingData = false;
      this.nzMessageService.error(`发生错误：${err.message}`);
    });
  }
  deleteTeacher(id: any) {
    console.log('asdfasdfasdfasd');
    this.userService.removeTeacher(id).then(classes => {
      if (classes.code === 200) {
        this.loadData(1);
        this.currentPageIndex = 1;
      } else {
        this.nzMessageService.error(classes.message);
      }
    }).catch(e => {
      this.nzMessageService.error(e.message);
    });
  }
  editTeacher(id: any) {
    this.router.navigate([`/admin/teachers/edit/${id}`]);
  }
  resetTeacherPw(id: any, phone: any) {
    this.userService.resetPassword({id, phone}).then( res => {
      this.nzMessageService.success('重置密码成功！');
    }).catch(err => {
      this.nzMessageService.error('操作异常！');
    });
  }
  setTmpNumId() {
    for (let i = 0; i < this.displayData.length; i++) {
      // @ts-ignore
      this.displayData[i].numId = i + 1 + (this.currentPageIndex - 1) * 10;
    }
  }
  changeTeachPermission(evt: any, row: any) {
    this.loadingData = true;
    this.userService.changeTeacherManage({id: row.id, evt}).then(r => {
      this.loadingData = false;
    }).catch(e => {
      this.loadingData = false;
      this.nzMessageService.error('操作异常！');
    });
  }
}

