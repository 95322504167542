import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { SchoolService } from '../../../../../services/school.service';
import { UserType } from '../../../../../model/Const';

@Component({
  selector: 'app-teachers-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class TeachersAddComponent implements OnInit {
  validateForm: UntypedFormGroup;
  isLoadingTeachers = false;
  teacherList = [];
  selectedTeacher = null;
  editTeacherId = null;
  user = null;
  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.validateForm = this.fb.group({
      name: [ '', [ Validators.required ] ],
      birthday: [ ''],
      sex: [''],
      phone: ['', [ Validators.required, Validators.pattern(/^1[3456789]\d{9}$/) ], [ this.phoneAsyncValidator ] ],
    });
    this.activatedRoute.params.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        // @ts-ignore
        this.editTeacherId = parseInt(params['id'], 10);
      }
    });
    this.user = this.userService.getUserInfo();
  }
  phoneAsyncValidator = (control: UntypedFormControl) => new Observable((observer: Observer<ValidationErrors>) => {
    const data = {phone: control.value};
    if (this.editTeacherId) {
      // @ts-ignore
      data['id'] = this.editTeacherId;
    }
    this.userService.checkUserExist(data).then(resp => {
      // console.log(97, resp);
      if (resp && resp.length) {
        observer.next({ error: true, duplicated: true });
      } else {
        // @ts-ignore
        observer.next(null);
      }
      observer.complete();
    });
    // setTimeout(() => {
    //   if (control.value === 'JasonWood') {
    //     observer.next({ error: true, duplicated: true });
    //   } else {
    //     observer.next(null);
    //   }
    //   observer.complete();
    // }, 1000);
  })
  ngOnInit() {
    if (this.editTeacherId) {
      this.userService.fetchTeacher(this.editTeacherId).then(td => {
        this.validateForm.controls['name'].setValue(td.name);
        this.validateForm.controls['birthday'].setValue(td.birthday);
        this.validateForm.controls['sex'].setValue(td.sex + '');
        // this.teacherSexValue = td.sex + '';
        this.validateForm.controls['phone'].setValue(td.phone);
      });
    }
  }
  formatDate (d: any) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }
  checkPhone(phone: any) {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      return false;
    }
    return true;
  }
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }

    if (value.birthday) {
      value.birthday = this.formatDate(value.birthday);
    } else {
      delete value['birthday'];
    }
    let redirect_url = '/admin/teachers/index';
    // @ts-ignore
    if (this.user.type === UserType.SCHOOL_ADMIN) {
      redirect_url = 'admin/teachers/index';
    }

    console.log('value:', value);

    if (this.editTeacherId) {
      this.userService.saveTeacher(this.editTeacherId, value).then( data => {
        console.log('data:', data);
        this.router.navigate([redirect_url]);
      });
    } else {
      this.userService.addTeacher(value).then( data => {
        this.router.navigate([redirect_url]);
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(key)) {
        this.validateForm.controls[ key ].markAsPristine();
        this.validateForm.controls[ key ].updateValueAndValidity();
      }
    }
  }


}
