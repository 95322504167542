// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { LessonResourceService } from '../../../../../../services/lesson-resource.service';
import { LessonResourceAudioThemeComponent } from './audio.component';

@Component({
  selector: 'app-lesson-resource-video-theme',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class LessonResourceVideoThemeComponent extends LessonResourceAudioThemeComponent implements OnInit, OnDestroy {

  // classId = 0;
  // returnPage = 1;
  //
  // curUserName = '';
  // curPhone = '';
  // user = null;
  // rows = [];
  // protected imageObserver: IntersectionObserver;
  public resourceType = 'video';



  constructor(protected userService: UserService,
              protected lessonResourceService: LessonResourceService,
              protected activatedRoute: ActivatedRoute ,
              protected router: Router ,
              protected nzMessageService: NzMessageService) {
    super(userService, lessonResourceService, activatedRoute, router, nzMessageService);




  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  ngOnInit() {
    super.ngOnInit();
  }

  formatDate (d) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }


}
