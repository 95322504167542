// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserType } from '../../../../../model/Const';


@Component({
  selector: 'app-class-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class ClassStatisticsComponent implements OnInit {
  displayData: any[] = [];
  totalDataCount = 0;
  currentPageIndex: string | number = 1;
  user = null;
  constUserType = UserType;
  classId = 0;
  calReporting = false;

  statisticsTerms: any[] = [];

  dateRange: any[] = [];


  loading = true;
  sortValue = null;
  sortKey = null;
  downloading = false;
  pageType = 0;
  dateArray: any[] = [];
  dateArrayIndex = 0;
  className = '';
  teacher = '';
  today_grading_rate = 0;
  today_report_rate = 0;
  weekRateData: {
    report_rate: number,
    grading_rate: number,
    cam_show_rate: number,
    dubbing_rate: number,
    listen_fulfill_rate: number,
  } = {
    report_rate: 0,
    grading_rate: 0,
    cam_show_rate: 0,
    dubbing_rate: 0,
    listen_fulfill_rate: 0,
  };

  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.dateRange = [
      // `${this.formatDate()} 00:00:00`,
      // `${this.formatDate()} 23:59:59`
      // @ts-ignore
      new Date(),
      // @ts-ignore
      new Date()
    ];
  }
  formatDate (d?: any, sep = '-') {
    if (typeof d === 'string') {
      d = new Date(d);
    } else if (typeof d === 'object') {
    } else {
      d = new Date();
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}${sep}${((d.getMonth() + 1) + '').padStart(2, '0')}${sep}${(d.getDate() + '').padStart(2, '0')}`;
  }
  ngOnInit() {
    this.loading = true;
    this.loadData(1);
    this.initWeeks();
    this.loadClassData();
    this.loadRateToday();
  }
  // @ts-ignore
  loadData(page: number, link = false) {
    // const start = `${this.formatDate(this.dateRange[0])}` ;
    // const end = `${this.formatDate(this.dateRange[1])}`;
    // console.log(1111)
    const query = {};
    if (this.dateRange[0]) {
      // @ts-ignore
      query['start'] = `${this.formatDate(this.dateRange[0])}`;
    }
    if (this.dateRange[1]) {
      // @ts-ignore
      query['end'] = `${this.formatDate(this.dateRange[1])}`;
    }
    if (this.sortKey) {
      // @ts-ignore
      query['sortKey'] = this.sortKey;
    }
    if (this.sortValue) {
      // @ts-ignore
      query['sortValue'] = this.sortValue;
    }
    if (link) {
      return this.clsService.downloadClassReports(this.classId, page, query);
    } else {
      this.clsService.fetchClassReports(this.classId, page, query).then(students => {
        // this.displayData = students.rows || [];
        // this.totalDataCount = students.total || 0;
        if (Array.isArray(students) && !students.length) {
          this.loading = false;
          return;
        }
        this.displayData = students.students || [];
        this.totalDataCount = students.students.length || 0;

        this.loading = false;
        this.currentPageIndex = page;
      }).catch(e => {
        this.nzMessageService.error('发生错误，请返回重试');
        this.loading = false;
      });
    }

  }

  loadClassData() {
    // let srv: any = this.schoolService;
    // if (this.user.type === UserType.TEACHER) {
    const srv = this.clsService;
    // }
    srv.fetchClassAndTeacher(this.classId).then(data => {
      console.log('----data;', data);

      this.className = data[0].class_name;
      this.teacher = data[0].teacher;
      // this.teacherId = data[0].teacher_id;
      // this.schoolId = data[0].school_id;

    });
  }



  onRangeChange(evt: any) {
    console.log('onRangeChange 1',  this.dateRange);
    console.log('onRangeChange 2', evt );
    this.dateRange = evt;
  }
  filterRangeData() {
    // console.log('filterRangeData', this.dateRange);
    this.loadData(1);
  }
  setFilterRange(type: number) {
    const curr = new Date();
    const day = curr.getDay();
    let firstDay = null;
    let lastDay = null;
    if (type === 0) {
      // firstDay = new Date(curr.getTime() - 60 * 60 * 24 * (day - 1 ) * 1000); // will return firstday (i.e. Sunday) of the week
      // lastDay = new Date(curr.getTime() + 60 * 60 * 24 * 5 * 1000);
      firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
      lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
    } else if (type === 1) {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth();
      firstDay = new Date(y, m, 1);
      lastDay = new Date(y, m + 1, 0);
    } else if (type === 2) {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth();
      const quarter = Math.floor((m / 3));
      firstDay = new Date(y, quarter * 3, 1);
      lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 3, 0);
    }

    console.log('firstDay:', firstDay);
    console.log('lastDay:', lastDay);
    // @ts-ignore
    this.dateRange = [firstDay, lastDay];
    this.filterRangeData();
  }

  sort(sort: any): void {
    // @ts-ignore
    this.sortKey = sort.key;
    // @ts-ignore
    this.sortValue = sort.value;
    this.loadData(1);
  }
  downloadStatisticData() {
    this.downloading = true;
    // @ts-ignore
    const url = this.loadData('all', true).then(res => {
      console.log(res);
      const data = [];
      const rows = res.rows || res.students;


      // <th nzShowSort nzSortKey="listen">听音</th>
      //     <th nzShowSort nzSortKey="animation">动画</th>
      //     <th nzShowSort nzSortKey="grading">分级作业</th>
      //     <th nzShowSort nzSortKey="read">课外</th>
      //     <th nzShowSort nzSortKey="dubbing">配音</th>
      //     <th nzShowSort nzSortKey="cam_show">视频秀</th>
      //     <th nzShowSort nzSortKey="lottery">奖券</th>
      //


      // <td>{{data.id}}</td>
      // <td>{{data.name}}</td>
      // <td>{{data.listen}} </td>
      // <td>{{data.animation}} </td>
      // <td>{{data.grading}} </td>
      // <td>{{data.read}} </td>
      // <td>{{data.dubbing}} </td>
      // <td>{{data.cam_show}} </td>
      // <td>{{data.lottery}} </td>
      //
      data.push(`姓名,听音,动画,分级作业,课外,配音,视频秀,奖券`);
      for (const row of rows) {
        row.name = !row.name ? '' : row.name;
        row.listen = !row.listen ? '' : row.listen;
        row.animation = !row.animation ? '' : row.animation;
        row.grading = !row.grading ? '' : row.grading;
        row.read = !row.read ? '' : row.read;
        row.dubbing = !row.dubbing ? '' : row.dubbing;
        row.cam_show = !row.cam_show ? '' : row.cam_show;
        row.lottery = !row.lottery ? '' : row.lottery;

        data.push(`${row.name},${row.listen},${row.animation},${row.grading},${row.read},${row.dubbing},${row.cam_show},${row.lottery}`);
      }
      const str_data = data.join('\n');
      const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), str_data], { type: 'text/csv;charset=utf-8' });
      const durl = window.URL.createObjectURL(blob);
      // window.location.href= durl;
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = durl;
      const s = `${this.formatDate(this.dateRange[0], '')}`;
      const e = `${this.formatDate(this.dateRange[1], '')}`;
      a.download = `${res.class_name}(${s}-${e}).csv`;
      a.click();
      // window.URL.revokeObjectURL(durl);
      a.remove();
      this.downloading = false;
    }).catch(err => {
      this.downloading = false;
    });
  }
  reCalcReportData() {
    this.calReporting = true;

    const tmpData = this.dateArray[this.dateArrayIndex];
    // @ts-ignore
    console.log(tmpData.dateStr);
    // @ts-ignore
    let dr = tmpData.dateStr.replace(' - ', '/');
    dr = dr.replace(/\./g, '-');

    this.clsService.reCalcReportData(this.classId, dr).then(r => {
      this.loadRateWeek();
      this.calReporting = false;
    }).catch(err => {
      this.nzMessageService.error(err.message);
      this.calReporting = false;
    });
  }
// @ts-ignore
  changeReportPage(pageType) {
    this.pageType = pageType;

    switch (this.pageType) {

      case 0:
        // 今日汇报
        this.dateRange = [
          // @ts-ignore
          new Date(),
          // @ts-ignore
          new Date()
        ];
        this.filterRangeData();
        break;

      case 1:
        // 周汇报
        this.dropdownClick(this.dateArrayIndex);
        break;

      case 2:
        // 月汇报
        const sDate = new Date();
        sDate.setDate(1);
        this.dateRange = [
          // @ts-ignore
          sDate,
          // @ts-ignore
          new Date()
        ];
        this.filterRangeData();
        break;

      case 3:
        // 自定义
        // @ts-ignore
        this.dateRange = [new Date(), new Date()];
        // this.filterRangeData();

    }

    console.log('dateRange: ', this.dateRange);
  }

  initWeeks() {console.log('initWeeks');
    const weeks = 5;
    const cur = new Date();
    const curTime = cur.getTime();
    const day = cur.getDay();
    const TIMESTAMP = 24 * 60 * 60 * 1000; // 一天（单位：毫秒）
    const arr = [];
    for (let i = 0; i <= weeks; i++) {
      const mondayTime = curTime - (day - 1) * TIMESTAMP - TIMESTAMP * i * 7;
      const monday = this.formatWeekDate(new Date(mondayTime));

      const sundayTime = curTime + (7 - day) * TIMESTAMP - TIMESTAMP * i * 7;
      const sunday = this.formatWeekDate(new Date(sundayTime));

      const tmpData = {
        'dateStr': monday + ' - ' + sunday,
        'dateStart': new Date(mondayTime),
        'dateEnd': new Date(sundayTime)
      };
      arr.push(tmpData);
    }
    // @ts-ignore
    this.dateArray = arr;

  }

  formatWeekDate(date: Date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      // @ts-ignore
      month = '0' + month;
    }
    if (day < 10) {
      // @ts-ignore
      day = '0' + day;
    }
    return year + '.' + month + '.' + day;
  }

  dropdownClick(index: number) {
    this.dateArrayIndex = index;

    const tmpData = this.dateArray[index];
    // @ts-ignore
    this.dateRange = [tmpData.dateStart, tmpData.dateEnd];
    this.filterRangeData();
    this.loadRateWeek();
  }


  loadRateToday(date = null) {
    if (!date) {
      // @ts-ignore
      date = this.formatDate(new Date());
    }
    console.log('dateL', date);
// @ts-ignore
    this.clsService.fetchClassReportDetail(this.classId, date).then(data => {
      console.log('data:', data);

      this.today_grading_rate = Math.floor(data.today_grading_rate * 100);
      this.today_report_rate = Math.floor(data.today_report_rate * 100);

    }).catch(e => {
      this.nzMessageService.error('发生错误，请返回重试');
    });
  }


  loadRateWeek() {

    const dateStart = this.formatDate(this.dateRange[0]);
    const dateEnd = this.formatDate(this.dateRange[1]);

// @ts-ignore
    this.clsService.fetchClassReportOverview(this.classId, [dateStart, dateEnd]).then(data => {
      console.log('data:', data);

      data.cam_show_rate = Math.floor(data.cam_show_rate * 100);
      data.dubbing_rate =  Math.floor(data.dubbing_rate * 100);
      data.listen_fulfill_rate =  Math.floor(data.listen_fulfill_rate * 100);
      data.report_rate =  Math.floor(data.report_rate * 100);

      this.weekRateData = data;
      // this.today_grading_rate = Math.floor(res.today_grading_rate * 100);
      // this.today_report_rate = Math.floor(res.today_report_rate * 100);

    }).catch(e => {
      if (e.errorCode === 1017) {
        return;
      }
      this.nzMessageService.error('发生错误，请返回重试')
    });


  }
}
