import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../services/user.service';
import {PermissionService} from '../services/permission.service';

@Injectable()
export class AdminAuthGuard implements CanActivate {

  constructor(
      private userService: UserService,
      private permission: PermissionService,
      private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('check admin ', this.userService.isValidAdmin(route));
    /*
    let ss: any = document.getElementById('cnzz-script');
    if (!ss) {
      ss = document.createElement('script');
      ss.setAttribute('id', 'cnzz-script');
      ss.src = 'ht' +
        'tps://s23.cnzz.com/z_stat.php?id=1277759870&web_id=1277759870';
      document.head.appendChild(ss);
    } else {
      ss.src = '';
    }

    ss.src = 'https://s23.cnzz.com/z_stat.php?id=1277759870&web_id=1277759870';
    */


    if (this.userService.isValidAdmin(route)) {
      const m = this.permission.filterMenu({url: this.router.url});
      if (!m) {
        return false;
      }
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
  }
}
