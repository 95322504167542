<nz-spin [nzSpinning]="!loadedAudioMetaData">
  <h3>{{title}}</h3>
  <nz-carousel #carousel [nzEffect]="'scrollx'" nzEnableSwipe
               nzAutoPlay="false"
               nzDots="false"
               (nzBeforeChange)="onBeforeChangeSlider($event)"
               nzAutoPlaySpeed="0">
    <div nz-carousel-content *ngFor="let item of content">
      <img class= 'carousel-img' src="{{item.pic}}" alt="">
    </div>
  </nz-carousel>
  <div>{{text}}</div>
</nz-spin>
<div>{{formatedPlayTime}}</div>
<nz-button-group >
  <button nz-button nzType="primary" (click)="goBackSlider($event)"><i nz-icon type="left"></i>Backward</button>
  <button nz-button nzType="primary" (click)="goNextSlider($event)">Forward<i nz-icon type="right"></i></button>
</nz-button-group>
