<div>
  <nz-spin *ngIf="!ltsUrl" [nzSpinning]="loading">
    <button  (click)="fetchLTS()" nz-button nzType="primary" [nzSize]="'large'">获取华为授权</button>
  </nz-spin>
  <button  (click)="openLTS()" *ngIf="ltsUrl" nz-button nzType="primary" [nzSize]="'large'">新窗口打开</button>
<!--  <div *ngIf="iframeLtsUrl" style="overflow: scroll;position: relative;">-->
<!--    <div style="position: absolute;-->
<!--    height: 93px;-->
<!--    width: 100%;-->
<!--    background: #fff;"></div>-->
<!--    <div style="    width: 49px;-->
<!--    position: absolute;-->
<!--    height: 100%;-->
<!--    background: #fff;"></div>-->
<!--    <iframe [src]="iframeLtsUrl" style="width:100%" height="855" id="ltsIframePage"></iframe>-->
<!--  </div>-->
</div>

