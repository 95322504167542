import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { PreviousRouteService } from '../../../../../services/previous-route.service';

@Component({
  selector: 'app-class-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ClassAddComponent implements OnInit, OnDestroy {
  validateForm: UntypedFormGroup;
  isLoadingTeachers = false;
  teacherList: any[] = [];
  selectedTeacher = null;
  editClassId = null;

  returnPage = 1;
  // private suber: Subscription;
  // private prevUrl: string;
  constructor(private fb: UntypedFormBuilder,
              private prService: PreviousRouteService,
              private clsService: ClassService,
              private userService: UserService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.validateForm = this.fb.group({
      name: [ '', [ Validators.required ] ],
      teacher_id   : [ '', [ Validators.required ] ],
      comment   : [ '' ],
    });
    this.activatedRoute.params.subscribe( (params: any) => {
      if (params.id && parseInt(params.id, 10) === params.id * 1) {
        // @ts-ignore
        this.editClassId = parseInt(params.id, 10);

      }
    });
    this.activatedRoute.queryParams.subscribe( (query: any) => {
      if (query.page && parseInt(query.page, 10) === query.page * 1) {
        this.returnPage = parseInt(query.page, 10);
      }
    });
  }
  ngOnDestroy() {
    // this.suber.unsubscribe();
  }
  ngOnInit() {
    // this.suber = this.router.events
    //   .pipe(filter((e: any) => e instanceof RoutesRecognized),
    //     pairwise()
    //   ).subscribe((e: any) => {
    //   this.prevUrl = e[0].urlAfterRedirects; // previous url
    // });

    // this.suber = this.router.events
    //   .subscribe((event) => {
    //     if (event instanceof NavigationStart) {
    //       console.log('previousUrl', this.router.url);
    //     }
    //   });

    // const info = this.userService.getUserInfo();
    this.schoolService.fetchAllTeachersBySchool().then(data => {
      data.map((r: any) => {
        r.display_name = `${r.name} | ${r.phone}`;
      });
      this.teacherList = data;
      if (this.editClassId) {
        this.clsService.fetchClass(this.editClassId).then(td => {
          this.validateForm.controls['name'].setValue(td.name);
          this.validateForm.controls['teacher_id'].setValue(td.teacher_id);
          this.validateForm.controls['comment'].setValue(td.comment);
        });
      }
    });


  }
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
    if (this.editClassId) {
      this.schoolService.saveClass(this.editClassId, value).then( data => {
        this.router.navigate(['/admin/classes/index'], {queryParams: { page: this.returnPage }});
      });
    } else {
      this.schoolService.addClass(value).then( data => {
        this.router.navigate(['/admin/classes/index'], {queryParams: { page: this.returnPage }});
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
  }
  teacherChange(evt?: any) {

  }
  goBack() {
    // [queryParams]="{ page: returnPage }"
    //   [routerLink]="'/admin/classes/index'"
    // console.log(this.prService.getPreviousUrl())
    const prevUrl = this.prService.getPreviousUrl();
    if (prevUrl) {
      this.router.navigate([prevUrl], {queryParams: { page: this.returnPage }});
    } else {
      history.go(-1);
    }


  }
}
