import { AfterContentInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutofocusSelect]'
})
export class AutofocusSelectDirective implements AfterContentInit {
  @Input()
  ngModel = '';

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterContentInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'autofocus', '');
    this.el.nativeElement.value = this.ngModel;
    this.el.nativeElement.select();
  }

}
