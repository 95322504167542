<div class="storybook-maker" #editorPage>
  <div class="pics" style="height: 250px">
    <!--<slideshow [imageUrls]="content.pics"-->
               <!--[debug]="false"-->
               <!--(onSlideLeft)="onSlideLeft(evt)"-->
               <!--(onSlideRight)="onSlideRight(evt)"></slideshow> -->
    <div class="left-pic" (click)="prevCarousel($event)">
      <i nz-icon nzType="caret-left" nzTheme="outline"></i>
    </div>
    <nz-carousel #carousel [nzEffect]="'scrollx'"
                 nzAutoPlay="false"
                 nzDots="false"
                 (nzBeforeChange)="beforeChangePic($event)"
                 nzAutoPlaySpeed="0">
      <div nz-carousel-content *ngFor="let src of resource.pics; let idx = index">
        <i nz-icon style="display: none;" nzType="close-circle" nzTheme="outline" class="content-image-remove" (click)="removeImage(idx)"></i>
        <img class= 'carousel-img' src="{{src}}" alt="">
      </div>
    </nz-carousel>
    <div class="right-pic" (click)="nextCarousel($event)">
      <i nz-icon nzType="caret-right" nzTheme="outline"></i>
    </div>
  </div>
  <br>
  <textarea style="margin-bottom: 24px;margin-top: 12px" rows="4"
            nz-input [(ngModel)]="text"
            (blur)="changeText($event)"></textarea>
  <br>
  <div id="audioContainer" style="margin-bottom: 12px;">
  <!--<div id="playbar" >-->
    <!--<div class="track-wrapper">-->
      <!--<div class="select-range">-->
        <!--<div class="range-bar"></div>-->
        <!--<div cdkDrag (cdkDragMoved)="dragRangeStartBar($event)" cdkDragLockAxis="x" class="start range-point" ></div>-->
        <!--<div cdkDrag (cdkDragMoved)="dragRangeEndBar($event)" cdkDragLockAxis="x" class="end range-point"></div>-->
      <!--</div>-->
      <!--<input type=range class="track">-->
    <!--</div>-->

  <!--</div>-->
  <div id="audiobar" >
    <nz-spin [nzSpinning]="!loadedAudioMetaData">
      <div class="editor-body">
        <div class="progress" #pointbar></div>
        <nz-slider #audioSlider style="margin-top: 20px;"
                   (ngModelChange)="onSliderChange($event)"
                   nzRange nzMin="0"
                   [nzTipFormatter]="formatSliderTip"
                   [(ngModel)]="currentTrackRange"
                   [nzMax]="maxDuration"></nz-slider>

        <input type=range class="track" autofocus
               (change)="audioSeek($event)"
               [max]="maxDuration"
               [(value)]="playTime">
      </div>

    <br>
      <div style="margin-top: 30px;">
    <nz-button-group >
      <button nz-button nzType="primary" (click)="playOrPause()">
        <ng-container *ngIf="!isPlaying">
          <i nz-icon nzType="play-circle" nzTheme="outline"></i>play
        </ng-container>
        <ng-container *ngIf="isPlaying">
          <i nz-icon nzType="pause-circle" nzTheme="outline"></i>pause
        </ng-container>

      </button>



      <button nz-button nzType="primary"  *ngIf="!isSetRangeStartPoint"
              (click)="setRange()">
        <i nz-icon nzType="plus-circle"></i>step 1
      </button>
      <button nz-button nzType="primary"  *ngIf="isSetRangeStartPoint && !isSetRangeEndPoint"
              (click)="setRange()">
        <i nz-icon nzType="plus-circle"></i>step 2
      </button>
      <button nz-button nzType="primary"  *ngIf="isSetRangeStartPoint && isSetRangeEndPoint"
              (click)="addClip($event)">
        <i nz-icon nzType="plus-circle"></i>step 3
      </button>


      <!--
      <button nz-button nzType="primary" (click)="setRange()" [disabled]="!!isSetRangeEndPoint">
        <ng-container *ngIf="!isSetRangeStartPoint">
          <i nz-icon type="download" theme="outline"></i>set start
        </ng-container>
        <ng-container *ngIf="isSetRangeStartPoint">
          <i nz-icon type="check" theme="outline" ></i>set end
        </ng-container>
      </button>
      <button nz-button nzType="primary"  *ngIf="!(currentEditIdx > -1)"
              [disabled]="!isSetRangeEndPoint"
              (click)="addClip($event)">
        <i nz-icon type="plus-circle"></i>add
      </button>
      -->
      <button nz-button nzType="primary"
              [disabled]="!isSetRangeStartPoint"
              (click)="clearCurrentClip(true, true)">
        <i nz-icon nzType="plus-circle"></i>clear
      </button>
      <!--<button nz-button nzType="primary" *ngIf="(currentEditIdx > -1)"-->
              <!--(click)="saveClip($event)">-->
        <!--<i nz-icon type="plus-circle"></i>save edit-->
      <!--</button>-->
      <button nz-button nzType="primary"(click)="saveBook($event)">
        <i nz-icon nzType="upload" nzTheme="outline"></i>save book</button>
    </nz-button-group>
      </div>
      <div>
        {{formatedPlayTime}}
      </div>
    </nz-spin>
  </div>
</div>
  <br>
  <div style="display: none;">
  <nz-list

    [nzDataSource]="storyPageData"
    [nzItemLayout]="'vertical'"
    [nzRenderItem]="item">
    <ng-template #item let-item let-i="index">
      <nz-list-item [nzContent]="item ? item.text : ''"
                    [nzActions]="[playAction, editAction]"
                    [nzExtra]="extra">
        <ng-template #playAction>
          <div (click)="startPreviewAudio(i)" *ngIf="previewPlayingIdx !== i">
          <i nz-icon nzType="play-circle" nzTheme="outline" style="margin-right: 8px;"></i> play
          </div>
          <div (click)="stopPreviewAudio(i)" *ngIf="previewPlayingIdx === i">
            <i nz-icon nzType="pause-circle" nzTheme="outline" style="margin-right: 8px;"></i> stop
          </div>
        </ng-template>
        <ng-template #editAction>
          <div (click)="startPreviewEdit(i)">
          <i nz-icon nzType="form" nzTheme="outline"></i> edit
          </div>
        </ng-template>
        <ng-template #extra>
          <img height="272" style="max-width: 300px;height: auto" alt="logo" src="{{item.pic}}">
        </ng-template>
      </nz-list-item>
    </ng-template>
  </nz-list>
  </div>
</div>
