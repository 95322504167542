import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class ClassService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  fetchAllClasses() {
    return super.get(`/teacher/classes`);
  }
  fetchClasses(page: string|number) {
    return super.get(`/teacher/classes/page/${page}`);
  }
  fetchClassAndTeacher(classId: string|number) {
    return super.get(`/teacher/class/${classId}`);
  }
  removeClassByTeacher(id: string|number) {
    return super.remove(`/teacher/class/${id}`);
  }
  fetchClass(id: string|number) {
    return super.get(`/class/${id}`);
  }
  fetchClassReports(class_id: string|number, page: string|number, data: any) {
    // let url = `/reports/class/${class_id}/page/${page}`;
    let url = `/v3/reports/statistics/class/${class_id}`;
    if (data) {
      const searchParams = new URLSearchParams();
      Object.keys(data).forEach(key => searchParams.append(key, data[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }
  downloadClassReports(class_id: string|number, page: string|number, data: any) {
    // let url = `/reports/class/${class_id}/page/${page}`;
    let url = `/v3/reports/statistics/class/${class_id}`;
    if (data) {
      const searchParams = new URLSearchParams();
      Object.keys(data).forEach(key => searchParams.append(key, data[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);

  }
  fetchClassReportDetail(class_id: string|number, date: string) {
    return super.get(`/reports/class/${class_id}/detail/${date}`);
  }
  fetchClassReportOverview(class_id: string|number, date: string) {
    return super.get(`/reports/class/${class_id}/overview/${date[0]}/${date[1]}`);
  }
  fetchClassMonthlyStatistics(class_id: string|number, page: string|number, data: any) {
    let url = `/reports/statistics/class/${class_id}/monthly/page/${page}`;
    if (data) {
      const searchParams = new URLSearchParams();
      Object.keys(data).forEach(key => searchParams.append(key, data[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }
  reCalcReportData(class_id: string|number, dateRange: string) {
    return super.post(`/reports/statistics/class/${class_id}/calc`, {
      week_key: dateRange
    });
  }
    fetchClassQuarterStatistics(class_id: string|number, page: string|number, data: any) {
    let url = `/reports/statistics/class/${class_id}/quarter/page/${page}`;
    if (data) {
      const searchParams = new URLSearchParams();
      Object.keys(data).forEach(key => searchParams.append(key, data[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }
  fetchUserReports(user_id: string|number, page: string|number, data: any) {
    let url = `/reports/user/${user_id}/page/${page}`;
    if (data) {
      const searchParams = new URLSearchParams();
      Object.keys(data).forEach(key => searchParams.append(key, data[key]));
      url += `?${searchParams.toString()}`;
    }
    return super.get(url);
  }












}
