// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SystemService } from '../../../../../../services/system.service';
import { UserService } from '../../../../../../services/user.service';
import { SchoolService } from '../../../../../../services/school.service';
import { QupeiyinService } from '../../../../../../services/qupeiyin.service';

@Component({
  selector: 'app-qupeiyin-index',
  templateUrl: './qupeiyin-index.component.html',
  styleUrls: ['./qupeiyin-index.component.scss']
})
export class QupeiyinIndexComponent implements OnInit {
  query = {
    level: 0,
    school_id: 0,
    kind: [],
    theme: [],
  };
  currentPageNumber = 1;
  dataLoading = false;
  tableData = {
    rows: [],
    total: 0,
    currentPage: 0,
  };

  formLevelOptions: any[] = [];
  formKindOptions: any[] = [];
  formThemeOptions: any[] = [];
  private user_info: any;

  constructor(private sysSrv: SystemService,
              private userSrv: UserService,
              private schoolSrv: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private qupeiyinSvc: QupeiyinService,
              private nzMessageService: NzMessageService) {
    this.user_info = this.userSrv.getUserInfo();
  }

  filterLevel(evt) {
    this.dataLoading = true;
    this.query.level = evt;
    this.loadData(1);
  }
  filterKind() {
    this.dataLoading = true;
    const kind = this.formKindOptions.filter(v => {
      return v.checked;
    }).map(v => v.value);
    this.query.kind = kind;
    this.loadData(1);
  }
  filterTheme () {
    this.dataLoading = true;
    const theme = this.formThemeOptions.filter(v => {
      return v.checked;
    }).map(v => v.value);
    this.query.theme = theme;
    this.loadData(1);
  }
  ngOnInit() {
    this.initConditions();
  }
  changePage(evt) {
    console.log(evt);
    this.loadData(evt);
  }
  delete(id) {
    this.qupeiyinSvc.deleteItem(id).then(data => {
      this.initConditions();
    });
  }
  initConditions() {
    this.dataLoading = true;
    this.qupeiyinSvc.loadFormInfo().then(data => {
      console.log('qupeiyinSvc', data);
      const formLevelOptions: any[] = [];
      const formKindOptions: any[] = [];
      const formThemeOptions: any[] = [];
      data.rows.forEach((info: any) => {
        if (info.type === 1) {
          formLevelOptions.push(info);
        } else if (info.type === 2) {
          formKindOptions.push({ label: info.name, value: info.id/*, checked: kind && kind.checked ? true : false*/ });
        } else if (info.type === 3) {

          formThemeOptions.push({ label: info.name, value: info.id/*, checked: theme && theme.checked ? true : false*/ });
        }
      });
      this.formLevelOptions = formLevelOptions;
      this.formKindOptions = formKindOptions;
      this.formThemeOptions = formThemeOptions;
      this.loadData(1);
    });
  }
  loadData(page) {

    if (!page || isNaN(page)) {
      page = this.currentPageNumber;
    } else {
      this.currentPageNumber = page;
    }

    this.query['page'] = page;
    console.log(this.query);
    this.query.school_id = this.user_info.school_id;
    this.dataLoading = true;
    this.qupeiyinSvc.list(this.query).then(data => {
      console.log('list', data);
      this.tableData = data;
      this.dataLoading = false;
    });
  }



}
