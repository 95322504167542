<div class="book-view" (contextmenu)="false" >
  <div class="content">
    <div class="header">
<!--      <a (click)="goBack()" class="back-to-list">-->
<!--        &lt;!&ndash;<img src="../../../../../assets/book-player/back1.png" alt="">&ndash;&gt;-->
<!--      </a>-->
      <div class="hc">
        <div class="title"> {{pages.length ? pages[0].title : ''}} </div>
        <div class="page-num">{{currentPlayIndex + 1}}/{{pages.length}}</div>
      </div>
      <ng-container *ngIf="usePreload">
      <a class="play-close" *ngIf="!autoPlay" (click)="toggleAutoPlay()"></a>
      <a class="play-open" *ngIf="autoPlay" (click)="toggleAutoPlay()"></a>
      </ng-container>
    </div>
    <div class="body">
      <div class="player">
        <div class="prev-btn" (click)="prevPage($event)" [ngStyle]="{'display':playedBook ? 'block' : 'none'}"></div>
        <div class="book-page">
          <nz-carousel [nzEffect]="'scrollx'" #player
                       [nzDots]="false"

                       (nzAfterChange)="afterChange($event)"
                       (nzBeforeChange)="beforeChange($event)">
            <div nz-carousel-content *ngFor="let page of pages">
              <div class="page" #pageContainer>
                <!--<div style="position: absolute;width:20px;height:20px; left:20px;top: 20px;background: red"></div>-->
                <div [ngStyle]="{
                'top.px': highlightPosition.top,
                'left.px': highlightPosition.left,
                'width.px': highlightPosition.width,
                'height.px': highlightPosition.height
                }" class="word-highlight" #wordHighlight></div>
                <img [src]="page.img" alt="" >
                <!--(click)="replayCurrentAudio()"-->
              </div>
            </div>
          </nz-carousel>
        </div>

        <div class="pause-btn" (click)="togglePauseCurrentAudio($event)" [ngStyle]="{'display':playedBook ? 'block' : 'none'}">
          <i nz-icon nzType="play-circle" nzTheme="outline" *ngIf="pauseCurrentAudio"></i>
          <i nz-icon nzType="pause-circle" nzTheme="outline" *ngIf="!pauseCurrentAudio"></i>
        </div>

        <div class="next-btn" (click)="nextPage($event)" [ngStyle]="{'display':playedBook ? 'block' : 'none'}"></div>
      </div>
    </div>
    <ng-container *ngIf="usePreload">
      <div class="loading-resource" *ngIf="resourceLoading">
        <nz-progress [nzPercent]="resourceLoadingPercent" nzType="circle" class="loading-progress"></nz-progress>
      </div>
      <div class="play-btn"  *ngIf="!resourceLoading" (click)="listenBook($event)" [ngStyle]="{'display':playedBook ? 'none' : 'block'}">
        ▶
      </div>
    </ng-container>
  </div>
</div>
<audio src="" muted="false" #audioPlayer></audio>
