// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserType } from '../../../../../model/Const';


@Component({
  selector: 'app-class-statistics-monthly',
  templateUrl: './statistics-monthly.component.html',
  styleUrls: ['./statistics-monthly.component.scss']
})
export class ClassStatisticsMonthlyComponent implements OnInit {
  selectedValue = 'listen';

  selectedValueMap = {
    'listen': '听音',
    'animation': '动画',
    'grading': '分级',
    'read': '课外',
    'dubbing': '配音',
    'cam_show': '视频秀'
  };


  displayData!: {
    name: any,
    total: any
  }[] = [];
  totalDataCount = 0;
  currentPageIndex = 1;
  user = null;
  displayWeeks = [0];
  constUserType = UserType;
  classId = 0;


  dateRange = [];


  loading = true;
  sortValue = null;
  sortKey = null;

  showItem = '';
  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.user = this.userService.getUserInfo();
    // @ts-ignore
    this.showItem = this.selectedValueMap[this.selectedValue];
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
    });
    this.dateRange = [
      // `${this.formatDate()} 00:00:00`,
      // `${this.formatDate()} 23:59:59`
      // @ts-ignore
      new Date(),
      // @ts-ignore
      new Date()
    ];
  }
  changeSelect(val: any) {

  }
  formatDate (d?: any) {
    if (typeof d === 'string') {
      d = new Date(d);
    } else if (typeof d === 'object') {
    } else {
      d = new Date();
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }



  ngOnInit() {
    this.loading = true;
    this.loadData(1);

  }
  loadData(page: any) {
    // const start = `${this.formatDate(this.dateRange[0])}` ;
    // const end = `${this.formatDate(this.dateRange[1])}`;
    // console.log(1111)
// @ts-ignore
    this.showItem = this.selectedValueMap[this.selectedValue];


    const query = {};
    if (this.dateRange[0]) {
      // @ts-ignore
      query['start'] = `${this.formatDate(this.dateRange[0])}`;
    }
    if (this.dateRange[1]) {
      // @ts-ignore
      query['end'] = `${this.formatDate(this.dateRange[1])}`;
    }
// @ts-ignore
    query['show'] = this.selectedValue;

    this.clsService.fetchClassMonthlyStatistics(this.classId, page, query).then(students => {
      this.displayData = students.rows || [];
      this.totalDataCount = students.total || 0;
      this.loading = false;
      this.currentPageIndex = page;
    });
  }



  onRangeChange(evt: any) {
    // console.log('onRangeChange 1',  this.dateRange);
    // console.log('onRangeChange 2', evt );
    // this.dateRange = evt;
  }
  filterRangeData() {
    // console.log('filterRangeData', this.dateRange);
    this.loadData(1);
  }
  setFilterRange(type: number) {
    const curr = new Date();
    const day = curr.getDay();
    let firstDay = null;
    let lastDay = null;
    if (type === 0) {
      // firstDay = new Date(curr.getTime() - 60 * 60 * 24 * (day - 1 ) * 1000); // will return firstday (i.e. Sunday) of the week
      // lastDay = new Date(curr.getTime() + 60 * 60 * 24 * 5 * 1000);
      firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
      lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
    } else if (type === 1) {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth();
      firstDay = new Date(y, m, 1);
      lastDay = new Date(y, m + 1, 0);
    } else if (type === 2) {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth();
      const quarter = Math.floor((m / 3));
      firstDay = new Date(y, quarter * 3, 1);
      lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 3, 0);
    }
// @ts-ignore
    this.dateRange = [firstDay, lastDay];
    this.filterRangeData();
  }

  sort(sort: any): void {
    // @ts-ignore
    this.sortKey = sort.key;
    // @ts-ignore
    this.sortValue = sort.value;
    this.loadData(1);
  }

  weekRangeChanged(evt: any) {
    const {start, end} = evt;
    // @ts-ignore
    this.dateRange = [start, end];
    const diffDays = Math.round(Math.abs((start.getTime() - end.getTime()) / (86400000))) + 1;
    this.displayWeeks = Array.from({length: diffDays / 7}, (_, i) => i);
    this.loadData(1);
  }
}
