import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { SchoolService } from '../../../../../services/school.service';

import * as XLSX from 'xlsx';
import { ClassService } from '../../../../../services/class.service';
import { SystemService } from '../../../../../services/system.service';


@Component({
  selector: 'app-students-batch-add',
  templateUrl: './batch_add.component.html',
  styleUrls: ['./batch_add.component.scss']

})
export class StudentsBatchAddComponent implements OnInit {
  validateForm: UntypedFormGroup;
  isLoadingTeachers = false;
  teacherList: any[] = [];
  selectedTeacher = null;
  editUserId = null;
  classId = 0;
  returnPage = 1;
  uploadFileName = '';
  loading = false;
  personTable: any[] = [];
  isSaving = false;
  teacher = '';
  className = '';
  teacherId = null;
  schoolId = null;
  errMsg = false;
  errlist = [];

  csvSeparator = ',';
  // @ts-ignore
  @ViewChild('customizeDiv', {static: true} ) private d1: ElementRef;


  constructor(private fb: UntypedFormBuilder,
              private sysSrv: SystemService,
              private userService: UserService,
              private schoolService: SchoolService,
              private clsService: ClassService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private renderer: Renderer2,
              private nzMessageService: NzMessageService) {
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
      console.log('params: ', params);

    });
    this.activatedRoute.queryParams.subscribe( query => {
      if (query['page'] && parseInt(query['page'], 10) === query['page'] * 1) {
        this.returnPage = parseInt(query['page'], 10);
      }
    });
    this.validateForm = this.fb.group({
      name: [ '', [ Validators.required ] ],
      // birthday: [ ''],
      // sex: [null],
      phone: ['', [ Validators.required, Validators.pattern(/^1[3456789]\d{9}$/) ], [ this.phoneAsyncValidator ] ],
    });
    this.activatedRoute.params.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        // @ts-ignore
        this.editUserId = parseInt(params['id'], 10);
      }
    });

  }



  phoneAsyncValidator = (control: UntypedFormControl) => new Observable((observer: Observer<ValidationErrors>) => {
    const data = {phone: control.value};
    if (this.editUserId) {
        // @ts-ignore
      data['id'] = this.editUserId;
    }
    this.userService.checkUserExist(data).then(resp => {
      // console.log(99, resp);
      if (resp && resp.length) {
        observer.next({ error: true, duplicated: true });
      } else {
        // @ts-ignore
        observer.next(null);
      }
      observer.complete();
    });
    // setTimeout(() => {
    //   if (control.value === 'JasonWood') {
    //     observer.next({ error: true, duplicated: true });
    //   } else {
    //     observer.next(null);
    //   }
    //   observer.complete();
    // }, 1000);
  })
  ngOnInit() {
    if (this.editUserId) {
      this.userService.fetchStudent(this.editUserId).then(td => {
        this.validateForm.controls['name'].setValue(td.name);
        this.validateForm.controls['birthday'].setValue(td.birthday);
        this.validateForm.controls['sex'].setValue(td.sex + '');
        // this.teacherSexValue = td.sex + '';
        this.validateForm.controls['phone'].setValue(td.phone);
      });
    }
    this.loadData();
  }

  loadData() {
    // let srv: any = this.schoolService;
    // if (this.user.type === UserType.TEACHER) {
    const srv = this.clsService;
    // }
    srv.fetchClassAndTeacher(this.classId).then(data => {
      console.log('----data;', data);

      this.className = data[0].class_name;
      this.teacher = data[0].teacher;
      this.teacherId = data[0].teacher_id;
      this.schoolId = data[0].school_id;
      // this.displayData = classes.rows;
      // this.totalClasses = classes.total;
      // this.currentPageIndex = page;
    });
  }

  formatDate (d: any) {
    if (typeof d === 'string') {
      d = new Date(d);
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }
  checkPhone(phone: string) {
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
      return false;
    }
    return true;
  }
  // @ts-ignore
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }

    if (value.birthday) {
      value.birthday = this.formatDate(value.birthday);
    } else {
      delete value['birthday'];
    }
    if (this.editUserId) {
      this.userService.saveStudent(this.classId, this.editUserId, value).then( data => {
        this.router.navigate([`/admin/classes/${this.classId}/students/index`], {queryParams: { page: this.returnPage }});
      });
    } else {
      this.userService.addStudent(this.classId, value).then( data => {
        this.router.navigate([`/admin/classes/${this.classId}/students/index`], {queryParams: { page: this.returnPage }});
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
  }
  teacherChange(evt?: any) {

  }




  downloadTemplate() {


    /* original data */
    const filename = 'template.xlsx';
    const data = [['姓名', '电话'], ['张三', 13012345678]];
    const ws_name = 'SheetJS';

    const wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(data);

    /* add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    /* write workbook */
    if (typeof console !== 'undefined') { console.log(new Date()); }
    XLSX.writeFile(wb, filename);
    if (typeof console !== 'undefined') { console.log(new Date()); }


    return;

//     // 要导出的数据
//     var jsonData = [{
//       aa: '张三',
//       bb: 13012345678
//     }];
//
//
//
//
//
//     let str = '<tr><td>姓名</td><td>电话</td></tr>';
//     // 循环遍历，每行加入tr标签，每个单元格加td标签
//     for (let i = 0 ; i < jsonData.length ; i++ ) {
//       str += '<tr>';
//       for (let item in jsonData[i]) {
//         // 增加\t为了不让表格显示科学计数法或者其他格式
//         str += `<td>${jsonData[i][item] + '\t'}</td>`;
//       }
//       str += '</tr>';
//
//     }
//
//
//     var worksheet = 'Sheet1';
//     var uri = 'data:application/vnd.ms-excel;base64,';
//
//     var template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
//     xmlns:x="urn:schemas-microsoft-com:office:excel"
//     xmlns="http://www.w3.org/TR/REC-html40">
//     <head><meta charset='utf-8' /><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
//     <x:Name>${worksheet}</x:Name>
//     <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
//     </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
//     </head><body><table>${str}</table></body></html>`;
// // 转base64
//     var url = uri + window.btoa(unescape(encodeURIComponent(template)));
// // 下载模板
//     this.downloadExcel(url, 'Template.csv');





    //
    // let data = [];
    // data.push('姓名,电话');
    // data.push('张三,13900000000');
    // // for (const row of res.rows) {
    // //   row.name = row.name === null ? '' : row.name;
    // //   row.listen = row.listen === null ? '' : row.listen;
    // //   row.animation = row.animation === null ? '' : row.animation;
    // //   row.read = row.read === null ? '' : row.read;
    // //   row.lottery = row.lottery === null ? '' : row.lottery;
    // //   row.grading = row.grading === null ? '' : row.grading;
    // //   row.cam_show = row.cam_show === null ? '' : row.cam_show;
    // //   row.dubbing = row.dubbing === null ? '' : row.dubbing;
    // //
    // //   data.push(`${row.name},${row.listen},${row.animation},${row.read},
    // ${row.lottery},${row.grading},${row.cam_show},${row.dubbing}`);
    // // }
    // const str_data = data.join('\n');
    // const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), str_data], { type: 'text/csv;charset=utf-8' });
    // const durl = window.URL.createObjectURL(blob);
    // // window.location.href= durl;
    // const a = document.createElement('a');
    // document.body.appendChild(a);
    // a.href = durl;
    // // const s = `${this.formatDate(this.dateRange[0], '/')}`;
    // // const e = `${this.formatDate(this.dateRange[1], '/')}`;
    // a.download = 'temp.xlsx';
    // a.click();
    // // window.URL.revokeObjectURL(durl);
    // a.remove();


  }


  downloadExcel(csv: any, name: string) {

      console.log('in downloadExcel');

      const eleLink = document.createElement('a');
      eleLink.download = name || 'work.csv';
      eleLink.style.display = 'none';
      // 字符内容转变成blob地址
      const blob = new Blob([csv]);
      eleLink.href = URL.createObjectURL(blob);
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);


    //
    //
    // // 利用a标签的download属性进行下载
    // var link = document.createElement("a");
    //
    // // 设置a标签的属性
    // link.href = url;
    // link.download = name || 'work.xls';
    //
    // // 加入dom树中，模拟用户点击并下载
    // // document.body.appendChild(link);
    //
    // this.renderer.appendChild(this.d1.nativeElement, link);
    // link.click();
    //
    // // 移除该元素，防泄漏
    //
    // this.renderer.removeChild(this.d1.nativeElement, link);
  }


  beforeUpload2 = (data: any) => {

    return false;

  }

  beforeUpload = (file: any) => {
    console.log('beforeUpload');
    // console.log('file2:', file);

    // this.loading = true;
    const that = this;

    const f = file;
    this.uploadFileName = f.name.toString();
    // console.log('this.uploadFileName :', this.uploadFileName );

    let wb; // 读取完成的数据
    const reader = new FileReader();
    reader.onload = (e) => {
      // console.log('e:', e);
      // @ts-ignore
      const data = e.target['result'];

      // that.beforeUpload2(data);
      // return;

      wb = XLSX.read(data, {
        type: 'binary'
      });

      // wb.SheetNames[0]是获取Sheets中第一个Sheet的名字
      // wb.Sheets[Sheet名]获取第一个Sheet的数据
      // @ts-ignore
      let persons = [];

      for (const sheet in wb.Sheets) {
        if (wb.Sheets.hasOwnProperty(sheet)) {
          const fromTo = wb.Sheets[sheet]['!ref'];
          // console.log(fromTo);
          // @ts-ignore
          persons = persons.concat(XLSX.utils.sheet_to_json(wb.Sheets[sheet]));
          // break; // 如果只取第一张表，就取消注释这行
        }
      }

      // console.log(persons);

      this.loading = false;

      // for (let i = 0; i < persons.length; i++) {
      //   console.log(persons[i]['姓名']);
      //   console.log(persons[i]['电话']);
      // }
      // @ts-ignore
      this.personTable = [...persons];
      console.log('persons', persons);

      //
      //
      // const tmpData = JSON.stringify( XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]) );
      // // document.getElementById("demo").innerHTML = tmpData;
      // console.log('data:' , tmpData);
    };
    this.loading = true;
    reader.readAsBinaryString(f);

    return false;

  }

  handleChange(info: any): void {
    console.log('handleChange:');
    console.log('file:', info);
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // // Get this url from response in real world.
        // this.getBase64(info.file!.originFileObj!, (img: string) => {
          this.loading = false;
        //   this.avatarUrl = img;
        // });

          // this.beforeUpload(info.file.originFileObj);
        break;
      case 'error':
        // this.msg.error('Network error');
        // this.loading = false;
        break;
    }
  }




  save() {

    console.log('in save');
    this.isSaving = true;

    const students = this.personTable;
    // @ts-ignore
    const new_s = [];
    for (let i = 0; i < students.length; i++) {

      // console.log('student: ', students[i]);

      if (!students[i]['电话']) {
        this.nzMessageService.error(`格式错误，${i + 1}行，手机号错误`);
        this.isSaving = false;
        return;
      }
      // @ts-ignore
      let p = students[i]['电话'].toString();
      p = p.trim();
      if (!/^1[3456789]\d{9}$/.test(p)) {
        this.nzMessageService.error(`格式错误，${i + 1}行，手机号错误`);
        this.isSaving = false;
        return;
      }

      if (!students[i]['姓名']) {
        this.nzMessageService.error(`格式错误，${i + 1}行，拆分名字错误`);
        this.isSaving = false;
        return;
      }
      let n = students[i]['姓名'];
      // @ts-ignore
      n = n.trim();
      if (!n) {
        this.nzMessageService.error(`格式错误，${i + 1}行，拆分名字错误`);
        this.isSaving = false;
        return;
      }
// @ts-ignore
      const pe = new_s.find(el => el.phone === p);
      if (pe) {
        this.nzMessageService.error(`格式错误，${i + 1}行，${p}已经存在！`);
        this.isSaving = false;
        return;
      }
      new_s.push({
        name: n,
        phone: p
      });
    }



    this.sysSrv.batchAddStudent(this.schoolId, this.teacherId, this.classId, new_s ).then(r => {
      console.log(r);
      this.personTable = [];
      this.isSaving = false;
      this.nzMessageService.success('保存成功');
      // this.selectedTeacher = '';
      // this.selectedClass = '';
    }).catch(e => {
      console.log(e);
      this.isSaving = false;
      this.errlist = e.data;
      this.errMsg = true;
      // this.nzMessageService.error(e.message +
      // (e.data && e.data.message ? e.data.message : ''), { nzDuration: 10000 });
    });
  }



  submitBtnClick() {
    this.save();
  }


  closeError() {
    this.errMsg = false;
    this.errlist = [];
  }






  // @ts-ignore
  exportCVS(value: any, columns: any, exportFilename: any) {
    const data = value;
    let csv = '\ufeff';
    // headers
    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      csv += '"' + (column.header || column) + '"';
      if (i < (columns.length - 1)) {
        csv += this.csvSeparator;
      }
    }
    // body
    data.forEach((record: any) => {
      csv += '\n';
      for (let i_1 = 0; i_1 < columns.length; i_1++) {
        const column = columns[i_1];
        csv += '"' + this.resolveFieldData(record, column) + '"';
        if (i_1 < (columns.length - 1)) {
          csv += this.csvSeparator;
        }
      }
    });
    const blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    });
    // @ts-ignore
    if (window.navigator.msSaveOrOpenBlob) {
      // @ts-ignore
      navigator.msSaveOrOpenBlob(blob, exportFilename + '.csv');
    } else {
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', exportFilename + '.csv');
        link.click();
      } else {
        csv = 'data:text/csv;charset=utf-8,' + csv;
        window.open(encodeURI(csv));
      }
      document.body.removeChild(link);
    }
  }

  resolveFieldData(data: any, field: any) {
    if (data && field) {
      if (field.indexOf('.') === -1) {
        return data[field];
      } else {
        const fields = field.split('.');
        let value = data;
        for (let i = 0, len = fields.length; i < len; ++i) {
          if (value === null) {
            return null;
          }
          value = value[fields[i]];
        }
        return value;
      }
    } else {
      return null;
    }
  }
}
