import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class QupeiyinService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  getItem(id: string|number) {
    return this.get(`/qupeiyin/item/${id}`);
  }
  deleteItem(id: string|number) {
    return this.post(`/qupeiyin/item/delete`, {id});
  }
  loadFormInfo() {
    return this.get(`/qupeiyin/form/info`);
  }
  save(school_id: string|number, data: any) {
    return this.post(`/qupeiyin/form/save?school_id=${school_id}`, data);
  }
  list(query: any) {
    let url = `/qupeiyin/form/list`;
    if (query) {
      const searchParams = new URLSearchParams();
      Object.keys(query).forEach(key => searchParams.append(key, query[key]));
      url += `?${searchParams.toString()}`;
    }

    return super.get(url);
  }










}
