
export class TimerTotal {

  total = 0; // 秒
  interval = 0;

  getTotal() {
    return this.total;
  }

  begin() {
    // @ts-ignore
    this.interval = setInterval(() => {
      this.total ++;
    }, 1000);
  }

  pause() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  resume() {
    // @ts-ignore
    this.interval = setInterval(() => {
      this.total ++;
    }, 1000);
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  reset() {
    this.total = 0;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  getTotalMinute() {
    let res = Math.floor(this.total / 60);
    if (this.total % 60 > 30) {
      res ++;
    }
    return res;
  }
}
