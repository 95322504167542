<div>
    <nz-modal
            [(nzVisible)]="setExpiredModalVisible"
            nzTitle="单系统平板续费"
            (nzOnCancel)="handleCancel()"
            (nzOnOk)="handleSure()"
            [nzOkLoading]="isSetExpiredLoading"
    >
            <nz-space *nzModalContent  nzDirection="vertical" style="width: 100%; ">
                <p>到期日期</p>
                <nz-date-picker  [(ngModel)]="expiredDate" ></nz-date-picker>
                <p style="margin-top: 1rem">金额</p>
                <nz-input-number-group nzPrefix="￥" style="width: 100%">
                    <nz-input-number [(ngModel)]="price" [nzStep]="1"></nz-input-number>
                </nz-input-number-group>
                <p style="margin-top: 1rem">备注</p>
                <textarea rows="4" nz-input [(ngModel)]="remark"></textarea>
            </nz-space>
    </nz-modal>
<!--    height: calc(100vh - 188px);-->
<!--    (nzCurrentPageDataChange)="onCurrentPageDataChange($event)"-->
    <nz-table #tabletInfoTable nzSize="small"
              [nzTotal]="total"
              [nzPageSize]="pageSize"
              [nzPageIndex]="pageIndex"
              (nzPageIndexChange)="onPageIndexChange($event)"
              [nzFrontPagination]="false"
              [nzLoading]="loadingData"
              [nzPageSize]="50"
              [nzScroll]="{ y: tableHeight + 'px'}"

              class="sm-mac-table"
              [nzData]="tableData">
        <thead>
<!--        (nzQueryParams)="onQueryParamsChange($event)"-->
        <tr>
<!--            <th>brand</th>-->
<!--            <th>model</th>-->
            <th nzWidth="50px">id</th>
            <th nzWidth="250px" nzColumnKey="sn" [nzFilters]="filters" [nzFilterFn]="true" nzCustomFilter style="min-width: 150px;text-align: left!important">SN
                <nz-filter-trigger [(nzVisible)]="snSearchVisible" [nzActive]="snSearchValue.length > 0" [nzDropdownMenu]="menuSN">
                    <span nz-icon nzType="search"></span>
                </nz-filter-trigger></th>
            <th nzWidth="150px">过期时间</th>
            <th nzWidth="150px">更新时间</th>
            <th nzWidth="200px">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of tabletInfoTable.data">
            <td>{{ data.id }}</td>
            <td style="text-align: left!important">{{ data.sn }}</td>
            <td>{{ data.expired_at }}</td>
            <td>{{ data.updated_at }}</td>
            <td>
<!--                <a-->
<!--                        [style]="{color: data.type==1? 'gray':'#f50'}"-->
<!--                        nz-popconfirm-->
<!--                        nzPopconfirmTitle="重置后使用时长清零，用户需要重新激活使用"-->
<!--                        (nzOnConfirm)="resetByMac(data)"-->
<!--                >重置</a>-->
<!--                <nz-divider nzType="vertical"></nz-divider>-->
                <a style="color: #1890ff" (click)="showSetExpiredModal(data)">续费</a>
<!--                <nz-divider nzType="vertical"></nz-divider>-->
<!--                <a style="color: #1890ff" (click)="showOtherInfo(data)">信息</a>-->
<!--                <nz-divider nzType="vertical"></nz-divider>-->
<!--                <a [style]="{color: data.type==1? 'gray':'#1890ff'}" (click)="syncInfo(data)">同步</a>-->

            </td>
        </tr>
        </tbody>
    </nz-table>

    <nz-dropdown-menu #menuSN="nzDropdownMenu">
        <div class="ant-table-filter-dropdown">
            <div class="search-box">
                <input type="text" nz-input placeholder="Search sn" [(ngModel)]="snSearchValue" />
                <button nz-button nzSize="small" nzType="primary" (click)="searchSNAction()" class="search-button">Search</button>
                <button nz-button nzSize="small" (click)="resetSearchSNAction()">Reset</button>
            </div>
        </div>
    </nz-dropdown-menu>
</div>

