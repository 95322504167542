import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class PromotionService extends BaseService {
  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }

  getPromotions(page: string|number) {
    return super.get(`/orders/promotions?page=${page}`);
  }
  getPromotion(id: string|number) {
    return super.get(`/orders/promotions/${id}`);
  }

  getCurrentPromotion() {
    return super.get(`/orders/current/promotion`);
  }
  addPromotion(data: any) {
    return super.post(`/orders/promotion`, data);
  }
  deletePromotion(id: string|number) {
    return super.remove(`/orders/promotion/${id}`);
  }

}
