<div class="book-title book-field">
  <input nz-input placeholder="storybook name"
         (ngModelChange)="capitalizeTitle($event)"
         [(ngModel)]="title" nzSize="large">
</div>
<div class="book-level book-field">
  <nz-tree-select style="width: 250px"
                  [nzSize]="'large'"
                  nzPlaceHolder="Please select"
                  [nzAllowClear]="false"
                  [nzDropdownStyle]="{ 'max-height': '300px' }"
                  [nzNodes]="levelTree"
                  [nzDefaultExpandAll]="true"
                  nzShowSearch
                  nzPlaceHolder="Please select"
                  [(ngModel)]="levelValue"
                  (ngModelChange)="onChangeTreeValue($event)">
  </nz-tree-select>

</div>
<div class="book-images book-field">
  <nz-upload
    nzType="drag"
    [nzMultiple]="true"
    nzAccept = "image/*"
    nzListType="picture"
    nzAction="https://jsonplaceholder.typicode.com/posts/"
    [nzBeforeUpload]="handleBeforeUpload">
    <p class="ant-upload-drag-icon">
      <i nz-icon nzType="inbox"></i>
    </p>
    <p class="ant-upload-text">Click or drag file to this area to upload</p>
    <p class="ant-upload-hint">Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files</p>
  </nz-upload>
</div>

<div class="book-audio book-field">
  <app-upload-audio
    (audioUploaded)="onAudioUploadSuccess($event)"
  ></app-upload-audio>
</div>

<div class="images-list book-field">
  <div class="pictures">
    <div nz-row nzGutter="16">
      <div nz-col class="gutter-row" nzSpan="6" *ngFor="let pic of picturesList; let idx = index">
        <div class="gutter-box">
          <i nz-icon nzType="close-circle" theme="outline" class="upload-remove" (click)="removeImage(idx)"></i>
          <app-upload-image-with-preview
            [picItem]="pic"
            (imageUploaded)="onImageUploadSuccess($event)"
          ></app-upload-image-with-preview>
        </div>
      </div>
    </div>
  </div>
</div>


<button nz-button nzType="primary" (click)="doMakeStory($event)" [disabled]="!(levelValue && title && (_piclst.length === picturesList.length) && audioHash)">制作绘本</button>
