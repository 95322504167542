import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';

// import {_coursewareFolderService} from '../../../services/courseware-folder.service';

@Component({
  selector: 'app-move-dialog',
  templateUrl: './move-dialog.component.html',
  styleUrls: ['./move-dialog.component.scss']
})
export class MoveDialogComponent {
  isVisible = false;
  expand = true;
  folderSid = '0';
  expandKeys = ['0'];
  nodes = [new NzTreeNode({
    key: '0',
    sid: '0',
    id: 0,
    title: 'All',
    name: 'All',
    expanded: true,
    children: []
  })];
  folderName = '';
  targetFolder = null;

  @Input() disabled = false;
  @Input() data = [];
  @Output() target = new EventEmitter();

  checkedList = [];

  constructor(
    // private coursewareFolderService: _coursewareFolderService
  ) {
  }

  onHandleOpen() {
    // @ts-ignore
    this.checkedList = this.data.filter(item => item._checked && item.type === 1);
    /* this.coursewareFolderService.get(this.folderSid).then((data) => {
      const { folders } = data;
      const list = folders.filter(item => {
        if (!!_.find(this.checkedList, { id: item.id })) {
          return false;
        } else if (_.get(item, 'link_share_id') > 0) {
          return false;
        }
        return true;
      }).map(item => {
        item.children = [];
        item.key = item.sid;
        item.title = item.name;
        return item;
      });
      this.nodes[0].addChildren(list);
    }); */
  }

  onHandleClose() {
    this.nodes[0].clearChildren();
  }

  mouseAction(e: NzFormatEmitEvent): void {
    // @ts-ignore
    e.node.clearChildren();
    // @ts-ignore
    if (e.node.isExpanded) {
      /* this.coursewareFolderService.get(e.node.origin.sid).then((data) => {
        const { folders } = data;
        const list = folders.filter(item => {
          if (!!_.find(this.checkedList, { id: item.id })) {
            return false;
          } else if (_.get(item, 'link_share_id') > 0) {
            return false;
          }
          return true;
        }).map(item => {
          item.children = [];
          item.key = item.sid;
          item.title = item.name;
          return item;
        });
        e.node.addChildren(list);
      });*/
    }
  }
  onNodeClick(e: any) {
    e.node.isSelected = true;
    this.folderName = e.node.origin.name;
    this.targetFolder = e.node.origin;
  }
  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.target.emit(this.targetFolder);
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  openFolder = (node: any) => {

  }
}
