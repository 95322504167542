import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TabletService } from '../../../../../../services/tablet.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-pad-active-manage-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class TabletManageIndexComponent implements OnInit {
  tableData: any[] = [];
  public tableHeight: any;
  loadingData = true;
  pageIndex = 1;
  total = 0;
  pageSize = 50;
  macSearchValue = '';
  phoneSearchValue = '';
  snSearchValue = '';
  snSearchVisible = false;
  phoneSearchVisible = false;
  macSearchVisible = false;
  private currentParams: any = {};
  addContractModalVisible = false;
  isAddContractLoading = false;
  rowDataHolder!: any;
  selectContract: any = null;
  contractGroup = [
    {type: 'trial1', days: 1},
    {type: 'trial3', days: 3},
    {type: 'trial7', days: 7},
    {type: 'trial30', days: 31},
    {type: 'months1', days: 31},
    {type: 'months3', days: 93},
    {type: 'months6', days: 186},
    {type: 'years1', days: 372},
    {type: 'years2', days: 742},
    {type: 'years3', days: 1116},
    {type: 'years4', days: 1488},
    {type: 'years5', days: 1860},
    {type: 'years6', days: 2232},
    {type: 'years7', days: 2604},
    {type: 'years8', days: 2976},
    {type: 'years9', days: 3348},
    {type: 'years10', days: 3720},
    {type: 'years11', days: 4092},
    {type: 'years12', days: 4464},
  ];
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private tabletService: TabletService,
              private modalService: NzModalService,
              private nzMessageService: NzMessageService) {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.tableHeight = window.innerHeight - 336;
  }
  ngOnInit() {
    this.tableHeight = window.innerHeight - 336;
    // this.loadData({pageIndex: this.pageIndex});
  }
  loadData(query: {pageIndex: number, pageSize?: number, mac?: string}) {
    if (!query.pageSize) {
      query.pageSize = this.pageSize;
    }
    this.loadingData = true;
    this.tabletService.list(query).then((res: any) => {
      console.log(res);
      this.tableData = res.rows;
      this.total = res.total;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.data);
      this.loadingData = false;
    });
  }
  resetByMac(data: any) {
    this.loadingData = true;
    this.tabletService.reset(data.mac).then((res: any) => {
      if (this.currentParams) {
        this.loadData(this.currentParams);
      } else {
        this.loadData({pageIndex: 1});
      }
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      this.loadingData = false;
    });
  }
  addContract(data: any) {
    this.loadingData = true;
    this.tabletService.addContract(data).then((res: any) => {
      this.tableData = res.rows;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.message);
      this.loadingData = false;
    });
  }
  onQueryParamsChange(params: any): void {
    console.log(params);
    this.currentParams = params;
    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort && sort.find((item: any) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    this.loadData(this.currentParams);
  }
  searchAction() {
    // this.currentParams['mac'] = this.macSearchValue;
    // this.currentParams['mac'] = this.macSearchValue;
    // this.currentParams['mac'] = this.macSearchValue;
    this.onQueryParamsChange({
      page: 1,
      mac: this.macSearchValue,
      phone: this.phoneSearchValue,
      sn: this.snSearchValue,
    });
  }
  searchMacAction() {
    this.searchAction();
    this.macSearchVisible = false;
    this.loadingData = true;
  }
  searchSNAction() {
    this.searchAction();
    this.snSearchVisible = false;
    this.loadingData = true;
  }
  searchPhoneAction() {
    this.searchAction();
    this.phoneSearchVisible = false;
    this.loadingData = true;
  }

  resetSearchMacAction() {
    this.macSearchValue = '';
    delete this.currentParams['mac'];
    this.onQueryParamsChange(this.currentParams);
  }
  resetSearchPhoneAction() {
    this.phoneSearchValue = '';
    delete this.currentParams['phone'];
    this.onQueryParamsChange(this.currentParams);
  }
  resetSearchSNAction() {
    this.snSearchValue = '';
    delete this.currentParams['sn'];
    this.onQueryParamsChange(this.currentParams);
  }

  syncInfo(data: any) {
    this.tabletService.sync(data.mac).then((res: any) => {
      if (this.currentParams) {
        this.loadData(this.currentParams);
      } else {
        this.loadData({pageIndex: 1});
      }
    }).catch((err: any) => {
      this.nzMessageService.error(err.data);
      this.loadingData = false;
    });

  }
  showOtherInfo(data: any) {
    let content  = '<p>无</p>';
    if (data && data.info) {
      if (typeof data.info === 'string') {
        data.info = JSON.parse(data.info);
      }
      const c: string[] = [];
      const ks = Object.keys(data.info);
      ks.forEach(k => {
        c.push(`<p>${k}: ${data.info[k]}</p>`);
      });
      content = c.join('');
    }
    this.modalService.create({
      nzTitle: '设备信息',
      nzContent: content,
      nzWidth: '650px',
      // nzComponentParams: data,
      // nzMaskClosable: false,
      // nzClosable: false,
      nzClassName: 'tablet-info-modal',
      nzFooter: null
      // nzFooter: [
      //   {
      //     label: '关闭',
      //     type: 'default',
      //     loading: false,
      //     onClick(): void {
      //
      //       modal.destroy();
      //     }
      //   }
      // ]
    });
  }
  showAddContractModal(data: any) {
    // if (!data.phone) {
    //   this.nzMessageService.error('该平板尚未激活，无法续费');
    //   return;
    // }
    this.addContractModalVisible = true;
    this.rowDataHolder = data;


  }
  handleCancelAddContract() {
    this.addContractModalVisible = false;
  }
  handleAddContract() {
    this.isAddContractLoading = true;
    console.log(this.rowDataHolder, this.selectContract);
    this.tabletService.addContract({
      id: this.rowDataHolder.id,
      phone: this.rowDataHolder.phone,
      mac: this.rowDataHolder.mac,
      contract: this.selectContract}).then((res) => {
      setTimeout(() => {
        this.isAddContractLoading = false;
        this.addContractModalVisible = false;
        if (this.currentParams) {
          this.loadData(this.currentParams);
        } else {
          this.loadData({pageIndex: 1});
        }
      }, 3 * 1000);
    }).catch((err: any) => {
      this.isAddContractLoading = false;
      this.nzMessageService.error(err.data || err.message);
    });
  }

  // onCurrentPageDataChange(evt: any) {
  //   console.log(evt);
  // }
}
