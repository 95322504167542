<div class="table-operations" *ngIf="!teachingbook">
    <nz-card nzHoverable class="card" [nzCover]="coverTemplate">
        <button nz-button nzType="primary" (click)="goCreate()">制作教材</button>

        <p style="text-align: left; margin-top: 20px;">
        1、教材包含多个单元，每个单元下有多个阅读内容。制作时按照教材目录添加单元，再添加相应的阅读内容到单元下。
        </p>
        <p style="text-align: left;">
        2、如果教材是一本绘本或者一个故事，也可以进行制作，方法详见制作教材中的视频介绍。
        </p>
    </nz-card>
    <ng-template #coverTemplate>
        <img alt="example" style="margin: 3%; width: 94%;" src="//hw-cdn.ireadabc.com/web/assets/imgs/teaching-cover.png" />
    </ng-template>

    <nz-card nzHoverable class="card" [nzCover]="coverListening" style="margin-left: 20px;">
        <button nz-button nzType="primary" (click)="goResource('audio')">上传音频</button>

        <p style="text-align: left; margin-top: 20px;">
        目前只支持不超过100M的MP3格式的音频
        </p>
    </nz-card>
    <ng-template #coverListening>
        <img alt="example" style="margin: 3%; width: 94%;" src="//hw-cdn.ireadabc.com/web/assets/imgs/listening-cover.png" />
    </ng-template>

    <nz-card nzHoverable class="card" [nzCover]="coverWatching" style="margin-left: 20px;">
        <button nz-button nzType="primary" (click)="goResource('video')">上传视频</button>

        <p style="text-align: left; margin-top: 20px;">
目前只支持MP4格式的视频
        </p>
    </nz-card>
    <ng-template #coverWatching>
        <img alt="example" style="margin: 3%; width: 94%;" src="//hw-cdn.ireadabc.com/web/assets/imgs/watching-cover.png" />
    </ng-template>



    <nz-card nzHoverable class="card" [nzCover]="coverSchoolTesting" style="margin-left: 20px;">
        <button nz-button nzType="primary" (click)="goSchoolTesting()">自制习题</button>

        <p style="text-align: left; margin-top: 20px;">
            自定义习题
        </p>
    </nz-card>
    <ng-template #coverSchoolTesting>
        <img alt="example" style="margin: 3%; width: 94%;" src="//hw-cdn.ireadabc.com/web/assets/imgs/school-testing-cover.png" />
    </ng-template>


<!--    <nz-card nzHoverable class="card" [nzCover]="coverWatching" style="margin-left: 20px;">-->

<!--        <a [routerLink]="['/admin/qupeiyin/index']" >-->
<!--            <button nz-button nzType="primary">趣配音</button>-->
<!--        </a>-->

<!--        <p style="text-align: left; margin-top: 20px;">-->
<!--            趣配音-->
<!--        </p>-->
<!--    </nz-card>-->
<!--    <ng-template #coverWatching>-->
<!--        <img alt="example" style="margin: 3%; width: 94%;" src="../../../../assets/watching-cover.png" />-->
<!--    </ng-template>-->

</div>
<!-- <iframe *ngIf="teachingbook" [src]="teachingbook" frameborder="0" [ngStyle]="{'width': '100%', 'height': winHeight+'px'}"></iframe> -->

