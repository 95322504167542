import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, Params, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { PermissionService } from '../../services/permission.service';

interface IBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  tabs: any[];
  breadcrumb = ['首页'];
  isCollapsed = true;
  triggerTemplate = null;
  user = null;
  // @ts-ignore
  breadcrumbs: IBreadcrumb[];
  constructor( private userService: UserService,
               private router: Router,
               private activatedRoute: ActivatedRoute ,
               private permission: PermissionService) {
    this.user = userService.getUserInfo();
    const cfg: any = this.router.config;
    // @ts-ignore
    const admin_router_cfg = cfg.find(item => {
      if (item.path === 'admin') {
        return item.children;
      }
    });
    this.tabs = this.getMenu(admin_router_cfg);
    // console.log(this.tabs);
    router.events.subscribe((val) => {

      if (val instanceof NavigationError) {
        return;
      }

      // TODO check token expire and role
      if (val instanceof NavigationEnd) {

        let url = val.urlAfterRedirects.replace('/admin/', '');
        url = url.replace(/\/\d+/g, '');
        url = url.replace(/\?.*$/g, '');

        // console.log('url2: ', url);

        const parts = url.split('/');
        // console.log('parts: ', parts);

        const nameArr = [];
        let tmpArr = this.tabs;
        for (let i = 0; i < parts.length; i++) {
          if (!tmpArr) {
            continue;
          }
          for (let j = 0; j < tmpArr.length; j++) {
            let path = tmpArr[j]['path'];
            path = path.replace(/^:.*\//g, '');
            path = path.replace(/\/:.*$/g, '');

            // console.log('path:', path);

            if (parts && path === parts[i] && tmpArr[j].name) {
              nameArr.push(tmpArr[j].name);
              tmpArr = tmpArr[j].children;
              break;
            }

          }
        }
        // console.log(nameArr);
        this.breadcrumb = nameArr;


        // const str = ':classid/aaa';
        // const str2 = 'bbb/:classid';
        //
        // let newStr = str2.replace(/^:.*\//g, '');
        // newStr = str2.replace(/\/:.*$/g, '');
        // // console.log('str:', newStr);
        //
        //
        // let root: ActivatedRoute = this.activatedRoute.root;
        // this.breadcrumbs = this.getBreadcrumbs(root);
        // console.log('this.breadcrumbs:', this.breadcrumbs);
        //
        //
        // // const url = val.urlAfterRedirects.replace('/admin/', '');
        // // const parts = url.split('/');
        // // parts.unshift('admin')
        // let lvl = 1;
        // let p: any;
        // p = this.router.config;
        // console.log('---p--- :', p);
        // const breadcrumb = [];
        // for (const part of parts) {
        //   if ( p && p.constructor.name === 'Array' ) {
        //     // console.log(p)
        //     p = p.find(item => {
        //       if (item.redirectTo) {
        //         return false;
        //       }
        //
        //       /* return item.url.length === lvl && item.url[lvl - 1] === part; */
        //       // const rp = item.path.replace(/\/:[^/]+/, '')
        //       // console.log(item.path, item.name, item.children ? item.children.length : 0 );
        //       // console.log('getItem, item:', item);
        //
        //       const cp = this.getItemPaths(item, item['name']);
        //       // console.log(11111111, cp, url)
        //       for (const c of cp) {
        //         // console.log(22, c );
        //         const rps = c.path.split('/'); // item.path.split('/');
        //         for (const rp of rps) {
        //           // console.log(33, rp);
        //           // is param
        //           const param_cond = rp.startsWith(':');
        //           // console.log(99, rp, part, param_cond, '|', item.path);
        //           if (rp === part /*|| param_cond*/) {
        //             if (c.name) {
        //               breadcrumb.push(c.name);
        //             }
        //             return true;
        //           }
        //         }
        //       }
        //
        //
        //       return false;
        //     });
        //   }
        //   if (p && p.children && p.children.length) {
        //     p = p.children;
        //   }
        //   lvl += 1;
        // }
        // if (p) {
        //   this.breadcrumb = breadcrumb;
        // }


      }
    });

  }



  ngOnInit() {

  }
  sideEvent(isCol: boolean) {
    this.isCollapsed = isCol;
  }
  shouldHighlight(item: any): boolean {
    // console.log('shouldHighlight',item.url, this.router.isActive(item.url, false))
    if (item.redirectTo) {
      return false;
    }
    return  this.router.isActive(item.url, false);
    const cs = this.router.url.split('/');
    cs.pop();
    const c = cs.join('/');

    const us = item.url.split('/');
    us.pop();
    const u = us.join('/');

    // const c = this.router.url.replace('/index', '')
    // const u = item.url.replace('/index', '')
    return us.length > 2 && c.startsWith(u);
    // return this.router.isActive(this.router.url, false);
  }
  isActive(item: any): boolean {
    // return this.router.url.startsWith(item.url);
    // const paths = item.url;
    // console.log(item.name,item.url,this.router.isActive('/admin/' + item.url, false))
    // const pu = [];
    /*for (const path of paths) {
      pu.push(path);
      const p = this.tabs.find(tab => {
        return tab.url.join('/') === pu.join('/');
      });
      // if ( p.children.length > 0) {
      //   pu.push('classes');
      // }
    }*/
    // const parts = this.router.url.split('/');
    // const _tmp = [];
    // for (const part of paths) {
    //
    // }
    // console.log(123, this.router.url,  item.url, this.router.isActive(this.router.url, false))
    return this.router.isActive(this.router.url, false);
    // return this.router.isActive('/admin/' + item.url, false);
  }
  filterMenu(node: any) {
    return this.permission.filterMenu(node);
  }
  checkMenuVisiblePermission(node: { name: any; hidden: any; }) {

    return node.name && !node.hidden;
  }
  getItemPaths(item: { [x: string]: any; path: any; children: any; }, parent_name: any): any {
    const pn = item.path;
    const _t = {name: item['name'] ? item['name'] : parent_name, path: pn};
    const p = [_t];
    if (item.children) {
      for (const c of item.children) {
        const _p = this.getItemPaths(c, item['name']);
        for (const r of _p) {
          p.push(r);
        }
      }
    }
    return p;

  }
  getMenu(cfg: any) {
    const rs = [];
    for (const child of cfg.children) {
      if (this.checkMenuVisiblePermission(child)) {
        // console.log(1, child)
        if (child.data && child.data.role) {
          // @ts-ignore
          if (!child.data.role.includes(this.user.type)) {
            continue;
          }
        }
        if (child.data && child.data.type) {
          // @ts-ignore
          if (!child.data.type.includes(this.user.school_type)) {
            continue;
          }
        }
        if (child.children && child.children.length) {
          rs.push(this.getMenuItem(child, [], []));
        } else {
          if (!child.tree) {
            child.tree = [];
          }
          child.url = '/' + [cfg.path, child.path].join('/');
          rs.push(child);
        }

      }
    }
    return rs;
  }
  getMenuItem(item: any, path: any[], breadcrumb: any[]) {
    const _breadcrumb = breadcrumb.concat([]);
    const _path = path.concat([]);
    const displayName = item.name || item.data?.name;
    if(! displayName) {
      console.warn('cannot found display name', item);
      return item;
    }
    _breadcrumb.push(item.name);
    _path.push(item.path);
    const children = [];
    if (item.children && item.children.length) {
      let _tmp_children_count = 0;
      for (const c of item.children) {

        if (this.checkMenuVisiblePermission(c)) {
          _tmp_children_count += 1;
          children.push(this.getMenuItem(c, _path, _breadcrumb));
        }
      }
      if (_tmp_children_count === 0) {
        _path.push('index');
      }
    }
    item.breadcrumb = _breadcrumb;
    item.url = `/admin/${_path.join('/')}`;
    item.tree = children;
    return item;
    // return {
    //   name: item.name,
    //   url: _path,
    //   breadcrumb: _breadcrumb,
    //   icon: item.icon || '',
    //   children: children
    // };
  }
}
