<div class="table-operations">
    <button nz-button nzType="primary" [nzSize]="'large'"
            [routerLink]="'/admin/promotions/index'"
    >
        <!--(click)="goBack()"-->
        <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
</div>
<form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>活动名称</nz-form-label>
        <nz-form-control [nzSpan]="12" nzHasFeedback [nzErrorTip]="titleErrorTpl">
            <input nz-input formControlName="title"/>
            <ng-template #titleErrorTpl let-control>
                <ng-container *ngIf="control?.hasError('required')">
                    Please input title!
                </ng-container>
            </ng-template>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>描述</nz-form-label>
        <nz-form-control [nzSpan]="12" nzErrorTip="请填写活动描述!">
            <textarea formControlName="desc" nz-input rows="2" placeholder="活动描述"></textarea>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>时间范围</nz-form-label>
        <nz-form-control [nzSpan]="12">
            <nz-range-picker nzShowTime formControlName="rangePickerTime"></nz-range-picker>
        </nz-form-control>
    </nz-form-item>



    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>学校类型</nz-form-label>
        <nz-form-control [nzSpan]="12">
            <nz-radio-group formControlName="school_type">
                <label nz-radio nzValue="0">全部</label>
                <label nz-radio nzValue="1">直营校</label>
                <label nz-radio nzValue="2">加盟校</label>
                <label nz-radio nzValue="3">按量付费校</label>
            </nz-radio-group>
        </nz-form-control>
    </nz-form-item>



    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>活动价格</nz-form-label>
        <nz-form-control [nzSpan]="12"
                         nzExtra="活动期间的销售价格"
                         nzErrorTip="0-999范围内">
            <nz-input-number formControlName="promo_price" [maxlength]="3"  [nzMin]="0" [nzMax]="999" [nzStep]="0.01"></nz-input-number> 元
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>数量赠送</nz-form-label>
        <nz-form-control [nzSpan]="12"
                         nzExtra="基于购买数量的赠送，例如设置购买50赠送10，则用户每购买50个则获得10，购买50共获得60个帐号"
                         >
        <!--<nz-input-number-->
                <!--nz-col-->
                <!--[nzSpan]="5"-->
                <!--formControlName="quantity_rate"-->
                <!--[maxlength]="2"  [nzMin]="0" [nzMax]="999"-->
                <!--[nzStep]="1"></nz-input-number>-->
            <!--<label nz-checkbox [(ngModel)]="checked">使用名额赠送</label>-->
            <div nz-row>
                <nz-form-item>
                    <nz-form-label nz-col [nzSpan]="4" nzRequired>基于</nz-form-label>
                    <nz-form-control nz-col [nzSpan]="8"
                                     nzErrorTip="0-999范围内">
                        <nz-input-number formControlName="from_quantity"
                                         [maxlength]="3"  [nzMin]="0"
                                         [nzMax]="999" [nzStep]="1"></nz-input-number>个
                    </nz-form-control>
                    <nz-form-label nz-col [nzSpan]="4" nzRequired>赠送</nz-form-label>
                    <nz-form-control nz-col [nzSpan]="8"
                                     nzErrorTip="0-999范围内">
                        <nz-input-number formControlName="gift_quantity"
                                         [maxlength]="3"  [nzMin]="0"
                                         [nzMax]="999" [nzStep]="1"></nz-input-number>个
                    </nz-form-control>
                </nz-form-item>
            </div>
        </nz-form-control>

    </nz-form-item>

    <nz-form-item>
        <nz-form-label [nzSpan]="7" nzRequired>使用天数</nz-form-label>
        <nz-form-control [nzSpan]="6" nzErrorTip="使用数字">
            <nz-input-number formControlName="days" [nzMax]="999" [nzMin]="1" [maxlength]="3" [nzStep]="1"></nz-input-number>
        </nz-form-control>
    </nz-form-item>
    <nz-form-item>
        <nz-form-control [nzOffset]="7" [nzSpan]="12">
            <button [nzLoading]="submiting" nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
        </nz-form-control>
    </nz-form-item>
</form>
