
<div>
    <button nz-button nzType="primary"
            [queryParams]="{ page: returnPage }"
            [routerLink]="'/admin/gifts/index'"
            [nzSize]="'large'">
        <i nz-icon nzType="left" nzTheme="outline"></i>返回列表</button>
    <p></p>
    <p></p>
    <form nz-form

            [nzLayout]="'horizontal'"
            [formGroup]="giftForm"
            (ngSubmit)="submitForm()" >
        <nz-form-item style="margin: 0">
            <nz-form-label [nzSpan]="4">奖品图片</nz-form-label>
            <nz-form-control [nzSpan]=" 14" nzErrorTip="coverErrorTpl">
                <app-upload-image-with-preview
                        [picPreview]="true"
                        style="width: 100px;display: inline-block;"
                        [displayText]="null"
                        [picUrl]="previewImgUrl"
                        (imageUploaded)="onImageUploaded($event)"
                        displayIcon="plus"
                        displayRatioWidth="1"
                        displayRatioHeight="1"></app-upload-image-with-preview>
                <div class="ant-form-explain" *ngIf="giftForm.controls['cover']?.errors && giftForm.controls['cover']?.errors?.required">
                    <div style="color: #f5222d;" class="ng-trigger ng-trigger-helpMotion">
                        请设置奖品图片!
                    </div>
                </div>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="4">奖品名称</nz-form-label>
            <nz-form-control [nzSpan]="14" nzErrorTip="请正确设置奖品名称!">
                <input nz-input id="name" formControlName="name" />
            </nz-form-control>
        </nz-form-item>

        <nz-form-item>
            <nz-form-label [nzSpan]="4">所需金币</nz-form-label>
            <nz-form-control [nzSpan]="14"  nzErrorTip="请正确设置所需金币!">
                <nz-input-number id="price" formControlName="price" [nzMin]="1" [nzMax]="9999" [nzStep]="1"></nz-input-number>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="4">库存数量</nz-form-label>
            <nz-form-control [nzSpan]="20"  nzErrorTip="请正确设置库存数量!" >
                <nz-input-number id="amount" formControlName="amount" [nzMin]="-1" [nzMax]="9999" [nzStep]="1"></nz-input-number>
                <div class="tip">(数值<em> -1 </em>为库存无限制)</div>
            </nz-form-control>

        </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzSpan]="12" [nzOffset]="5">
                <button [nzLoading]="saving" nz-button nzType="primary">Submit</button>
            </nz-form-control>
        </nz-form-item>
    </form>
    <div style="color: #666;">
        备注：学生通过学习，每天大概可获得10个金币(作业按照1个绘本、一个听音、一个动画片、一个习题练习的作业量估计，每天可获得大概10个金币)
    </div>
</div>
