<div id="header">
  <div id="logo"></div>
  <div class="page-switch">
    <ng-container *ngIf="userRole === CUserRoleType.TEACHER">
    <span class="switch-class" [ngClass]="{'active': viewType === 'classes'}" (click)="selectType($event, 'classes')">班级</span>
    </ng-container>
    <span class="switch-storybook" [ngClass]="{'active': viewType === 'books'}" (click)="selectType($event, 'books')">绘本</span>
    <ng-container *ngIf="userRole === CUserRoleType.TEACHER || userRole === CUserRoleType.SCHOOL_ADMIN || userRole === CUserRoleType.SUPER_ADMIN">
      <span class="admin" [routerLink]="'/admin/classes/index'">管理</span>
    </ng-container>
  </div>
  <div id="user-box">
    <div class="user-info">
      <div class="user-name">{{userName}}</div>
      <div class="user-type">{{userType}}</div>
    </div>
    <div [nzPlacement]="'bottomRight'" nz-dropdown [nzDropdownMenu]="menu" >
      <nz-dropdown-menu class="user-avatar"   #menu="nzDropdownMenu">

        <nz-avatar nzIcon="user" *ngIf="userRole === CUserRoleType.TEACHER" nzSrc="../../../../assets/teacher.png"></nz-avatar>
        <nz-avatar nzIcon="user" *ngIf="userRole === CUserRoleType.STUDENT" nzSrc="../../../../assets/student.png"></nz-avatar>
        <nz-avatar nzText="管" *ngIf="userRole === CUserRoleType.SCHOOL_ADMIN || userRole === CUserRoleType.SUPER_ADMIN " style="color:#f56a00; background-color:#fde3cf;"></nz-avatar>
      </nz-dropdown-menu>
      <ul nz-menu>
        <li nz-menu-item (click)="logout()">
          <a>logout</a>
        </li>
      </ul>
    </div>
  </div>
</div>
