// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SchoolService } from '../../../../../services/school.service';
import { ClassService } from '../../../../../services/class.service';
import { UserType } from '../../../../../model/Const';


@Component({
  selector: 'app-class-statistics-quarter',
  templateUrl: './statistics-quarter.component.html',
  styleUrls: ['./statistics-quarter.component.scss']
})
export class ClassStatisticsQuarterComponent implements OnInit {
  selectedValue = 'listen';

  selectedValueMap = {
    'listen': '听音',
    'animation': '动画',
    'grading': '分级',
    'read': '课外',
    'dubbing': '配音',
    'cam_show': '视频秀'
  };

  selectedYearValue = new Date('2018');
  selectedQuarterValue = '1';
  selectedQuarterValueMap = {
    '1': {start: '01-21', end: '04-20'},
    '2': {start: '04-21', end: '07-20'},
    '3': {start: '07-21', end: '10-20'},
    '4': {start: '10-21', end: '01-20'},
  };

  displayData: {
    name: any,
    col0: any,
    col1: any,
    col2: any,
    total: any,
  }[] = [];
  totalDataCount = 0;
  currentPageIndex = 1;
  user = null;
  displayWeeks = [0, 1, 2, 3];
  constUserType = UserType;
  classId = 0;


  dateRange: any[] = [];


  loading = true;
  sortValue = null;
  sortKey = null;
  showItem = '';
  constructor(private userService: UserService,
              private clsService: ClassService,
              private schoolService: SchoolService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    // @ts-ignore
    this.showItem = this.selectedValueMap[this.selectedValue];
    this.user = this.userService.getUserInfo();
    this.activatedRoute.params.subscribe( params => {
      if (params['class_id'] && parseInt(params['class_id'], 10) === params['class_id'] * 1) {
        this.classId = parseInt(params['class_id'], 10);
      }
      const curr = new Date();
      const curr_days = this.daysOfYear(curr);
      let y = curr.getFullYear();


      const a1 = new Date(`${y}-01-21 00:00:00`);
      const b1 = new Date(`${y}-04-21 00:00:00`);
      const c1 = new Date(`${y}-07-21 00:00:00`);
      const d1 = new Date(`${y}-10-21 00:00:00`);

      const a2 = new Date(`${y}-04-21 00:00:00`);
      const b2 = new Date(`${y}-07-21 00:00:00`);
      const c2 = new Date(`${y}-10-21 00:00:00`);
      const d2 = new Date(`${y + 1}-01-21 00:00:00`);
      const e2 = new Date(`${y}-01-21 00:00:00`);
      const e1 = new Date(`${y - 1}-10-21 00:00:00`);
      // if (curr < d2) {
      //   // y -= 1;
      //   // d1 = new Date(`${y}-10-21`);
      //   d2 = new Date(`${y}-01-20`);
      // }
      if (curr < e2 && curr >= e1) {
        y -= 1;
        this.selectedQuarterValue = '4';
      } else if (curr < a2 && curr >= a1 ) {
        this.selectedQuarterValue = '1';
      } else if (curr < b2 && curr >= b1) {
        this.selectedQuarterValue = '2';
      } else if (curr < c2 && curr >= c1) {
        this.selectedQuarterValue = '3';
      } else if (curr < d2 && curr >= d1) {
        this.selectedQuarterValue = '4';
      }
      this.selectedYearValue = new Date(y + '');
    });
    // this.dateRange = [
    //   // `${this.formatDate()} 00:00:00`,
    //   // `${this.formatDate()} 23:59:59`
    //   new Date(),
    //   new Date()
    // ];
  }
  daysOfYear(date: Date) {
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }
  formatDate (d?: any): string {
    if (typeof d === 'string') {
      d = new Date(d);
    } else if (typeof d === 'object') {
    } else {
      d = new Date();
    }
    // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;
    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  }



  ngOnInit() {
    this.loading = true;
    this.loadData(1);

  }
  loadData(page: string|number) {
    // @ts-ignore
    this.showItem = this.selectedValueMap[this.selectedValue];
    // const start = `${this.formatDate(this.dateRange[0])}` ;
    // const end = `${this.formatDate(this.dateRange[1])}`;
    // console.log(1111)
    const query = {};
    /*let range = this.selectedQuarterValueMap[this.selectedQuarterValue];
    if (this.selectedQuarterValue === '4') {
      const curr = new Date();
      let y = curr.getFullYear()
      range = {
        start: `${y}-${range.start}`,
        end: `${y + 1}-${range.end}`
      };
    }
    query['start'] = range.start;*/
    // @ts-ignore
    query['quarter'] = this.selectedQuarterValue;
    // @ts-ignore
    query['show'] = this.selectedValue;
    // @ts-ignore
    query['year'] = new Date(this.selectedYearValue).getFullYear();

    this.clsService.fetchClassQuarterStatistics(this.classId, page, query).then(students => {
      this.displayData = students.rows || [];
      this.totalDataCount = students.total || 0;
      this.loading = false;
      // @ts-ignore
      this.currentPageIndex = page;
    });
  }

  filterRangeData() {
    // console.log('filterRangeData', this.dateRange);
    this.loadData(1);
  }
  sort(sort: any): void {
    // @ts-ignore
    this.sortKey = sort.key;
    // @ts-ignore
    this.sortValue = sort.value;
    this.loadData(1);
  }
  /*
  onRangeChange(evt) {
    // console.log('onRangeChange 1',  this.dateRange);
    // console.log('onRangeChange 2', evt );
    // this.dateRange = evt;
  }

  setFilterRange(type) {
    const curr = new Date();
    const day = curr.getDay();
    let firstDay = null;
    let lastDay = null;
    if (type === 0) {
      // firstDay = new Date(curr.getTime() - 60 * 60 * 24 * (day - 1 ) * 1000); // will return firstday (i.e. Sunday) of the week
      // lastDay = new Date(curr.getTime() + 60 * 60 * 24 * 5 * 1000);
      firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
      lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
    } else if (type === 1) {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth();
      firstDay = new Date(y, m, 1);
      lastDay = new Date(y, m + 1, 0);
    } else if (type === 2) {
      const date = new Date(), y = date.getFullYear(), m = date.getMonth();
      const quarter = Math.floor((m / 3));
      firstDay = new Date(y, quarter * 3, 1);
      lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 3, 0);
    }

    this.dateRange = [firstDay, lastDay];
    this.filterRangeData();
  }



  weekRangeChanged(evt) {
    const {start, end} = evt;
    this.dateRange = [start, end];
    const diffDays = Math.round(Math.abs((start.getTime() - end.getTime()) / (86400000))) + 1;
    this.displayWeeks = Array.from({length: diffDays / 7}, (_, i) => i);
    this.loadData(1);
  }*/
  onYearChange (evt: any) {

  }
}
