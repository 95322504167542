import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class PadService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  fetchAllVideo(categoryId: string|number) {
    return super.get(`/pad-video/category/${categoryId}`);
  }
  fetchAllVideoCategories(group: string|number) {
    return super.get(`/pad-video/categories?group=${group}`);
  }

  addVideoCategory(data: any) {
    return super.post(`/pad-video/add/category`, data);
  }
  removeVideoCategory(id: string|number) {
    return super.post(`/pad-video/remove/category`, {id});
  }
  addVideo(data: any) {
    return super.post(`/pad-video/category/add/video`, data);
  }
  removeVideo(id: string|number) {
    return super.post(`/pad-video/category/delete/video`, {id});
  }

  sortCategories(data: any) {
    return super.post(`/pad-video/category/resort`, data);
  }
  sortCategoriesTree(data: any) {
    return super.post(`/pad-video/category/resort-tree`, data);
  }
  sortVideo(data: any) {
    return super.post(`/pad-video/category/video/resort`, data);
  }
  sortVideoV2(data: any) {
    return super.post(`/pad-video/category/video/resort/v2`, data);
  }

  updateVideoCategories(data: any) {
    return super.post(`/pad-video/category/update/video`, data);
  }


}
