import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TabletService } from '../../../../../../services/tablet.service';
import { NzModalService } from 'ng-zorro-antd/modal';


@Component({
  selector: 'app-tablet-manage-single-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class TabletManageSingleIndexComponent implements OnInit {
  tableData: any[] = [];
  public tableHeight: any;
  loadingData = true;
  pageIndex = 1;
  total = 0;
  pageSize = 50;
  macSearchValue = '';
  phoneSearchValue = '';
  snSearchValue = '';
  snSearchVisible = false;
  phoneSearchVisible = false;
  macSearchVisible = false;
  private currentParams: any = {};
  setExpiredModalVisible = false;
  isSetExpiredLoading = false;
  rowDataHolder!: any;
  expiredDate: any = null;
  remark: any = null;
  price = 0;
  filters: any[] = [];

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private tabletService: TabletService,
              private modalService: NzModalService,
              private nzMessageService: NzMessageService) {
  }
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.tableHeight = window.innerHeight - 336;
  }
  ngOnInit() {
    // @ts-ignore
    this.tableHeight = window.innerHeight - 336;
    this.loadData({pageIndex: this.pageIndex});
  }
  loadData(query: {pageIndex: number, pageSize?: number, sn?: string, order?: string, way?: string}) {
    if (!query.pageSize) {
      query.pageSize = this.pageSize;
    }
    this.loadingData = true;
    this.tabletService.singleSystemList(query).then((res: any) => {
      console.log(res);
      this.tableData = res.rows;
      this.pageIndex = res.currentPage;
      this.total = res.total;
      this.loadingData = false;
    }).catch((err: any) => {
      this.nzMessageService.error(err.data);
      this.loadingData = false;
    });
  }


  onPageIndexChange(pageIndex: number): void {
    this.currentParams = {
      sn: this.snSearchValue,
      pageIndex,
    } ;

    // const { pageSize, pageIndex, sort, filter } = params;
    // const currentSort = sort && sort.find((item: any) => item.value !== null);
    // const sortField = (currentSort && currentSort.key) || null;
    // const sortOrder = (currentSort && currentSort.value) || null;
    // if (filter) {
    //   filter.forEach((f: { key: string | number; value: any[]; }) => {
    //     if ( f.value[0]) {
    //       this.currentParams[f.key] = f.value[0];
    //     }
    //   });
    // }
    // delete this.currentParams['filter'];
    // console.log(this.currentParams);
    this.loadData(this.currentParams);
  }

  onQueryParamsChange___(params: any): void {
    console.error(params);
    this.currentParams = params;

    const { pageSize, pageIndex, sort, filter } = params;
    const currentSort = sort && sort.find((item: any) => item.value !== null);
    const sortField = (currentSort && currentSort.key) || null;
    const sortOrder = (currentSort && currentSort.value) || null;
    if (filter) {
      filter.forEach((f: { key: string | number; value: any[]; }) => {
        if ( f.value[0]) {
          this.currentParams[f.key] = f.value[0];
        }
      });
    }
    delete this.currentParams['filter'];
    console.log(this.currentParams);
    this.loadData(this.currentParams);
  }
  searchAction() {
    // this.currentParams['mac'] = this.macSearchValue;
    // this.currentParams['mac'] = this.macSearchValue;
    // this.currentParams['mac'] = this.macSearchValue;
    this.onPageIndexChange(1);
  }

  searchSNAction() {
    this.searchAction();
    this.snSearchVisible = false;
    this.loadingData = true;
  }

  resetSearchSNAction() {
    this.snSearchValue = '';
    this.onPageIndexChange(1);
  }

  showSetExpiredModal(data: any) {
    this.setExpiredModalVisible = true;
    this.expiredDate = data.expired_at;
    this.price = 0;
    this.remark = '';
    this.rowDataHolder = data;


  }
  handleCancel() {
    this.setExpiredModalVisible = false;
  }
  handleSure() {

    console.log(this.rowDataHolder, this.expiredDate, this.price, this.remark);
    if (!this.expiredDate) {
      this.nzMessageService.error('请正确填写过期时间');
      return;
    }
    // if (!this.price) {
    //   this.nzMessageService.error('请正确填写金额');
    //   return;
    // }
    this.isSetExpiredLoading = true;
    this.tabletService.setSingleSystemExpired({
      sn: this.rowDataHolder.sn,
      price: this.price,
      remark: this.remark,
      expired_at: this.expiredDate}).then((res) => {
      setTimeout(() => {
        this.isSetExpiredLoading = false;
        this.setExpiredModalVisible = false;
        if (this.currentParams) {
          this.loadData(this.currentParams);
        } else {
          this.loadData({pageIndex: 1});
        }
      }, 1 * 1000);
    }).catch((err: any) => {
      this.isSetExpiredLoading = false;
      this.nzMessageService.error(err.data || err.message);
    });
  }

  // onCurrentPageDataChange(evt: any) {
  //   console.log(evt);
  // }
}
