import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {NoopInterceptor} from './noop-interceptor';
import {TokenInterceptor} from './token-interceptor';
import {LoggingInterceptor} from './logging-interceptor';
import {ErrorInterceptor} from './error-interceptor';
import {TransformInterceptor} from './transform-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: NoopInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: TransformInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
];
