
<div class="gutter-example">
    <div nz-row nzGutter="16">
        <div nz-col class="gutter-row" nzSpan="8">
            <div class="gutter-box">
                <nz-select style="width: 100%;"
                           [compareWith]="compareFn"
                           nzMode="default"
                           [(ngModel)]="selectedSchool"
                           nzPlaceHolder="Select School"
                           nzShowSearch
                           [nzServerSearch]="true"
                           (ngModelChange)="doSelectSchool($event)"
                           (nzOnSearch)="doSearchSchool($event)">
                    <ng-container *ngFor="let o of schoolList">
                        <nz-option *ngIf="!loadingSchool" [nzValue]="o" [nzLabel]="o.school_name+'|'+o.phone"></nz-option>
                    </ng-container>
                    <nz-option *ngIf="loadingSchool" nzDisabled nzCustomContent>
                        <i nz-icon nzType="loading" class="loading-icon"></i> Loading Data...
                    </nz-option>
                </nz-select>
            </div>
        </div>
        <div nz-col class="gutter-row" nzSpan="8">
            <div class="gutter-box">
                <nz-select style="width: 100%;"
                           [(ngModel)]="selectedTeacher"
                           (ngModelChange)="doSelectTeacher($event)"
                           nzPlaceHolder="Select Teacher" nzAllowClear>
                    <nz-option *ngFor="let t of teacherList" [nzValue]="t" [nzLabel]="t.name+'|'+t.phone"></nz-option>
                </nz-select>
            </div>
            <div class="gutter-box">
                <p></p>
                <p><input nz-input [(ngModel)]="newTeacherName" placeholder="Teacher Name" nzSize="small"></p>
            </div>
            <div class="gutter-box">
                <p><input nz-input [(ngModel)]="newTeacherPhone" placeholder="Teacher Phone" nzSize="small"></p>
            </div>
            <div class="gutter-box">
                <p><button nz-button nzType="primary"
                           [disabled]="!selectedSchool"
                           (click)="addTeacher()" nzSize="small">添加教师</button></p>
            </div>
        </div>
        <div nz-col class="gutter-row" nzSpan="8">
            <div class="gutter-box">
                <nz-select style="width: 100%;"
                           [(ngModel)]="selectedClass"
                           nzPlaceHolder="Select Class" nzAllowClear>
                    <nz-option *ngFor="let c of classList" [nzValue]="c" [nzLabel]="c.name"></nz-option>
                </nz-select>
            </div>
            <div class="gutter-box">
                <p></p>
                <p><input nz-input [(ngModel)]="newTeacherClassName" placeholder="Class Name" nzSize="small"></p>
            </div>
            <div class="gutter-box">
                <p><button nz-button nzType="primary"
                           [disabled]="!(selectedSchool && selectedTeacher) "
                           (click)="addTeacherClass()" nzSize="small">添加班级</button></p>
            </div>
        </div>
    </div>
    <p *ngIf="selectedSchool">{{selectedSchool.address}}</p>
</div>
<ng-container *ngIf="selectedClass && selectedTeacher && selectedSchool">
    <div>
        <textarea rows="20" nz-input [(ngModel)]="students" #studentTextarea></textarea>
        <button  nzSize="large" nz-button nzType="primary" (click)="save()" [nzLoading]="isSaving">Save!</button>
    </div>
</ng-container>
<nz-modal [(nzVisible)]="errMsg" nzTitle="错误学生" (nzOnCancel)="closeError()" (nzOnOk)="closeError()">
    <ng-container *nzModalContent>
    <p style="font-size: 1.5rem;color:red;" *ngFor="let l of errlist">{{l}}</p>
    </ng-container>
</nz-modal>
