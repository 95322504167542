import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MediaService } from '../../../../../../services/media.service';


@Component({
  selector: 'app-resources-audio-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class ResourcesAudioAddComponent implements OnInit {
  validateForm: UntypedFormGroup;

  editId = null;
  _currentSelectCount = 0;
  _currentUploadCount = 0;
  _tmpSelectCount = 0;
  _tmpFiles = [];
  displayFilesList = [];
  coverUrl = '';
  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private mSrv: MediaService,
              private activatedRoute: ActivatedRoute ,
              private router: Router ,
              private nzMessageService: NzMessageService) {
    this.validateForm = this.fb.group({
      name: [ '', [ Validators.required ] ],
      cover_id: [null, [ Validators.required ]],
      finishUploading: [false, [ Validators.requiredTrue ]],
    });
    this.activatedRoute.params.subscribe( params => {
      if (params['id'] && parseInt(params['id'], 10) === params['id'] * 1) {
        // @ts-ignore
        this.editId = parseInt(params['id'], 10);
        this.validateForm.controls['finishUploading'].setValue(true);
      }
    });
  }
  ngOnInit() {
    if (this.editId) {
      this.mSrv.fetchAudioById(this.editId).then(td => {
        this.coverUrl = td.cover;
        this.validateForm.controls['name'].setValue(td.name);
        // this.validateForm.controls['birthday'].setValue(td.birthday);
        this.validateForm.controls['cover_id'].setValue(td.cover_id );
        this.displayFilesList = td.content || [];
      });
    }
  }
  getAudiolist() {
    const audioList = this.displayFilesList.map((item: any) => {
      return {id: item.res_id, name: item.name, size: item.size};
    });
    return audioList;
  }
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsDirty();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
    const content = this.getAudiolist();
    if (this.editId) {
      this.mSrv.updateAudioSeries(this.editId, {content, ...value}).then( data => {
        this.router.navigate(['/admin/resources/audio/index']);
      });
    } else {
      this.mSrv.addAudioSeries({content, ...value}).then( data => {
        this.router.navigate(['/admin/resources/audio/index']);
      });
    }
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[ key ].markAsPristine();
      this.validateForm.controls[ key ].updateValueAndValidity();
    }
    console.log(this.displayFilesList);
  }

  handleBeforeUpload = (item: any, fileList: any[]) => {
    // this.uploader.fireUpload(item);
    const has = this._tmpFiles.some(f => {
      // @ts-ignore
      return f.lastModified === item.lastModified && f.name === item.name && f.size === item.size;
    });
    if (!has) {
      // @ts-ignore
      this._tmpFiles.push(item);
    } else {
      return false;
    }
    this._tmpSelectCount++;
    if (fileList.length === this._tmpSelectCount) {
        this.displayFilesList = this._tmpFiles.concat(this.displayFilesList);
        this._tmpFiles.length = 0;
        this._tmpSelectCount = 0;
        // this._currentSelectCount = 0;
        if (this._currentUploadCount === 0) {
          this._currentUploadCount = fileList.length;
        } else {
          this._currentUploadCount += fileList.length;
        }

        this.validateForm.controls['finishUploading'].setValue(false);
    }
    return false;
  }
  listOrderChanged (evt: any) {
    console.log(this.displayFilesList);
  }
  onImageUploadSuccess(evt: any) {
    console.log('onImageUploadSuccess', evt);
    if (evt.id) {
      this.validateForm.controls['cover_id'].setValue(evt.id);
    }
    // cover_id
    console.log('onImageUploadSuccess', evt);
  }
  onAudioUploadSuccess(evt: any) {
    console.log('onAudioUploadSuccess', evt);
    this._currentUploadCount--;
    if (this._currentUploadCount === 0) {
      this.validateForm.controls['finishUploading'].setValue(true);
    }
  }
  onAudioUploadFailure(file: any) {
    const idx = this.displayFilesList.findIndex( f => {
      return f === file;
    });
    // this.displayFilesList.splice(idx, 1);
    if (idx > -1) {
      this.displayFilesList.splice(idx, 1);
      this._currentUploadCount--;
      if (this._currentUploadCount === 0) {
        this.validateForm.controls['finishUploading'].setValue(true);
      }
    }
  }
  removeAudioFile(item: any, evt: any) {
    const idx = this.displayFilesList.findIndex( f => {
      // @ts-ignore
      return f.res_id === item.res_id;
    });
    // this.displayFilesList.splice(idx, 1);
    if (idx > -1) {
      this.displayFilesList.splice(idx, 1);
      // this._currentUploadCount--;
      // if (this._currentUploadCount === 0) {
      //   this.validateForm.controls['finishUploading'].setValue(true);
      // }
    }
  }
  changeTitle(evt: any) {
    // console.log('changeTitle', this.validateForm.get('name').dirty)
    // this.validateForm.get('name').markAsDirty();
    // this.validateForm.get('name').updateValueAndValidity();
    // @ts-ignore
    if (this.validateForm.get('name').dirty && this.editId) {
      // this.validateForm.controls['finishUploading'].setValue(true);
    }

  }
}
