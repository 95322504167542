import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class SystemService extends BaseService {

  constructor(protected override http: HttpClient, protected override router: Router) {
    super(http, router);
  }
  calcUserLottery(user_id: any) {
    return super.post(`/system/calc-lottery`, {
      user_id
    });
  }
  fetchSystemStatistics() {

    return super.get(`/system/statistics`);
  }
  searchUserByPhone(phone: any) {
    return super.post(`/system/search-user`, {phone});
  }
  searchUserSchoolByName(name: any) {
    return super.post(`/system/search-user-by-school`, {name});
  }
  searchSchool(name: any) {
    return this.http.post(`/system/search-school-by-name`, {name});
  }
  updateStudentSchool(school_id: any, users_id: any) {
    return super.post(`/system/update-student-school`, {school_id, users_id});
  }
  loadTeacherAndClasses(school_id: any) {
    return super.post(`/system/fetch-teacher`, {school_id});
  }
  batchAddStudent(school_id: any, teacher_id: any, class_id: any, students: any) {
    return super.post(`/system/batch-add-student`, {school_id, teacher_id, class_id, students});
  }
  addStudents(class_id: any, students: any) {
    return super.post(`/system/batch-add-student`, {class_id, students});
  }


  listAppVersion(page: any) {
    return this.get(`/app/versions/page/${page}`);
  }

  listAppVersionV2(page: any, appIdentity= '') {
    return this.get(`/v2/app/versions/page/${page}?domain=${appIdentity}`);
  }
  updateAppVersionInfo(id: string|number, data: any) {
    return this.post(`/app/version/${id}`, data);
  }
  addAppVersionInfo(data: any) {
    return this.post(`/app/version/new/add`, data);
  }
  deleteAppVersion(id: string|number) {
    return this.remove(`/app/version/${id}`);
  }
}
